import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, FormLabel,
    Select, MenuItem, OutlinedInput, Checkbox, Radio,
    RadioGroup, FormControlLabel, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getPriceCalForList, runPriceCalcForSimulation, getQualifierConfig, getCustomerMasterData, getMaterialMasterData } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 40
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        // height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35,
    },
}));

const PriceSimulationCPQ = props => {
    const classes = useStyles();
    const { className } = props;
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [quantity, setQuantity] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateRangeKey, setPricingDateRangeKey] = React.useState('pricing_date');
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [optionalFieldArray, setOptionalFieldArray] = React.useState([]);
    const [companyCode, setcompanyCode] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [plant, setPlant] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [salesBundlePricing, setSalesBundlePricing] = React.useState(false);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [materialNumberListArray, setMaterialNumberListArray] = React.useState([]);
    const [materialNumberList, setMaterialNumberList] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        setPricingDateStart(Moment.utc(new Date().getMonth() + 1 + "/" + (new Date().getDate()) + "/" + new Date().getFullYear()))
        props.getQualifierConfig(1, 0);
        props.onCheckCustomerTable();
        props.getMaterialMasterData()
    }, []);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = [];
            var tempNameArray = [];
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNumberList(tempArray)
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setMaterialNumberList(tempArray)
            setMaterialNumberListArray(tempNameArray);
        }
    }, [props.materialMasterData])
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push(e.formula_name)
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        var newArray = [];
        if (props.optionalFieldData && props.optionalFieldData.records) {
            props.optionalFieldData.records.map(e => {
                newArray.push(e.qualifier_key)
            })
            setOptionalFieldArray(newArray)
        }
    }, [props.optionalFieldData]);
    function handleCustomerNumber(newValue) {
        //setCustomerNumber([...customerNumber, newValue])
        setCustomerNumber(newValue)
    }

    function handleMaterialNumber(newValue) {
        //setMaterialNumber([...materialNumber, newValue])
        setMaterialNumber(newValue)
    }


    function handleQuantityAdd(newValue) {
        // setQuantity([...quantity, newValue]);
        setQuantity(newValue)
    }
    function handleQuantityDelete(newValue) {
        var deletedQuantity = quantity.filter(item => item !== newValue)
        setQuantity(deletedQuantity)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(Moment(newValue));
    }
    function handleTagsAdd(newValue) {
        // setTags([...tags, newValue]);
        setTags(newValue)
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    const handleCPQCheckbox = (event) => {
        setSalesBundlePricing(event.target.checked);
    }


    const handleClear = () => {
        setCustomerNumber([]);
        setMaterialNumber([]);
        setQuantity([]);
        setPriceCalcFormula('');
        setPricingDateStart(null);
        setSalesOrganization('');
        setcompanyCode('');
        setFlexAttribute1('');
        setFlexAttribute2('');
        setFlexAttribute3('');
        setFlexAttribute4('');
        setDistributionChannel('');
        setDivision('');
        setPlant('');
        setTags([]);
    }
    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleDialog = () => {
        const formData = {
            "customer_number": customerNumber,
            "material_number": materialNumber,
            "pricing_calculation_formula": priceCalcFormula,
            "quantity": parseInt(quantity),
            "pricing_date": Moment.utc(pricingDateStart).local(),//toISOString().split('T')[0].concat('T00:00:00.000Z'),
            "sales_organization": salesOrganization,
            "company_code": companyCode,
            "distribution_channel": distributionChannel,
            "division": division,
            "plant": plant,
            "flex_attribute1": flexAttribute1,
            "flex_attribute2": flexAttribute2,
            "flex_attribute3": flexAttribute3,
            "flex_attribute4": flexAttribute4,
            "tags": tags,
            "calculation_type": 'CPQ',
            "sales_bundle_pricing": salesBundlePricing
        };
        setOpen(false);
        props.onSubmit(formData);
        handleClear();
    }

    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const filterOptionsMaterial = createFilterOptions({
        stringify: (option) => materialNumberListArray[materialNumberList.indexOf(option)] + option
    });

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h2">
                        Price Simulation
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Customer Number
                                </FormLabel>
                                <Autocomplete
                                    multiple
                                    options={customerNumberList}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                    )}
                                    filterOptions={filterOptions}
                                    value={customerNumber}
                                    style={{ marginBottom: 10, marginTop: 10 }}
                                    onChange={(event, newValue) => handleCustomerNumber(newValue)}
                                    renderOption={(props, option) => (
                                        <li {...props}>{customerNameListArray[customerNumberList.indexOf(option)] ? customerNameListArray[customerNumberList.indexOf(option)] : ''} - ({option})  </li>
                                    )}
                                    classes={{
                                        option: {
                                            borderBottom: `1px solid red`,
                                            // Hover
                                            '&[data-focus="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                            // Selected
                                            '&[aria-selected="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                        },
                                        listbox: {
                                            padding: 0,
                                        },
                                        inputRoot: {
                                            borderRadius: '0px !important'
                                        }
                                    }}
                                />
                                {/*<ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomerNumber(chips)}
                                    onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />*/}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Material Number
                                </FormLabel>
                                <Autocomplete
                                    multiple
                                    options={materialNumberList}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" size="small" style={{ height: 20, borderRadius: '0px !important' }} />
                                    )}
                                    filterOptions={filterOptionsMaterial}
                                    value={materialNumber}
                                    style={{ marginBottom: 10, marginTop: 10, height: 32 }}
                                    onChange={(event, newValue) => handleMaterialNumber(newValue)}
                                    renderOption={(props, option) => (
                                        <li {...props}>{materialNumberListArray[materialNumberList.indexOf(option)] ? materialNumberListArray[materialNumberList.indexOf(option)] : ''} - ({option})  </li>
                                    )}
                                    classes={{
                                        option: {
                                            borderBottom: `1px solid red`,
                                            // Hover
                                            '&[data-focus="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                            // Selected
                                            '&[aria-selected="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                        },
                                        listbox: {
                                            padding: 0,
                                        },
                                        inputRoot: {
                                            borderRadius: '0px !important'
                                        }
                                    }}
                                />
                                {/*<ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />*/}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Quantity
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={quantity}
                                    onAdd={(chips) => handleQuantityAdd(chips)}
                                    onDelete={(chip) => handleQuantityDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <div style={{ marginTop: 18, height: '2.9rem' }}>
                                    <ChipInput data={quantity} onChange={handleQuantityAdd} />
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel} required>
                                    Pricing Calculation Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    style={{ marginTop: 17, height: 35 }}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            ><FormLabel required>Pricing Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '3px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 5,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: 35
                                                }
                                            }}
                                            value={pricingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            onChange={handlePricingDateStart}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '-5px' }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                                <ChipInput data={tags} onChange={handleTagsAdd} />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('sales_organization') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Sales Organization
                                </FormLabel>
                                <OutlinedInput
                                    value={salesOrganization}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setSalesOrganization(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('company_code') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Company Code
                                </FormLabel>
                                <OutlinedInput
                                    value={companyCode}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setcompanyCode(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('distribution_channel') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Distribution Channel
                                </FormLabel>
                                <OutlinedInput
                                    value={distributionChannel}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setDistributionChannel(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('division') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Division
                                </FormLabel>
                                <OutlinedInput
                                    value={division}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setDivision(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('plant') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Plant
                                </FormLabel>
                                <OutlinedInput
                                    value={plant}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setPlant(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 1
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute1}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute1(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 2
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute2}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute2(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 3
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute3}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute3(e.target.value)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (optionalFieldArray.includes('flex_attribute4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 4
                                </FormLabel>
                                <OutlinedInput
                                    value={flexAttribute4}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setFlexAttribute4(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                        <Grid container style={{ marginTop: 10 }}>
                            <Grid
                                item
                                md={4}
                                xs={4}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormControlLabel
                                    value="external"
                                    control={<Checkbox color="primary"
                                        onChange={handleCPQCheckbox}
                                        checked={salesBundlePricing}
                                    />}
                                    label="Sales Bundle Pricing"
                                    labelPlacement="right"
                                    style={{ display: 'flex', height: '100%' }}
                                />
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    customerNumber && materialNumber && priceCalcFormula && quantity && pricingDateStart
                                        ? false : true}

                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Price Simulation CPQ?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.pricingData.priceCalForListData,
        priceCalForSimulationData: state.pricingData.priceCalForSimulationData,
        optionalFieldData: state.pricingData.qualifierConfigData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit)),
        onSubmit: (formData) => dispatch(runPriceCalcForSimulation(formData)),
        getQualifierConfig: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PriceSimulationCPQ);