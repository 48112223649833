import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    useMediaQuery,
    TablePagination, Typography, Fab, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getExternalFormula, createExternalFormula, getLblDispDesFieldValue, getExternalFormulaViewDetails } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MaterialTable from 'material-table';
import { tableIcons } from "../../components/Icons/TableIcons";

import { CloudUpload, LibraryAdd, SearchSharp } from '@mui/icons-material';
import { NavLink as RouterLink } from 'react-router-dom';
import Moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
import { HashLoader } from 'react-spinners';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        height: '100%'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        },
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));
const ExternalFormulaResultsMain = props => {
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = React.useState(null);
    const type = history.location.type == 'edit' ? true : false;
    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_quota-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => viewQuota(rowData.ID)}
                        size="large">
                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_document_management-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editQuota(rowData.ID)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_document_management-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => {
                            setSelectedRowData(rowData);
                            setOpenDialog(true);
                        }}
                        size="large">
                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
            </div>
    }

    function editQuota(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/external-formula-results/edit-external/' + id,
            id: id
        });
    }
    function viewQuota(id) {
        props.getExternalFormulaViewDetails(id);
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/external-formula-results/view-external/' + id,
            id: id
        });
    }


    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        if (props.quotaData && props.quotaData.records && props.quotaData.records.length >= 0 && type) {
            setCount(props.quotaData.total_record)
            setDataRows(props.quotaData.records)
            var data = { "data_source": "external_formula_results" };
            props.loadSourceDataType(data, 'post');
        }
    }, [props.quotaData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    // newArray.push({
                    //     field: item.key,
                    //     title: item.current,
                    //     type: item.key != 'formula_result' ? 'string' : 'Number',
                    //     editable: 'never',
                    //     render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                    //         Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    // })

                    const columnDefinition = {
                        field: item.key,
                        title: item.current,
                        type: item.key !== 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                    };
                    if (item.key === 'formula_result') {
                        columnDefinition.render = (rowData) => {
                            if (rowData[item.key]) {
                                const words = rowData.formula_result && parseFloat(rowData.formula_result).toLocaleString();
                                return <div>{words}</div>;
                            }
                            return "";
                        };
                    }
                    else if (item.key === 'start_date' || item.key === 'end_date') {
                        columnDefinition.render = (rowData) => (
                            <div>
                                {Moment.utc(rowData[item.key]).format('MM/DD/YYYY')}
                            </div>
                        );
                    } else {
                        columnDefinition.render = (rowData) => (
                            <div>{rowData[item.key]}</div>
                        );
                    }

                    newArray.push(columnDefinition);
                });
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])
    return (

        <LoadingOverlay
            active={props.loading}
            spinner={<HashLoader />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >   <div className={clsx(classes.root, className)}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary' style={{ margin: 10 }} > External Formula Results</Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_formula-' + sessionStorage.getItem('application')) &&
                            <>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    classes={{ root: classes.fabContainer }}
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/external-formula-results/add-external'
                                >
                                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                </Fab>
                                <Fab aria-label="edit" variant="extended"
                                    size='medium'
                                    className={classes.fabContainer}
                                    component={CustomRouterLink}
                                    to='/external-formula-results/external-formula-results-excel-upload'
                                >
                                    <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                                </Fab>
                            </>
                        }
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            component={CustomRouterLink}
                            to='/external-formula-search'
                        >
                            <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                        </Fab>
                    </div>





                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            emptyRowsWhenPaging: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [
                                15,
                                20,
                                50,
                            ],
                        }}

                    />
                    :
                    <div className={classes.noDataContainer}>
                        Display External Formula by clicking on Search Icon.
                    </div>
                }
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                props.deleteQuotaData(selectedRowData, selectedRowData.ID);
                                setOpenDialog(false);
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </LoadingOverlay>


    );
};
const mapStateToProps = state => {
    return {
        quotaData: state.externalFormulaResultsData.externalFormulaData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        loading: state.addMultipleConfigurationData.loading
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getExternalFormula: (pagination, limit, keyword, data) => dispatch(getExternalFormula(pagination, limit, keyword, data)),
        deleteQuotaData: (data, id) => dispatch(createExternalFormula(data, 'delete', id)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getExternalFormulaViewDetails: (id) => dispatch(getExternalFormulaViewDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalFormulaResultsMain);