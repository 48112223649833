import React, { useEffect } from "react";
import Moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Select, MenuItem, FormLabel, Typography, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { getAnalysisFormulaByName, getAnaylsisFormula, getAttributesConfig, getGrossToNet } from "../../redux/actions";
import MultiSelectDropdown from "../../components/Inputs/MultiSelectDropdown";
import { AllOtherAttributeMasterComponent, ChipDropdown, SearchDropdown, DropdownArray } from "../../components/Inputs";
import BarChart from "../Analytics/Charts/BarChart";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
    },
    select: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white'
    },
    selectedItem: {
        borderRadius: 5
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    spinner: {
        height: '100vh'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
        width: 80,
    },
}));

const MarginByAttribute = (props) => {


    const [simulationType, setSimulationType] = React.useState('');
    const [viewFormat, setViewFormat] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [formulaList, setFormulaList] = React.useState([]);
    const [formula, setFormula] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [filterData, setFilterData] = React.useState({});
    const [grossNetData, setGrossNetData] = React.useState([]);
    const dimension = { yAxisTitle: "Margin Price Percentage" };
    const [attribute, setAttribute] = React.useState("margin_price_percentage");
    const classes = useStyles();
    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    useEffect(() => {
        setStartDate(Moment().subtract(1, 'months').startOf('month'));
        setEndDate(Moment().subtract(1, 'months').endOf('month'));
        setGrossNetData([])
    }, [])

    useEffect(() => {
        props.getConfigData()
        props.getAttributeData()
        setGrossNetData([])
    }, [])

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setFormulaList(props.configData.map(item => item.formula_name));
        }
    }, [props.configData])
    useEffect(() => {
        if (props.attributesData && props.attributesData.length > 0) {
            setAttributeArray(props.attributesData.map(item => item.qualifier_key));
        }
    }, [props.attributesData])
    useEffect(() => {
        let newArray = []
        if (props.grossNetData && props.grossNetData.length > 0) {
            props.grossNetData.map(item => {
                newArray.push({ 'value': item[attributes], 'margin_price_percentage': item.margin_price_percentage })
            })
            var graphData = [
                {
                    data: newArray,
                    xName: "value",
                    yName: "margin_price_percentage",
                    legend: attributes.length > 0 ? attributes.replace(/_/g, " ") : '',
                    type: "Column",
                },
            ];
            setGrossNetData(graphData);
        } else {
            setGrossNetData([]);
        }
    }, [props.grossNetData]);
    const handleFilterData = (value, key) => {
        setFilterData(prevState => ({
            ...prevState,
            [key]: value
        }))
    }
    const handleRun = () => {
        var sd = startDate ? Moment.utc(startDate).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
        var ed = endDate ? Moment.utc(endDate).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
        var data = {
            "formula_name": formula,
            "start_date": sd,
            "end_date": ed,
            "simulation_category": simulationType,
            "attributes": [attributes],
            "filter": filterData
        }
        props.onSubmit(data)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>
                    <Typography color="Primary" variant='h1' style={{ marginLeft: 17, marginTop: 15, marginBottom: 15 }}>Margin by Attribute</Typography>
                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid
                            item
                            md={1}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Formula</Typography>
                                <Select
                                    value={formula}
                                    onChange={(e) => setFormula(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {formulaList && formulaList.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Simulation Category</Typography>
                                <Select
                                    value={simulationType}
                                    onChange={(e) => setSimulationType(e.target.value)}
                                    label='Posting Type'
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}

                                >
                                    <MenuItem value={"Historical - Actual"}>
                                        Historical - Actual
                                    </MenuItem>
                                    <MenuItem value={"Historical - What-If"}>
                                        Historical - What-If
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    //borderRadius: 5,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    //borderRadius: 5,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={endDate}
                                            minDate={startDate ? startDate : false}
                                            onChange={handleEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <Typography classes={{ root: classes.fontSetting }} >Attributes</Typography>
                            <div style={{ marginTop: -20 }}>
                                <DropdownArray
                                    data={attributeArray ? attributeArray : []} placeholder={' '} twoline='true'
                                    onChange={(value => setAttributes(value))}
                                    defaultValue={attributes} capitalize
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <Typography classes={{ root: classes.fontSetting }} >Filters</Typography>
                            <div style={{ marginTop: -25 }}>
                                <MultiSelectDropdown id='filter-list' capitalize={true}
                                    listArray={attributeArray} onChange={(value => setFilters(value))}
                                    data={filters}
                                />
                            </div>
                        </Grid>
                        {filters && filters.length > 0 &&
                            filters.map(item => (
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ paddingRight: 15 }}
                                >
                                    <Typography classes={{ root: classes.fontSetting }} >{item.replace(/_/g, ' ')}</Typography>

                                    {item == 'material_number' || item == 'customer_number' || item == 'supplier_number' || item == 'employee_number' ?

                                        <SearchDropdown id='payment_single' multiple
                                            table={item == 'supplier_number' ? 'supplier_master' : item == 'employee_number' ? 'employee_master'
                                                : item == 'material_number' ? 'material_master' : 'customer_master'}
                                            keyName={item}
                                            description={true}
                                            onChange={(value) => handleFilterData(value, item)}
                                        />
                                        :
                                        item === 'customer_chain' || item === 'customer_group' || item === 'material_group' || item === 'product_hierarchy' || item === 'company_code' || item === 'sales_org' || item === 'supplier_group' ?
                                            <AllOtherAttributeMasterComponent attributeName={item} value={filterData[item]} onChange={(e) => handleFilterData(e, item)} prevalue={filterData[item]} withOutLabel={true} id={item} multiple />

                                            :
                                            <ChipDropdown id={item} data={filterData[item]} onChange={(value) => handleFilterData(value, item)}
                                            />
                                    }
                                </Grid>
                            ))
                        }
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleRun}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    :
                                    'Apply'
                                }
                            </Button>
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}>
                        {props.grossNetData && props.grossNetData.length > 0
                            &&
                            <div style={{ padding: '5rem' }}>
                                <BarChart
                                    data={grossNetData}
                                    attribute={attribute}
                                    dimension={dimension}
                                />
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        configData: state.profitOptimizationData.priceCalForListData,
        attributesData: state.profitOptimizationData.qualifierConfigData,
        grossNetData: state.profitOptimizationData.grossNetData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getGrossToNet(data)),
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        getAttributeData: () => dispatch(getAttributesConfig()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarginByAttribute);