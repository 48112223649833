import React from 'react';
import {
    Typography,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { SetupSalesBasisTab, MultipleConfigurationTab } from './containers';
import { Dropdown } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 23,
        marginTop: 16
    },
    dropdownContainer: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const Configuration = props => {
    const classes = useStyles();
    const { className } = props;
    const [value, setValue] = React.useState(0);
    const handleChange = (event) => {
        setValue(event);
    };
    const data = [
        { key: '0', value: 'Multiple Configuration' },
        { key: '1', value: 'Setup Sales Basis' }
    ];

    return (
        <div>
            <Grid container spacing={1} classes={{ root: classes.flexEnd }} >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Typography variant="h1"> Configuration </Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Dropdown data={data} className={classes.dropdownContainer} onChange={(e) => handleChange(parseInt(e))} defaultValue='true' />
                </Grid>
            </Grid>
            <div
                className={clsx(classes.root, className)}>
                <TabPanel value={value} index={1}>
                    <SetupSalesBasisTab />
                </TabPanel>
                <TabPanel value={value} index={0}>
                    <MultipleConfigurationTab />
                </TabPanel>
            </div>
        </div >
    );

};

export default Configuration;