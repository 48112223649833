import React, { useEffect } from "react";
import Moment from 'moment';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Select, MenuItem, FormLabel, Typography, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { getAnaylsisFormula, getAttributesConfig, getGrossToNet } from "../../../redux/actions";
import { DropdownArray } from "../../../components/Inputs";
import { getMedianMargin } from "../../../redux/actions/ProfitOptimizationConfig/AuthAction";
import Scatter from "../../Analytics/Charts/Scatter";



const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
    },
    select: {
        width: '100%',
        color: '#1675e0',
        backgroundColor: 'white'
    },
    selectedItem: {
        borderRadius: 5
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    spinner: {
        height: '100vh'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
        width: 80,
    },
}));

const MedianMargin = (props) => {


    const [simulationType, setSimulationType] = React.useState('');
    const [viewFormat, setViewFormat] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [formulaList, setFormulaList] = React.useState([]);
    const [formula, setFormula] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributes, setAttributes] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [marginData, setMarginData] = React.useState([]);
    const [onSubmit, setOnSubmit] = React.useState(false);
    const [filterNames, setFilterNames] = React.useState([]);
    const classes = useStyles();
    const marker = { visible: false, width: 10, height: 10, shape: 'Circle' }
    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    useEffect(() => {
        setStartDate(Moment().subtract(1, 'months').startOf('month'));
        setEndDate(Moment().subtract(1, 'months').endOf('month'));
    }, [])

    useEffect(() => {
        props.getConfigData()
        props.getAttributeData()
    }, [])

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setFormulaList(props.configData.map(item => item.formula_name));
        }
    }, [props.configData])
    useEffect(() => {
        if (props.attributesData && props.attributesData.length > 0) {
            setAttributeArray(props.attributesData.map(item => item.qualifier_key));
        }
    }, [props.attributesData])
    useEffect(() => {
        var temp = []
        if (props.marginData && Object.entries(props.marginData).length > 0 && onSubmit) {
            temp.push(
                { data: props.marginData.lower_median, type: 'Scatter', xName: attributes, yName: 'margin_price_percentage', marker: marker, name: 'Lower Median' },
                { data: props.marginData.upper_median, type: 'Scatter', xName: attributes, yName: 'margin_price_percentage', marker: marker, name: 'upper Median' },
                //{ data: props.marginData.median, type: 'line', yName: 'median', name: 'Median' },
            )
            setMarginData(temp);
            let xaxis = props.marginData.lower_median ? props.marginData.lower_median.map(item => item[attributes]) : []
            let xaxis1 = props.marginData.upper_median ? props.marginData.upper_median.map(item => item[attributes]) : []
            setLabels([...xaxis1, ...xaxis])
            setOnSubmit(false)
        }
        else {
            setMarginData([])
            if (!props.loading && onSubmit)
                setOnSubmit(false)
        }
    }, [props.marginData, props.loading])

    const handleRun = () => {
        setOnSubmit(true)
        var sd = startDate ? Moment.utc(startDate).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
        var ed = endDate ? Moment.utc(endDate).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
        var data = {
            "formula_name": formula,
            "start_date": sd,
            "end_date": ed,
            "simulation_category": simulationType,
            "attribute_name": attributes,
        }
        props.onSubmit(data)
    }
    return (
        <LoadingOverlay
            active={onSubmit}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>
                    <Typography color="Primary" variant='h1' style={{ marginLeft: 17, marginTop: 15, marginBottom: 15 }}>Median Margin</Typography>
                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid container
                            item
                            md={filters && filters.length > 0 ? 8 : 7}
                            xs={12}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Formula</Typography>
                                    <Select
                                        value={formula}
                                        onChange={(e) => setFormula(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {formulaList && formulaList.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Simulation Category</Typography>
                                    <Select
                                        value={simulationType}
                                        onChange={(e) => setSimulationType(e.target.value)}
                                        label='Posting Type'
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}

                                    >
                                        <MenuItem value={"Historical - Actual"}>
                                            Historical - Actual
                                        </MenuItem>
                                        <MenuItem value={"Historical - What-If"}>
                                            Historical - What-If
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        //borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={handleStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ paddingRight: 15 }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        //borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={endDate}
                                                minDate={startDate ? startDate : false}
                                                onChange={handleEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <Typography classes={{ root: classes.fontSetting }} >Attributes</Typography>
                            <div style={{ marginTop: -20 }}>
                                <DropdownArray data={attributeArray} capitalize
                                    twoline='true' onChange={(value => setAttributes(value))}
                                    prevalue={attributes} class={classes.select}
                                />
                            </div>
                        </Grid>

                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleRun}
                                twoline="true"
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    :
                                    'Apply'
                                }
                            </Button>
                        </div>
                    </Grid>

                </Grid>
                {marginData && marginData.length > 0 &&
                    <Scatter scatterData={marginData} xLegend={attributes} yLegend={'Margin Percentage'} labels={labels} />
                }
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.profitOptimizationData.loadGrossNet,
        configData: state.profitOptimizationData.priceCalForListData,
        attributesData: state.profitOptimizationData.qualifierConfigData,
        marginData: state.profitOptimizationData.medianMargin
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getMedianMargin(data)),
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        getAttributeData: () => dispatch(getAttributesConfig()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedianMargin);