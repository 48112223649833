import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import { List, ListItem, Button, Collapse, ListItemText } from '@mui/material';
import { DashIcon } from '../../../../Icons';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import RowingOutlinedIcon from '@mui/icons-material/RowingOutlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PieChartIcon from '@mui/icons-material/PieChart';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { AnalyticsIcon, CalculationIcon, ConfigIcon, ContractIcon, DataSetupIcon, DataSetupTransactionIcon, FinancialIcon, OperationalIcon, HourGlassIcon } from '../../../../Icons';
import { history } from '../../../../../../components/Helpers';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import OutlinedFlagOutlinedIcon from '@mui/icons-material/OutlinedFlagOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import Filter1Icon from '@mui/icons-material/Filter1';
import  secureLocalStorage  from  "react-secure-storage";
import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { MenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';

const useStyles = makeStyles(theme => ({
  root: {},
  icon: {
    color: theme.palette.icon,
    width: 16,
    height: 16,
    display: 'flex',
    paddingLeft: 3
  },
  button: {
    color: theme.palette.white,
    padding: 10,
    paddingTop: 9,
    paddingRight: 0,
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    textTransform: 'none',
    borderRadius: 6
  },
  active: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    textTransform: 'none',
    '&.active, &:hover, &.active:hover': {
      '& path': {
        color: theme.palette.primary.main
      },
      '& span': {
        color: theme.palette.primary.main
      },
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  buttonSub: {
    color: theme.palette.white,
    paddingBottom: 10,
    paddingRight: 0,
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    textTransform: 'none',
    borderRadius: 6,
  },
  activeSub: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    textTransform: 'none',
    '&.active, &:hover, &.active:hover': {
      '& path': {
        color: theme.palette.primary.main
      },
      '& span': {
        color: theme.palette.primary.main
      },
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    paddingLeft: 9,
    fontFamily: 'Roboto Slab, serif',
    fontSize: '1em'
  },
  dividerColor: {
    background: theme.palette.border.divider,
    margin: '20px 0px 20px 0px'
  },
  expandIcon: {
    display: 'flex',
    justifyContent: 'center'
  },
  listSideMenu: {
    fontSize: 13,
    marginTop: 7,
    paddingLeft: 14
  },
  submenuButton: {
    marginTop: 0
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const adminPages = [
  {
    title: 'On Demand Query',
    icon:
      <OperationalIcon />,
    href: '/dynamic-query'
  }
];

const AuditLab = [
  {
    title: 'Contract Change Report',
    icon:
      <DashIcon />,
    href: '/contract-change-reports'
  },


  {
    title: 'Financial Postings History',
    icon:
      <DashIcon />,
    href: '/financial-approvals-reports'
  },
  {
    title: 'Error Log',
    icon:
      <DashIcon />,
    href: '/error-logs'
  },
  {
    title: 'Message Log',
    icon:
      <DashIcon />,
    href: '/message-logs'
  },
  {
    title: 'Exception Management',
    icon:
      <DashIcon />,
    href: '/exception-management-view'
  },

  {
    title: 'On Demand Query',
    icon:
      <OperationalIcon />,
    href: '/dynamic-query'
  },

];

const SideBarNav = props => {
  const { pages, superUserPages, className, ...rest } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openContractSetup, setOpenContractSetup] = React.useState(false);
  const [openSupplierChargeBack, setOpenSupplierChargeBack] = React.useState(false);
  // const [openExpectedAccruals, setOpenExpectedAccruals] = React.useState(false);
  const [openCustomerChargeBack, setOpenCustomerChargeBack] = React.useState(false);
  const [openFinancialPostings, setOpenFinancialPostings] = React.useState(false);
  const [openDesigner, setOpenDesigner] = React.useState(false);
  const [openCalculationDesigner, setOpenCalculationDesigner] = React.useState(false);
  const [openClaimDesigner, setOpenClaimDesigner] = React.useState(false);
  const [openFinancialDesigner, setOpenFinancialDesigner] = React.useState(false);
  const [openApprovalDesigner, setOpenApprovalDesigner] = React.useState(false);
  const [openPaymentVariance, setOpenPaymentVariance] = React.useState(false);
  const [openAccrualVariance, setOpenAccrualVariance] = React.useState(false);
  const [openPAC, setOpenPAC] = React.useState(false);
  const [openAccrualPostings, setOpenAccrualPostings] = React.useState(false);
  const [openPaymentPostings, setOpenPaymentPostings] = React.useState(false);
  const [openBudgeting, setOpenBudgeting] = React.useState(false);
  const [openBatchJob, setOpenBatchJob] = React.useState(false);
  const [openTerritory, setOpenTerritory] = React.useState(false);
  const [openDirectSales, setOpenDirectSales] = React.useState(true);
  const [openIndirectSales, setOpenIndirectSales] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openForecasting, setOpenForecasting] = React.useState(false);
  const [openHistoricalAnalysis, setOpenHistoricalAnalysis] = React.useState(false);
  const [openAnalytics, setOpenAnalytics] = React.useState(false);
  const [openExternalInputs, setOpenExternalInputs] = React.useState(false);
  const [openPricingConfig, setOpenPricingConfig] = React.useState(false);
  const [openPricingManagment, setOpenPricingManagment] = React.useState(false);
  const [openIntegrationBatchJob, setOpenIntegrationBatchJob] = React.useState(false);
  const [OffinvoiceAdjustments, setOffinvoiceAdjustments] = React.useState(false);
  const [openPriceMaintenance, setOpenPriceMaintenance] = React.useState(false);
  const [openPriceSimulation, setOpenPriceSimulation] = React.useState(false);
  const [openPromoConfig, setOpenPromoConfig] = React.useState(false);
  const [openPromoTransaction, setOpenPromoTransaction] = React.useState(false);
  const [openPromoPricing, setOpenPromoPricing] = React.useState(false);
  const [openPromoIndirectSales, setPromoIndirectSales] = React.useState(false);
  const [openPromoSalesForecast,setPromoSalesForecase] = React.useState(false);
  const [openXREF, setOpenXREF] = React.useState(false);
  const [openPriceOptimization, setOpenPriceOptimization] = React.useState(false);
  const [openConfig, setOpenConfig] = React.useState(false);
  const [openPriceCatalog, setOpenPriceCatalog] = React.useState(false);
  const [openMarketPrice, setOpenMarketPrice] = React.useState(false);
  const [openPriceAgreement, setOpenPriceAgreement] = React.useState(false);
  const [openPricingBatchJob,setOpenPricingBatchJob] = React.useState(false);
  const [openExternalData,setOpenExternalData] = React.useState(false)
  const [openPriceAnalytics,setOpenPriceAnalytics] = React.useState(false)
  const [openPricingExternalData,setOpenPricingExternalData] = React.useState(false);
  const [openPriceReporting,setOpenPriceReporting] = React.useState(false);
  const handleAccrualApprovals = () => {
    history.push('/accrual-approvals')
  }
  const handlePaymentApprovals = () => {
    history.push('/payment-approvals')
  }

  const handleExpansion = (page) => {

    if (page.title === 'Master Data') {
      setOpen(!open);
    }
    if (page.title === 'Contracts') {
      setOpenContractSetup(!openContractSetup);
    }
    if (page.title === 'Potential Action Items') {
      setOpenPAC(!openPAC);
    }
    if (page.title === 'Claims Management') {
      setOpenCustomerChargeBack(!openCustomerChargeBack);
    }
    if (page.title === 'Accrual Postings') {
      setOpenAccrualPostings(!openAccrualPostings);
    }
    if (page.title === 'Payment Postings') {
      setOpenPaymentPostings(!openPaymentPostings);
    }
    // if (page.title === 'Expected Accruals') {
    //   setOpenExpectedAccruals(!openExpectedAccruals);
    // }
    if (page.title === 'Batch Job') {
      setOpenBatchJob(!openBatchJob);
    }
    if (page.title === 'Direct - Sales Data') {
      setOpenDirectSales(!openDirectSales);
    }
    if (page.title === 'Indirect - Sales Data') {
      setOpenIndirectSales(!openIndirectSales);
    }
    if (page.title === 'Direct -Purchase Data') {
      setOpenPurchase(!openPurchase);
    }
    if (page.title === 'Price Config') {
      setOpenPricingConfig(!openPricingConfig);
    }
    if(page.title==='Batch Job') {
      setOpenPricingBatchJob(!openPricingBatchJob);
    }
    if (page.title === 'Price Maintenance') {
      setOpenPriceMaintenance(!openPriceMaintenance);
    }
    if (page.title === 'Price Management') {
      setOpenPricingManagment(!openPricingManagment);
    }
    if (page.title === 'Price Simulation') {
      setOpenPriceSimulation(!openPriceSimulation)
    }
    if (page.title === 'Price Analytics') {
      setOpenPriceAnalytics(!openPriceAnalytics)
    }
    if (page.title === 'Price Reporting') {
      setOpenPriceReporting(!openPriceReporting)
    }
    if (page.title === 'Integration Batch Job') {
      setOpenIntegrationBatchJob(!openIntegrationBatchJob);
    }
    if (page.title === 'XREF') {
      setOpenXREF(!openXREF)
    }
    if (page.title === 'Price Optimization') {
      setOpenPriceOptimization(!openPriceOptimization)
    }
    if (page.title === 'Price Catalog') {
      setOpenPriceCatalog(!openPriceCatalog);
    }
    if (page.title === 'Market Price') {
      setOpenMarketPrice(!openMarketPrice);
    }
    if (page.title === 'Price Contracts') {
      setOpenPriceAgreement(!openPriceAgreement)
    }
    if(page.title === 'External Data') {
      setOpenPricingExternalData(!openPricingExternalData)
    }
  };
  const handleExpansionDesigner = (page) => {
    if (page.title === 'Contract Designer') {
      setOpenDesigner(!openDesigner);
    }
    if (page.title === 'Calculation Designer') {
      setOpenCalculationDesigner(!openCalculationDesigner);
    }
    if (page.title === 'Claim Designer') {
      setOpenClaimDesigner(!openClaimDesigner);
    }
    if (page.title === 'Postings Designer') {
      setOpenFinancialDesigner(!openFinancialDesigner);
    }
    if (page.title === 'Approval Designer') {
      setOpenApprovalDesigner(!openApprovalDesigner);
    }
    if (page.title === 'Budgeting') {
      setOpenBudgeting(!openBudgeting);
    }
    if (page.title === 'Master Data') {
      setOpenExternalInputs(!openExternalInputs);
    }
    if (page.title === 'Historical Analysis') {
      setOpenHistoricalAnalysis(!openHistoricalAnalysis);
    }
    if (page.title === 'Forecasting') {
      setOpenForecasting(!openForecasting);
    }
    if (page.title === 'Analytics') {
      setOpenAnalytics(!openAnalytics);
    }
    if (page.title === 'Configuration') {
      setOpenPromoConfig(!openPromoConfig);
    }
    if (page.title === 'Transaction Data') {
      setOpenPromoTransaction(!openPromoTransaction);
    }
    if (page.title === 'Pricing') {
      setOpenPromoPricing(!openPromoPricing);
    }
    if (page.title === 'Config') {
      setOpenConfig(!openConfig);
    }
    if(page.title === 'External Data') {
      setOpenExternalData(!openExternalData)
    }
  };

  const customerChargeBackPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard',
      displayName: 'Dashboard'
    },
    {
      title: 'Contracts',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role &&  secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
      displayName: sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan' : 'Contracts'
    },
    {
      title: 'Calculation Simulation',
      icon:
        <CalculationIcon />,
      href: '/calculation-simulation',
      hidden: true,
      displayName: 'Calculation Simulation'
    },
    {
      title: 'Supplier Claims',
      icon:
        <CalculationIcon />,
      href: '/supplier-incoming-claims',
      displayName: 'Claims Management '
    },
    {
      title: 'Claims Management',
      icon:
        <CalculationIcon />,
      href: '#',
      displayName: 'Claims Management',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_multiple_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_single_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_claim-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Accrual Postings',
      icon:
        <FinancialIcon />,
      href: '/outgoing-claims-accrual-postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&secureLocalStorage.getItem('dYtz').role.rights.includes('run_accrual_post-'+sessionStorage.getItem('application')) ?false : 'hide',
      displayName: 'Accrual Postings',
    },
    {
      title: 'Payment Postings',
      icon:
        <FinancialIcon />,
      href: '/outgoing-claims-payment-postings',
      displayName: 'Payment Postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_payment_post-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Operational Reports',
      icon:
        <OperationalIcon />,
      href: '/select-operational-reports',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
      displayName: 'Operational Reports'
    },
    {
      title: 'On Demand Query',
      icon:
        <OperationalIcon />,
      href: '/dynamic-query',
      displayName: 'On Demand Query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Quota Management',
      icon:
        <OperationalIcon />,
      href: '/quota-management',
      displayName: 'Quota Management',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' || sessionStorage.getItem('application') === 'Supplier Rebate' || sessionStorage.getItem('application') === 'Customer Rebate' ? false : 'hide'
    },
    {
      title: 'Performance Metric',
      icon:
        <OperationalIcon />,
      href: '/performance-metric',
      displayName: 'Performance Metric',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' ? false : 'hide'
    },
    {
      title: 'Performance Weightage',
      icon:
        <OperationalIcon />,
      href: '/performance-metric-weightage',
      displayName: 'Performance Weightage',
      hidden: sessionStorage.getItem('application') === 'Sales Commission' ? false : 'hide'
    },
    {
      title: 'Exception Management',
      icon:
        <OperationalIcon />,
      href: '/exception-management',
      hidden: true,
      displayName: 'Exception Management'
    },
    {
      title: 'Potential Action Items',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application')) ? false : 'hide',
      displayName: 'Potential Action Items'
    },
    {
      title: 'Batch Job',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ? false : 'hide',
      displayName: 'Batch Job'
    },
    {
      title: 'Query Field',
      icon:
        <OperationalIcon />,
      href: '/query-field',
      displayName: 'Query Field',
      hidden: sessionStorage.getItem('application') === 'Customer Rebate' ? false : 'hide'
  }  ];
  const masterDataPages = [
    {
      title: 'Customer Master',
      icon:
        <DashIcon />,
      href: '/customer-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'Material Master',
      icon:
        <DashIcon />,
      href: '/material-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'Supplier Master',
      icon:
        <DashIcon />,
      href: '/supplier-master',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_supplier_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_supplier_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_supplier_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Membership',
      icon:
        <DashIcon />,
      href: '/membership',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_membership-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_membership-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_membership-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Sales Bundle',
      icon:
        <DashIcon />,
      href: '/bom',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_bundle-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_sales_bundle-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_sales_bundle-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Employee Master',
      icon:
        <DashIcon />,
      href: '/employee',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_employee-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_employee_territory_assignment-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Territory Master',
      icon:
        <DashIcon />,
      href: '/terittory',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_territory_master-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Attributes',
      icon:
        <DashIcon />,
      href: '/attributes',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_attributes-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Product Group',
      icon:
        <DashIcon />,
      href: '/product-group',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_group-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_group-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_product_group-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Currency Conversion',
      icon:
        <DashIcon />,
      href: '/currency-conversion',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_currency_conversions-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'UoM',
      icon:
        <DashIcon />,
      href: '/uom',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_uom-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_uom-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_uom-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Product Feature',
      icon:
        <DashIcon />,
      href: '/product-feature',
     // hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_product_feature-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_product_feature-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_product_feature-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'XREF',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_xref-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contract_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_cot_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_cot_xref-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_cot_xref-'+sessionStorage.getItem('application'))
      ) ?false : 'hide',
    },
  ];
  const transactionDataPages = [
    {
      title: 'Direct - Sales Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_direct_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_direct_sales_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Indirect - Sales Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Direct -Purchase Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_purchase_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_purchase_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_purchase_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Commitment Data',
      icon:
        <DashIcon />,
      href: '/target-invoice-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_commitment_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_commitment_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_commitment_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Inventory Data',
      icon:
        <DashIcon />,
      href: '/inventory-data',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_inventory_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Demand Supply',
      icon:
        <DashIcon />,
      href: '/demand-supply',
      //hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_inventory_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_inventory_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Competitor Data',
      icon:
        <DashIcon />,
      href: '/competitor-data',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_competitor_data-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
  ];
  const PricingPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/pricing-dashboard',
      displayName: 'Dashboard'
    },
    {
      title: 'Price Maintenance',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_price_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_market_price_calculation-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Management',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_calculation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_catalog-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_edi_file-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_policy_exceptions-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Optimization',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_forecast_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_historical_price_simulation-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'CPQ',
      icon:
        <DashIcon />,
      href: '/price-simulation-cpq',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('run_cpq-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_aggregated_margin-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_pricing_sim_report-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Analytics',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_level_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_level_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_reference_level_analysis-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Reporting',
      icon:
        <DashIcon />,
      href: '#',
    },
    {
      title: 'Price Contracts',
      icon:
      <DashIcon />,
      href:'#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Price Config',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_simulation-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_type-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_list-'+sessionStorage.getItem('application'))
      ) ?false : 'hide',
    },
    {
      title: 'Error Log',
      icon:
        <DashIcon />,
      href: '/error-logs',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Message Log',
      icon:
        <DashIcon />,
      href: '/message-logs',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Batch Job',
      icon:
          <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_error_logs-'+sessionStorage.getItem('application')) ?false : 'hide',
      displayName: 'Batch Job'
  }
  
  ];
  const promotionsPlanningPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/promo-plan-analytics'
    },
    {
      title: 'Budgeting',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('download_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('clone_promotion_setup-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_global_setup-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_sim-'+sessionStorage.getItem('application')) ||secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_costings-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Analytics',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_analytics-'+sessionStorage.getItem('application'))? false : 'hide'
    },
    {
      title: 'Master Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_master-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_master-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_coverage_cost-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_coverage_cost-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_finance_cost-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_finance_cost-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_territory-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_territory-'+sessionStorage.getItem('application')) 
      ) ? false : 'hide'
    },
    {
      title: 'Transaction Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'On Demand Query',
      icon:
        <DashIcon />,
      href: '/dynamic-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Configuration',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_dropdown_values-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('create_promotion_planning-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_planning-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_promotion_planning-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Pricing',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_pricing_attributes-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_list-'+sessionStorage.getItem('application')) 
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    // {
    //   title: 'Dashboard',
    //   icon:
    //     <DashIcon />,
    //   href: '/dashboard'
    // },
    // {
    //   title: 'Gross to Net Trends',
    //   icon:
    //     <DashIcon />,
    //   href: '/historical-trends'
    // },
    // {
    //   title: 'Forecasting',
    //   icon:
    //     <DashIcon />,
    //   href: '/forecasting'
    // },
    // {
    //   title: 'Budgeting',
    //   icon:
    //     <DashIcon />,
    //   href: '#'
    // },
    // {
    //   title: 'Promotion Plan',
    //   icon:
    //     <DashIcon />,
    //   href: '/promotion-trends'
    // },
    // {
    //   title: 'Profitability Analysis',
    //   icon:
    //     <DashIcon />,
    //   href: '/promotion-sales-analysis'
    // },
    // {
    //   title: 'Time To Market',
    //   icon:
    //     <DashIcon />,
    //   href: '/time-to-market'
    // },
    // {
    //   title: 'Market Share',
    //   icon:
    //     <DashIcon />,
    //   href: '/market-share'
    // }
  ];
  const supplierChargeBackPages = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard'
    },
    {
      title: 'Contracts',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
  
    {
      title: 'Claims Management',
      icon:
        <CalculationIcon />,
      href: '/supplier-incoming-claims',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  (secureLocalStorage.getItem('dYtz').role.rights.includes('post_claim-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_response_format-'+sessionStorage.getItem('application'))) ?false : 'hide',
    },
    {
      title: 'Claims Status',
      icon:
        <OperationalIcon />,
      href: '/supplierChargeback-claim-status',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('get_claim_status-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Multiple Claims',
      icon:
        <CalculationIcon />,
      href: '/supplier-multiple-claims',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_multiple_claims-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Claims Provision',
      icon:
        <CalculationIcon />,
      href: '/financial-postings',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_claims_provision-'+sessionStorage.getItem('application')) ?false : 'hide',
    },
    {
      title: 'Payment Postings',
      icon:
        <FinancialIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_payment_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('approve_payment_postings-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_payment_post-'+sessionStorage.getItem('application'))) ? false : 'hide'
    },
    {
      title: 'Operational Reports',
      icon:
        <OperationalIcon />,
      href: '/select-operational-reports',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
  
    {
      title: 'On Demand Query',
      icon:
        <OperationalIcon />,
      href: '/dynamic-query',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('execute_report-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Potential Action Items',
      icon:
        <ContractIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application')) ? false : 'hide',
    }
  ];
  const designerStudioPages = [
    {
      title: 'Contract Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('contract_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Calculation Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('calculation_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Claim Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('claim_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Postings Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('postings_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Master Data Designer',
      icon:
        <DashIcon />,
      href: '/master-data-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('master_data_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Approval Designer',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approval_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Label Designer',
      icon:
        <DashIcon />,
      href: '/label-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('label_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Display Designer',
      icon:
        <DashIcon />,
      href: '/common-display-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('display_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Analytics Designer',
      icon:
        <DashIcon />,
      href: '/analytics-designer',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('analytics_designer-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Help Desk',
      icon:
        <DashIcon />,
      href: '/help-desk',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('help_desk-'+sessionStorage.getItem('application')) ? false : 'hide',
    }
  ];
  const securityPages = [
    {
      title: 'Organization Setup',
      icon:
        <DashIcon />,
      href: '/organisation-setup'
    },
    {
      title: 'Security Role Designer',
      icon:
        <DashIcon />,
      href: '/access',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_security_role') ? false : 'hide',
    },
    {
      title: 'User Setup',
      icon:
        <DashIcon />,
      href: '/user-role',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_user') ? false : 'hide',
    },
    {
      title: 'Collaboration ID',
      icon:
        <DashIcon />,
      href: '/collaboration-id',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_collaboration') ? false : 'hide',
    }
  ];
  const profitOptimization = [
    {
      title: 'Dashboard',
      icon:
        <DashIcon />,
      href: '/dashboard'
    },
    {
      title: 'Driver Maintenance',
      icon:
        <DashIcon />,
      href: '/driver-maintenance',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_driver_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_driver_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_driver_data-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Historical Analysis',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis_report-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_promotion_plan-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_gross_to_net_trends-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('get_profitability_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_regional_analysis-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_market_share-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_time_to_market-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Forecasting',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_forecast_config-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_historical_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_sales_forecast-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_profitability_forecasting-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'Config',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_attributes-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_drivers-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_level-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_formula-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
    {
      title: 'External Data',
      icon:
        <DashIcon />,
      href: '#',
      hidden:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && 
      (secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_analysis_data-'+sessionStorage.getItem('application'))
      ||secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_external_config-'+sessionStorage.getItem('application'))
      ) ? false : 'hide'
    },
  ];
  const IntegrationStudioPages = [
    {
      title: 'Integration Setup',
      icon:
        <DashIcon />,
      href: '/integration-setup',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_integration_setup-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Integration Manager',
      icon:
        <DashIcon />,
      href: '/integration-manager',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_integration_setup-'+sessionStorage.getItem('application')) ? false : 'hide',
    },
    {
      title: 'Integration Mapping',
      icon:
        <DashIcon />,
      href: '/integration-mapping',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_mapping-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_mapping-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_integration_mapping-'+sessionStorage.getItem('application'))) ? false : 'hide',
    },
    {
      title: 'Integration Batch Job',
      icon:
        <ContractIcon />,
      href: '#',
      displayName: 'Integration Batch Job',
      hidden: secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job_status-'+sessionStorage.getItem('application'))) ? false : 'hide',
    }
  
  ];
  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {sessionStorage.getItem('application') != 'Designer Studio' && sessionStorage.getItem('application') != 'Security Cockpit' && sessionStorage.getItem('application') != 'Transaction Data'
        && sessionStorage.getItem('application') != 'Master Data' && sessionStorage.getItem('application') != 'Integration Studio' &&
        sessionStorage.getItem('application') != 'Collaboration Portal' && sessionStorage.getItem('application') != 'Analytics' &&
        sessionStorage.getItem('application') != 'Supplier Chargeback' && sessionStorage.getItem('application') != 'Promotions' &&
        sessionStorage.getItem('application') != 'Audit Lab' && sessionStorage.getItem('application') != 'Pricing' &&
        sessionStorage.getItem('application') != 'Customer Chargeback' && sessionStorage.getItem('application') != 'Profit Optimization' &&
        <div>
          {pages
            .filter(item => item.title == 'Supplier Claims' ? sessionStorage.getItem('application') == 'Supplier Chargeback' : item)
            .filter(item => item.title == 'Claims Management' ? sessionStorage.getItem('application') == 'Customer Chargeback' : item)
            .filter(item => sessionStorage.getItem('application') === 'Customer Chargeback' ? item.hidden != true : item)
            .filter(item => sessionStorage.getItem('application') === 'Customer Chargeback' ? item.hidden != true : item)
            .filter(item => item.hidden != 'hide')
            .map(page => (
              <div>
                <ListItem
                  disableGutters
                  className={classes.item}
                  key={page.title}
                  onClick={() => handleExpansion(page)}
                >
                  <Button
                    className={classes.button}
                    activeClassName={page.title == 'Master Data' || page.title == 'Contracts'
                      || page.title == 'Claims Management'
                      || page.title == 'Analytics' || page.title == 'Potential Action Items'
                      || page.title == 'Accrual Postings' || page.title == 'Payment Postings'
                      || page.title == 'Batch Job'
                      ? '' : classes.active}
                    component={CustomRouterLink}
                    to={page.href}
                  >
                    <div className={classes.icon} >{page.icon}</div>
                    <div className={classes.title}>
                      {page.displayName}
                    </div>
                    {page.title === 'Master Data' &&
                      <div className={classes.expandIcon}>
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Contracts' &&
                      <div className={classes.expandIcon}>
                        {openContractSetup ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Potential Action Items' &&
                      <div className={classes.expandIcon}>
                        {openPAC ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Accrual Postings' &&
                      <div className={classes.expandIcon}>
                        {openAccrualPostings ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Payment Postings' &&
                      <div className={classes.expandIcon}>
                        {openPaymentPostings ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }

                    {page.title === 'Claims Management' &&
                      <div className={classes.expandIcon}>
                        {openCustomerChargeBack ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Batch Job' &&
                      <div className={classes.expandIcon}>
                        {openBatchJob ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                  </Button>
                </ListItem>
                {page.title === 'Batch Job' &&
                  <Collapse in={openBatchJob} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablepadding 
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application')) ? false : 'hide'} >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/batchjob-setup'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`} >
                            Batch Job Setup
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablepadding 
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application')) ? false : 'hide'}>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/batchjob-status'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Batch Job Status
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }
                {page.title === 'Contracts' &&
                  <Collapse in={openContractSetup} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && ((secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application')))||(secureLocalStorage.getItem('dYtz').role.rights.includes('post_incentive_plan_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_incentive_plan_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('clone_incentive_plan_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_incentive_plan_setup-'+sessionStorage.getItem('application')))) ?false : 'hide'}
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}                        
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/contract-setup'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Setup' : 'Contract Setup'}
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                      <List component="div" disablePadding
                      hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application'))) ? false : 'hide'}>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/approval-reject'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Approval' : 'Contract Approval'}
                          </div>
                        </Button>
                      </ListItem>

                    </List>
                  </Collapse>
                }
                {page.title === 'Accrual Postings' &&
                  <Collapse in={openAccrualPostings} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_accrual_post-'+sessionStorage.getItem('application')) ? false : 'hide'}
                    >

                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/financial-postings'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Accrual Postings
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                      <List component="div" disablePadding
                      hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_accrual_posting-'+sessionStorage.getItem('application')) ? false : 'hide'}
                      >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          onClick={handleAccrualApprovals}

                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Accrual Approvals
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }

                {page.title === 'Payment Postings' &&
                  <Collapse in={openPaymentPostings} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_payment_post-'+sessionStorage.getItem('application')) ?false : 'hide'}
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/payment-postings'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Payment Postings
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                      <List component="div" disablePadding
                      hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_payment_posting-'+sessionStorage.getItem('application')) ?false : 'hide'}>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/payment-approvals'
                          onClick={handlePaymentApprovals}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Payment Approvals
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                      <List component="div" disablePadding
                      hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_report-'+sessionStorage.getItem('application')) ?false : 'hide'}>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/partner-statement'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Partner Statement
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                  </Collapse>
                }


                {page.title === 'Potential Action Items' &&
                  <Collapse in={openPAC} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/expiring-contracts'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Expiring Contracts
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/expired-contracts'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Expired Contracts
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }
                {page.title === 'Supplier Claims' &&
                  <Collapse in={openSupplierChargeBack} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-incoming-claims-upload'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Incoming Claims Upload
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-incoming-claims'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Incoming Claims
                          </div>
                        </Button>
                      </ListItem>

                    </List>
                  </Collapse>
                }

                {page.title === 'Claims Management' &&
                  <Collapse in={openCustomerChargeBack} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/outgoing-claims-post'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Create Claims
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-outgoing-claims'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Post Claims
                          </div>
                        </Button>
                      </ListItem>

                    </List>
                  </Collapse>
                }
                {page.title === 'Master Data' &&
                  <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 15, }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Customer Master
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-x-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Customer X Ref
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/material-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardGiftcardOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Material Master
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/material-x-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardGiftcardOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Material Master X Ref
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><RowingOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Supplier Master
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/membership'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardMembershipIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Membership
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }

              </div>
            ))}
        </div>
      }
      {/* Customer chargeback */}
      {sessionStorage.getItem('application') === 'Customer Chargeback'
        &&
        <div>
          {customerChargeBackPages
            .filter(item => item.title == 'Supplier Claims' ? sessionStorage.getItem('application') == 'Supplier Chargeback' : item)
            .filter(item => item.title == 'Claims Management' ? sessionStorage.getItem('application') == 'Customer Chargeback' : item)
            .filter(item => sessionStorage.getItem('application') === 'Customer Chargeback' ? item.hidden != true : item)
            .filter(item => sessionStorage.getItem('application') === 'Customer Chargeback' ? item.hidden != true : item)
            .filter(item => item.hidden != 'hide')
            .map(page => (
              <div>
                <ListItem
                  disableGutters
                  className={classes.item}
                  key={page.title}
                  onClick={() => handleExpansion(page)}
                >
                  <Button
                    className={classes.button}
                    activeClassName={page.title == 'Master Data' || page.title == 'Contracts'
                      || page.title == 'Claims Management'
                      || page.title == 'Analytics' || page.title == 'Potential Action Items'
                      || page.title == 'Batch Job'
                      ? '' : classes.active}
                    component={CustomRouterLink}
                    to={page.href}
                  >
                    <div className={classes.icon} >{page.icon}</div>
                    <div className={classes.title}>
                      {page.displayName}
                    </div>
                    {page.title === 'Master Data' &&
                      <div className={classes.expandIcon}>
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Contracts' &&
                      <div className={classes.expandIcon}>
                        {openContractSetup ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Potential Action Items' &&
                      <div className={classes.expandIcon}>
                        {openPAC ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Claims Management' &&
                      <div className={classes.expandIcon}>
                        {openCustomerChargeBack ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                    {page.title === 'Batch Job' &&
                      <div className={classes.expandIcon}>
                        {openBatchJob ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    }
                  </Button>
                </ListItem>
                {page.title === 'Batch Job' &&
                  <Collapse in={openBatchJob} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/batchjob-setup'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`} >
                            Batch Job Setup
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/batchjob-status'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Batch Job Status
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }
                {page.title === 'Contracts' &&
                  <Collapse in={openContractSetup} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))) ?false : 'hide'}
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/contract-setup'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Setup' : 'Contract Setup'}
                          </div>
                        </Button>
                      </ListItem>
                      </List>
                      <List component="div" disablePadding
                       hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application')) ? false : 'hide'}
                       >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/approval-reject'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Approval' : 'Contract Approval'}
                          </div>
                        </Button>
                      </ListItem>
                      </List>

                    
                  </Collapse>
                }

                {page.title === 'Potential Action Items' &&
                  <Collapse in={openPAC} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/expiring-contracts'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Expiring Contracts
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/expired-contracts'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Expired Contracts
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }
                {page.title === 'Supplier Claims' &&
                  <Collapse in={openSupplierChargeBack} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-incoming-claims-upload'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Incoming Claims Upload
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-incoming-claims'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Incoming Claims
                          </div>
                        </Button>
                      </ListItem>

                    </List>
                  </Collapse>
                }

                {page.title === 'Claims Management' &&
                  <Collapse in={openCustomerChargeBack} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding
                    >
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_claim-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/outgoing-claims-post'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Create Claims
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_single_claim-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-outgoing-claims'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Manage Single Claim
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_multiple_claim-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-outgoing-disputed-claims'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Manage Multiple Claims
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_claim-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/claim-status'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Claim Status
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }
                {page.title === 'Master Data' &&
                  <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 15, }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Customer Master
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/customer-x-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Customer X Ref
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/material-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardGiftcardOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Material Master
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/material-x-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardGiftcardOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Material Master X Ref
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/supplier-master'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><RowingOutlinedIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Supplier Master
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/membership'
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><CardMembershipIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Membership
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                }

              </div>
            ))}
        </div>
      }





      {sessionStorage.getItem('application') === 'Designer Studio' &&
        <div>
          {designerStudioPages
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansionDesigner(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={(page.title != 'Contract Designer' && page.title != 'Calculation Designer' && page.title != 'Claim Designer' && page.title != 'Postings Designer' && page.title != 'Approval Designer' && page.title != 'Master Data Designer') || page.title == 'Sales Basis' && !openDesigner
                    ? classes.active : ''}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {(page.title === 'Contract Designer' || page.title === 'Calculation Designer' || page.title === 'Claim Designer' || page.title === 'Postings Designer' || page.title === 'Approval Designer') &&
                    <div className={classes.expandIcon}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }

                </Button>
              </ListItem>
              {page.title === 'Contract Designer' &&
                <Collapse in={openDesigner} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/multiple-configuration'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Dropdown Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/field-display-configuration'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Display Designer
                        </div>
                      </Button>
                    </ListItem>

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/eligibility-designer'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Eligibility Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/keyfigure-configuration'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Calculation Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/contract-default-designer'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`} style={{ paddingRight: 10 }}>
                          Default Designer
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }

              {page.title === 'Calculation Designer' &&
                <Collapse in={openCalculationDesigner} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>

                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/sales-basis'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Global Eligibility
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/key-figure-mapping'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Key Figure Mapping
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/attribute-mapping'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Attribute Mapping
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/basis-mapping'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Basis Mapping
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/calculation-analysis'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Calculation Analysis
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Claim Designer' &&
                <Collapse in={openClaimDesigner} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>

                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/claim-configuration'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Configuration
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/rejection-reasons'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Rejection Reasons
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Postings Designer' &&
                <Collapse in={openFinancialDesigner} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>

                  <List component="div" disablePadding>

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/accrual-designer'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Accrual Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/payment-designer'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Payment Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/holiday-calendar'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Holiday Calendar
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/financial-close'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Financial Close
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/fiscal-year-definition'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Fiscal Year Definition
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/org-defaults'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Statement Defaults
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Approval Designer' &&
                <Collapse in={openApprovalDesigner} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>

                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/approval-group'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Approval Group
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/approval-limits'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Approval Limits
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }

            </div>
          ))}
        </div>
      }


      {sessionStorage.getItem('application') === 'Security Cockpit' &&
        <div>
          {securityPages
            .filter(page => secureLocalStorage.getItem('dYtz').role.name != 'admin' ? page.title != 'Organization Setup' : page)
            .filter(page => secureLocalStorage.getItem('dYtz').role.name === 'admin' ? page.title === 'Organization Setup' : page)
            .filter(item => item.hidden != 'hide')
            .map(page => (
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
              >
                <Button
                  className={classes.button}
                  activeClassName={classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                </Button>
              </ListItem>
            ))}
        </div>
      }
      {secureLocalStorage.getItem('dYtz').role.name == 'admin' &&
        adminPages
          .map(page => (
            <ListItem
              disableGutters
              className={classes.item}
              key={page.title}
            >
              <Button
                className={classes.button}
                activeClassName={classes.active}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon} >{page.icon}</div>
                <div className={classes.title}>
                  {page.title}
                </div>
              </Button>
            </ListItem>
          ))
      }
      {/*sessionStorage.getItem('application') === 'Transaction Data' &&
        <div>
          {transactionDataPages.map(page => (
            <ListItem
              disableGutters
              className={classes.item}
              key={page.title}
            >
              <Button
                className={classes.button}
                activeClassName={classes.active}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon} >{page.icon}</div>
                <div className={classes.title}>
                  {page.title}
                </div>
              </Button>
            </ListItem>
          ))}
        </div>
          */ }
      {sessionStorage.getItem('application') === 'Master Data' &&
        <div>
          {masterDataPages
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansion(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={page.title == 'Territory' || page.title == 'XREF'
                    ? '' : classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {
                    page.title === 'XREF' &&
                    <div className={classes.expandIcon}>
                      {openXREF ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>
              </ListItem>
              {page.title === 'XREF' &&
                <Collapse in={openXREF} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/customer-x-master'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Customer XREF
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/material-x-master'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Material XREF
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/contract-xref'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Contract XREF
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/cot-xref'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          COT XREF
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }

            </div>
          ))}
        </div>
      }
      {sessionStorage.getItem('application') === 'Integration Studio' &&
        IntegrationStudioPages
        .filter(item => item.hidden != 'hide')
        .map(page => (
          <div>
            {/* {IntegrationStudioPages.map(page => ( */}
            <ListItem
              disableGutters
              className={classes.item}
              key={page.title}
              onClick={() => handleExpansion(page)}
            >
              <Button
                className={classes.button}
                // activeClassName={classes.active}
                activeClassName={(page.title != 'Integration Batch Job')
                  ? classes.active : ''}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon} >{page.icon}</div>
                <div className={classes.title}>
                  {page.title}
                </div>
                {page.title === 'Integration Batch Job' &&
                  <div className={classes.expandIcon}>
                    {openIntegrationBatchJob ? <ExpandLess /> : <ExpandMore />}
                  </div>
                }
              </Button>
            </ListItem>
            {page.title === 'Integration Batch Job' &&
              <Collapse in={openIntegrationBatchJob} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                <List component="div" disablePadding
                hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application')) ? false : 'hide'}>
                  <ListItem
                    disableGutters
                    className={classes.item}
                    key={page.title}
                  >
                    <Button
                      className={classes.buttonSub}
                      activeClassName={classes.activeSub}
                      component={CustomRouterLink}
                      to='/integration-batchjob-setup'
                    >
                      <div className={classes.icon} style={{ fontSize: 13 }}><AccountBoxOutlinedIcon /></div>
                      <div className={`${classes.title} ${classes.listSideMenu}`} >
                        Batch Job Setup
                      </div>
                    </Button>
                  </ListItem>
                </List>
                <List component="div" disablePadding
                hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job_status-'+sessionStorage.getItem('application')) ? false : 'hide'}>
                  <ListItem
                    disableGutters
                    className={classes.item}
                    key={page.title}
                  >
                    <Button
                      className={classes.buttonSub}
                      activeClassName={classes.activeSub}
                      component={CustomRouterLink}
                      to='/integration-batchjob-status'
                    >
                      <div className={classes.icon} style={{ fontSize: 13 }}><DeleteSweepIcon /></div>
                      <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Batch Job Status
                      </div>
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            }
            {/* ))} */}

          </div>
        ))
      }
      {sessionStorage.getItem('application') === 'Collaboration Portal' &&
        <List component="div" disablePadding>
          <ListItem
            disableGutters
            className={classes.item}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/collaboration-portal'
              classes={{
                root: classes.submenuButton
              }}
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Collaboration Portal
              </div>
            </Button>
          </ListItem>
          <List component="div" disablePadding
          hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))) ?false : 'hide'}
          >
          <ListItem
            disableGutters
            className={classes.item}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/contract-setup'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Contract Setup
              </div>
            </Button>
          </ListItem>
          </List>
          <List component="div" disablePadding
           hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application')) ? false : 'hide'}
           >
          <ListItem
            disableGutters
            className={classes.item}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/approval-reject'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Contract Approval
              </div>
            </Button>
          </ListItem>
          </List>
          <ListItem
            disableGutters
            className={classes.item}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/customer-outgoing-claims'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Claim Reconcile
              </div>
            </Button>
          </ListItem>
        </List>
      }
      {sessionStorage.getItem('application') === 'Analytics' &&
        <List component="div" disablePadding
        >
          <ListItem
            disableGutters
            className={classes.item}
            key={'Posting Stacks'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/analytics'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><PieChartIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Incentive Strategy
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Analytics Comparison'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/analytics-comparison'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><TrendingUpIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Postings Summary
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Analytics Comparison'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/sales-payment-comparison'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><VerticalSplitIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Gross to Net
              </div>
            </Button>
          </ListItem>
          {/* <ListItem
            disableGutters
            className={classes.item}
            key={'Georgraphical Chart'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/georgraphical-chart'
            >
              <div className={classes.icon} style={{ fontSize: '1em'}}><VerticalSplitIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Georgraphical Chart
              </div>
            </Button>
          </ListItem> */}
          <ListItem
            disableGutters
            className={classes.item}
            key={'Georgraphical Chart'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/geographical-chart-us'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><OutlinedFlagOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Regional Analysis
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Variance Combined'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/variance-combined'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><DonutSmallOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Postings Variance
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Incentive Trends'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/incentive-trends'
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><TimelineIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Incentive Trends
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'page.title'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/top25'
              classes={{
                root: classes.submenuButton
              }}
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><VerticalAlignTopOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Top Incentives
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'page.title'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/historical-trends'
              classes={{
                root: classes.submenuButton
              }}
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><CallMissedOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Gross to Net Trends
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'page.title'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/rebate-trends'
              classes={{
                root: classes.submenuButton
              }}
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><MoneyOutlinedIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Rebate Trends
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'page.title'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/contract-trends'
              classes={{
                root: classes.submenuButton
              }}
            >
              <div className={classes.icon} style={{ fontSize: '1em' }}><Filter1Icon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Calculation Analysis
              </div>
            </Button>
          </ListItem>
          {/* <ListItem
            disableGutters
            className={classes.item}
            key={'Analytics Comparison'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/contract-variance'
            >
              <div className={classes.icon} style={{ fontSize: '1em'}}><TrendingFlatIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Contract Variance
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Analytics Comparison'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/sold-to-party-variance'
            >
              <div className={classes.icon} style={{ fontSize: '1em'}}><TrendingFlatIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Sold to Party Variance
              </div>
            </Button>
          </ListItem>
          <ListItem
            disableGutters
            className={classes.item}
            key={'Analytics Comparison'}
          >
            <Button
              className={classes.buttonSub}
              activeClassName={classes.activeSub}
              component={CustomRouterLink}
              to='/material-number-variance'
            >
              <div className={classes.icon} style={{ fontSize: '1em'}}><TrendingFlatIcon /></div>
              <div className={`${classes.title} ${classes.listSideMenu}`}>
                Material Number Variance
              </div>
            </Button>
          </ListItem> */}
        </List>
      }
      {/*transactiondata */}
      {sessionStorage.getItem('application') === 'Transaction Data' &&
        transactionDataPages
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansion(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={page.title == 'Direct - Sales Data' || page.title == 'Indirect - Sales Data' ||
                    page.title === 'Direct -Purchase Data' ? '' : classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {page.title === 'Direct - Sales Data' &&
                    <div className={classes.expandIcon}>
                      {openDirectSales ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Indirect - Sales Data' &&
                    <div className={classes.expandIcon}>
                      {openIndirectSales ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Direct -Purchase Data' &&
                    <div className={classes.expandIcon}>
                      {openPurchase ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }

                </Button>

              </ListItem>
              {page.title === 'Direct - Sales Data' &&
                <Collapse in={openDirectSales} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/sales-inovoice-query'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Upload Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('delete_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/delete-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Delete Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/display-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Display Sales Data
                        </div>
                      </Button>
                    </ListItem>
                  </List>

                </Collapse>
              }

              {/*indirect sales data */}
              {page.title === 'Indirect - Sales Data' &&
                <Collapse in={openIndirectSales} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/indirect-sales-inovoice-query'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Upload  Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-indirect-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/delete-indirect-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Delete Sales Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/display-indirect-sales-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Display Sales Data
                        </div>
                      </Button>
                    </ListItem>
                  </List>

                </Collapse>
              }
              {/*purchase data */}



              {page.title === 'Direct -Purchase Data' &&
                <Collapse in={openPurchase} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_purchase_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/purchase-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Upload Purchase Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_purchase_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-purchase-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Purchase Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('delete_purchase_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/delete-purchase-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Delete Purchase Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_direct_sales_data-'+sessionStorage.getItem('application')) ?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/display-purchase-invoice'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Display Purchase Data
                        </div>
                      </Button>
                    </ListItem>
                  </List>

                </Collapse>
              }

            </div>

          ))}

      {/* supplier chargeback menu */}
      {sessionStorage.getItem('application') === 'Supplier Chargeback' &&
        supplierChargeBackPages
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansion(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={page.title == 'Master Data' || page.title == 'Contracts'
                    || page.title == 'Supplier Claims'
                    // || page.title == 'Expected Accruals'
                    || page.title == 'Claims Management'
                    || page.title == 'Analytics' || page.title == 'Potential Action Items'
                    || page.title == 'Accrual Postings' || page.title == 'Payment Postings'
                    ? '' : classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {page.title === 'Master Data' &&
                    <div className={classes.expandIcon}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }

                  {/* {page.title === 'Expected Accruals' &&
                    <div className={classes.expandIcon}>
                      {openExpectedAccruals ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  } */}
                  {page.title === 'Payment Postings' &&
                    <div className={classes.expandIcon}>
                      {openPaymentPostings ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Potential Action Items' &&
                    <div className={classes.expandIcon}>
                      {openPAC ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Contracts' &&
                    <div className={classes.expandIcon}>
                      {openContractSetup ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>
              </ListItem>

              {/* {page.title === 'Expected Accruals' &&
                <Collapse in={openExpectedAccruals} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}> */}
              {/* <ListItem
                    disableGutters
                    className={classes.item}
                    key={page.title}
                  >
                    <Button
                      className={classes.buttonSub}
                      activeClassName={classes.activeSub}
                      component={CustomRouterLink}
                      to='/calculation-simulation'
                      classes={{
                        root: classes.submenuButton
                      }}
                    >
                      <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                      <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Accrual Simulation
                      </div>
                    </Button>
                  </ListItem> */}
              {/* <ListItem
                    disableGutters
                    className={classes.item}
                    key={page.title}
                  >
                    <Button
                      className={classes.buttonSub}
                      activeClassName={classes.activeSub}
                      component={CustomRouterLink}
                      to='/financial-postings'
                    >
                      <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                      <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Claims Provision
                      </div>
                    </Button>
                  </ListItem> */}
              {/* <ListItem
                    disableGutters
                    className={classes.item}
                    key={page.title}
                  >
                    <Button
                      className={classes.buttonSub}
                      activeClassName={classes.activeSub}
                      component={CustomRouterLink}
                      to='/financial-postings-approvals'
                    >
                      <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                      <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Accrual Approvals
                      </div>
                    </Button>
                  </ListItem> */}
              {/* </Collapse> */}
              {/* } */}
              {page.title === 'Payment Postings' &&
                <Collapse in={openPaymentPostings} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_payment_postings-'+sessionStorage.getItem('application')) ?false : 'hide'}
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/supplier-payment-postings'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Payment Postings
                        </div>
                      </Button>
                    </ListItem>
                    </List>
                    <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_payment_postings-'+sessionStorage.getItem('application')) ?false : 'hide'}>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/payment-approvals'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Payment Approvals
                        </div>
                      </Button>
                    </ListItem>
                    {/* <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/partner-statement'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Partner Statement
                        </div>
                      </Button>
                    </ListItem> */}
                  </List>
                </Collapse>
              }
              {page.title === 'Potential Action Items' &&
                <Collapse in={openPAC} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/expiring-contracts'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Expiring Contracts
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/expired-contracts'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Expired Contracts
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }

              {page.title === 'Contracts' &&
                <Collapse in={openContractSetup} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))) ?false : 'hide'}
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/contract-setup'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Contract Setup
                        </div>
                      </Button>
                    </ListItem>
                    </List>
                    <List component="div" disablePadding
                     hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application')) ? false : 'hide'}
                     >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/approval-reject'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Contract Approval
                        </div>
                      </Button>
                    </ListItem>
                    </List>                  
                </Collapse>
              }

            </div>
          ))}
      {/* Promotions */}
      {sessionStorage.getItem('application') === 'Promotions' &&
        promotionsPlanningPages
        .filter(item => item.hidden != 'hide')
        .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansionDesigner(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={(page.title != 'Budgeting' && page.title != 'Analytics' && page.title != 'Historical Analysis' && page.title != 'Forecasting' && page.title != 'Master Data' && page.title != 'Transaction Data' && page.title != 'Configuration' && page.title != 'Pricing') || page.title == 'Sales Basis' && !openDesigner
                    ? classes.active : ''}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {page.title === 'Budgeting' &&
                    <div className={classes.expandIcon}>
                      {openBudgeting ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Master Data' &&
                    <div className={classes.expandIcon}>
                      {openExternalInputs ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Transaction Data' &&
                    <div className={classes.expandIcon}>
                      {openPromoTransaction ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Historical Analysis' &&
                    <div className={classes.expandIcon}>
                      {openHistoricalAnalysis ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Forecasting' &&
                    <div className={classes.expandIcon}>
                      {openForecasting ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Analytics' &&
                    <div className={classes.expandIcon}>
                      {openAnalytics ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Configuration' &&
                    <div className={classes.expandIcon}>
                      {openPromoConfig ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Pricing' &&
                    <div className={classes.expandIcon}>
                      {openPromoPricing ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>
              </ListItem>
              {page.title === 'Analytics' &&
                <Collapse in={openAnalytics} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/monthly-prom-type'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Promotion Type
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/cost-to-revenue'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Cost to Revenue
                        </div>
                      </Button>
                    </ListItem>

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/cost-allocation'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Cost Allocation
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/geographical-chart-us'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Regional Analysis
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/market-share'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Market Share
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/time-to-market'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Time To Market
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Budgeting' &&
                <Collapse in={openBudgeting} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_global_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_global_setup-'+sessionStorage.getItem('application')))?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promotion-global-setup'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Global Setup
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('download_promotion_setup-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('clone_promotion_setup-'+sessionStorage.getItem('application')))?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promotion-setup'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Promotion Setup
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_sim-'+sessionStorage.getItem('application'))?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/costing-simulation'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Costing Simulation
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_cost_postings-'+sessionStorage.getItem('application'))?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/costing-postings'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Costing Postings
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_costings-'+sessionStorage.getItem('application'))?'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/costing-approvals'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Costing Approvals
                        </div>
                      </Button>
                    </ListItem>

                  </List>
                </Collapse>
              }
              {page.title === 'Master Data' &&
                <Collapse in={openExternalInputs} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/material-master-promo'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Material Master
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/coverage-cost'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Coverage Cost
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/finance-cost'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Finance Cost
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promotion-territory'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Promotion Territory
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Transaction Data' &&
                <Collapse in={openPromoTransaction} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding>
                  <ListItem
                      disableGutters
                      className={classes.item}
                      key={'Sales Forecast'}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights  && secureLocalStorage.getItem('dYtz').role.rights.includes('post_sales_forecast-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                      onClick={() => setPromoSalesForecase(!openPromoSalesForecast)}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'#'}
                      >
                        <div className={classes.icon} >{page.icon}</div>
                        <div className={classes.title}>
                          {'Sales Forecast'}
                        </div>
                        <div className={classes.expandIcon}>
                          {openPromoSalesForecast ? <ExpandLess /> : <ExpandMore />}
                        </div>
                      </Button>
                    </ListItem>
                    <Collapse in={openPromoSalesForecast} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          exact
                          to='/sales-forecasting/sales-forecasting-excel-upload'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Upload  Sales Forecast
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          exact
                          to='/sales-forecasting-display'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Display Sales Forecast
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={'Indirect - Sales Data'}
                      onClick={() => setPromoIndirectSales(!openPromoIndirectSales)}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application')))?'block':'none'}}
                    >
                      <Button
                        className={classes.button}
                        /*activeClassName={(page.title != 'Budgeting' && page.title != 'Analytics' && page.title != 'Historical Analysis' && page.title != 'Forecasting' && page.title != 'Master Data' && page.title != 'Transaction Data' && page.title != 'Configuration' && page.title != 'Pricing') || page.title == 'Sales Basis' && !openDesigner
                          ? classes.active : ''}*/
                        component={CustomRouterLink}
                        to={'#'}
                      >
                        <div className={classes.icon} >{page.icon}</div>
                        <div className={classes.title}>
                          {'Indirect - Sales Data'}
                        </div>
                        <div className={classes.expandIcon}>
                          {openPromoIndirectSales ? <ExpandLess /> : <ExpandMore />}
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                  <Collapse in={openPromoIndirectSales} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_indirect_sales_data-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/indirect-sales-inovoice-query'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Upload  Sales Data
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_indirect_sales_data-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/build-indirect-sales-invoice'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Build Sales Data
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('delete_indirect_sales_data-'+sessionStorage.getItem('application'))?'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/delete-indirect-sales-invoice'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Delete Sales Data
                          </div>
                        </Button>
                      </ListItem>
                    </List>

                  </Collapse>
                </Collapse>

              }
              {page.title === 'Configuration' &&
                <Collapse in={openPromoConfig} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promo-configuration'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Dropdown Designer
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promo-mapping'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Promotion Mapping
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Pricing' &&
                <Collapse in={openPromoPricing} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-qualifier-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Pricing Attributes
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-type-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price Types
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-list-master'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price List Master
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-maintenance'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price Maintenance
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
            </div>
          ))}

      {sessionStorage.getItem('application') === 'Audit Lab' &&
        AuditLab
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansionDesigner(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={(page.title != 'Budgeting') || page.title == 'Sales Basis' && !openDesigner
                    ? classes.active : ''}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>

                </Button>
              </ListItem>
            </div>
          ))}
      {/* promotion optimization */}
      {sessionStorage.getItem('application') === 'Profit Optimization' &&
        profitOptimization
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansionDesigner(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={(page.title != 'Budgeting' && page.title != 'Historical Analysis' && page.title != 'Forecasting' && page.title != 'Config' && page.title!='External Data' && page.title != 'External Inputs') || page.title == 'Sales Basis' && !openDesigner
                    ? classes.active : ''}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                  {page.title === 'Historical Analysis' &&
                    <div className={classes.expandIcon}>
                      {openHistoricalAnalysis ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Forecasting' &&
                    <div className={classes.expandIcon}>
                      {openForecasting ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Config' &&
                    <div className={classes.expandIcon}>
                      {openConfig ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'External Data' &&
                    <div className={classes.expandIcon}>
                      {openExternalData ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>
              </ListItem>
              {page.title === 'Historical Analysis' &&
                <Collapse in={openHistoricalAnalysis} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/margin-analysis'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                         Flexible Analysis
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_flexible_analysis_report-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/flexible-analysis-report'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                         Flexible Analysis Report
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_promotion_plan-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promotion-trends'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Promotion Plan 
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_gross_to_net_trends-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/historical-trends'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Gross to Net Trends
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_profitability_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/promotion-sales-analysis'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Profitability Analysis
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_regional_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/geographical-chart-us'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Regional Analysis
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_market_share-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/market-share'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Market Share
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_time_to_market-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/time-to-market'
                        classes={{
                          root: classes.submenuButton
                        }}
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Time To Market
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }

              {page.title === 'Forecasting' &&
                <Collapse in={openForecasting} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_forecast_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_forecast_config-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/forecasting-config'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Forecasting Config
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_historical_sales_forecast-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/historical-sales-upload'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Historical Sales Upload
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_historical_sales_forecast-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-forecast-data'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Forecast Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_sales_forecast-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        component={CustomRouterLink}
                        activeClassName={classes.activeSub}
                        to='forecasting-sales'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Sales Forecasting
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_sales_forecast-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        component={CustomRouterLink}
                        activeClassName={classes.activeSub}
                        to='display-forecast'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Display Forecast
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_profitability_forecasting-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        component={CustomRouterLink}
                        to='#'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Profitability Forecasting
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Config' &&
                <Collapse in={openConfig} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_attributes-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/attributes-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Attributes
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_drivers-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_drivers-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/drivers'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Drivers
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_level-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_level-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/analysis-level'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Analysis Level
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_analysis_formula-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_analysis_formula-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/analysis-formula'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Analysis Formula
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'External Data' &&
                <Collapse in={openExternalData} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/external-data-excel-upload'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Upload External Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_analysis_data-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-analysis-data'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Analyis Data
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_external_config-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_external_config-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/external-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          External Config
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
            </div>
          ))}
      {sessionStorage.getItem('application') === 'Pricing' &&
        <div>
          {PricingPages
          .filter(item => item.hidden != 'hide')
          .map(page => (
            <div>
              <ListItem
                disableGutters
                className={classes.item}
                key={page.title}
                onClick={() => handleExpansion(page)}
              >
                <Button
                  className={classes.button}
                  activeClassName={(page.title == 'Price Config')||page.title=='Batch Job'
                  || page.title == 'Contracts' || page.title == 'Price Management' || page.title == 'Price Simulation' || page.title == 'Price Maintenance' || page.title == 'Price Optimization' || page.title=='Price Contracts'|| page.title=='Price Analytics'|| page.title =='Price Reporting'
                    ? '' : classes.active}
                  component={CustomRouterLink}
                  to={page.href}
                >
                  <div className={classes.icon} >{page.icon}</div>
                  <div className={classes.title}>
                    {page.title}
                  </div>
                   {page.title === 'Contracts' &&
                    <div className={classes.expandIcon}>
                      {openContractSetup ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Price Config' &&
                    <div className={classes.expandIcon}>
                      {openPricingConfig ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Price Maintenance' &&
                    <div className={classes.expandIcon}>
                      {openPriceMaintenance ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Price Management' &&
                    <div className={classes.expandIcon}>
                      {openPricingManagment ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Price Contracts' &&
                    <div className={classes.expandIcon}>
                      {openPriceAgreement ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {/* {page.title === 'Price Simulation' &&
                    <div className={classes.expandIcon}>
                      {openPriceSimulation ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  } */}
                   {page.title === 'Price Analytics' &&
                    <div className={classes.expandIcon}>
                      {openPriceAnalytics ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  { page.title === 'Price Reporting' &&
                  <div className={classes.expandIcon}>
                    {openPriceReporting ? <ExpandLess /> : <ExpandMore />}
                </div>
                  }
                  {page.title === 'Price Optimization' &&
                    <div className={classes.expandIcon}>
                      {openPriceOptimization ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                  {page.title === 'Batch Job' &&
                    <div className={classes.expandIcon}>
                      {openPricingBatchJob ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>
              </ListItem>
              {page.title === 'Price Config' &&
                <Collapse in={openPricingConfig} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_pricing_attributes-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-qualifier-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Price Attributes
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_type-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_type-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-type-config'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Price Types
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_list-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_list-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-list-master'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price List Master
                        </div>
                      </Button>
                    </ListItem>
                
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_simulation-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_simulation-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-calculation-formula'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Formula - Price Simulation
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('get_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_history-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_history-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-formula'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Formula - Price Points
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Price Maintenance' &&
                <Collapse in={openPriceMaintenance} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-maintenance'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price Maintenance
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={'External Data'}
                      onClick={() => setOpenPricingExternalData(!openPricingExternalData)}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_data-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'#'}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}>{page.icon}</div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          External Data
                        </div>
                        <div className={classes.expandIcon}>
                          {openPricingExternalData ? <ExpandLess /> : <ExpandMore />}
                        </div>
                      </Button>
                    </ListItem>
                    <Collapse in={openPricingExternalData} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/external-data-excel-upload-pricing'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Upload External Data
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/build-analysis-data-pricing'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Build Analyis Data
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_market_price_calculation-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/external-config-pricing'
                        >
                          <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            External Config
                          </div>
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                    {/* <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('build_price_data-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/build-off-Invoice-cost'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Build Price Data
                        </div>
                      </Button>
                    </ListItem> */}
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={'Market Price'}
                      onClick={() => setOpenMarketPrice(!openMarketPrice)}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_market_price_calculation-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'#'}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}>{page.icon}</div>
                        <div className={classes.title}>
                          Market Price
                        </div>
                        <div className={classes.expandIcon}>
                          {openMarketPrice ? <ExpandLess /> : <ExpandMore />}
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                  <Collapse in={openMarketPrice} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/competitor-data'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Competitor Data
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_competitor_data-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_competitor_data-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/list-price'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            List Price
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_market_price_calculation-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/pricing-market-price'
                        >
                          <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Market Price Calculation
                          </div>
                        </Button>
                      </ListItem>
                    </List>

                  </Collapse>
                </Collapse>
              }
              {page.title === 'Price Management' &&
                <Collapse in={openPricingManagment} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_calculation-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-calculation'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price Points
                        </div>
                      </Button>
                    </ListItem>

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={'Price Catalog'}
                      onClick={() => setOpenPriceCatalog(!openPriceCatalog)}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights &&( secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_catalog-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('run_edi_file-'+sessionStorage.getItem('application'))) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'#'}
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}>{page.icon}</div>
                        <div className={classes.title}>
                          {'Price Catalog'}
                        </div>
                        <div className={classes.expandIcon}>
                          {openPriceCatalog ? <ExpandLess /> : <ExpandMore />}
                        </div>
                      </Button>
                    </ListItem>
                    <Collapse in={openPriceCatalog} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                    <List component="div" disablePadding>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_price_catalog-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/price-catalog'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            Price Catalog
                          </div>
                        </Button>
                      </ListItem>
                      <ListItem
                        disableGutters
                        className={classes.item}
                        key={page.title}
                        style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_edi_file-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                      >
                        <Button
                          className={classes.buttonSub}
                          activeClassName={classes.activeSub}
                          component={CustomRouterLink}
                          to='/EDI-file'
                          classes={{
                            root: classes.submenuButton
                          }}
                        >
                          <div className={classes.icon} style={{ fontSize: '1em' }}><DashIcon /></div>
                          <div className={`${classes.title} ${classes.listSideMenu}`}>
                            EDI File
                          </div>
                        </Button>
                      </ListItem>
                    </List>

                  </Collapse>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_policy_exceptions-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-policy-exceptions-report'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Price Policy Exceptions
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                  
                </Collapse>
              }
              {page.title === 'Batch Job' &&
                <Collapse in={openPricingBatchJob} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                    hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_batch_job-'+sessionStorage.getItem('application')) ? false : 'hide'}>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-batchjob-setup'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><AccountBoxOutlinedIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`} >
                          Batch Job Setup
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                  <List component="div" disablePadding
                  hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_batch_job-'+sessionStorage.getItem('application')) ? false : 'hide'}>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/pricing-batchjob-status'
                      >
                        <div className={classes.icon} style={{ fontSize: '1em' }}><DeleteSweepIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Batch Job Status
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }             
              {/* {page.title === 'Price Simulation' &&
                <Collapse in={openPriceSimulation} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_cpq-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-simulation-cpq'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          CPQ
                        </div>
                      </Button>
                    </ListItem> */}
                    
                    {/* <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_pricing_sim_report-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-simulation-report'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Pricing Simulation Report
                        </div>
                      </Button>
                    </ListItem> */}
                  {/* </List>
                </Collapse>
              } */}
              {page.title === 'Price Analytics' &&
                <Collapse in={openPriceAnalytics} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >

                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_customer_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-analytics'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                           Analytics
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-revenue-margin-analytics'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Revenue-Margin Analytics
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                     // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                    <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/top-growing-declining-performers'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                       Top Bottom Performers
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                     // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_material_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                    <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/margin-price-percentage'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                       Margin Price Diff
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_reference_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/margin-analytics'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                           Margin Analytics
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_reference_level_analysis-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-waterfallchart'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                           Gross to Net
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_aggregated_margin-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/customer-margin'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Portfolio Margin
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      // style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_aggregated_margin-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        // component={CustomRouterLink}
                        // to=''
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                        Profitability
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Price Reporting' &&
                <Collapse in={openPriceReporting} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_pricing_sim_report-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-simulation-report'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Pricing Simulation Report
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Price Optimization' &&
                <Collapse in={openPriceOptimization} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  >
                    
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_historical_price_simulation-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-simulation-historical'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Historical
                        </div>
                      </Button>
                    </ListItem>
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                      style={{display:secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('run_forecast_price_simulation-'+sessionStorage.getItem('application')) ? 'block':'none'}}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/price-simulation-forecast'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Forecast
                        </div>
                      </Button>
                    </ListItem>
                  </List>
                </Collapse>
              }
              {page.title === 'Price Contracts' &&
                <Collapse in={openPriceAgreement} timeout="auto" unmountOnExit style={{ paddingLeft: 15 }}>
                  <List component="div" disablePadding
                  hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && (secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('get_contracts-'+sessionStorage.getItem('application'))||secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+sessionStorage.getItem('application'))) ?false : 'hide'}
                  >
                    
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/contract-setup'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                         Contract Setup
                        </div>
                      </Button>
                    </ListItem>
                    </List>
                    <List component="div" disablePadding
                     hidden={secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('approve_contract-'+sessionStorage.getItem('application')) ? false : 'hide'}
                     >
                    <ListItem
                      disableGutters
                      className={classes.item}
                      key={page.title}
                    >
                      <Button
                        className={classes.buttonSub}
                        activeClassName={classes.activeSub}
                        component={CustomRouterLink}
                        to='/approval-reject'
                      >
                        <div className={classes.icon} style={{ fontSize: 13 }}><OperationalIcon /></div>
                        <div className={`${classes.title} ${classes.listSideMenu}`}>
                          Contract Approval
                        </div>
                      </Button>
                    </ListItem>
                    </List>
                </Collapse>
              }
            </div>
          ))}
        </div>
      }
    </List>

  );

};

SideBarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SideBarNav;
