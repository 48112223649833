import React, { useEffect, forwardRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    IconButton,
    OutlinedInput,
    FormLabel
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import momentt from 'moment-timezone';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        // marginTop: 10,
        height: "37px",
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        // fontSize: theme.typography.h3.fontSize,
        fontSize: 14,
        height: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));
const OrganizationData = forwardRef((props) => {
    const [organizationStore, setOrganizationStore] = React.useState([]);
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('')
    const [purchaseOrganization, setPurchaseOrganization] = React.useState('');
    const [companyCode, setCompanyCode] = React.useState('');
    const [payer, setPayer] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [alternativePayee, setAlternativePayee] = React.useState('');
    const [headOffice, setHeadOffice] = React.useState('');
    const [currentEditId, setCurrentEditId] = React.useState('');
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.organizations)
            setOrganizationStore(props.supplierMasterData.organizations.reverse());
    }, [props.supplierMasterData]);
    useEffect(() => {
        props.onChange(organizationStore);
    });
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setPurchaseOrganization(item.purchase_org)
        setCompanyCode(item.company_code)
        setFlexAttribute3(item.flex_attribute_3)
        setFlexAttribute4(item.flex_attribute_4)
        setAlternativePayee(item.alternate_payee)
        setHeadOffice(item.head_office)
        setPayer(item.payer)
        if (item.ID)
            setCurrentEditId(item.ID);
    }
    function handlePurchaseOrganization(event) {
        setPurchaseOrganization(event.target.value);
    }
    function handleAlternativePayee(event) {
        setAlternativePayee(event.target.value);
    }
    function handleHeadOffice(event) {
        setHeadOffice(event.target.value);
    }
    function handleCompanyCode(event) {
        setCompanyCode(event.target.value);
    }
    function handlePayer(event) {
        setPayer(event.target.value);
    }
    function handleFlexAttribute3(event) {
        setFlexAttribute3(event.target.value);
    }
    function handleFlexAttribute4(event) {
        setFlexAttribute4(event.target.value);
    }

    var data = {
        "purchase_org": purchaseOrganization,
        "company_code": companyCode,
        "flex_attribute_3": flexAttribute3,
        "flex_attribute_4": flexAttribute4,
        "alternate_payee": alternativePayee,
        "head_office": headOffice,
    };
    function handleSubmit() {
        if (currentEditId)
            data["ID"] = currentEditId;
        if (editIndex || editIndex === 0) {
            var editedArray = [...organizationStore];
            editedArray[editIndex] = data;
            setOrganizationStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setOrganizationStore([...organizationStore, data]);
            props.onChange([...organizationStore, data]);
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setPurchaseOrganization('');
        setCompanyCode('');
        setFlexAttribute3('');
        setFlexAttribute4('');
        setAlternativePayee('');
        setHeadOffice('');
        setCurrentEditId('');
    }
    const handleDeleteLocally = (index) => {
        setOrganizationStore(item => item.filter((item, i) => i !== index));
        props.onChange(organizationStore.filter((item, i) => i !== index))
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Purchase Organization*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={purchaseOrganization}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePurchaseOrganization} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Company Code*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={companyCode}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCompanyCode} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 3
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute3}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute3} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 4
                                        </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute4}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute4} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Alternate Payee*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={alternativePayee}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleAlternativePayee} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Head Office*
                                        </FormLabel>
                                        <OutlinedInput
                                            value={headOffice}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleHeadOffice} />
                                    </div>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 19, marginTop: '0.5rem' }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={purchaseOrganization && companyCode && alternativePayee && headOffice ? false : true}
                                                    onClick={handleSubmit}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="SUPPLIER DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Purchase Organization</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Company Code</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Flex Attribute 3</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Flex Attribute 4</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Alternate Payee</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Head Office</TableCell>
                                                <TableCell align='center' className={classes.tabHead} style={{ width: 70 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.purchase_org}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.company_code}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.flex_attribute_3}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.flex_attribute_4}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.alternate_payee}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.head_office}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                {<DeleteForeverIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );
});
const mapStateToProps = state => {
    return {
        supplierMasterData: state.customerData.supplierMasterViewData
    }
};
export default connect(mapStateToProps, null)(OrganizationData);