import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import { getCollaborationDesignerData } from '../../../../../../../redux/actions'
const handleClick = (pge) => {
    history.push(pge)
}
const CollaborationPortal = (props) => {
    const [item, setItem] = useState([])
    const [menuItems, setMenuItems] = useState([])
    useEffect(() => {
        props.getCollaborationData(1, 10);
    }, []);
    useEffect(() => {
        var temp = [];
        var temp2 = []
        var functionality = [];
        if (props.collaborationData && props.collaborationData.records && props.collaborationData.records.length > 0) {
            props.collaborationData.records.map((item) => {
                functionality.push(item.functionality)
            })
            //  var require=functionality.filter((v,i,a)=>a.findIndex(v2=>(v2===v))===i) 
            if (functionality.includes('Exception Management')) {
                temp.push({ 'Exception Management': '/exception-management' })
            }
            if (functionality.includes('On Demand Queries')) {
                temp.push({ 'On Demand Queries': '/collaboration-portal' })
            }
            if (functionality.includes('Calculation Analysis')) {
                temp.push({ "Calculation Analysis": "/contract-trends-cp" })
            }
            if (functionality.includes('Contract SetUp')) {
                temp.push({ 'Contract SetUp': '/contract-setup' })
            }
            if (functionality.includes('Claim Reconcile')) {
                temp.push({ 'Claim Reconcile': '/customer-outgoing-claims' })
            }
            if (functionality.includes('On Demand Videos')) {
                temp2.push({ 'On Demand Videos': '/help-desk-cp' })
            }
            if (functionality.includes('GPT')) {
                temp2.push({ 'GPT': '/chat-gpt' })
            }
            if (functionality.includes('Agent Chat')) {
                temp2.push({ 'Agent Chat': '/community' })
            }
            temp.push({ 'Help': temp2 })
            setMenuItems(temp)
        }
    }, [props.collaborationData])
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })} style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>{Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}>
                            {Object.values(item)[0].map((e) => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>

                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )
                }
            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
        collaborationData: state.CollaborationDesignerData.CollaborationData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
        getCollaborationData: (pagination, limit) => dispatch(getCollaborationDesignerData(pagination, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationPortal);