import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, OutlinedInput, FormLabel, Radio, RadioGroup, FormControlLabel, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, DropdownArray, Dropdown, ChipInput } from '../../components/Inputs';
import SimpleDialog from '../../components/Dialog';
import { runBuildIndirectSalesData, getDefaultValues } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset',
        width: '339px'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    calendarLeftGridContainer: {
        padding: '15px 0px'
    }
}));

const DeleteIndirectSalesQuery = props => {
    const classes = useStyles();
    const { className } = props;
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [billingDocumentNumber, setBillingDocumentNumber] = React.useState([]);
    const [billingDocumentLine, setBillingDocumentLine] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [salesTracingDateStart, setSalesTracingDateStart] = React.useState(null);
    const [salesTracingDateEnd, setSalesTracingDateEnd] = React.useState(null);
    const [resaleDateStart, setResaleDateStart] = React.useState(null);
    const [resaleDateEnd, setResaleDateEnd] = React.useState(null);
    const [billingDocMultiple, setBillingDocMultiple] = React.useState([]);
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [billingDocLineRange, setBillingDocLineRange] = React.useState('');
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [salesOrgMultiple, setSalesOrgMultiple] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [channelPartnerId, setChannelPartnerId] = React.useState([]);
    const [uniqueIdentifier, setUniqueIdentifier] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    const [open, setOpen] = useState(false);
    const formData = {
        "billing_date.range": (billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (billingDateEnd ? Moment(billingDateEnd).local().format('YYYY-MM-DD') : ''),
        "billing_doc_number.in": billingDocumentNumber.toString(),
        "billing_line.in": billingDocumentLine.toString(),
        "sales_Tracing_date.range": (salesTracingDateStart ? Moment(salesTracingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (salesTracingDateEnd ? Moment(salesTracingDateEnd).local().format('YYYY-MM-DD') : ''),
        "resale_date.range": (resaleDateStart ? Moment(resaleDateStart).local().format('YYYY-MM-DD') : '') + ',' + (resaleDateEnd ? Moment(resaleDateEnd).local().format('YYYY-MM-DD') : ''),
        "company_code.in": companyCodeMultiple.toString(),
        "sales_org.in": salesOrgMultiple.toString(),
        "calendar_year_month.in": calendarYearMonth.toString(),
        "material_number.in": materialNumberMultiple.toString(),
        "material_group.in": materialGroupMultiple.toString(),
        "product_hierarchy.in": productHierarchyMultiple.toString(),
        "plant.in": plantMultiple.toString(),
        "profit_center.in": profitCenterMultiple.toString(),
        "flex_attribute1.in": flexAttribute1.toString(),
        "flex_attribute2.in": flexAttribute2.toString(),
        "customer_number.in": customerNumber.toString(),
        "distribution_channel.in": distributionChannel.toString(),
        "division.in": division.toString(),
        "employee_number.in": employeeNumber.toString(),
        "employee_name.in": employeeName.toString(),
        "territory_id .in": terittoryID.toString(),
        "sales_district.in": salesDistrict.toString(),
        "sales_office.in": salesOffice.toString(),
        "sales_group.in": salesGroup.toString(),
        "channel_partner_id": channelPartnerId.toString(),
        "unique_identifier": uniqueIdentifier.toString()
    };
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData, 'delete', radioGroupValue,);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setRadioGroupValue('');
        setBillingDocumentNumber([]);
        setBillingDocumentLine([]);
        setDistributionChannel([]);
        setDivision([]);
        setEmployeeNumber([]);
        setEmployeeName([]);
        setTerittoryID([]);
        setSalesDistrict([]);
        setSalesOffice([]);
        setSalesGroup([]);
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setCustomerNumber([]);
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocMultiple([]);
        setBillingDocLineMultiple([]);
        setBillingDocLineRange('');
        setCompanyCodeMultiple([]);
        setSalesOrgMultiple([]);
        setMaterialNumberMultiple([]);
        setCalendarYearMonth([]);
        setMaterialGroupMultiple([]);
        setProductHierarchyMultiple([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
        setSalesTracingDateStart(null)
        setSalesTracingDateEnd(null)
        setResaleDateStart(null)
        setResaleDateEnd(null)
        setChannelPartnerId([])
        setUniqueIdentifier([])
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }
    const handleSalesOrgMultiple = (newValue) => {
        setSalesOrgMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);

    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
    }

    const handleCalendarYearMonth = (newValue) => {
        setCalendarYearMonth(newValue);
    }

    const handleChannelPartnerId = (newValue) => {
        setChannelPartnerId(newValue);
    }

    const handleCustomerNumber = (newValue) => {
        setCustomerNumber(newValue);
    }

    const handleFlexAttribute1 = (newValue) => {
        setFlexAttribute1(newValue);
    }

    const handleFlexAttribute2 = (newValue) => {
        setFlexAttribute2(newValue);
    }

    const handleUniqueIdentifier = (newValue) => {
        setUniqueIdentifier(newValue);
    }

    const handleBillingDocumentNumber = (newValue) => {
        setBillingDocumentNumber(newValue);
    }

    const handleBillingDocumentLine = (newValue) => {
        setBillingDocumentLine(newValue);
    }

    const handleDistributionChannel = (newValue) => {
        setDistributionChannel(newValue);
    }

    const handleDivision = (newValue) => {
        setDivision(newValue);
    }

    const handleEmployeeNumber = (newValue) => {
        setEmployeeNumber(newValue);
    }

    const handleEmployeeName = (newValue) => {
        setEmployeeName(newValue);
    }

    const handleTerittoryID = (newValue) => {
        setTerittoryID(newValue);
    }

    const handleSalesDistrict = (newValue) => {
        setSalesDistrict(newValue);
    }

    const handleSalesOffice = (newValue) => {
        setSalesOffice(newValue);
    }

    const handleSalesGroup = (newValue) => {
        setSalesGroup(newValue);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Delete Indirect Sales Data </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Calendar Year Month</FormLabel>
                                    <ChipInput
                                        data={calendarYearMonth}
                                        onChange={newValue => handleCalendarYearMonth(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setCalendarYearMonth(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid><Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Billing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        height: 33,
                                                        // marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={date => handleBillingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        height: 33,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={date => handleBillingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Sales Tracing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        height: 33,
                                                        //marginTop: 10,
                                                        border: !salesTracingDateStart && salesTracingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={salesTracingDateStart}
                                                onChange={date => setSalesTracingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        marginLeft: 10,
                                                        marginRight: 15,
                                                        height: 33
                                                    }
                                                }}
                                                value={salesTracingDateEnd}
                                                onChange={date => setSalesTracingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={salesTracingDateStart ? salesTracingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >

                                <FormLabel classes={{ root: classes.fontSetting }}>Resale Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        height: 33,
                                                        border: !resaleDateStart && resaleDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={resaleDateStart}
                                                onChange={date => setResaleDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        marginLeft: 10,
                                                        marginRight: 15,
                                                        height: 33
                                                    }
                                                }}
                                                value={resaleDateEnd}
                                                onChange={date => setResaleDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={resaleDateStart ? resaleDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Channel Partner ID</FormLabel>
                                    <ChipInput
                                        data={channelPartnerId}
                                        onChange={newValue => handleChannelPartnerId(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setChannelPartnerId(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Number</FormLabel>
                                    <ChipInput
                                        data={customerNumber}
                                        onChange={newValue => handleCustomerNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setCustomerNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput
                                        data={plantMultiple}
                                        onChange={newValue => handlePlantMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handlePlantMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput
                                        data={profitCenterMultiple}
                                        onChange={newValue => handleProfitCenterMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleProfitCenterMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput
                                        data={materialNumberMultiple}
                                        onChange={newValue => handleMaterialNumberMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialNumberMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput
                                        data={materialGroupMultiple}
                                        onChange={newValue => handleMaterialGroupMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Product Hierarchy</FormLabel>
                                    <ChipInput
                                        data={productHierarchyMultiple}
                                        onChange={newValue => handleProductHierarchyMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleProductHierarchyMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput
                                        data={flexAttribute1}
                                        onChange={newValue => handleFlexAttribute1(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Unique Identifier</FormLabel>
                                    <ChipInput
                                        data={uniqueIdentifier}
                                        onChange={newValue => handleUniqueIdentifier(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setUniqueIdentifier(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Number</FormLabel>
                                    <ChipInput
                                        data={billingDocumentNumber}
                                        onChange={newValue => handleBillingDocumentNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setBillingDocumentNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing  Line</FormLabel>
                                    <ChipInput
                                        data={billingDocumentLine}
                                        onChange={newValue => handleBillingDocumentLine(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setBillingDocumentLine(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput
                                        data={flexAttribute2}
                                        onChange={newValue => handleFlexAttribute2(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexAttribute2(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput
                                        data={companyCodeMultiple}
                                        onChange={newValue => handleCompanyCodeMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCompanyCodeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Org</FormLabel>
                                    <ChipInput
                                        data={salesOrgMultiple}
                                        onChange={newValue => handleSalesOrgMultiple(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSalesOrgMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Distribution Channel</FormLabel>
                                    <ChipInput
                                        data={distributionChannel}
                                        onChange={newValue => handleDistributionChannel(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setDistributionChannel(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Division</FormLabel>
                                    <ChipInput
                                        data={division}
                                        onChange={newValue => handleDivision(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setDivision(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Number</FormLabel>
                                    <ChipInput
                                        data={employeeNumber}
                                        onChange={newValue => handleEmployeeNumber(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setEmployeeNumber(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Name</FormLabel>
                                    <ChipInput
                                        data={employeeName}
                                        onChange={newValue => handleEmployeeName(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setEmployeeName(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Territory ID</FormLabel>
                                    <ChipInput
                                        data={terittoryID}
                                        onChange={newValue => handleTerittoryID(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setTerittoryID(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales District</FormLabel>
                                    <ChipInput
                                        data={salesDistrict}
                                        onChange={newValue => handleSalesDistrict(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesDistrict(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Office</FormLabel>
                                    <ChipInput
                                        data={salesOffice}
                                        onChange={newValue => handleSalesOffice(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesOffice(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Group</FormLabel>
                                    <ChipInput
                                        data={salesGroup}
                                        onChange={newValue => handleSalesGroup(newValue)}
                                    />
                                    {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setSalesGroup(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                </div>
                            </Grid>


                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Staging sales data"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Delete Staging Sales Data </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Sales data"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Delete Sales Data </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                disabled={!radioGroupValue ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to Delete ' + radioGroupValue + '?'} handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData, type, item) => dispatch(runBuildIndirectSalesData(formData, type, item)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteIndirectSalesQuery);
