import React, { useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSingleAnalysisDesigner } from '../../redux/actions';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const ViewAnalyticsDesigner = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [editID, setEditID] = React.useState(0);
    const [filters, setFilters] = React.useState('');
    const [analyticName, setAnalyticName] = React.useState('');
    const [dashBoardName, setDashBoardName] = React.useState('');
    const [dashBoardDescription, setDashBoardDescription] = React.useState('');
    const [applicationType, setApplicationType] = React.useState([]);
    const [functionality, setFunctionality] = React.useState('');
    const [securityRole, setSecurityRole] = React.useState([])
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getSingleAttributeMapping(appId)
    }, []);
    useEffect(() => {
        if (props.attributeMapData) {
            setApplicationType(props.attributeMapData.application_type);
            setSecurityRole(props.attributeMapData.security_role);
            setFunctionality(props.attributeMapData.functionality);
            if (props.attributeMapData.analytic_name)
                setAnalyticName(props.attributeMapData.analytic_name);
            if (props.attributeMapData.dashboard_name)
                setDashBoardName(props.attributeMapData.dashboard_name);
            setDashBoardDescription(props.attributeMapData.dashboard_description || ''); // Explicitly setting empty string when undefined
            // if (props.attributeMapData.dashboard_description)
            //     setDashBoardDescription(props.attributeMapData.dashboard_description);
            if (props.attributeMapData.filters) {
                const { application_type, ...restFilters } = props.attributeMapData.filters;
                setFilters(restFilters);
            } else {
                setFilters('');
            }
        }
    }, [props.attributeMapData]);

    const capitalizeWords = (rolesArray) => {
        return rolesArray.map(role => role.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ');
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/dashboard-designer'
                >
                    DashBoard Designer
                </Link>
                <Typography color="Primary" variant='h4'>View DashBoard Designer</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                {props.attributeMapData ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead} >Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Persona</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >DashBoard Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Analytic Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >DashBoard Description</TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Filters </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                <StyledTableRow  >
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{applicationType}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{capitalizeWords(securityRole)}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{dashBoardName}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{analyticName}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{dashBoardDescription}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{JSON.stringify(filters).replace(/"|{|}|_/g, ' ')}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        attributeMapData: state.AnalyticsDesignerData.AnalysisDataSingle,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getSingleAttributeMapping: (id) => dispatch(getSingleAnalysisDesigner(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAnalyticsDesigner);


