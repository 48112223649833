import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    FormLabel, TextField, Checkbox, Button, Paper
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({

    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    contractContiner: {
        padding: '15px 0px 18px'
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const AutoCompleteMultiSelect = forwardRef((props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const filter = createFilterOptions();
    const [singleData, setSingleData] = React.useState(props.defaultValue ? 0 : '');
    const [changedFlag, setChangedFlag] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [multiData, setMultiData] = useState([]);



    const handleSelectedData = (e, value, type) => {
        if (type == 'single') {
            setSingleData(value)
            props.onChange(value)
        }
        else {
            if (type == 'clear') {
                setSelected([])
                setMultiData([])
                props.onChange([])
            }
            else if (type == 'remove-option') {
                setSelected(value)
                setMultiData(value)
                props.onChange(value)
            }
            else {
                let newSelected = value;
                if (value.length > 0) {
                    setSelected(newSelected)
                }
                else {
                    setSelected([])
                }
            }
        }
    };
    const reset = () => {
        setSingleData('');
    };
    useEffect(() => {
        setChangedFlag(0);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            reset: reset,
            value: !changedFlag && props.prevalue ? props.prevalue : singleData
        };
    });

    useEffect(() => {
        if (props.prevalue) {
            if (props.multiple) {
                setSelected(props.prevalue)
                setMultiData(props.prevalue)
            }
            else
                setSingleData(props.prevalue)
        }
    }, [props.prevalue])
    function onAdd() {
        setMultiData(selected)
        props.onChange(selected)
    }
    function onCancel(type) {


        setSelected(multiData)
        props.onChange(multiData)

    }

    return (
        <div className={classes.contractContiner}>
            {props.heading &&
                <FormLabel required={props.required ? props.required : false} style={{ fontSize: theme.typography.h3.fontSize }}>{props.heading} </FormLabel>
            }
            {props.multiple ?
                <Autocomplete
                    multiple
                    id={props.id}
                    limitTags={3}
                    size="small"
                    options={props.data}
                    getOptionLabel={(option) => {
                        return option ? option.toString() : ''
                    }}
                    defaultValue={multiData}
                    value={selected}
                    renderInput={params => (

                        <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important', paddingBottom: '4px !important' }} />

                    )}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push(inputValue);
                        }
                        return filtered;
                    }}
                    onClose={() => setSelected(multiData)}
                    // onInputChange={(e,value,reason)=>handleContractNumber(e,value,reason)}
                    classes={{
                        inputRoot: { borderRadius: '0px !important' }
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                color='primary'
                                checked={selected} />
                            {option}

                        </li>
                    )}
                    style={{ width: "100%", marginBottom: 15, marginTop: 10 }}
                    onChange={(event, newValue, reason) => { handleSelectedData(event, newValue, reason) }}
                    PaperComponent={({ children }) => {
                        return (
                            <Paper>
                                {children}
                                <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onMouseDown={onCancel}>
                                        Cancel
                                    </Button>

                                    <Button variant="contained" color="primary" className={classes.btn} onMouseDown={onAdd} >
                                        Apply
                                    </Button>
                                </div>
                            </Paper>
                        );
                    }}
                />
                :
                <Autocomplete
                    id={props.id}
                    size="small"
                    options={props.data}
                    getOptionLabel={options => options}
                    defaultValue={singleData}
                    value={singleData}
                    // renderTags={}
                    renderInput={params => (

                        <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important', paddingBottom: '4px !important' }} />

                    )}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push(inputValue);
                        }
                        return filtered;
                    }}
                    classes={{
                        inputRoot: { borderRadius: '0px !important' }
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {option}

                        </li>
                    )}
                    style={{ width: "100%", marginBottom: 15, marginTop: 10 }}
                    onChange={(event, newValue) => { handleSelectedData(event, newValue, 'single') }}
                />
            }
        </div >
    );

});



export default AutoCompleteMultiSelect;