import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getSalesForecastFormatFields
} from '../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const SalesForecastViewQuery = props => {
    const classes = useStyles();
    const [salesForecastData, setSalesForecastData] = React.useState(sessionStorage.getItem('sales-forecast-query') ? JSON.parse(sessionStorage.getItem('sales-forecast-query')) : []);

    useEffect(() => {
        props.getSalesForecastFormatFields();
    }, []);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">

                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/sales-forecasting/sales-forecasting-excel-upload'
                >
                    Sales Forecasting Format
                </Link>
                <Typography color="textPrimary" variant='h1'>View Format</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {salesForecastData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesForecastData).length > 0 && Object.entries(salesForecastData)

                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'organization_id' && k != 'created_by_id' && k != 'updated_by_id' && v > 0)
                                                        .sort(function (a, b) {
                                                            return a[1] > b[1] ? 1 : -1;
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        {JSON.parse(sessionStorage.getItem('sales-forecast-query'))[k] ? JSON.parse(sessionStorage.getItem('sales-forecast-query'))[k] : ''}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {

        getSalesForecastFormatFields: () => dispatch(getSalesForecastFormatFields())
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.dataSetupData.salesForecastFormatFieldData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesForecastViewQuery);