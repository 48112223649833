import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useMediaQuery, Button } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import ViewColumn from '@mui/icons-material/ViewColumn';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const DsiplayForecastResultView = props => {
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();

    const [dataRows, setDataRows] = React.useState([]);

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    useEffect(() => {
        var tempRows = [];
        console.log(props)
        if (props.displayForecastResults && props.displayForecastResults.length > 0)
            props.displayForecastResults
                .map(e => {
                    tempRows.push({
                        ai_algorithm: e.ai_algorithm,
                        billing_date: e.billing_date,
                        forecast_level: e.forecast_level,
                        quantity: e.quantity,
                        sales_data_source: e.sales_data_source,
                        class_of_trade: e.class_of_trade,
                        cost: e.cost,
                        country: e.country,
                        currency: e.currency,
                        customer_name: e.customer_name,
                        customer_number: e.customer_number,
                        customer_industry: e.customer_industry,
                        distribution_channel: e.distribution_channel,
                        division: e.division,
                        employee_number: e.employee_number,
                        material_group: e.material_group,
                        material_number: e.material_number,
                        customer_chain: e.customer_chain,
                        customer_classification: e.customer_classification,
                        customer_group: e.customer_group,
                        supplier_name: e.supplier_name,
                        supplier_number: e.supplier_number,
                        sales_org: e.sales_org,
                        net_value: e.net_value,
                        // flex_attribute01: e.,
                        // flex_attribute02: e.,
                        // flex_attribute03: e.,
                        // flex_attribute04: e.,
                        // flex_attribute05: e.,
                        // flex_key_figure01: e.,
                        // flex_key_figure02: e.,
                        // flex_key_figure03: e.,
                        // flex_key_figure04: e.,
                        // flex_key_figure05: e.,
                        // customer_group1:e.,
                        // customer_group2: e.,
                        // customer_industry: e.customer_industry,
                        //calendar_year_month:e.calendar_year_month ,
                        // material_description: e.material_description,
                        // material_group1: e.,
                        // material_group2: e.,
                    });
                })
        setDataRows(tempRows);
    }, [props.displayForecastResults]);
    const columns = [
        {
            field: 'ai_algorithm',
            title: 'AI Algorithm',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.ai_algorithm}</a>
        },
        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.customer_number}</a>
        },
        {
            field: 'forecast_level',
            title: 'Forecast level',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.forecast_level}</a>

        },
        {
            field: 'quantity',
            title: 'Quantity',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.quantity}</a>
        },
        {
            field: 'sales_data_source',
            title: 'Sales Data Source',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.sales_data_source}</a>
        },
        {
            field: 'billing_date',
            title: 'Billing Date',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.billing_date.split("T")[0]}</a>

        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.material_number}</a>
        },
        {
            field: 'cost',
            title: 'Cost',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.cost}</a>
        },
        {
            field: 'net_value',
            title: 'Net Value',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.net_value}</a>
        },
        {
            field: 'class_of_trade',
            title: 'Class of trade',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.class_of_trade}</a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.country}</a>
        },
        {
            field: 'currency',
            title: 'Currency',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.currency}</a>
        },
        {
            field: 'customer_chain',
            title: 'Customer Chain',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.customer_chain}</a>
        },
        {
            field: 'supplier_name',
            title: 'Supplier Name',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.supplier_name}</a>
        },
        {
            field: 'supplier_number',
            title: 'Supplier Number',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.supplier_number}</a>
        },
        {
            field: 'sales_org',
            title: 'Sales Org',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.sales_org}</a>
        },
        {
            field: 'distribution_channel',
            title: 'Distribution Channel',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.distribution_channel}</a>
        },
        {
            field: 'customer_classification',
            title: 'Customer Classification',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.customer_classification}</a>
        },
        {
            field: 'customer_group',
            title: 'Customer Group',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.customer_group}</a>
        },
        {
            field: 'customer_industry',
            title: 'Customer Industry',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.customer_industry}</a>
        },
        {
            field: 'customer_name',
            title: 'Customer Name',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.customer_name}</a>
        },
        {
            field: 'division',
            title: 'Division',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.division}</a>
        },
        {
            field: 'employee_number',
            title: 'Employee Number',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.employee_number}</a>
        },
        {
            field: 'material_group',
            title: 'Material Group',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.material_group}</a>
        },
    ];

    const theme = createTheme({
        overrides: {
            MuiFormControl: {
                root: {
                },
            },
            MuiTableCell: {
                root: {
                    padding: 10
                },
            },
            MuiToolbar: {
                root: {
                    minHeight: 0
                },
            },
        }
    });
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 22 }}> Display Forecast Data </div>

                </div>
                {props.displayForecastResults && props.displayForecastResults.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />

                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        displayForecastResults: state.forecastData.displayForecastResult,
        loading: state.forecastData.loading,
    }
};

const mapDispatchToProps = dispatch => {
}

export default connect(mapStateToProps, mapDispatchToProps)(DsiplayForecastResultView);