import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    Dialog, DialogActions, DialogContent, DialogTitle,
    List, ListItem, ListItemText, Fab
} from '@mui/material';
import { Edit, Visibility, Publish, DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {

    createPriceMaintenanceUpload,
    fetchPriceListDataUpload,
    uploadListDataFile
} from '../../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LibraryAdd } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        //padding: 29,
        // marginTop: 16,

    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        zIndex: 1
    },
    tableFont: {
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }

}));
const PriceListDataExcelUpload = props => {
    useEffect(() => {
        props.getListFormatData();
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [listData, setListData] = useState([]);
    const [priceListID, setPriceListID] = useState('');
    const [errorList, setErrorList] = useState('');
    const [dialog, setDialog] = useState(false);
    const [open, setOpen] = useState(false);

    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadListFile(currentEditableID, priceListID, data);
        setCurrentEditableID('')
        setPriceListID('')
        setOpen(true)
    }
    useEffect(() => {
        if (props.listDataUploadError) {
            setErrorList(props.listDataUploadError);
            if (Object.entries(props.listDataUploadError).length > 0 && props.listDataUploadError.error_list != null && open)
                setDialog(true)
        }
    }, [props.listDataUploadError]);
    useEffect(() => {
        if (props.listFormatData) {
            setListData(props.listFormatData)
        }
    }, [props.listFormatData]);
    function runQuery(item) {
        setCurrentEditableID(item.id);
        setPriceListID(item.price_list_number)
        inputFile.current.click();
    }
    function editQuery(item) {
        sessionStorage.setItem('pricing-maintenance-query', JSON.stringify(item))
        console.log(item)
        if (!item.mandatory)
            history.push('/pricing-maintenance/pricing-maintenance-pricetype-excel-upload/edit/' + item.id);
        else
            history.push('/pricing-maintenance/pricing-maintenance-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        sessionStorage.setItem('pricing-maintenance-query', JSON.stringify(item))
        history.push('/pricing-maintenance/pricing-maintenance-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteListFormatData(item.id);
    }
    function handleError() {
        setDialog(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginTop: 16 }}>
                    Price Maintenance Excel Upload</Typography>
                <div>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/pricing-maintenance/pricing-maintenance-excel-upload/add-query')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>{isDesktop ? 'Add - Selected Price Type' : ''}</Typography>
                    </Fab>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/pricing-maintenance/pricing-maintenance-pricetype-excel-upload/add-query')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>{isDesktop ? 'Add- All Price Type' : ''}</Typography>
                    </Fab>

                </div>
            </div>
            {
                listData && listData.length > 0 ?
                    <TableContainer style={{ marginTop: -15 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {listData
                                    .sort((a, b) => a.id > b.id ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} classes={{ root: classes.tableFont }}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} classes={{ root: classes.fontSetting }} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e, item.id, item.price_list_number)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <Publish color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForever color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
            <Dialog
                open={dialog}
                classes={{ paper: classes.paperContainer }}
            >
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div style={{ color: 'green', marginBottom: 20 }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon style={{ color: '#FF1A1A', marginRight: 5 }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>

                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        listFormatData: state.pricingData.listDataUploadFormat,
        listDataUploadError: state.pricingData.listDataUploadError,
        loading: state.pricingData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadListFile: (id, priceListId, file) => dispatch(uploadListDataFile(id, priceListId, file)),
        deleteListFormatData: (id) => dispatch(createPriceMaintenanceUpload(null, id, 'delete')),
        getListFormatData: () => dispatch(fetchPriceListDataUpload()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceListDataExcelUpload);