import React, { useEffect } from 'react';
import {
    Grid,
    Card,
    OutlinedInput,
    FormLabel, CardHeader,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { TextInputWithLabel } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 10
    },
    // gridContainer: {
    //     padding: '0px 45px 0px 45px !important'
    // },
}));

const Ima360Data = (props) => {
    const classes = useStyles();
    const [cost, setCost] = React.useState('');
    const [msrp, setMsrp] = React.useState('');
    const [pricingInventory, setPricingInventory] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    var formData = {
        cost: parseFloat(cost),
        msrp: parseFloat(msrp),
        pricing_inventory_alert_qty: parseFloat(pricingInventory),
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4
    }
    //functions
    function handleCost(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setCost(e.target.value);
        }
    }
    function handleMsrp(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setMsrp(e.target.value);
        }
    }
    function handlePricingInventory(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setPricingInventory(e.target.value);
        }
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue);
    }
    return (
        <div className={classes.root}>
            <div >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <CardHeader
                                title="IMA360 DATA"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <Grid container
                                style={{ marginBottom: 16, margin: 10 }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel required={fieldLabel['cost'] ? fieldLabel['cost']['mandatory'] : false} className={classes.formLabel}>
                                        {fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={cost}
                                        inputProps={{ type: "number", step: "1", min: "0" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCost} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel required={fieldLabel['msrp'] ? fieldLabel['msrp']['mandatory'] : false} className={classes.formLabel}>
                                        {fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={msrp}
                                        inputProps={{ type: "number", step: "1", min: "0" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleMsrp} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel required={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['mandatory'] : false} className={classes.formLabel}>
                                        {fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={pricingInventory}
                                        inputProps={{ type: "number", step: "1", min: "0" }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handlePricingInventory} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 0 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute1}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute1} />
                                    {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} /> */}
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 0 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute2}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute2} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 0 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute3}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute3} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: 0 }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={flexAttribute4}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleFlexAttribute4} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div>
        </div >
    );
};
const mapDispatchToProps = dispatch => {
    return {
    }
}
const mapStateToProps = state => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null)(Ima360Data);