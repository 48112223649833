import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, TextInputWithLabel, DropdownArray, DropdownKeyValue } from '../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const Accrual = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    //arrays
    const [accrualFrequencyArray, setAccrualFrequencyArray] = React.useState([]);
    const [accrualCalendarArray, setAccrualCalendarArray] = React.useState([]);
    const [accrualLevelArray, setAccrualLevelArray] = React.useState([]);
    const [accruaGLAccountCreditArray, setAccruaGLAccountCreditArray] = React.useState([]);
    const [accruaGLAccountDebitArray, setAccruaGLAccountDebitArray] = React.useState([]);
    const [copaLevelArray, setCopaLevelArray] = React.useState([]);
    //new values
    const internalReferenceRef = useRef(null);
    const [internalReference, setInternalReference] = React.useState('');
    const [internalReferenceArray, setInternalReferenceArray] = React.useState([]);
    const postingsBlockRef = useRef(null);
    const [postingsBlock, setPostingsBlock] = React.useState('');
    const [postingsBlockArray, setPostingsBlockArray] = React.useState([]);
    const financialPostingsStartDateRef = useRef(null);
    const [financialPostingsStartDate, setFinancialPostingsStartDate] = React.useState('');
    const [financialPostingsStartDateArray, setFinancialPostingsStartDateArray] = React.useState([]);
    const financialPostingsEndDateRef = useRef(null);
    const [financialPostingsEndDate, setFinancialPostingsEndDate] = React.useState('');
    const [financialPostingsEndDateArray, setFinancialPostingsEndDateArray] = React.useState([]);
    const [accrualCreditProfitCenter, setAccrualCreditProfitCenter] = React.useState('');
    const [accrualCreditProfitCenterArray, setAccrualCreditProfitCenterArray] = React.useState([]);
    const [accrualDebitProfitCenter, setAccrualDebitProfitCenter] = React.useState('');
    const [accrualDebitProfitCenterArray, setAccrualDebitProfitCenterArray] = React.useState([]);
    const [autoRunPpaArray, setAutoRunPpaArray] = React.useState([]);
    const [flexFields4Array, setFlexFields4Array] = React.useState([]);
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const [shortTermPeriod, setShortTermPeriod] = React.useState(0);
    const [longTermPeriod, setLongTermPeriod] = React.useState(0);
    const [shortTermAccount, setShortTermAccount] = React.useState('');
    const [longTermAccount, setLongTermAccount] = React.useState('');
    const [amortizationValue, setAmortizationValue] = React.useState('');
    const [amortizationValueList, setAmortizationValueList] = React.useState({});
    const [contractData, setContractData] = React.useState({});

    const classes = useStyles();
    const { className } = props;
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState('');
    const [accrualGLCredit, setAccrualGLCredit] = React.useState('');
    const [accrualGLDebit, setAccrualGLDebit] = React.useState('');
    const [autoRunPpa, setAutoRunPpa] = React.useState('');
    const [flexFields4, setFlexFields4] = React.useState('');
    const [accrualRun, setAccrualRun] = React.useState('');
    var accrualValues = {
        internal_reference: internalReference,
        accrual_frequency: accrualFrequency,
        accrual_level: accrualLevel,
        copa_level: copaLevel,
        accrual_calendar: accrualCalendar,
        accrual_gl_account_credit: accrualGLCredit,
        accrual_gl_account_debit: accrualGLDebit,
        accrual_credit_profit_center: accrualCreditProfitCenter,
        accrual_debit_profit_center: accrualDebitProfitCenter,
        flex_fields_4: flexFields4,
        accrual_run: accrualRun,
        amortization_value: amortizationValue,
        short_term_period: shortTermPeriod ? parseInt(shortTermPeriod) : 0,
        long_term_period: longTermPeriod ? parseInt(longTermPeriod) : 0,
        short_term_account: shortTermAccount,
        long_term_account: longTermAccount,
    };
    //var mode = localStorage.getItem('mode');
    const accrualFrequencylRef = useRef(null);
    const accrualLevelRef = useRef(null);
    const copaRef = useRef(null);
    const accrualCalendarRef = useRef(null);
    const accrualCreditRef = useRef(null);
    const accrualDebitRef = useRef(null);
    const amortizationRef = useRef(null);
    const [accrualDataListofFields, setAccrualDataListofFields] = React.useState(
        ['accrual_frequency',
            'accrual_level',
            'copa_level',
            'accrual_calendar',
            'accrual_gl_account_credit',
            'accrual_gl_account_debit',
            'internal_reference',
            'flex_fields_4',
            'accrual_run',
            'accrual_credit_profit_center',
            'accrual_debit_profit_center',


            // 'amortization_value',
            // 'short_term_period',
            // 'long_term_period',
            // 'short_term_account',
            // 'long_term_account',
        ]
    )
    const [mandatroyFields, setManddatoryFields] = React.useState([])
    useEffect(() => {
        let tempArray = []
        if (props.fieldData && Object.values(props.fieldData) && Object.values(props.fieldData).length > 0) {
            Object.values(props.fieldData)
                .filter(item => accrualDataListofFields.includes(item.key))
                .map(item => {
                    if (item.mandatory) {
                        tempArray.push(item.key)
                    }
                })
        }
        setManddatoryFields(tempArray)
        props.onChange('', '', tempArray);
    }, [props.fieldData, accrualDataListofFields]);
    useImperativeHandle(ref, () => ({
        validationCheck() {
            // if (mode === 'edit')
            //     props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!accrualFrequencylRef.current.value || accrualFrequencylRef.current.value === '')
                setAccrualFrequency(false);
            if (!accrualLevelRef.current.value || accrualLevelRef.current.value === '')
                setAccrualLevel(false);
            if (!accrualCalendarRef.current.value || accrualCalendarRef.current.value === '')
                setAccrualCalendar(false);
            if (!copaRef.current.value || copaRef.current.value === '')
                setCopaLevel(false);
            if (!accrualCreditRef.current.value || accrualCreditRef.current.value === '')
                setAccrualGLCredit(false);
            if (!accrualDebitRef.current.value || accrualDebitRef.current.value === '')
                setAccrualGLDebit(false);
            //new values
            if (!internalReferenceRef.current.value || internalReferenceRef.current.value === '')
                setInternalReference(false);
            if (!postingsBlockRef.current.value || postingsBlockRef.current.value === '')
                setPostingsBlock(false);
            if (!financialPostingsStartDateRef.current.value || financialPostingsStartDateRef.current.value === '')
                setFinancialPostingsStartDate(false);
            if (!financialPostingsEndDateRef.current.value || financialPostingsEndDateRef.current.value === '')
                setFinancialPostingsEndDate(false);
        },
        getValues() {
            accrualValues = {
                postings_block: postingsBlockRef.current.value,
                accrual_frequency: accrualFrequencylRef.current.value,
                accrual_level: accrualLevelRef.current.value,
                copa_level: copaRef.current.value,
                accrual_calendar: accrualCalendarRef.current.value,
                accrual_gl_account_credit: accrualCreditRef.current.value,
                accrual_gl_account_debit: accrualDebitRef.current.value,
                //new values
                internal_reference: internalReferenceRef.current.value,
                postings_start_date: financialPostingsStartDateRef.current.value,
                postings_end_date: financialPostingsEndDateRef.current.value,
            };
            return accrualValues;
        }
    }));
    useEffect(() => {
        if (props.data && Object.entries(props.data).length > 0) {
            setFlexFields4(props.data.flex_fields_4)
            setAccrualRun(props.data.accrual_run)
            if (props.data.postingSchema == 'Payment then Amortization') {
                if (!accrualDataListofFields.includes('short_term_period')) {
                    setAccrualDataListofFields(['amortization_value',
                        'short_term_period',
                        'long_term_period',
                        'short_term_account',
                        'long_term_account', , ...accrualDataListofFields])
                    // setPaymentDataListofFields(paymentDataListofFields.filter(item => item !== 'payment_partner_role' && item !== 'payment_partner'))
                }
            }
        }
    }, [props.data])
    function handleAccrualFrequency(newValue) {
        setAccrualFrequency(newValue);
        props.onChange('accrual_frequency', newValue, mandatroyFields);
    }
    function handleAccrualLevel(newValue) {
        setAccrualLevel(newValue);
        props.onChange('accrual_level', newValue, mandatroyFields);
    }
    function handleCopaLevel(newValue) {
        setCopaLevel(newValue);
        props.onChange('copa_level', newValue, mandatroyFields);
    }
    function handleAccrualCalendar(newValue) {
        setAccrualCalendar(newValue);
        props.onChange('accrual_calendar', newValue, mandatroyFields);
    }
    function handleAccrualGLCredit(newValue) {
        setAccrualGLCredit(newValue);
        props.onChange('accrual_gl_account_credit', newValue, mandatroyFields);
    }
    function handleAccrualGLDebit(newValue) {
        setAccrualGLDebit(newValue);
        props.onChange('accrual_gl_account_debit', newValue, mandatroyFields);
    }
    //new values
    function handleInternalReference(newValue) {
        setInternalReference(newValue);
        props.onChange('internal_reference', newValue, mandatroyFields);
    }

    function handleAccrualCreditProfitCenter(newValue) {
        setAccrualCreditProfitCenter(newValue);
        props.onChange('accrual_credit_profit_center', newValue, mandatroyFields);
    }
    function handleAccrualDebitProfitCenter(newValue) {
        setAccrualDebitProfitCenter(newValue);
        props.onChange('accrual_debit_profit_center', newValue, mandatroyFields);
    }
    function handleFlexFields4(newValue) {
        setFlexFields4(newValue);
        props.onChange('flex_fields_4', newValue, mandatroyFields);
    }
    function handleAccrualRun(newValue) {
        setAccrualRun(newValue);
        props.onChange('accrual_run', newValue, mandatroyFields);
    }
    function handleAmortizationValue(newValue) {
        setAmortizationValue(newValue);
        props.onChange('amortization_value', newValue, mandatroyFields);
    }
    function handleShortTermAccount(newValue) {
        setShortTermAccount(newValue);
        props.onChange('short_term_account', newValue, mandatroyFields);
    }
    function handleLongTermAccount(newValue) {
        setLongTermAccount(newValue);
        props.onChange('long_term_account', newValue, mandatroyFields);
    }
    function handleShortTermPeriod(newValue) {
        setShortTermPeriod(newValue);
        props.onChange('short_term_period', parseInt(newValue), mandatroyFields);
    }
    function handleLongTermPeriod(newValue) {
        setLongTermPeriod(newValue);
        props.onChange('long_term_period', parseInt(newValue), mandatroyFields);
    }


    return (
        <div
            className={clsx(classes.root, className)}
            style={{ display: props.fieldData && accrualDataListofFields.some(key => props.fieldData.hasOwnProperty(key) ? true : false) ? 'block' : 'none' }}
        >
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-1.5rem' }}
                >
                    <CardHeader
                        title="ACCRUAL"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['accrual_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }} heading={props.fieldData['accrual_level']['current']}
                                        required={props.fieldData && props.fieldData['accrual_level'] ? props.fieldData['accrual_level']['mandatory'] : false}
                                        data={props.fieldData['accrual_level']['drop_down_reference_value']} placeholder={' '} labelFormat={'desc'}
                                        twoline='true' onChange={handleAccrualLevel} error={typeof accrualLevel === 'boolean' && !accrualLevel && props.savetriggered ? true : false}
                                        ref={accrualLevelRef} prevalue={props.data ? props.data.accrual_level : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['copa_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        required={props.fieldData && props.fieldData['copa_level'] ? props.fieldData['copa_level']['mandatory'] : false}
                                        heading={props.fieldData['copa_level']['current']}
                                        data={props.fieldData['copa_level']['drop_down_reference_value']} labelFormat={'desc'}
                                        placeholder={' '} twoline='true' onChange={handleCopaLevel}
                                        error={typeof copaLevel === 'boolean' && !copaLevel && props.savetriggered ? true : false}
                                        ref={copaRef} prevalue={props.data ? props.data.copa_level : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_frequency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['accrual_frequency']['current']} labelFormat={'desc'}
                                        required={props.fieldData && props.fieldData['accrual_frequency'] ? props.fieldData['accrual_frequency']['mandatory'] : false}
                                        data={props.fieldData['accrual_frequency']['drop_down_reference_value']} placeholder={' '} twoline='true'
                                        onChange={handleAccrualFrequency} error={typeof accrualFrequency === 'boolean' && !accrualFrequency && props.savetriggered ? true : false}
                                        ref={accrualFrequencylRef} prevalue={props.data ? props.data.accrual_frequency : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_calendar'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['accrual_calendar']['current']}
                                        required={props.fieldData && props.fieldData['accrual_calendar'] ? props.fieldData['accrual_calendar']['mandatory'] : false}
                                        data={props.fieldData['accrual_calendar']['drop_down_values']}
                                        twoline='true' onChange={handleAccrualCalendar} prevalue={props.data ? props.data.accrual_calendar : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['internal_reference'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['internal_reference'] ? props.fieldData['internal_reference']['mandatory'] : false}
                                        heading={props.fieldData['internal_reference']['current']}
                                        twoline='true' onChange={handleInternalReference} prevalue={props.data ? props.data.internal_reference : ''} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['accrual_gl_account_credit'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['accrual_gl_account_credit'] ? props.fieldData['accrual_gl_account_credit']['mandatory'] : false}
                                        heading={props.fieldData['accrual_gl_account_credit']['current']}
                                        twoline='true' onChange={handleAccrualGLCredit} error={typeof accrualGLCredit === 'boolean' && !accrualGLCredit ? true : false}
                                        prevalue={props.data ? props.data.accrual_gl_account_credit : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_gl_account_debit'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['accrual_gl_account_debit'] ? props.fieldData['accrual_gl_account_debit']['mandatory'] : false}
                                        heading={props.fieldData['accrual_gl_account_debit']['current']}
                                        twoline='true' onChange={handleAccrualGLDebit} error={typeof accrualGLDebit === 'boolean' && !accrualGLDebit ? true : false}
                                        ref={accrualDebitRef} prevalue={props.data ? props.data.accrual_gl_account_debit : ''} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['accrual_credit_profit_center'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['accrual_credit_profit_center'] ? props.fieldData['accrual_credit_profit_center']['mandatory'] : false}
                                        heading={props.fieldData['accrual_credit_profit_center']['current']}
                                        twoline='true' onChange={handleAccrualCreditProfitCenter} required={false} prevalue={props.data ? props.data.accrual_credit_profit_center : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_debit_profit_center'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        mandatory={props.fieldData && props.fieldData['accrual_debit_profit_center'] ? props.fieldData['accrual_debit_profit_center']['mandatory'] : false}
                                        heading={props.fieldData['accrual_debit_profit_center']['current']}
                                        twoline='true' onChange={handleAccrualDebitProfitCenter} required={false} prevalue={props.data ? props.data.accrual_debit_profit_center : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_run'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['accrual_run']['drop_down_reference_value'] && props.fieldData['accrual_run']['drop_down_reference_value'].length > 0 ?
                                        <div style={{ marginTop: '0.9rem' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['accrual_run'] ? props.fieldData['accrual_run']['mandatory'] : false} >
                                                {props.fieldData['accrual_run']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={accrualRun}
                                                onChange={(e) => handleAccrualRun(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['accrual_run']['drop_down_reference_value'] && props.fieldData['accrual_run']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['accrual_run']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </div> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            mandatory={props.fieldData && props.fieldData['accrual_run'] ? props.fieldData['accrual_run']['mandatory'] : false}
                                            heading={props.fieldData['accrual_run']['current']}
                                            twoline='true' onChange={handleAccrualRun} prevalue={props.data ? props.data.accrual_run : ''} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_4'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_4']['drop_down_reference_value'] && props.fieldData['flex_fields_4']['drop_down_reference_value'].length > 0 ?
                                        <div style={{ marginTop: '0.9rem' }}>
                                            <FormLabel classes={{ root: classes.fontSetting }} required={props.fieldData && props.fieldData['flex_fields_4'] ? props.fieldData['flex_fields_4']['mandatory'] : false} >
                                                {props.fieldData['flex_fields_4']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields4}
                                                onChange={(e) => handleFlexFields4(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                <MenuItem value='' key=''>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_4']['drop_down_reference_value'] && props.fieldData['flex_fields_4']['drop_down_reference_value'].length > 0
                                                    && props.fieldData['flex_fields_4']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                            </Select>
                                        </div> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_4']['current']}
                                            mandatory={props.fieldData && props.fieldData['flex_fields_4'] ? props.fieldData['flex_fields_4']['mandatory'] : false}
                                            twoline='true' onChange={handleFlexFields4} prevalue={props.data ? props.data.flex_fields_4 : ''} />
                                    }
                                </Grid>
                            }
                            {props.postingSchema == 'Payment then Amortization' &&
                                <>
                                    {props.fieldData && props.fieldData['short_term_period'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel heading={props.fieldData['short_term_period']['current']}
                                                mandatory={props.fieldData && props.fieldData['short_term_period'] ? props.fieldData['short_term_period']['mandatory'] : false}
                                                twoline='true' onChange={handleShortTermPeriod} type="Number"
                                                prevalue={props.data ? props.data.short_term_period : ''} />

                                        </Grid>}
                                    {props.fieldData && props.fieldData['long_term_period'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel mandatory={props.fieldData && props.fieldData['long_term_period'] ? props.fieldData['long_term_period']['mandatory'] : false} heading={props.fieldData['long_term_period']['current']} twoline='true' onChange={handleLongTermPeriod} type="Number" prevalue={props.data ? props.data.long_term_period : ''} />

                                        </Grid>}
                                    {props.fieldData && props.fieldData['short_term_account'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel mandatory={props.fieldData && props.fieldData['short_term_account'] ? props.fieldData['short_term_account']['mandatory'] : false} heading={props.fieldData['short_term_account']['current']} twoline='true' onChange={handleShortTermAccount} prevalue={props.data ? props.data.short_term_account : ''} />
                                        </Grid>}
                                    {props.fieldData && props.fieldData['long_term_account'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel heading={props.fieldData['long_term_account'][['current']]}
                                                mandatory={props.fieldData && props.fieldData['long_term_account'] ? props.fieldData['long_term_account']['mandatory'] : false}
                                                twoline='true' onChange={handleLongTermAccount}
                                                prevalue={props.data ? props.data.long_term_account : ''} />
                                        </Grid>
                                    }
                                    {props.fieldData && props.fieldData['amortization_value'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <DropdownKeyValue classes={{ root: classes.fontSetting }}
                                                required={props.fieldData && props.fieldData['amortization_value'] ? props.fieldData['amortization_value']['mandatory'] : false}
                                                heading={props.fieldData['amortization_value']['current']}
                                                data={props.fieldData['amortization_value']['drop_down_reference_value']}
                                                placeholder={' '} twoline='true' onChange={handleAmortizationValue}
                                                ref={amortizationRef} prevalue={props.data ? props.data.amortization_value : ''} />

                                        </Grid>
                                    }
                                </>

                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

export default connect(null, null, null, { forwardRef: true })(Accrual);
