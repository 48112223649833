import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getWorkFlowAssignmentsDetails } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '20px 10px 0px 10px',
        backgroundColor: 'white',
        marginTop: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        height: '100%'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const ViewWorkFlowAssignments = props => {
    const classes = useStyles();
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getWorkFlowAssignmentsDetails(appId)
    }, []);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/workflow-assignment'
                >
                    WorkFlow Assignment
                </Link>
                <Typography color="textPrimary" variant='h4'>View WorkFlow Assignment</Typography>
            </Breadcrumbs>
            {props.contractDefaultDetail &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        {props.contractDefaultDetail.application_type &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Application Type' data={props.contractDefaultDetail.application_type} twoline='true' />
                            </Grid>
                        }
                        {props.contractDefaultDetail.document_analyst_details &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Document Analyst' data={props.contractDefaultDetail.document_analyst_details.userName} twoline='true' />
                            </Grid>
                        }
                        {props.contractDefaultDetail.document_manager_details &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading='Document Manager' data={props.contractDefaultDetail.document_manager_details.userName} twoline='true' />
                            </Grid>
                        }
                        {props.contractDefaultDetail.filters &&
                            Object.entries(props.contractDefaultDetail.filters).map(([key, value]) => {
                                return (

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading={key && key.includes('_') ? key.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : key} data={value} twoline='true' />
                                    </Grid>
                                )

                            })

                        }
                    </Grid>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        contractDefaultDetail: state.customerData.workFlowAssignmentsDetail,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getWorkFlowAssignmentsDetails: (id) => dispatch(getWorkFlowAssignmentsDetails(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewWorkFlowAssignments);