import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Dialog,
    DialogActions, FormLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, OutlinedInput, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../components/Dialog';
import { getAnaylsisFormula, runMarginAnalysis, getAnalysisLevel, runProfitCalcForSimulation, addPricingBatchJobSetup } from '../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 170
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: '2.4rem',
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    inputTwoLine: {
        marginTop: 2,
        width: '100%',
        paddingLeft: 10,
        height: 37
    },
}));

const MarginAnalysis = props => {
    const classes = useStyles();
    const { className } = props;
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [materialNumber, setMaterialNumber] = React.useState([]);
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [optionalFieldArray, setOptionalFieldArray] = React.useState([]);
    const [listOF, setListOF] = React.useState([]);
    const [salesOrganization, setSalesOrganization] = React.useState([]);
    const [companyCode, setcompanyCode] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [flexAttribute3, setFlexAttribute3] = React.useState([]);
    const [flexAttribute4, setFlexAttribute4] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [plant, setPlant] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierGroup, setSupplierGroup] = React.useState([]);
    const [supplierFlexAtt1, setSupplierFlexAtt1] = React.useState([]);
    const [supplierFlexAtt2, setSupplierFlexAtt2] = React.useState([]);
    const [supplierGroup1, setSupplierGroup1] = React.useState([]);
    const [supplierGroup2, setSupplierGroup2] = React.useState([]);
    const [supplierType, setSupplierType] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [classOfTrade, setClassOfTrade] = React.useState([]);
    const [country, setCountry] = React.useState([]);
    const [customerClassification, setCustomerClassification] = React.useState([]);
    const [customerGroup1, setCustomerGroup1] = React.useState([]);
    const [customerGroup2, setCustomerGroup2] = React.useState([]);
    const [customerGroup3, setCustomerGroup3] = React.useState([]);
    const [customerGroup4, setCustomerGroup4] = React.useState([]);
    const [customerGroup5, setCustomerGroup5] = React.useState([]);
    const [customerHierarchy, setCustomerHierarchy] = React.useState([]);
    const [customerChain, setCustomerChain] = React.useState([]);
    const [customerFlexAttribute1, setCustomerFlexAttribute1] = React.useState([]);
    const [customerFlexAttribute2, setCustomerFlexAttribute2] = React.useState([]);
    const [customerFlexAttribute3, setCustomerFlexAttribute3] = React.useState([]);
    const [customerFlexAttribute4, setCustomerFlexAttribute4] = React.useState([]);
    const [materialGroup, setMaterialGroup] = React.useState([]);
    const [productHierarchy, setProductHierarchy] = React.useState([]);
    const [materialGroup1, setMaterialGroup1] = React.useState([]);
    const [materialGroup2, setMaterialGroup2] = React.useState([]);
    const [materialGroup3, setMaterialGroup3] = React.useState([]);
    const [materialGroup4, setMaterialGroup4] = React.useState([]);
    const [materialGroup5, setMaterialGroup5] = React.useState([]);
    const [materialFlexAttribute1, setMaterialFlexAttribute1] = React.useState([]);
    const [materialFlexAttribute2, setMaterialFlexAttribute2] = React.useState([]);
    const [materialFlexAttribute3, setMaterialFlexAttribute3] = React.useState([]);
    const [materialFlexAttribute4, setMaterialFlexAttribute4] = React.useState([]);
    const [materialType, setMaterialType] = React.useState([]);
    const [report, setReport] = React.useState('')
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState([])
    const [tag, setTag] = React.useState([])
    const [radioGroupValue, setRadioGroupValue] = React.useState('Historical - Actual');
    const [status, setStatus] = React.useState(false);
    const [batchJobName, setBatchJobName] = React.useState('');
    const [open2, setOpen2] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        var pathname = window.location.pathname;
        if (pathname == '/flexible-analysis-report')
            setReport('report')
        else
            setReport('result')
        /*var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getConfigData(appId);
        props.getPriceListFields()*/
    }, [window.location.pathname]);
    useEffect(() => {
        props.getConfigData(1, 10);
        props.getOptionalField(1, 0);
    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push({ 'formula': e.formula_name, 'analysis_level_id': e.analysis_level_id })
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        var newArray = [];
        if (props.optionalField && props.optionalField.records) {
            props.optionalField.records
                .map(e => {
                    newArray.push(e.analysis_level_id + '-' + e.qualifier_key);
                })
            setOptionalFieldArray(newArray)
        }
    }, [props.optionalField]);
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
        if (newValue.target.value === "Historical - What-If") {
            setStatus(true)
        } else {
            setStatus(false)
        }
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber([...customerNumber, newValue])
    }
    function handleCustomerNumberDelete(newValue) {
        var deletedCustomerNumber = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedCustomerNumber)
    }
    function handleChipInput(newValue, type) {
        if (type == 'tag')
            setTag([...tag, newValue])
        else
            setCalculationSimulationNumber([...calculationSimulationNumber, newValue])
    }
    function handleChipInputDelete(newValue, type) {
        if (type == 'tag') {
            var tags = tag.filter(item => item !== newValue)
            setTag(tags)
        }
        else {
            var deletedCalculationSimulationNumber = calculationSimulationNumber.filter(item => item !== newValue)
            setCalculationSimulationNumber(deletedCalculationSimulationNumber)
        }
    }
    function handleCustomerGroup(newValue) {
        setCustomerGroup([...customerGroup, newValue])
    }
    function handleCustomerGroupDelete(newValue) {
        var deletedCustomerGroup = customerGroup.filter(item => item !== newValue)
        setCustomerGroup(deletedCustomerGroup)
    }
    function handleClassOfTrade(newValue) {
        setClassOfTrade([...classOfTrade, newValue])
    }
    function handleClassOfTradeDelete(newValue) {
        var deletedClassOfTrade = classOfTrade.filter(item => item !== newValue)
        setClassOfTrade(deletedClassOfTrade)
    }
    function handleCountry(newValue) {
        setCountry([...country, newValue])
    }
    function handleCountryDelete(newValue) {
        var deletedCountry = country.filter(item => item !== newValue)
        setCountry(deletedCountry)
    }
    function handleCustomerClassification(newValue) {
        setCustomerClassification([...customerClassification, newValue])
    }
    function handleCustomerClassificationDelete(newValue) {
        var deletedCustomerClassification = customerClassification.filter(item => item !== newValue)
        setCustomerClassification(deletedCustomerClassification)
    }
    function handleCustomerGroup1(newValue) {
        setCustomerGroup1([...customerGroup1, newValue])
    }
    function handleCustomerGroup1Delete(newValue) {
        var deletedCustomerGroup1 = customerGroup1.filter(item => item !== newValue)
        setCustomerGroup1(deletedCustomerGroup1)
    }
    function handleCustomerGroup2(newValue) {
        setCustomerGroup2([...customerGroup2, newValue])
    }
    function handleCustomerGroup2Delete(newValue) {
        var deletedCustomerGroup2 = customerGroup2.filter(item => item !== newValue)
        setCustomerGroup2(deletedCustomerGroup2)
    }
    function handleCustomerGroup3(newValue) {
        setCustomerGroup3([...customerGroup3, newValue])
    }
    function handleCustomerGroup3Delete(newValue) {
        var deletedCustomerGroup3 = customerGroup3.filter(item => item !== newValue)
        setCustomerGroup3(deletedCustomerGroup3)
    }
    function handleCustomerGroup4(newValue) {
        setCustomerGroup4([...customerGroup4, newValue])
    }
    function handleCustomerGroup4Delete(newValue) {
        var deletedCustomerGroup4 = customerGroup4.filter(item => item !== newValue)
        setCustomerGroup4(deletedCustomerGroup4)
    }
    function handleCustomerGroup5(newValue) {
        setCustomerGroup5([...customerGroup5, newValue])
    }
    function handleCustomerGroup5Delete(newValue) {
        var deletedCustomerGroup5 = customerGroup5.filter(item => item !== newValue)
        setCustomerGroup5(deletedCustomerGroup5)
    }
    function handleCustomerChain(newValue) {
        setCustomerChain([...customerChain, newValue])
    }
    function handleCustomerChainDelete(newValue) {
        var deletedCustomerChain = customerChain.filter(item => item !== newValue)
        setCustomerChain(deletedCustomerChain)
    }
    function handleCustomerHierarchy(newValue) {
        setCustomerHierarchy([...customerHierarchy, newValue])
    }
    function handleCustomerHierarchyDelete(newValue) {
        var deletedCustomerHierarchy = customerHierarchy.filter(item => item !== newValue)
        setCustomerHierarchy(deletedCustomerHierarchy)
    }
    function handleCustomerFlexAttribute1(newValue) {
        setCustomerFlexAttribute1([...customerFlexAttribute1, newValue])
    }
    function handleCustomerFlexAttribute1Delete(newValue) {
        var deletedCustomerFlexAttribute1 = customerFlexAttribute1.filter(item => item !== newValue)
        setCustomerFlexAttribute1(deletedCustomerFlexAttribute1)
    }
    function handleCustomerFlexAttribute2(newValue) {
        setCustomerFlexAttribute2([...customerFlexAttribute2, newValue])
    }
    function handleCustomerFlexAttribute2Delete(newValue) {
        var deletedCustomerFlexAttribute2 = customerFlexAttribute2.filter(item => item !== newValue)
        setCustomerFlexAttribute2(deletedCustomerFlexAttribute2)
    }
    function handleCustomerFlexAttribute3(newValue) {
        setCustomerFlexAttribute3([...customerFlexAttribute3, newValue])
    }
    function handleCustomerFlexAttribute3Delete(newValue) {
        var deletedCustomerFlexAttribute3 = customerFlexAttribute3.filter(item => item !== newValue)
        setCustomerFlexAttribute3(deletedCustomerFlexAttribute3)
    }
    function handleCustomerFlexAttribute4(newValue) {
        setCustomerFlexAttribute4([...customerFlexAttribute4, newValue])
    }
    function handleCustomerFlexAttribute4Delete(newValue) {
        var deletedCustomerFlexAttribute4 = customerFlexAttribute4.filter(item => item !== newValue)
        setCustomerFlexAttribute4(deletedCustomerFlexAttribute4)
    }
    function handleMaterialNumber(newValue) {
        setMaterialNumber([...materialNumber, newValue])
    }
    function handleMaterialNumberDelete(newValue) {
        var deletedMaterialNumber = materialNumber.filter(item => item !== newValue)
        setMaterialNumber(deletedMaterialNumber)
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
        var [first, second] = newValue.split('-')
        {
            optionalFieldArray.map((e) => {
                var [index, value] = e.split('-')
                if (second == index) {
                    setListOF(value.split(','));
                }
            })
        }
    }
    function handleBillingDateStart(newValue) {
        setBillingDateStart(Moment(newValue));
    }
    function handleBillingDateEnd(newValue) {
        setBillingDateEnd(Moment(newValue));
    }
    function handleSalesOrganization(newValue) {
        setSalesOrganization([...salesOrganization, newValue])
    }
    function handleSalesOrganizationDelete(newValue) {
        var deletedSalesOrganization = salesOrganization.filter(item => item !== newValue)
        setSalesOrganization(deletedSalesOrganization)
    }
    function handleCompanyCode(newValue) {
        setcompanyCode([...companyCode, newValue])
    }
    function handleCompanyCodeDelete(newValue) {
        var deletedCompanyCode = companyCode.filter(item => item !== newValue)
        setcompanyCode(deletedCompanyCode)
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1([...flexAttribute1, newValue])
    }
    function handleFlexAttribute1Delete(newValue) {
        var deletedFlexAttribute1 = flexAttribute1.filter(item => item !== newValue)
        setFlexAttribute1(deletedFlexAttribute1)
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2([...flexAttribute2, newValue])
    }
    function handleFlexAttribute2Delete(newValue) {
        var deletedFlexAttribute2 = flexAttribute2.filter(item => item !== newValue)
        setFlexAttribute2(deletedFlexAttribute2)
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3([...flexAttribute3, newValue])
    }
    function handleFlexAttribute3Delete(newValue) {
        var deletedFlexAttribute3 = flexAttribute3.filter(item => item !== newValue)
        setFlexAttribute3(deletedFlexAttribute3)
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4([...flexAttribute4, newValue])
    }
    function handleFlexAttribute4Delete(newValue) {
        var deletedFlexAttribute4 = flexAttribute4.filter(item => item !== newValue)
        setFlexAttribute4(deletedFlexAttribute4)
    }
    function handleDistributionChannel(newValue) {
        setDistributionChannel([...distributionChannel, newValue])
    }
    function handleDistributionChannelDelete(newValue) {
        var deletedDistributionChannel = distributionChannel.filter(item => item !== newValue)
        setDistributionChannel(deletedDistributionChannel)
    }
    function handleDivision(newValue) {
        setDivision([...division, newValue])
    }
    function handleDivisionDelete(newValue) {
        var deletedDivision = division.filter(item => item !== newValue)
        setDivision(deletedDivision)
    }
    function handlePlant(newValue) {
        setPlant([...plant, newValue])
    }
    function handlePlantDelete(newValue) {
        var deletedPlant = plant.filter(item => item !== newValue)
        setPlant(deletedPlant)
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber([...supplierNumber, newValue])
    }
    function handleSupplierNumberDelete(newValue) {
        var deletedSupplierNumber = supplierNumber.filter(item => item !== newValue)
        setSupplierNumber(deletedSupplierNumber)
    }
    function handleSupplierFlexAtt1(newValue) {
        setSupplierFlexAtt1([...supplierFlexAtt1, newValue])
    }
    function handleSupplierFlexAtt1Delete(newValue) {
        var deletedSupplierFlexAtt1 = supplierFlexAtt1.filter(item => item !== newValue)
        setSupplierFlexAtt1(deletedSupplierFlexAtt1)
    }
    function handleSupplierFlexAtt2(newValue) {
        setSupplierFlexAtt2([...supplierFlexAtt2, newValue])
    }
    function handleSupplierFlexAtt2Delete(newValue) {
        var deletedSupplierFlexAtt2 = supplierFlexAtt2.filter(item => item !== newValue)
        setSupplierFlexAtt2(deletedSupplierFlexAtt2)
    }
    function handleSupplierGroup(newValue) {
        setSupplierGroup([...supplierGroup, newValue])
    }
    function handleSupplierGroupDelete(newValue) {
        var deletedSupplierGroup = supplierGroup.filter(item => item !== newValue)
        setSupplierGroup(deletedSupplierGroup)
    }
    function handleSupplierGroup1(newValue) {
        setSupplierGroup1([...supplierGroup1, newValue])
    }
    function handleSupplierGroup1Delete(newValue) {
        var deletedSupplierGroup1 = supplierGroup1.filter(item => item !== newValue)
        setSupplierGroup1(deletedSupplierGroup1)
    }
    function handleSupplierGroup2(newValue) {
        setSupplierGroup2([...supplierGroup2, newValue])
    }
    function handleSupplierGroup2Delete(newValue) {
        var deletedSupplierGroup2 = supplierGroup2.filter(item => item !== newValue)
        setSupplierGroup2(deletedSupplierGroup2)
    }
    function handleSupplierType(newValue) {
        setSupplierType([...supplierType, newValue])
    }
    function handleSupplierTypeDelete(newValue) {
        var deletedSupplierType = supplierType.filter(item => item !== newValue)
        setSupplierType(deletedSupplierType)
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup([...materialGroup, newValue])
    }
    function handleMaterialGroupDelete(newValue) {
        var deletedMaterialGroup = materialGroup.filter(item => item !== newValue)
        setMaterialGroup(deletedMaterialGroup)
    }
    function handleProductHierarchy(newValue) {
        setProductHierarchy([...productHierarchy, newValue])
    }
    function handleProductHierarchyDelete(newValue) {
        var deletedProductHierarchy = productHierarchy.filter(item => item !== newValue)
        setProductHierarchy(deletedProductHierarchy)
    }
    function handleMaterialGroup1(newValue) {
        setMaterialGroup1([...materialGroup1, newValue])
    }
    function handleMaterialGroup1Delete(newValue) {
        var deletedMaterialGroup1 = materialGroup1.filter(item => item !== newValue)
        setMaterialGroup1(deletedMaterialGroup1)
    }
    function handleMaterialGroup2(newValue) {
        setMaterialGroup2([...materialGroup2, newValue])
    }
    function handleMaterialGroup2Delete(newValue) {
        var deletedMaterialGroup2 = materialGroup2.filter(item => item !== newValue)
        setMaterialGroup2(deletedMaterialGroup2)
    }
    function handleMaterialGroup3(newValue) {
        setMaterialGroup3([...materialGroup3, newValue])
    }
    function handleMaterialGroup3Delete(newValue) {
        var deletedMaterialGroup3 = materialGroup3.filter(item => item !== newValue)
        setMaterialGroup3(deletedMaterialGroup3)
    }
    function handleMaterialGroup4(newValue) {
        setMaterialGroup4([...materialGroup4, newValue])
    }
    function handleMaterialGroup4Delete(newValue) {
        var deletedMaterialGroup4 = materialGroup4.filter(item => item !== newValue)
        setMaterialGroup4(deletedMaterialGroup4)
    }
    function handleMaterialGroup5(newValue) {
        setMaterialGroup5([...materialGroup5, newValue])
    }
    function handleMaterialGroup5Delete(newValue) {
        var deletedMaterialGroup5 = materialGroup5.filter(item => item !== newValue)
        setMaterialGroup5(deletedMaterialGroup5)
    }
    function handleMaterialFlexAttribute1(newValue) {
        setMaterialFlexAttribute1([...materialFlexAttribute1, newValue])
    }
    function handleMaterialFlexAttribute1Delete(newValue) {
        var deletedMaterialFlexAttribute1 = materialFlexAttribute1.filter(item => item !== newValue)
        setMaterialFlexAttribute1(deletedMaterialFlexAttribute1)
    }
    function handleMaterialFlexAttribute2(newValue) {
        setMaterialFlexAttribute2([...materialFlexAttribute2, newValue])
    }
    function handleMaterialFlexAttribute2Delete(newValue) {
        var deletedMaterialFlexAttribute2 = materialFlexAttribute2.filter(item => item !== newValue)
        setMaterialFlexAttribute2(deletedMaterialFlexAttribute2)
    }
    function handleMaterialFlexAttribute3(newValue) {
        setMaterialFlexAttribute3([...materialFlexAttribute3, newValue])
    }
    function handleMaterialFlexAttribute3Delete(newValue) {
        var deletedMaterialFlexAttribute3 = materialFlexAttribute3.filter(item => item !== newValue)
        setMaterialFlexAttribute3(deletedMaterialFlexAttribute3)
    }
    function handleMaterialFlexAttribute4(newValue) {
        setMaterialFlexAttribute4([...materialFlexAttribute4, newValue])
    }
    function handleMaterialFlexAttribute4Delete(newValue) {
        var deletedMaterialFlexAttribute4 = materialFlexAttribute4.filter(item => item !== newValue)
        setMaterialFlexAttribute4(deletedMaterialFlexAttribute4)
    }
    function handleMaterialType(newValue) {
        setMaterialType([...materialType, newValue])
    }
    function handleMaterialTypeDelete(newValue) {
        var deletedMaterialType = materialType.filter(item => item !== newValue)
        setMaterialType(deletedMaterialType)
    }
    function handleBatchOnSubmit() {
        setOpen2(false)
        setBatchJobName('');
        clean(format2.batch_filter_criteria[0].filters)
        props.onSubmitBatchJob(format2)
    }
    const handleClear = () => {
        setCustomerNumber([]);
        setCustomerGroup([]);
        setClassOfTrade([]);
        setCountry([]);
        setCustomerClassification([]);
        setCustomerGroup1([]);
        setCustomerGroup2([]);
        setCustomerGroup3([]);
        setCustomerGroup4([]);
        setCustomerGroup5([]);
        setCustomerHierarchy([]);
        setCustomerChain([]);
        setCustomerFlexAttribute1([]);
        setCustomerFlexAttribute2([]);
        setCustomerFlexAttribute3([]);
        setCustomerFlexAttribute4([]);
        setMaterialNumber([]);
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setPriceCalcFormula([]);
        setSalesOrganization([]);
        setcompanyCode([]);
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setFlexAttribute3([]);
        setFlexAttribute4([]);
        setDistributionChannel([]);
        setDivision([]);
        setPlant([]);
        setTag([])
        setSupplierNumber([]);
        setSupplierGroup([]);
        setSupplierFlexAtt1([]);
        setSupplierFlexAtt2([]);
        setSupplierGroup1([]);
        setSupplierGroup2([]);
        setSupplierType([]);
        setMaterialGroup([]);
        setProductHierarchy([]);
        setMaterialGroup1([]);
        setMaterialGroup2([]);
        setMaterialGroup3([]);
        setMaterialGroup4([]);
        setMaterialGroup5([]);
        setMaterialFlexAttribute1([]);
        setMaterialFlexAttribute2([]);
        setMaterialFlexAttribute3([]);
        setMaterialFlexAttribute4([]);
        setMaterialType([]);
        setListOF([]);
        setCalculationSimulationNumber([])
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClickOpen2 = () => {
        setOpen2(true)
    };
    function handleCancelDialog() {
        setOpen2(false)
        setBatchJobName('');
    }
    const formData = {
        "material_number": materialNumber,
        "material_group": materialGroup[0] ? materialGroup[0] : '',
        "product_hierarchy": productHierarchy[0] ? productHierarchy[0] : '',
        "material_group1": materialGroup1[0] ? materialGroup1[0] : '',
        "material_group2": materialGroup2[0] ? materialGroup2[0] : '',
        "material_group3": materialGroup3[0] ? materialGroup3[0] : '',
        "material_group4": materialGroup4[0] ? materialGroup4[0] : '',
        "material_group5": materialGroup5[0] ? materialGroup5[0] : '',
        "material_flex_attribute1": materialFlexAttribute1[0] ? materialFlexAttribute1[0] : '',
        "material_flex_attribute2": materialFlexAttribute2[0] ? materialFlexAttribute2[0] : '',
        "material_flex_attribute3": materialFlexAttribute3[0] ? materialFlexAttribute3[0] : '',
        "material_flex_attribute4": materialFlexAttribute4[0] ? materialFlexAttribute4[0] : '',
        "material_type": materialType[0] ? materialType[0] : '',
        "formula": priceCalcFormula,
        "start_date": Moment.utc(billingDateStart).local(),
        "end_date": Moment.utc(billingDateEnd).local(),
        "customer_number": customerNumber,
        "customer_group": customerGroup[0] ? customerGroup[0] : '',
        "class_of_trade": classOfTrade[0] ? classOfTrade[0] : '',
        "country": country[0] ? country[0] : '',
        "customer_classification": customerClassification[0] ? customerClassification[0] : '',
        "customer_group1": customerGroup1[0] ? customerGroup1[0] : '',
        "customer_group2": customerGroup2[0] ? customerGroup2[0] : '',
        "customer_group3": customerGroup3[0] ? customerGroup3[0] : '',
        "customer_group4": customerGroup4[0] ? customerGroup4[0] : '',
        "customer_group5": customerGroup5[0] ? customerGroup5[0] : '',
        "customer_hierarchy": customerHierarchy[0] ? customerHierarchy[0] : '',
        "customer_chain": customerChain[0] ? customerChain[0] : '',
        "customer_flex_attribute1": customerFlexAttribute1[0] ? customerFlexAttribute1[0] : '',
        "customer_flex_attribute2": customerFlexAttribute2[0] ? customerFlexAttribute2[0] : '',
        "customer_flex_attribute3": customerFlexAttribute3[0] ? customerFlexAttribute3[0] : '',
        "customer_flex_attribute4": customerFlexAttribute4[0] ? customerFlexAttribute4[0] : '',
        "sales_organization": salesOrganization[0] ? salesOrganization[0] : '',
        "company_code": companyCode[0] ? companyCode[0] : '',
        "distribution_channel": distributionChannel[0] ? distributionChannel[0] : '',
        "division": division[0] ? division[0] : '',
        "plant": plant[0] ? plant[0] : '',
        "flex_attribute1": flexAttribute1[0] ? flexAttribute1[0] : '',
        "flex_attribute2": flexAttribute2[0] ? flexAttribute2[0] : '',
        "flex_attribute3": flexAttribute3[0] ? flexAttribute3[0] : '',
        "flex_attribute4": flexAttribute4[0] ? flexAttribute4[0] : '',
        "supplier_number": supplierNumber,
        "supplier_group": supplierGroup[0] ? supplierGroup[0] : '',
        "supplier_flex_attribute1": supplierFlexAtt1[0] ? supplierFlexAtt1[0] : '',
        "supplier_flex_attribute2": supplierFlexAtt2[0] ? supplierFlexAtt2[0] : '',
        "supplier_group1": supplierGroup1[0] ? supplierGroup1[0] : '',
        "supplier_group2": supplierGroup2[0] ? supplierGroup2[0] : '',
        "supplier_type": supplierType[0] ? supplierType[0] : '',
        'calculation_type': radioGroupValue,
        'tags': tag
    };
    const format2 = {
        "app_type": sessionStorage.getItem('application'),
        "batch_job_type": "Margin Simulation",
        "batch_job_name": batchJobName,
        "planned_date_type": "static",
        "planned_start_date": Moment.utc(new Date()).add(1, 'minute').local().format('YYYY-MM-DDTHH:mm:ssZ').toString(),
        "planned_start_calendar": "",
        "planned_start_time": Moment.utc(new Date()).add(1, 'minute').local().format('HH:mm:00').toString(),
        "frequency": "One Time",
        "batch_filter_criteria": [
            {
                "filters": {
                    ...formData,
                }
            }]
    }
    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName].length == 0) {
                delete obj[propName];
            }
        }
        return obj
    }
    const handleDialog = (bool) => {
        if (bool) {
            clean(formData)
            if (report == 'report') {
                formData['calculation_simulation_number'] = calculationSimulationNumber ? Number(calculationSimulationNumber[0]) : ''
                props.runProfitSimulation(formData)
            }
            else
                props.onSubmit(formData);
            setOpen(false);
            handleClear();
        }
        else {
            setOpen(false);
        }

    }
    const handleBatchJobName = (e) => {
        setBatchJobName(e.target.value);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 18 }}>
                        {report != 'report' ? 'Margin Simulation' : 'Margin Simulation Report'}
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}

                            >   <FormLabel classes={{ root: classes.fontSetting }} required >Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginBottom: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            //style={{marginTop:'-20px'}}
                            >   <FormLabel classes={{ root: classes.fontSetting }} required >End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    height: 37,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    // marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginBottom: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                    Analysis Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item.formula} key={item.formula}>
                                                {item.formula + "-" + item.analysis_level_id}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: report == 'report' ? 'block' : 'none' }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                    Calculation Simulation Number
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={calculationSimulationNumber}
                                    onAdd={(chips) => handleChipInput(chips, 'calSimulation number')}
                                    onDelete={(chip) => handleChipInputDelete(chip, 'calSimulation number')}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required={status} >
                                    Tag
                                </FormLabel>
                                <OutlinedInput
                                    value={tag}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={e => setTag(e.target.value)}
                                />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_number') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Number
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerNumber}
                                    onAdd={(chips) => handleCustomerNumber(chips)}
                                    onDelete={(chip) => handleCustomerNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup}
                                    onAdd={(chips) => handleCustomerGroup(chips)}
                                    onDelete={(chip) => handleCustomerGroupDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('class_of_trade') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Class of Trade
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={classOfTrade}
                                    onAdd={(chips) => handleClassOfTrade(chips)}
                                    onDelete={(chip) => handleClassOfTradeDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('country') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Country
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={country}
                                    onAdd={(chips) => handleCountry(chips)}
                                    onDelete={(chip) => handleCountryDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_classification') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Classification
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerClassification}
                                    onAdd={(chips) => handleCustomerClassification(chips)}
                                    onDelete={(chip) => handleCustomerClassificationDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup1}
                                    onAdd={(chips) => handleCustomerGroup1(chips)}
                                    onDelete={(chip) => handleCustomerGroup1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup2}
                                    onAdd={(chips) => handleCustomerGroup2(chips)}
                                    onDelete={(chip) => handleCustomerGroup2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group 3
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup3}
                                    onAdd={(chips) => handleCustomerGroup3(chips)}
                                    onDelete={(chip) => handleCustomerGroup3Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group 4
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup4}
                                    onAdd={(chips) => handleCustomerGroup4(chips)}
                                    onDelete={(chip) => handleCustomerGroup4Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_group5') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Group 5
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerGroup5}
                                    onAdd={(chips) => handleCustomerGroup5(chips)}
                                    onDelete={(chip) => handleCustomerGroup5Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_hierarchy') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Hierarchy
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerHierarchy}
                                    onAdd={(chips) => handleCustomerHierarchy(chips)}
                                    onDelete={(chip) => handleCustomerHierarchyDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_chain') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Chain
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerChain}
                                    onAdd={(chips) => handleCustomerChain(chips)}
                                    onDelete={(chip) => handleCustomerChainDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Flex Attribute 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerFlexAttribute1}
                                    onAdd={(chips) => handleCustomerFlexAttribute1(chips)}
                                    onDelete={(chip) => handleCustomerFlexAttribute1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Flex Attribute 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerFlexAttribute2}
                                    onAdd={(chips) => handleCustomerFlexAttribute2(chips)}
                                    onDelete={(chip) => handleCustomerFlexAttribute2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_flex_attribute3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Flex Attribute 3
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerFlexAttribute3}
                                    onAdd={(chips) => handleCustomerFlexAttribute3(chips)}
                                    onDelete={(chip) => handleCustomerFlexAttribute3Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('customer_flex_attribute4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Customer Flex Attribute 4
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={customerFlexAttribute4}
                                    onAdd={(chips) => handleCustomerFlexAttribute4(chips)}
                                    onDelete={(chip) => handleCustomerFlexAttribute4Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_number') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Number
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialNumber}
                                    onAdd={(chips) => handleMaterialNumber(chips)}
                                    onDelete={(chip) => handleMaterialNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup}
                                    onAdd={(chips) => handleMaterialGroup(chips)}
                                    onDelete={(chip) => handleMaterialGroupDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('product_hierarchy') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Product Hierarchy
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={productHierarchy}
                                    onAdd={(chips) => handleProductHierarchy(chips)}
                                    onDelete={(chip) => handleProductHierarchyDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup1}
                                    onAdd={(chips) => handleMaterialGroup1(chips)}
                                    onDelete={(chip) => handleMaterialGroup1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup2}
                                    onAdd={(chips) => handleMaterialGroup2(chips)}
                                    onDelete={(chip) => handleMaterialGroup2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group 3
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup3}
                                    onAdd={(chips) => handleMaterialGroup3(chips)}
                                    onDelete={(chip) => handleMaterialGroup3Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group 4
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup4}
                                    onAdd={(chips) => handleMaterialGroup4(chips)}
                                    onDelete={(chip) => handleMaterialGroup4Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_group5') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Group 5
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialGroup5}
                                    onAdd={(chips) => handleMaterialGroup5(chips)}
                                    onDelete={(chip) => handleMaterialGroup5Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Flex Attribute 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialFlexAttribute1}
                                    onAdd={(chips) => handleMaterialFlexAttribute1(chips)}
                                    onDelete={(chip) => handleMaterialFlexAttribute1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Flex Attribute 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialFlexAttribute2}
                                    onAdd={(chips) => handleMaterialFlexAttribute2(chips)}
                                    onDelete={(chip) => handleMaterialFlexAttribute2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_flex_attribute3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Flex Attribute 3
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialFlexAttribute3}
                                    onAdd={(chips) => handleMaterialFlexAttribute3(chips)}
                                    onDelete={(chip) => handleMaterialFlexAttribute3Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_flex_attribute4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Flex Attribute 4
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialFlexAttribute4}
                                    onAdd={(chips) => handleMaterialFlexAttribute4(chips)}
                                    onDelete={(chip) => handleMaterialFlexAttribute4Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('material_type') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Material Type
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={materialType}
                                    onAdd={(chips) => handleMaterialType(chips)}
                                    onDelete={(chip) => handleMaterialTypeDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('sales_organization') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Sales Organization
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={salesOrganization}
                                    onAdd={(chips) => handleSalesOrganization(chips)}
                                    onDelete={(chip) => handleSalesOrganizationDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('company_code') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Company Code
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={companyCode}
                                    onAdd={(chips) => handleCompanyCode(chips)}
                                    onDelete={(chip) => handleCompanyCodeDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('distribution_channel') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Distribution Channel
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={distributionChannel}
                                    onAdd={(chips) => handleDistributionChannel(chips)}
                                    onDelete={(chip) => handleDistributionChannelDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('division') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Division
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={division}
                                    onAdd={(chips) => handleDivision(chips)}
                                    onDelete={(chip) => handleDivisionDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('plant') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Plant
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={plant}
                                    onAdd={(chips) => handlePlant(chips)}
                                    onDelete={(chip) => handlePlantDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={flexAttribute1}
                                    onAdd={(chips) => handleFlexAttribute1(chips)}
                                    onDelete={(chip) => handleFlexAttribute1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={flexAttribute2}
                                    onAdd={(chips) => handleFlexAttribute2(chips)}
                                    onDelete={(chip) => handleFlexAttribute2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('flex_attribute3') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 3
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={flexAttribute3}
                                    onAdd={(chips) => handleFlexAttribute3(chips)}
                                    onDelete={(chip) => handleFlexAttribute3Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('flex_attribute4') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Flex Attribute 4
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={flexAttribute4}
                                    onAdd={(chips) => handleFlexAttribute4(chips)}
                                    onDelete={(chip) => handleFlexAttribute4Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_number') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Number
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierNumber}
                                    onAdd={(chips) => handleSupplierNumber(chips)}
                                    onDelete={(chip) => handleSupplierNumberDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_group') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Group
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierGroup}
                                    onAdd={(chips) => handleSupplierGroup(chips)}
                                    onDelete={(chip) => handleSupplierGroupDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_flex_attribute1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Flex Attribute 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierFlexAtt1}
                                    onAdd={(chips) => handleSupplierFlexAtt1(chips)}
                                    onDelete={(chip) => handleSupplierFlexAtt1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_flex_attribute2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Flex Aattribute 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierFlexAtt2}
                                    onAdd={(chips) => handleSupplierFlexAtt2(chips)}
                                    onDelete={(chip) => handleSupplierFlexAtt2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_group1') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Group 1
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierGroup1}
                                    onAdd={(chips) => handleSupplierGroup1(chips)}
                                    onDelete={(chip) => handleSupplierGroup1Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_group2') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Group 2
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierGroup2}
                                    onAdd={(chips) => handleSupplierGroup2(chips)}
                                    onDelete={(chip) => handleSupplierGroup2Delete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: (listOF.includes('supplier_type') ? 'block' : 'none') }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Supplier Type
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={supplierType}
                                    onAdd={(chips) => handleSupplierType(chips)}
                                    onDelete={(chip) => handleSupplierTypeDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: 50 }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Historical - Actual"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} >Historical Actual </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Historical - What-If"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} >Historical What-If </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    (radioGroupValue === "Historical - What-If" && priceCalcFormula.length > 0 && billingDateStart && billingDateEnd && tag.length > 0) || (radioGroupValue === "Historical - Actual" && priceCalcFormula.length > 0 && billingDateStart && billingDateEnd)
                                        ?
                                        report == 'report'
                                            ?
                                            (calculationSimulationNumber && calculationSimulationNumber.length > 0 ? false : true)
                                            :
                                            false
                                        :
                                        true
                                }
                            > {props.loading ? <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                : 'Run'
                                }
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen2} twoline='true'
                                disabled={
                                    (radioGroupValue === "Historical - What-If" && priceCalcFormula.length > 0 && billingDateStart && billingDateEnd && tag.length > 0) || (radioGroupValue === "Historical - Actual" && priceCalcFormula.length > 0 && billingDateStart && billingDateEnd)
                                        ?
                                        report == 'report'
                                            ?
                                            (calculationSimulationNumber && calculationSimulationNumber.length > 0 ? false : true)
                                            :
                                            false
                                        :
                                        true
                                }
                            > {props.loading ? <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                : 'Schedule Batch Job'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Margin Simulation ?' handleDialog={handleDialog} />
            </div>
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Batch Job Name
                    </FormLabel>
                    <OutlinedInput
                        value={batchJobName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleBatchJobName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.profitOptimizationData.priceCalForListData,
        optionalField: state.profitOptimizationData.priceListMasterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        onSubmit: (formData) => dispatch(runMarginAnalysis(formData)),
        runProfitSimulation: (formData) => dispatch(runProfitCalcForSimulation(formData)),
        getOptionalField: (pagination, limit) => dispatch(getAnalysisLevel(pagination, limit)),
        onSubmitBatchJob: (data) => dispatch(addPricingBatchJobSetup(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarginAnalysis);