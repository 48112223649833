import React from 'react';
import { ExternalPaymentPostingForm } from './containers';

const ExternalPaymentPosting = props => {
    const [paymentData, setpaymnetData] = React.useState();

    return (
        <ExternalPaymentPostingForm setPayload1={setpaymnetData} />
    );

};

export default ExternalPaymentPosting;