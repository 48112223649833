import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Typography, Breadcrumbs, Card, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getIntegrationMappingDataById } from '../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';

const ViewIntegrationMapping = ({ integrationData, getIntegrationData }) => {
    const theme = useTheme();
    const { id: appId } = useParams();
    const [dataRows, setDataRows] = useState([]);

    useEffect(() => {
        if (appId) {
            getIntegrationData(appId);
        }
    }, [appId, getIntegrationData]);

    useEffect(() => {
        setDataRows(integrationData || []);
    }, [integrationData]);

    const columns = [
        {
            field: 'integration_name',
            title: 'Integration Name',
            render: (item) => <Typography variant="body2">{item.integration_name}</Typography>,
        },
        {
            field: 'activity',
            title: 'Activity',
            render: (item) => <Typography variant="body2">{item.activity}</Typography>,
        },
        {
            field: 'source_field_name',
            title: 'Source Field Name',
            render: (item) => <Typography variant="body2">{item.source_field_name}</Typography>,
        },
        {
            field: 'target_field_name',
            title: 'Target Field Name',
            render: (item) => <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>{item.target_field_name.replace(/_/g, ' ')}</Typography>,
        },
    ];

    return (
        <div style={{ backgroundColor: theme.palette.background.paper, marginTop: 10, borderRadius: 10, padding: '27px 29px' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link to='/integration-mapping' sx={{ color: theme.palette.text.primary, opacity: 0.3, textDecoration: 'none', fontSize: 16 }}>
                    Integration Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>View Integration Mapping</Typography>
            </Breadcrumbs>
            <div style={{ backgroundColor: theme.palette.background.paper, border: '1px solid #EEEEEE', borderRadius: 6, marginBottom: 23, marginTop: 25 }}>
                <Grid style={{ marginTop: -23, marginLeft: -13 }}>
                    <Card>
                        <CardHeader
                            title="INTEGRATION MAPPING DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                            sx={{ marginTop: '-1rem' }}
                        />
                        <Grid container style={{ padding: '0px 16px', marginTop: '-1rem' }}>
                            <Grid item xs={12}>
                                {dataRows.length > 0 ? (
                                    <MaterialTable
                                        components={{
                                            Toolbar: () => <div style={{ height: "0px" }} />,
                                        }}
                                        title=''
                                        editable
                                        icons={tableIcons}
                                        columns={columns}
                                        data={dataRows}
                                        options={{
                                            search: false,
                                            filtering: true,
                                            paging: false,
                                            headerStyle: theme.mixins.MaterialHeader,
                                            cellStyle: theme.mixins.MaterialCell,
                                        }}
                                    />
                                ) : (
                                    <Typography variant='h4'>There is no data to show now.</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    integrationData: state.customerData.integrationMappingSingleData,
});

const mapDispatchToProps = (dispatch) => ({
    getIntegrationData: (id) => dispatch(getIntegrationMappingDataById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewIntegrationMapping);
