import React, { useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import Moment from "moment";
import allStates from "../../../Analytics/AllStates.json";
import {
  geographicalIncentive,
  loadValuesBasedOnAppTypeAndField,
  allowedApps,
} from "../../../../redux/actions";
import { connect } from "react-redux";
import { endOfMonth, subMonths, startOfMonth } from "date-fns";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const GeographicalChart = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: "20px 30px 0px 30px",
      borderRadius: 10,
    },
    rootDiv: {
      backgroundColor: theme.palette.white,
      borderRadius: 10,
      margin: "16px 0px 16px 0px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 32px 0px 20px",
      alignItems: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    select: {
      width: "100%",
      color: "#1675e0",
      backgroundColor: "white",
    },
    formLabel: {
      width: '100%',
      paddingLeft: 20,
      // paddingTop: 10,
      fontSize: theme.typography.h1.fontSize,
      display: 'flex',
      justifyContent: 'center',

    },
  }));
  const [offsets, setOffsets] = React.useState([]);
  const handleStartDate = (e) => {
    if (e && e.isValid()) {
      setStartDate(e);
    } else {
      setStartDate(null);
    }
    if (e && e.isValid())
      props.geographicalIncentive(
        Moment(e).format("MM/DD/YYYY"),
        Moment(endDate).format("MM/DD/YYYY"),
        postingType,
        applicationType,
        sourceDataType
      );
  };
  const handleEndDate = (e) => {
    if (e && e.isValid()) {
      setEndDate(e);
    } else {
      setEndDate(null);
    }
    if (e && e.isValid())
      props.geographicalIncentive(
        Moment(startDate).format("MM/DD/YYYY"),
        Moment(e).format("MM/DD/YYYY"),
        postingType,
        applicationType,
        sourceDataType
      );
  };

  const [geographicalIncentiveDataAPI, setGeographicalIncentiveDataAPI] =
    React.useState([]);
  useEffect(() => {
    if (props.geographicalIncentiveData) {
      setGeographicalIncentiveDataAPI(props.geographicalIncentiveData);
    }
  }, [props.geographicalIncentiveData]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [dateRangeConvertedStart, setDateRangeConvertedStart] =
    React.useState("");
  const [dateRangeConvertedEnd, setDateRangeConvertedEnd] = React.useState("");
  const [dateRangeValue, setDateRangeValue] = React.useState([
    new Date("2020-06-01T05:30:00"),
    new Date("2020-10-01T05:30:00"),
  ]);
  const classes = useStyles();
  useEffect(() => {
    setStartDate(
      Moment().subtract(2, "months").startOf("month").format("MM/DD/YYYY")
    );
    setEndDate(
      Moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY")
    );
    setSourceDataType("Direct - Sales Data");
    setDateRangeValue([
      startOfMonth(subMonths(new Date(), 2)),
      endOfMonth(subMonths(new Date(), 1)),
    ]);
    setDateRangeConvertedStart(
      Moment(startOfMonth(subMonths(new Date(), 2))).format("MM/DD/YYYY")
    );
    setDateRangeConvertedEnd(
      Moment(startOfMonth(subMonths(new Date(), 1))).format("MM/DD/YYYY")
    );
    if (props.page === "dashboard")
      props.geographicalIncentive(
        Moment().subtract(2, "months").startOf("month").format("MM/DD/YYYY"),
        Moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY"),
        "Accrual,Accrual Reversal",
        "Customer Rebate",
        "Direct - Sales Data"
      );
  }, []);

  useEffect(() => {
    props.allowedApps();
  }, []);

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);

  const [postingType, setPostingType] = React.useState("Accrual");
  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    if (sourceDataType)
      props.geographicalIncentive(
        Moment(startDate).format("MM/DD/YYYY"),
        Moment(endDate).format("MM/DD/YYYY"),
        e.target.value,
        applicationType,
        sourceDataType
      );
  };

  const [sourceDataType, setSourceDataType] = React.useState("");
  const [applicationType, setApplicationType] =
    React.useState("Customer Rebate");
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] =
    React.useState([]);
  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);
  useEffect(() => {
    props.loadSourceDataType("Customer Rebate", "source_data_type");
  }, []);

  return (
    <div className={classes.root}>
      <Typography color="primary" className={classes.formLabel}>Regional Analysis </Typography>
      <Grid container className={classes.root}>
        {/* {geographicalIncentiveDataAPI &&
          geographicalIncentiveDataAPI.length > 0 ? ( */}
        <ComposableMap
          projection="geoAlbersUsa"
          style={{
            marginTop: -60,
            // padding: "0px 40px 0px 40px",
            height: 500,
          }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#FFF"
                    geography={geo}
                    style={{
                      default: {
                        fill: "#CEF6F5",
                      },
                      hover: {
                        color: "white",
                      },
                      pressed: {
                        fill: "#E42",
                      },
                    }}
                  />
                ))}
                {geographies.map((geo) => {
                  const centroid = geoCentroid(geo);
                  const cur = allStates.find((s) => s.val === geo.id);
                  return (
                    <g key={geo.rsmKey + "-name"}>
                      {cur &&
                        centroid[0] > -160 &&
                        centroid[0] < -67 &&
                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                          <Marker coordinates={centroid}>
                            <>
                              <text
                                y="2"
                                fontSize={15}
                                textAnchor="middle"
                              ></text>
                              <text
                                x="-9"
                                y="12"
                                fontSize={22}
                                textAnchor="left"
                                color={"white"}
                              >
                                {geographicalIncentiveDataAPI
                                  .filter((item) => item["region"] === cur.id)
                                  .map(function (d, idx) {
                                    return d["incentive_ratio"]
                                      ? d["incentive_ratio"].toFixed(2)
                                      : "";
                                  })}
                              </text>
                            </>
                          </Marker>
                        ) : (
                          <Annotation
                            subject={centroid}
                            dx={offsets[cur.id][0]}
                            dy={offsets[cur.id][1]}
                          >
                            <text
                              x={4}
                              fontSize={9}
                              alignmentBaseline="middle"
                            >
                              {cur.id} - {cur.val}
                            </text>
                          </Annotation>
                        ))}
                    </g>
                  );
                })}
              </>
            )}
          </Geographies>
        </ComposableMap>
        {/* ) : (
        <div
          style={{
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "13.75rem"
          }}
        >
          <Typography variant="h4">There is no data to show now.</Typography>
        </div>
        )} */}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    geographicalIncentiveData: state.initialData.georgraphicalIncentive,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geographicalIncentive: (
      s,
      e,
      postingType,
      applicationType,
      sourceDataType
    ) =>
      dispatch(
        geographicalIncentive(
          s,
          e,
          postingType,
          applicationType,
          sourceDataType
        )
      ),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    allowedApps: () => dispatch(allowedApps()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeographicalChart);
