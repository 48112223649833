import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const Ima360Data = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [supplierMasterData, setSupplierMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setSupplierMasterData(props.masdata);
    }, [props.masdata]);
    return (
        <div className={clsx(classes.root, className)}>
            <Card>
                <CardHeader
                    title="IMA360 DATA"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {supplierMasterData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {supplierMasterData.claim_edi_format &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['claim_edi_format'] ? fieldLabel['claim_edi_format']['current'] : 'Claim Edit Format'} data={supplierMasterData.claim_edi_format} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.claim_excel_format &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['claim_excel_format'] ? fieldLabel['claim_excel_format']['current'] : 'Claim Excel Format'} data={supplierMasterData.claim_excel_format} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.claim_email_address &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['current'] : 'Claim Email Address'} data={supplierMasterData.claim_email_address} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.flex_attribute1 &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} data={supplierMasterData.flex_attribute1} twoline='true' />
                                </Grid>
                            }
                            {supplierMasterData.flex_attribute2 &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} data={supplierMasterData.flex_attribute2} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        // supplierMasterData: state.customerData.supplierMasterViewData
    }
}
export default connect(mapStateToProps, null)(Ima360Data);