import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    useMediaQuery,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadingOverlay from 'react-loading-overlay';
import { createTheme } from "@mui/material/styles";
import HashLoader from 'react-spinners/HashLoader';
import MaterialTable from 'material-table';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import ViewColumn from '@mui/icons-material/ViewColumn';
import Moment from 'moment';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    spinner: {
        height: '100vh'
    },
}));
const SalesForecast = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [dialog, setDialog] = React.useState(false)
    const [errorList, setErrorList] = React.useState([])
    const [salesForecastData, setSalesForecastData] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)

    const columns = [
        {
            field: 'billing_date',
            title: 'Billing Date',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.billing_date).format('MM/DD/YYYY')}</a>

        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.country}</a>
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.state}</a>
        },
        {
            field: 'material_number',
            title: 'Material Number',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.material_number}</a>
        },
        {
            field: 'quantity',
            title: 'Quantity',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.quantity}</a>
        },

        {
            field: 'net_value',
            title: 'Net Value',
            type: 'number',
            render: (rowData) => <a className={classes.hover} > {rowData.net_value}</a>
        },
    ];
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        var tempRows = [];
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        };
        if (props.salesForecastData && props.salesForecastData.records && props.salesForecastData.records.length > 0)
            props.salesForecastData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        billing_date: e.billing_date,
                        country: e.country,
                        state: e.state,
                        material_number: e.material_number,
                        quantity: e.quantity,
                        net_value: e.net_value,
                    });
                })
        setDataRows(tempRows);
    }, [props.salesForecastData]);
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    useEffect(() => {
        if (props.salesForecastError && props.salesForecastError.length > 0) {
            setErrorList(props.salesForecastError)
        }
        if (errorList && errorList.length > 0 && (JSON.stringify(errorList) === JSON.stringify(props.salesForecastError))) {
            setErrorDialogOpen(true)
        }
    }, [props.salesForecastError])
    useEffect(() => {
        if (props.salesForecast && props.salesForecast.records && props.salesForecast.records.length > 0) {
            setSalesForecastData(props.salesForecast.records)
        }
    }, [props.salesForecast])
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}

        >
            <Dialog
                open={errorDialogOpen}
            >
                <DialogTitle>Error Summary</DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>
                    <List>
                        {errorList && errorList.map((item, index) => {
                            return (<ListItem>
                                <ListItemText
                                    primary={[index + 1] + ' . ' + item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>

                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={() => (setErrorDialogOpen(false), setErrorList(null))}>Continue</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <div className={clsx(classes.root, className)}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 22 }}> Sales Forecast </div>
                </div>
                {props.salesForecastData && props.salesForecastData.records && props.salesForecastData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </div >
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        salesForecastData: state.dataSetupData.salesForecastData,
        salesForecastError: state.dataSetupData.salesForecastErrorList,
        salesForecast: state.dataSetupData.salesForecast,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};
export default connect(mapStateToProps, null)(SalesForecast);