import React, { useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import Moment from 'moment';
import allStates from "../../AllStates.json";
import { geographicalIncentive, loadValuesBasedOnAppTypeAndField, allowedApps } from "../../../../redux/actions";
import { connect } from 'react-redux';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';
import { makeStyles } from "@mui/styles";
import { Select, MenuItem, FormLabel, Typography, Button, TextField } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import Region from "../Components/../../Charts/Region"

// const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";



const colorScale = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];



const MapChart = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: '15px 0px 15px 0px',
            display: 'flex',
            alignItems: 'center'
        },
        rootDiv: {
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            margin: '5px 0px 16px 0px',
        },
        select: {
            width: "100%",
            color: "#1675e0",
        },
        selectedItem: {
            borderRadius: 5,
        },
        fontSetting: {
            fontSize: theme.typography.h4.fontSize
        },
        spinner: {

        },
        buttonContainer: {
            justifyContent: "center",
            display: "flex",
            paddingTop: 29,
            paddingBottom: 5,
        },
        button: {
            marginRight: 10,
            // width: 80,
        },
    }));
    const [offsets, setOffsets] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [optionType, setOptionType] = React.useState('');
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [groupBy, setGroupBy] = React.useState('contract_number');
    const classes = useStyles();

    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Incentive Ratio by Attribute" && item.dashboard_name === "Postings Detail Analysis"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy));
            setSourceDataType(allowedTilesFilter[0].data_source_type);
            setPostingType(capitalizeFirstLetter(allowedTilesFilter[0].posting_type));
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (startDate && endDate && sourceDataType && postingType && groupBy) {
            props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, groupBy);
        }
    }, [allowedTilesFilter]);

    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }
    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    // const handleVarience = (e) => {
    //     setOptionType(e.target.value);
    // }
    // const handleOutput = (e) => {
    //     setOutput(e.target.value);
    // }

    const [geographicalIncentiveDataAPI, setGeographicalIncentiveDataAPI] = React.useState([]);
    useEffect(() => {
        if (props.geographicalIncentiveData) {
            setGeographicalIncentiveDataAPI(props.geographicalIncentiveData);
        }
    }, [props.geographicalIncentiveData])

    useEffect(() => {
        props.allowedApps();
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const [postingType, setPostingType] = React.useState('Accrual');
    const handlePostingType = (e) => {
        setPostingType(e.target.value);
    }
    const [sourceDataType, setSourceDataType] = React.useState('Direct  - Sales Data');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    // useEffect(() => {

    //     props.geographicalIncentive(Moment().subtract(2, 'months').startOf('month').format('MM/DD/YYYY'), Moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'), 'accrual', sessionStorage.getItem("application"), 'Direct - Sales Data', 'contract_type');
    // }, []);

    function convertToSnakeCase(string) {
        if (string) {
            return string.replace(/\s+/g, '_').toLowerCase();
        }
        return "";
    }

    function capitalizeFirstLetter(word) {
        if (word && word.length > 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return ""; // Or any other default value you prefer
    }
    function handleSubmit() {
        props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, groupBy);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>
                    <Grid container style={{ display: 'flex', flexWrap: 'nowrap', padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}
                    >
                        {/* <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Application Type</Typography>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps
                                        .filter(item => item !== 'Customer Chargeback' && item !== 'Promotions' && item !== 'Supplier Chargeback' && item !== 'Pricing' && item !== 'Profit Optimization')
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid> */}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={endDate}
                                            minDate={startDate ? startDate : false}
                                            onChange={handleEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Source Data Type</Typography>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Posting Type</Typography>
                                <Select
                                    value={postingType}
                                    onChange={handlePostingType}
                                    // label='Posting Type'
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}

                                >
                                    <MenuItem value={'Accrual'}>
                                        Accrual
                                    </MenuItem>
                                    <MenuItem value={'Payment'}>
                                        Payment
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmit}
                                twoline="true"
                                disabled={startDate && endDate && sourceDataType && postingType && groupBy ? false : true}
                            >
                                <DirectionsRunIcon />
                            </Button>
                        </div>
                    </Grid>
                    {/* to unhide */}
                    <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: 36
                        }}
                    >
                        {geographicalIncentiveDataAPI && geographicalIncentiveDataAPI.length > 0 ?
                            <Region data={geographicalIncentiveDataAPI} />
                            // <ComposableMap projection="geoAlbersUsa" style={{ width: '700px', height: '725px', margin: '-7em 1em -6em 2em', padding: '0px' }}>
                            //     <Geographies geography={geoUrl}>
                            //         {({ geographies }) => (
                            //             <>
                            //                 {geographies.map(geo => (
                            //                     <Geography
                            //                         key={geo.rsmKey}
                            //                         stroke="red"
                            //                         geography={geo}
                            //                         fill={'Yellow'}
                            //                     />
                            //                 ))}
                            //                 {geographies.map(geo => {
                            //                     const centroid = geoCentroid(geo);
                            //                     const cur = allStates.find(s => s.val === geo.id);
                            //                     return (
                            //                         <g key={geo.rsmKey + "-name"}>
                            //                             {cur &&
                            //                                 centroid[0] > -160 &&
                            //                                 centroid[0] < -67 &&
                            //                                 (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                            //                                     <Marker coordinates={centroid}>
                            //                                         <>
                            //                                             <text y="2" fontSize={8} textAnchor="middle">
                            //                                                 {cur.id}

                            //                                             </text>
                            //                                             <text x="-9" y="12" fontSize={10} textAnchor="left">
                            //                                                 {geographicalIncentiveDataAPI
                            //                                                     .filter(item => item['region'] === cur.id)
                            //                                                     .map(function (d, idx) {
                            //                                                         return (d['incentive_ratio'] ? d['incentive_ratio'].toFixed(2) : '')
                            //                                                     })}
                            //                                             </text>
                            //                                         </>
                            //                                     </Marker>
                            //                                 ) : (
                            //                                     <Annotation
                            //                                         subject={centroid}
                            //                                         dx={offsets[cur.id][0]}
                            //                                         dy={offsets[cur.id][1]}
                            //                                     >
                            //                                         <text x={4} fontSize={9} alignmentBaseline="middle">
                            //                                             {cur.id} -  {cur.val}
                            //                                         </text>
                            //                                     </Annotation>
                            //                                 ))}
                            //                         </g>
                            //                     );
                            //                 })}
                            //             </>
                            //         )}
                            //     </Geographies>
                            // </ComposableMap>
                            :
                            <Typography variant='h4' align="center">
                                No Data
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        geographicalIncentiveData: state.initialData.georgraphicalIncentive,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        geographicalIncentive: (s, e, postingType, applicationType, sourceDataType, groupBy) => dispatch(geographicalIncentive(s, e, postingType, applicationType, sourceDataType, groupBy)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapChart);