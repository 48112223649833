import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Breadcrumbs,

    Card,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getCotXRefDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';

var editableId = '';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const ViewCotXRef = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [membershipOwnerId, setMembershipOwnerId] = React.useState('');
    const [membershipOwnerName, setMembershipOwnerName] = React.useState('');
    const [membershipOwnerClassofTrade, setMembershipOwnerClassofTrade] = React.useState('');
    const [imaClassOfTrade, setImaClassOfTrade] = React.useState('');
    const [imaClassOfTradeDescription, setImaClassOfTradeDescription] = React.useState('');
    const channelPartnerTypeRef = useRef(null);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getCotXRefDetails(appId);
    }, []);
    useEffect(() => {
        if (props.cotXrefViewData) {
            setMembershipOwnerId(props.cotXrefViewData.channel_partner_id);
            setMembershipOwnerName(props.cotXrefViewData.channel_partner_name);
            setMembershipOwnerClassofTrade(props.cotXrefViewData.channel_partner_class_of_trade);
            setImaClassOfTrade(props.cotXrefViewData.ima_class_of_trade);
            setImaClassOfTradeDescription(props.cotXrefViewData.ima_class_of_trade_description);
        }
    }, [props.cotXrefViewData]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/cot-xref'
                >
                    COT XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>View COT XREF</Typography>
            </Breadcrumbs>
            {props.cotXrefViewData &&
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    {membershipOwnerId &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading='Membership Owner ID' data={membershipOwnerId} twoline='true' />
                                        </Grid>
                                    }
                                    {membershipOwnerName &&
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <LabelText classes={{ root: classes.formLabel }} heading='Membership Owner Name' data={membershipOwnerName} twoline='true' />
                                        </Grid>
                                    }
                                    {membershipOwnerClassofTrade && <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Membership Owner Class of Trade' data={membershipOwnerClassofTrade} twoline='true' />
                                    </Grid>}
                                    {imaClassOfTrade && <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='IMA Class of Trade' data={imaClassOfTrade} twoline='true' />
                                    </Grid>}
                                    {imaClassOfTradeDescription && <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='IMA Class of Trade Description' data={imaClassOfTradeDescription} twoline='true' />
                                    </Grid>}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            }
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getCotXRefDetails: (id) => dispatch(getCotXRefDetails(id)),
    }
}

const mapStateToProps = state => {
    return {
        cotXrefViewData: state.customerData.cotXrefViewData,
        dropdownData: state.customerData.dropdownDataMaterialXRef,
        loadingAPI: state.customerData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCotXRef);