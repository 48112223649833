import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Button, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { Edit, DeleteForever, AddBoxOutlined, LibraryAdd } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { createPriceCalForList, getPriceCalForList } from '../../../redux/actions';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import secureLocalStorage from 'react-secure-storage';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 15,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const PriceCalculationFormula = props => {
    useEffect(() => {
        props.getConfigData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [configData, setConfigData] = React.useState([]);

    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.length > 0)
            props.configData
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        formula_name: e.formula_name,
                        formula_description: e.formula_description,
                        formula_type: e.formula_type,
                        pricing_strategy: e.pricing_strategy
                    });

                })
        setDataRows(tempRows);
    }, [props.configData])
    function editConfig(id) {
        history.push({
            pathname: '/pricing-calculation-formula/edit-formula/' + id,
            id: id
        });
    }
    function viewConfig(id) {
        history.push({
            pathname: '/pricing-calculation-formula/view-formula/' + id,
            id: id
        });
    }

    const deleteConfig = (id) => {
        props.onDelete(id);
    }
    const columns = [
        {
            field: 'formula_name',
            title: 'Formula Name',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewConfig(rowData.formula_name)} > {rowData.formula_name}</a>
        },
        {
            field: 'formula_description',
            title: 'Formula Description',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewConfig(rowData.formula_name)}> {rowData.formula_description}</a>
        },
        {
            field: 'formula_type',
            title: 'Formula Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewConfig(rowData.formula_name)}> {rowData.formula_type}</a>
        },
        {
            field: 'pricing_strategy',
            title: 'Pricing Strategy',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewConfig(rowData.formula_name)}> {rowData.pricing_strategy}</a>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div> {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_simulation-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editConfig(rowData.formula_name)}
                        size="large">
                        <Edit color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula_price_simulation-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => deleteConfig(rowData.id)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ]



    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Formula Procedure </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula_price_simulation-' + sessionStorage.getItem('application')) &&
                        // <Button
                        //     variant="contained"
                        //     color="primary"
                        //     startIcon={<AddBoxOutlined />}
                        //     classes={{
                        //         startIcon: !isDesktop && classes.startIcon,
                        //         root: !isDesktop && classes.container
                        //     }}
                        //     onClick={() => history.push('/pricing-calculation-formula/add-formula')}
                        // >
                        //     {isDesktop ? 'Add Formula' : ''}
                        // </Button>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            // component={CustomRouterLink}
                            onClick={() => history.push('/pricing-calculation-formula/add-formula')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>

                    }
                </div>
                {configData ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ textTransform: 'capitalize', marginTop: '-18px' }}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            // pageSize: rowsPerPage,
                            // count:count,
                            // page:page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            // pageSize: 10,
                            // pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        configData: state.pricingData.priceCalForListData,
        approvalGroup: state.addMultipleConfigurationData.approvalGroupData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getPriceCalForList(pagination, limit, 'Price Calculation Formula')),
        onDelete: (id) => dispatch(createPriceCalForList(null, id, 'delete')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculationFormula);