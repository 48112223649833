import React, { useEffect, useRef } from 'react';
import {
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },

}));

const Ima360Data = props => {
    const classes = useStyles();
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [materialMasterData, setMaterialMasterData] = React.useState([]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    useEffect(() => {
        setMaterialMasterData(props.masdata);
    }, [props.masdata]);

    return (
        <div className={clsx(classes.root, className)}>
            {materialMasterData &&
                <div className={classes.container}>
                    <Grid container className={classes.gridContainer}>
                        {materialMasterData.cost && materialMasterData.cost != 0 ?
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'} data={materialMasterData.cost} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {materialMasterData.msrp && materialMasterData.msrp != 0 ?
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'} data={materialMasterData.msrp} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {materialMasterData.pricing_inventory_alert_qty && materialMasterData.pricing_inventory_alert_qty != 0 ?
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'} data={materialMasterData.pricing_inventory_alert_qty} twoline='true' />
                            </Grid>
                            : ""
                        }
                        {materialMasterData.flex_attribute1 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} data={materialMasterData.flex_attribute1} twoline='true' />
                            </Grid>
                        }
                        {materialMasterData.flex_attribute2 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'} data={materialMasterData.flex_attribute2} twoline='true' />
                            </Grid>
                        }
                        {materialMasterData.flex_attribute3 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'} data={materialMasterData.flex_attribute3} twoline='true' />
                            </Grid>
                        }
                        {materialMasterData.flex_attribute4 &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <LabelText classes={{ root: classes.formLabel }} heading={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'} data={materialMasterData.flex_attribute4} twoline='true' />
                            </Grid>
                        }
                    </Grid>
                </div>
            }
        </div>
    );
}
const mapStateToProps = state => {
    return {
        // materialMasterData: state.customerData.materialMasterViewData
    }
}
export default connect(mapStateToProps, null)(Ima360Data);