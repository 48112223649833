import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
    Typography,
    Breadcrumbs,
    Grid
} from '@mui/material';
import { connect } from 'react-redux';
import { getPromoFinanceCostDetails } from '../../redux/actions';
import { createTheme } from "@mui/material/styles";
import { LabelText } from '../../components/Inputs';
import { Link } from 'react-router-dom';

const theme = createTheme({
    overrides: {

        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const ViewFinanceCost = props => {
    const { className } = props;
    const classes = useStyles();
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getPromoFinanceCostDetails(appId);
    }, []);
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link,
                }}
                    style={{
                        color: theme.palette.text.primary,
                        opacity: 0.3,
                        textDecoration: 'none',
                        border: 0,
                        fontSize: 16,
                        paddingRight: 10
                    }}
                    to='/finance-cost'
                >
                    Finance Cost
                </Link>
                <Typography color="textPrimary" variant='h1'>View Finance Cost</Typography>
            </Breadcrumbs>
            <div className={classes.root}>

                {props.promoFinanceCostDetailsData &&
                    <div className={classes.bodyContainer}>
                        <div className={classes.container2}>
                            <Grid container spacing={2} style={{ paddingLeft: 10 }}>
                                {props.promoFinanceCostDetailsData.finance_name &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Finance Name' data={props.promoFinanceCostDetailsData.finance_name} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.term_wording_en &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Term Wording EN' data={props.promoFinanceCostDetailsData.term_wording_en} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.term_wording_fr &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Term Wording FR' data={props.promoFinanceCostDetailsData.term_wording_fr} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender_wording &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender Wording' data={props.promoFinanceCostDetailsData.lender_wording} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.sb_term_full_wording_en &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='SB Term Full Wording EN' data={props.promoFinanceCostDetailsData.sb_term_full_wording_en} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.sb_term_full_wording_fr &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='SB Term Full Wording FR' data={props.promoFinanceCostDetailsData.sb_term_full_wording_fr} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.sb_cover_page_wording_en &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='SB CoverPage Wording EN' data={props.promoFinanceCostDetailsData.sb_cover_page_wording_en} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.sb_cover_page_wording_fr &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='SB CoverPage Wording FR' data={props.promoFinanceCostDetailsData.sb_cover_page_wording_fr} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.country &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Country' data={props.promoFinanceCostDetailsData.country} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.interest_rate != 0 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Interest Rate' data={props.promoFinanceCostDetailsData.interest_rate} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.fin_term_structure &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Finance Term Structure' data={props.promoFinanceCostDetailsData.fin_term_structure} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.finance_cost != 0 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Finance Cost' data={props.promoFinanceCostDetailsData.finance_cost} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender1 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender 1' data={props.promoFinanceCostDetailsData.lender1} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender2 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender 2' data={props.promoFinanceCostDetailsData.lender2} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender3 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender 3' data={props.promoFinanceCostDetailsData.lender3} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender4 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender 4' data={props.promoFinanceCostDetailsData.lender4} twoline='true' />
                                    </Grid>
                                }
                                {props.promoFinanceCostDetailsData.lender5 &&
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}

                                    >
                                        <LabelText classes={{ root: classes.formLabel }} heading='Lender 5' data={props.promoFinanceCostDetailsData.lender5} twoline='true' />
                                    </Grid>
                                }
                            </Grid>

                        </div>


                    </div >
                }
            </div>

        </div >
    );
};
const mapStateToProps = state => {
    return {

        promoFinanceCostDetailsData: state.promotionData.promoFinanceCostDetailsData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPromoFinanceCostDetails: (id) => dispatch(getPromoFinanceCostDetails(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFinanceCost);