import React, { useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { RingLoader } from 'react-spinners';

const PromoTable = (props) => {
    const [colorPicker, setColorPicker] = React.useState({});
    useEffect(() => {
        if (props.colorPicker) {
            setColorPicker(prev => ({
                ...prev, ...props.colorPicker
            }))
        }
    }, [props.colorPicker])

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "30px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader />}
        >
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>

                        <TableRow>
                            <TableCell></TableCell>
                            {props.columnHeaders && props.columnHeaders.map(header => (
                                <TableCell key={header} align="left" className='promo-font'>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* <TableBody>{console.log(props.filteredData)} */}
                    {props.filteredData && Object.values(props.filteredData).map((data, index) => (
                        <TableBody style={{ borderBottom: '2px solid' }}>
                            {data && Object.entries(data).map(([rowHeader, value], i) => (
                                <TableRow key={rowHeader} >
                                    <TableCell component="th" scope="row" className='promo-font'>
                                        {rowHeader}
                                    </TableCell>
                                    {props.columnHeaders && props.columnHeaders.map((colHeader, i) => {
                                        const cellData = value?.[colHeader];
                                        const stickerName = cellData?.sticker_name;
                                        const color = colorPicker && stickerName && colorPicker[stickerName] ? colorPicker[stickerName] : 'red';
                                        const bgclr = cellData?.highlight ? 'yellow' : ''
                                        const activeState = props.comparedState ? ((props.comparedState.state == cellData?.state) && (props.comparedState.region == cellData?.region)) : false
                                        console.log(props.comparedState)
                                        return (
                                            <TableCell key={`${rowHeader}-${colHeader}`} align="right" className='promo-font'
                                                style={{ backgroundColor: props.compare && activeState ? bgclr : '' }}
                                            >
                                                {cellData ?
                                                    <div style={{ display: 'flex', alignItems: 'center' }} key={`${rowHeader}-${colHeader}`}>
                                                        {stickerName &&
                                                            <CircleIcon
                                                                style={{ color: color, width: '12px', height: '12px' }}
                                                                key={stickerName} />
                                                        }
                                                        <span style={{ marginLeft: '5px' }}>{cellData['sticker_description']}</span>
                                                    </div>
                                                    : ''}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    ))}
                    {/* </TableBody> */}
                </Table>
            </TableContainer>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
    }
};
export default connect(mapStateToProps, null)(PromoTable);































