import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";


const SecurityCockpit = (props) => {

    const handleClick = (pge) => {
        history.push(pge)
    }

    const user = secureLocalStorage.getItem("dYtz");
    const hasRight = (right) => {


        return (
            (user.role.rights &&
                user.role.rights.includes(`${right}`))
        );
    };

    const hasSecurityRoleRight = hasRight("post_security_role") || hasRight("put_security_role") || hasRight("delete_security_role") || user.role.name.includes('org_admin');
    const hasCreateUserRight = hasRight("post_user") || hasRight("put_user") || hasRight("delete_user");
    const hasCreateCollaborationIdRight = hasRight("post_collaboration");

    const menuItems = [

        // { 'Security Role Designer': '/access' },
        // { 'User Setup': '/user-role' },
        // { 'Collaboration ID': '/collaboration-id' },
        // { 'Collaboration Designer': '/collaboration-designer' }
        ...(hasSecurityRoleRight ? [{ 'Security Role Designer': '/access' }] : []),
        ...(hasCreateUserRight ? [{ 'User Setup': '/user-role' }] : []),
        ...(hasCreateCollaborationIdRight ? [{ 'Collaboration ID': '/collaboration-id' }] : []),
        ...(hasCreateCollaborationIdRight ? [{ 'Collaboration Designer': '/collaboration-designer' }] : [])
    ]

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })} key={i}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]} key={i}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityCockpit);