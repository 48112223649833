import React from 'react';
import { makeStyles } from "@mui/styles";
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
    },
    icon: {
        fontSize: 60
    }
}));

const UpcomingFeature = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BatteryCharging90Icon className={classes.icon} />
            <h1> COMING SOON </h1>
        </div>
    );
    
};

export default UpcomingFeature;