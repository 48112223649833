import React, { useEffect } from 'react';
import { makeStyles} from '@mui/styles';
import { Typography,Paper,} from '@mui/material';
import { connect } from 'react-redux';
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import {
  ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Sort, Resize, Aggregate,
  ExcelExport, Toolbar, PdfExport, ColumnMenu, VirtualScroll
} from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.white,
      borderRadius: 10,
      padding: '10px 10px 0px 10px',
      //width:'100%',
      width:'fit-content',
      overflow: 'scroll'
      
  },
  gridBox: {
      backgroundColor: theme.palette.white,
      borderRadius: 10,
      padding: '10px 10px 0px 10px',
      marginBottom:10,
      //overflowX: 'hidden',
      overflowY: 'scroll'
  }
  }));
const PriceElasticityResult = props => {
    let grid;
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const sortedArray=[
      'material_number',
      'price_impact',
      'volume_impact',
      'net_impact',
      'price_elasticity',
      'delta_net_value_percentage',
      'delta_unit_price_percentage',
      'historical_net_value',
      'current_net_value',
      'delta_net_value',
      'historical_cost',
      'current_cost',	'delta_cost',	
      'delta_cost_percentage',
      'historical_quantity',	
      'current_quantity',
      'delta_quantity',
      'delta_quantity_percentage',
      'historical_margin',
      'current_margin',
      'delta_margin',
      'delta_margin_percentage',
      'historical_unit_price',
      'current_unit_price',
      'delta_unit_price'];
    useEffect(() => {
      var newArray=[]
      if (props.priceElasticityData && props.priceElasticityData.length > 0) {            
          Object.values(props.priceElasticityData).map(item=>{
             Object.keys(item).map((e,index)=>{
                  newArray.push({'field':e,'title':e ? e.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ')}):e})
              })
              
          }) 
          setColumns(newArray.sort(function (a, b) {
            return sortedArray.indexOf(a.field) - sortedArray.indexOf(b.field);
        }).filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.title === value.title
          ))
        ))
          setDataRows(props.priceElasticityData);
      }
  }, [props.priceElasticityData]);
  const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
  const toolbarClick = (args) => {
      if (grid && args.item.id === 'grid_pdfexport') {
          grid.showSpinner();
          const exportProperties = {
              pageOrientation: 'Landscape',
              pageSize: 'A0',
              fileName: Moment.utc(new Date()).format('MM/DD/YYYY')+'.pdf',
              theme: {
                  header: {
                      bold: true,
                      fontSize: 14,
                  },
                  record: {
                      bold: false,
                      fontSize: 14,
                  },
                  caption: {
                      bold: true,
                      fontSize: 14,
                  },
              }
          };
          grid.pdfExport(exportProperties);
      }
      if (grid && args.item.id === 'grid_excelexport') {
          grid.showSpinner();
          const excelExportProperties = {
              dataSource: [...dataRows],
          };
          grid.excelExport(excelExportProperties);
      }
  };
  
  const pdfExportComplete = () => {
      if (grid) {
          grid.hideSpinner();
      }
  };
  const excelExportComplete = () => {
      if (grid) {
          grid.hideSpinner();
      }
  };
  const filterSettings = { type: 'CheckBox' };
  const groupOptions={showGroupedColumn:true}
  const valueFormat = (field, data, column) => {
    if (data &&data[field].includes('.')) {
        return parseFloat(data[field]).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).toString()

    }
   else if (data && data[field].includes('T00:00:00Z')) {
        return Moment(data[field], true).isValid()
            ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
    }
    else
        return data[field];
  };
  const dataBound = () => {
      if (grid) {
        grid.autoFitColumns([
            'delta_cost',
            'delta_margin',
            'delta_margin_percentage',
            'delta_net_value',
            'delta_quantity',
            'delta_unit_price',
            'material_number',
            'net_impact',
            'price_elasticity',
            'price_impact',
            'volume_impact'
        ])
  
      }
    };
  
    return (
        <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      {props.priceElasticityData && props.priceElasticityData.length > 0 ?
                <Paper className={classes.gridBox} style={{ paddingTop: 0,}}>
                   <div style={{ marginTop: 10, overflow: 'scroll'}}>
                     <GridComponent
                    dataSource={dataRows}
                    id='grid' 
                    dataBound={dataBound} 
                    allowSorting={true} allowGrouping={true} showColumnMenu={true}
                    groupSettings={groupOptions}
                    filterSettings={filterSettings}
                    allowFiltering={true}
                    ref={g => grid = g}
                    toolbar={toolbarOptions}
                    toolbarClick={toolbarClick}
                    allowPdfExport={true} allowExcelExport={true}
                    pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                    height={window.screen.height * .80}
                    gridLines='Both' allowResizing={true}
                    enableVirtualization={true} enableColumnVirtualization={false}
                    >
                    <ColumnsDirective>
                    {columns.map((item, order) => {
                        return (
                        <ColumnDirective
                        field={item.field}
                        headerText={item.title}
                        width="200px" minWidth='100px' maxWidth='300px'
                        valueAccessor={valueFormat}
                    />
                    );
                    })
                    }
                    </ColumnsDirective>
                    <Inject services={[Toolbar, PdfExport, ExcelExport,Aggregate,Group,Sort,ColumnMenu,Filter,VirtualScroll,Resize]} />
                    </GridComponent>
                   </div>
                   </Paper>
                    :
                    <Typography variant='h4'>
                         There is no data to show now.
                    </Typography>
                
                }
    </LoadingOverlay>
    );

};


const mapStateToProps = state => {
    return {
        priceElasticityData: state.pricingData.priceElasticityData,
    }
};


export default connect(mapStateToProps, null)(PriceElasticityResult);