import React, { useEffect } from 'react';
import {
    Grid, Button, Typography,
    FormLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../components/Dialog';
import { getAnaylsisFormula, runMarginPolicyException, getAnalysisLevel, runProfitCalcForSimulation } from '../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import { RotateLoader, HashLoader } from "react-spinners";
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 170
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: '2.4rem',
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const MarginPolicyExceptions = props => {
    const classes = useStyles();
    const { className } = props;
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [radioGroupValue, setRadioGroupValue] = React.useState('Historical - Actual');

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        props.getConfigData(1, 10);
    }, []);
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push({ 'formula': e.formula_name, 'analysis_level_id': e.analysis_level_id })
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    console.log(props.marginPolicyExpectionData)
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    function handleBillingDateStart(newValue) {
        setBillingDateStart(Moment(newValue));
    }
    function handleBillingDateEnd(newValue) {
        setBillingDateEnd(Moment(newValue));
    }
    const handleClear = () => {
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setPriceCalcFormula([]);
        setRadioGroupValue('')
    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleDialog = (bool) => {
        if (bool) {

            const formData = {
                "start_date": Moment.utc(billingDateStart).format("YYYY-MM-DD"),
                "end_date": Moment.utc(billingDateEnd).format("YYYY-MM-DD"),
                "formula_name": priceCalcFormula,
                "simulation_category": radioGroupValue,
            };
            props.onSubmit(formData);
            setOpen(false);
            handleClear();
        }

    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 18 }}>
                        Margin Policy Exceptions
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}

                            >   <FormLabel classes={{ root: classes.fontSetting }} required >Start Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    height: 37,
                                                    marginBottom: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            //style={{marginTop:'-20px'}}
                            >   <FormLabel classes={{ root: classes.fontSetting }} required >End Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    height: 37,
                                                    marginBottom: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    //marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDate={billingDateStart ? billingDateStart : ''}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                    Analysis Formula
                                </FormLabel>
                                <Select
                                    value={priceCalcFormula}
                                    onChange={(e) => handlePriceCalFormula(e.target.value)}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item.formula} key={item.formula}>
                                                {item.formula + "-" + item.analysis_level_id}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: 50 }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Historical - Actual"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} >Historical Actual </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Historical - What-If"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} >Historical What-If </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={
                                    priceCalcFormula && billingDateStart && billingDateEnd
                                        ? false : true}
                            > {props.loading ? <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                : 'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Margin Policy Exceptions ?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        configData: state.profitOptimizationData.priceCalForListData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        onSubmit: (formData) => dispatch(runMarginPolicyException(formData)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarginPolicyExceptions);