import React, { useState, useEffect } from 'react';
import {
    Card,
    Button,
    TextField, Dialog, DialogContent, DialogTitle, DialogActions, Typography, Grid, Box
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { connect } from 'react-redux';
import { uploadAttachment } from '../../../../redux/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/Icons/TableIcons";
import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, Article } from '@mui/icons-material';
import { FormLabel } from '@mui/material';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        paddingLeft: 10,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    notesDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 10,
        marginBottom: 10
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15,
        overflow: 'hidden'
    },
    commentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'grey',
        fontSize: 13
    },
    dividerColor: {
        background: theme.palette.border.main,
        margin: '6px 0px 10px 0px'
    },
    dialogTitleRoot: {
        padding: '9px 24px'
    },
    dialogController: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },
    fileContainer: {
        fontSize: 12,
        justifyContent: 'center',
        height: '200',
        margin: '10px 20px 10px 10px'
    }
}));

const InternalNotes = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [notesArray, setNotesArray] = React.useState([]);
    const [fileName, setFileName] = useState('');
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileUrl, setCurrentFileUrl] = useState('');
    const [currentNote, setCurrentNote] = useState('');
    const [note, setNote] = React.useState('');
    const [executed, setExecuted] = useState(false);
    const [notes, setNotes] = useState('');
    const [open2, setOpen2] = React.useState(false);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    useEffect(() => {
        if (props.generalData && props.generalData.notes && props.generalData.notes.length > 0 && !executed) {
            setNotesArray(props.generalData.notes.filter((item, i) => item.note_type != "Submit Decision"));
            setExecuted(true)
        }
    }, [props.generalData])
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setNote('');
        setCurrentFileName('');
        setCurrentFileUrl('');
        setOpen(false);
        setFileName('')
    };
    const handleSave = (file) => {
        setNote('');
        setCurrentNote(note);
        setCurrentFileName(fileName);
        setOpen(false);
        var data = {
            text: note,
            attachments: currentFileUrl ? [currentFileUrl] : [],
            file_name: fileName
        };
        setNotesArray([...notesArray, data]);
        props.onChange([...notesArray, data]);
        setCurrentFileName('');
        setFileName('')
        setCurrentFileUrl('')
    };
    const handleFileUpload = (event) => {
        if (event.target.files) {
            setFileName(event.target.files[0].name);
            const data = new FormData();
            data.append('file', event.target.files[0]);
            // setCurrentFile(data);
            setCurrentFileName(event.target.files[0].name)
            props.uploadAttachment(data);
        }
    };
    const handleRemoveCard = (index) => {

        props.onChange([
            ...notesArray.slice(0, index),
            ...notesArray.slice(index + 1, notesArray.length)
        ]);
        setNotesArray([
            ...notesArray.slice(0, index),
            ...notesArray.slice(index + 1, notesArray.length)
        ]);


    }
    useEffect(() => {
        if (props.uploadUrl)
            setCurrentFileUrl(props.uploadUrl)
    }, [props.uploadUrl]);
    const handleNote = (e) => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        if (format.test(e.target.value)) {

        } else {
            setNote(e.target.value)
        }
    }
    const columns = [
        {
            field: "text",
            title: "Text",
            render: (rowData) => (
                <div style={{ padding: 5, justifyContent: 'center' }}>
                    {rowData.text.length > 45 ? rowData.text.slice(0, 45) + '...' : rowData.text}
                    {rowData.text.length > 45 && (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={(e) => handleToggleExpand(e, rowData.text)}
                            className={classes.buttonContainer}
                        >
                            Read More
                        </Link>
                    )}
                </div>
            )
        },
        {
            field: "attachments",
            title: "File",
            render: (rowData) => (
                <div>
                    {rowData.attachments && rowData.attachments.length > 0 &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {rowData && rowData.attachments[0].substring(rowData.attachments[0]).split('.').pop() === 'pdf' ?

                                <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                    <img src={require('../../../../library/images/pdf.png')} height={25} style={{ paddingRight: 10 }} />
                                    {rowData.file_name}
                                </a>
                                :
                                rowData && rowData.attachments[0].substring(rowData.attachments[0]).split('.').pop() === 'xlsx' ?
                                    <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                        <img src={require('../../../../library/images/excel.png')} height={25} style={{ paddingRight: 10 }} />
                                        {rowData.file_name}
                                    </a>
                                    :
                                    <a href={rowData.attachments[0]} target="_blank" style={{ display: 'flex' }}>
                                        <img src={require('../../../../library/images/image_logo.png')} height={25} style={{ paddingRight: 10 }} />
                                        {rowData.file_name}
                                    </a>
                            }
                        </div>
                    }
                </div>
            )
        },
        {
            field: "created_by",
            title: "Created By",
            render: (rowData) => (
                <div >
                    {rowData.ID ? rowData.created_by
                        :
                        sessionStorage.getItem('loginData') ?
                            JSON.parse(sessionStorage.getItem('loginData')).email : ''
                    }
                </div>
            )
        },
        {
            field: "created_on",
            title: "Created On",
            render: (rowData) => (
                <div >
                    {Moment(rowData.created_on).format('MM/DD/YYYY hh:mm:ss')
                    }
                </div>
            )
        },
        {
            field: "Actions",
            title: "Actions",
            type: "string",
            editable: "never",
            sorting: false,
            render: (rowData, index) => (
                <div>
                    <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => handleRemoveCard(rowData.tableData.id)}
                        size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        }
    ]
    function handleToggleExpand(event, text) {
        event.preventDefault();
        setOpen2(true)
        setNotes(text)
    }
    function handleClose2() {
        setOpen2(false)
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    {(props.mode != 'view' && props.status != 'submit_approval') &&
                        <div className={classes.notesDiv}>
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                Add Note
                            </Button>
                        </div>
                    }


                    {notesArray && notesArray.length > 0 &&
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    ></div>
                                ),
                            }}
                            title={" "}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={notesArray}
                            style={{ marginTop: '-18px', fontSize: theme.typography.h4.fontSize }}
                            options={{
                                search: false,
                                sorting: true,
                                emptyRowsWhenPaging: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                filtering: true,

                                //   pageSize: 10,
                                //   pageSizeOptions: [
                                //     10,
                                //     20,
                                //     50,
                                //     { value: props.data.length, label: "Show all" },
                                // ],
                            }}
                        />
                    }
                </form>
            </Card>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
                maxWidth={'lg'}>
                <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleRoot }}>
                    <div style={{ fontSize: 17 }}> Add Notes and Attachments</div>
                </DialogTitle>
                <DialogContent dividers>

                    <TextField
                        value={note}
                        label="Enter your notes here"
                        multiLine={true}
                        maxRows={4}
                        onChange={handleNote}
                        variant="filled"
                    />
                    <div className={classes.dialogController}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                component="label"
                                endIcon={<AttachFileIcon />}
                                variant='outlined'
                                color='primary'
                            >
                                Add Attachment
                                <input
                                    type="file"
                                    hidden
                                    accept=".xls,.xlsx,.pdf,.jpg,.png,.jpeg,.tiff,.tif"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                            <div className={classes.fileContainer}
                                style={{ color: currentFileUrl ? 'green' : props.uploadingFile ? 'grey' : 'red' }}>
                                {currentFileName}
                            </div>
                            {props.uploadingFile && <CircularProgress color="primary" />}
                        </div>
                        <div>
                            <Button autoFocus onClick={() => handleSave(fileName)} color="primary" disabled={(props.uploadingFile || !note.trim()) ?
                                true : false}>
                                Save Note
                            </Button>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
            <BootstrapDialog
                onClose={handleClose2}
                aria-labelledby="customized-dialog-title"
                open={open2}
            >
                <DialogTitle id="customized-dialog-title" className={{ root: classes.dialogTitleRoot }}>
                    <Grid container direction="row" style={{ justifyContent: 'space-between' }} >
                        <div style={{ display: 'flex' }}><Article />
                            <FormLabel className={classes.dialogTitle}>Note</FormLabel>
                        </div>
                        <Box>
                            <IconButton onClick={handleClose2} style={{ paddingTop: 5 }} size="large">
                                <Close />
                            </IconButton>
                        </Box>
                    </Grid>
                </DialogTitle>


                <DialogContent dividers>
                    <Typography gutterBottom>
                        {notes}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose2}>
                        Cancel
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );

}

const mapStateToProps = state => ({
    uploadUrl: state.addContractData.uploadUrl,
    uploadingFile: state.addContractData.loadingFile
});

const mapDispatchToProps = dispatch => {
    return {
        uploadAttachment: (file) => dispatch(uploadAttachment(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalNotes);