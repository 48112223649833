import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../../Styles/global.css'

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: "20px",
        padding: '10px',
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    container: {
        position: 'relative',
        minHeight: '80vh',
    },
}));

const PreMatureBox = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.buttonContainer}>
                <Button className='cancel-btn' variant="outlined">Cancel</Button>
                <Button className='create-btn' variant="contained" color="primary" style={{ marginLeft: "10px" }}>Save Changes</Button>
            </div>
        </div>
    );
};

export default PreMatureBox;
