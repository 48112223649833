import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    MenuItem,
    FormLabel,
    IconButton,
    OutlinedInput,
    Checkbox, ListItemText, Input
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { connect } from 'react-redux';
import { getGlobalSetupData } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '26px 0px 26px 0px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));

const EditAssignSticker = (props) => {


    const classes = useStyles();
    const { className } = props;
    const [promoFields, setPromoFields] = React.useState([]);
    const [addMaterial, setAddMaterial] = React.useState(1);
    const [promotionName, setPromotionName] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [promotion, setPromotion] = React.useState([]);
    const [promotionbenefits, setPromotionbenefits] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [globalPromo, setGlobalPromo] = React.useState([]);

    const format = {
        promotion_name: '',
        promotion_description: '',

        benefit_rebate: 0.0, benefit_coverage_type: '', benefit_coverage_length: 0.0, benefit_spiff: 0.0, benefit_promo_finance_interest: 0.0, benefit_promo_finance_term_structure: '', benefit_extra_rebate: 0.0,
        takerate_global: 0.0, takerate_rebate: 0.0, takerate_coverage: 0.0, takerate_spiff: 0.0, takerate_std_finance: 0.0, takerate_promo_finance: 0.0, takerate_extra_rebate: 0.0
    }
    const isAllSelected =
        promotion && promotion.length > 0 && selected.length === promotion.length;


    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        props.getGlobalSetupData(props.country)
    }, [props.country]);
    useEffect(() => {
        if (props.budgetData.promotion_budget_benefits && props.budgetData.promotion_budget_benefits.length > 0) {

            setPromoFields(props.budgetData.promotion_budget_benefits)
            setAddMaterial(props.budgetData.promotion_budget_benefits.length)

        }
    }, [props.promoData]);

    function handleChangeValue(event) {
        var index = event.target.id;
        if (event.target.name == 'benefit_coverage_type' || event.target.name == 'benefit_promo_finance_term_structure' || event.target.name == 'promotion_name' || event.target.name == 'promotion_description') {
            promoFields[index][event.target.name] = event.target.value
        }
        else {
            promoFields[index][event.target.name] = parseFloat(event.target.value)
        }


        props.onChange(promoFields)
    }

    useEffect(() => {
        var temp = []
        if (props.globalData && props.globalData.records && props.globalData.records.length > 0) {
            setGlobalPromo(props.globalData.records)
            props.globalData.records.map((item, index) => {
                if (!temp.includes(item.promotion_name)) {
                    temp.push(item.promotion_name)
                }
            })
            setPromotion(temp)
        }
    }, [props.globalData]);

    function handleOnAdd() {
        var newValue = promoFields
        newValue = [...newValue, format]
        setPromoFields(newValue);
        setPromotionbenefits([...promotionbenefits, format])
        setAddMaterial(addMaterial + 1)


    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === promotion.length ? [] : promotion);
        }
        else
            setSelected(newSelected)

    }
    function onAdd() {
        handleGlobalPromotion(selected)
        setPromotionName(selected)
        setOpen(false)


    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function handleOpen() {
        setSelected([])
        setOpen(true)
    }
    function handleGlobalPromotion(list) {
        var flag = 0
        var len = addMaterial
        var global = globalPromo.filter(item => list.includes(item.promotion_name))
        global.map((item, index) => {

            delete global[index]['ID']
        })
        setPromoFields([...promoFields, ...global])

        setAddMaterial(addMaterial + global.length)
        Object.entries(promoFields[len - 1]).map(([k, v]) => {
            if (v != '' || v != 0)
                flag = flag + 1

        })
        if (flag == 0) {
            setPromoFields(item => item.filter((item, i) => i !== len - 1));
            setAddMaterial(addMaterial + global.length - 1)
        }
        props.onChange([...promoFields, ...global])
        setPromotionName([])

    }
    function handleDeleteLocally(index, id) {
        var len = promoFields.length
        setPromoFields(item => item.filter((item, i) => i !== index));
        if (addMaterial - 1 > 0)
            setAddMaterial(addMaterial - 1)
        if (len - 1 == 0) {
            setPromoFields([format]);
        }
        props.onChange(promoFields.filter((item, i) => i !== index))
    }

    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Grid container spacing={2} style={{ padding: 20 }}>

                            <Grid
                                item
                                md={3}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel variant="h5" className={classes.formDiv}>
                                        Promotion Name
                                    </FormLabel>
                                    <div className={classes.dropdownContainer}>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={promotionName}
                                            style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                            input={<OutlinedInput />}
                                            onOpen={() => handleOpen()}
                                            onClose={() => setOpen(false)}
                                            open={open}
                                            renderValue={(appType) => {
                                                if (selected.length > 0)
                                                    return appType.join(", ")
                                                else
                                                    return ""
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {promotion && promotion
                                                .map(item => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValues(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected.indexOf(item) > -1} />
                                                            <ListItemText primary={item} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <div className={classes.dropdownbottom}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>


                                        </Select>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                        <div className={classes.container} >

                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell align='center' style={{ minWidth: 100 }} rowSpan={2} >Promotion Name</TableCell>
                                            <TableCell align='center' style={{ minWidth: 150 }} rowSpan={2} >Promotion Description</TableCell>
                                            <TableCell align='center' colSpan={7}>Cost</TableCell>
                                            <TableCell align='center' colSpan={7}>Take Rate</TableCell>
                                            <TableCell align='center' style={{ width: 50, paddingLeft: 2, paddingRight: 2 }} rowSpan={2}>Actions</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Rebate</TableCell>
                                            <TableCell align="center" style={{ minWidth: 50 }}>Cov Type</TableCell>
                                            <TableCell align="center">Cov Length</TableCell>
                                            <TableCell align="center">SPIFF</TableCell>
                                            <TableCell align="center">F2 Interest</TableCell>
                                            <TableCell align="center">F2 Fin Structure</TableCell>
                                            <TableCell align="center">Extra</TableCell>
                                            <TableCell align="center">Global</TableCell>
                                            <TableCell align="center">Rebate</TableCell>
                                            <TableCell align="center" style={{ minWidth: 30 }}>Cov</TableCell>
                                            <TableCell align="center">SPIFF</TableCell>
                                            <TableCell align="center" style={{ minWidth: 50 }}>Std Fin</TableCell>
                                            <TableCell align="center">Promo Fin</TableCell>
                                            <TableCell align="center">Extra</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {

                                            promoFields && promoFields
                                                .map((formItem, i) => {
                                                    var len = Object.entries(formItem).length
                                                    return (
                                                        <StyledTableRow key={i}>

                                                            <TableCell key={'promotion_name' + i}>
                                                                <OutlinedInput twoline='true'
                                                                    key={'input_name' + i}
                                                                    name={'promotion_name'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={promoFields[i]['promotion_name'] ? promoFields[i]['promotion_name'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'promotion_desc' + i}>
                                                                <OutlinedInput twoline='true'
                                                                    style={{ width: '100%' }}
                                                                    key={'input_desc' + i}
                                                                    name={'promotion_description'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={promoFields[i]['promotion_description'] ? promoFields[i]['promotion_description'] : ''}
                                                                />

                                                            </TableCell>

                                                            <TableCell key={'benefit_reb' + i} >
                                                                <OutlinedInput twoline='true'
                                                                    key={'input_reb' + i}
                                                                    type={'number'}
                                                                    name={'benefit_rebate'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_rebate'] ? formItem['benefit_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'b_covtype' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'inputb_covtype' + i}
                                                                    name={'benefit_coverage_type'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_coverage_type'] ? formItem['benefit_coverage_type'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'b_covlen' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'inputb_covlen' + i}
                                                                    type={'number'}
                                                                    name={'benefit_coverage_length'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_coverage_length'] ? formItem['benefit_coverage_length'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'b_spiff' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'inputb_spiff' + i}
                                                                    type={'number'}
                                                                    name={'benefit_spiff'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_spiff'] ? formItem['benefit_spiff'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell key={'b_promofin' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'inputb_promofin' + i}
                                                                    type={'number'}
                                                                    name={'benefit_promo_finance_interest'}
                                                                    id={i}
                                                                    onClick={e => e.target.focus()}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_promo_finance_interest'] ? formItem['benefit_promo_finance_interest'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'promo_fin' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'input_fin' + i}
                                                                    name={'benefit_promo_finance_term_structure'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_promo_finance_term_structure'] ? formItem['benefit_promo_finance_term_structure'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'extra_rebate' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_reb' + i}
                                                                    type={'number'}
                                                                    name={'benefit_extra_rebate'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['benefit_extra_rebate'] ? formItem['benefit_extra_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'takerate_global' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_global' + i}
                                                                    type={'number'}
                                                                    name={'takerate_global'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_global'] ? formItem['takerate_global'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'takerate_rebate' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_rebate' + i}
                                                                    type={'number'}
                                                                    name={'takerate_rebate'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_rebate'] ? formItem['takerate_rebate'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'takerate_cov' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_cov' + i}
                                                                    type={'number'}
                                                                    name={'takerate_coverage'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_coverage'] ? formItem['takerate_coverage'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'takerate_spiff' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_spiff' + i}
                                                                    type={'number'}
                                                                    name={'takerate_spiff'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_spiff'] ? formItem['takerate_spiff'] : ''}
                                                                />

                                                            </TableCell>
                                                            <TableCell key={'takerate_std' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_std' + i}
                                                                    type={'number'}
                                                                    name={'takerate_std_finance'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_std_finance'] ? formItem['takerate_std_finance'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell key={'takerate_promo' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_promo' + i}
                                                                    type={'number'}
                                                                    name={'takerate_promo_finance'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_promo_finance'] ? formItem['takerate_promo_finance'] : ''}
                                                                />
                                                            </TableCell>
                                                            <TableCell key={'takerate_extra' + i}>

                                                                <OutlinedInput twoline='true'
                                                                    key={'i_extra' + i}
                                                                    type={'number'}
                                                                    name={'takerate_extra_rebate'}
                                                                    id={i}
                                                                    onBlur={handleChangeValue}
                                                                    defaultValue={formItem['takerate_extra_rebate'] ? formItem['takerate_extra_rebate'] : ''}
                                                                />

                                                            </TableCell>

                                                            <TableCell key={'action' + i} style={{ display: 'flex', paddingTop: 30, paddingBottom: 30 }}>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleDeleteLocally(i)}
                                                                    size="large">
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ visibility: i === addMaterial - 1 ? 'visible' : 'hidden', padding: 0 }}
                                                                    onClick={handleOnAdd}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <AddBoxOutlinedIcon />
                                                                </IconButton>

                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );


                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </form>
                </Card>
            </div >
        </div >
    );

};



const mapDispatchToProps = dispatch => {
    return {
        getGlobalSetupData: (country) => dispatch(getGlobalSetupData(country)),
    }
}

const mapStateToProps = state => {
    return {
        budgetData: state.promotionData.promotionSetupSingleData,
        globalData: state.promotionData.globalSetupData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignSticker);