import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    MenuItem,
    Select,
    IconButton
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { getDefaultValues, addKeyFigureDefaultDesigner, getSingleKeyFigure, loadValuesBasedOnAppTypeAndField, getDynamicQuerySalesDataFields, getDefaultValuesAllData, getLblDispDesFieldValue, allowedApps } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import ChipInput from 'material-ui-chip-input';
import { TextInputWithLabel, DropdownArray } from '../../components/Inputs';
import { AddBoxOutlined, DeleteForever } from '@mui/icons-material';
var editableId;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const ViewKeyFigure = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [attributeName, setAttributeName] = React.useState([]);
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState([]);
    const [keyFigure, setKeyFigureData] = React.useState([]);
    const [mappedKeyFigure, setMappedKeyFigureData] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [optionData, setOptionData] = React.useState([]);
    const optionDataArray = ['EQUAL', 'NOT EQUAL'
        // { key: 'EQUAL', value: 'EQUAL' },
        // { key: 'NOT EQUAL', value: 'NOT EQUAL' },
    ];
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [newArrayOfKeys, setNewArrayOfKeys] = React.useState([]);
    const [newArrayOfValues, setNewArrayOfValues] = React.useState([]);
    const [newArrayOfKeys2, setNewArrayOfKeys2] = React.useState([]);
    const [newArrayOfValues2, setNewArrayOfValues2] = React.useState([]);
    const [mappingValuesSet, setMappingValuesSet] = React.useState([]);
    const format = { attribute_name: '', attribute_values: '', options: '' }
    const [allDataNew, setAllDataNew] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        setOpen(true);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        if (appId)
            props.getKeyFigureDataSingle(appId)
        //setOpen(true);
        props.onLoadingDefaultAll();
        props.allowedApps()
        //   setAllDataNew([...allDataNew,format])
    }, []);
    for (let i = 0; i < 5; i++) {
        if (allDataNew && allDataNew.length < 5) {
            setAllDataNew([...allDataNew, format])
        }
    }
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (allDataNew) {
            setAllDataNew([...allDataNew])
        }
    }, [allDataNew])
    useEffect(() => {
        if (props.keyFigureSingle) {
            props.loadSourceDataType(props.keyFigureSingle['application_type'], 'source_data_type');
            props.getDefaultKeyFigureData('key_figure_name', props.keyFigureSingle['source_data_type'], props.keyFigureSingle['application_type']);
            props.getDefaultValuesAllData('attribute_name', props.keyFigureSingle['source_data_type'], props.keyFigureSingle['application_type']);
            // props.onLoadingLabelDesignerNew('field-label-designer', props.keyFigureSingle['application_type'], props.keyFigureSingle['source_data_type']);
            var data = { "data_source": props.keyFigureSingle['source_data_type'] }
            props.getLblDispDesFieldValue(data, 'post');
            setApplicationType(props.keyFigureSingle['application_type']);
            setSourceDataType(props.keyFigureSingle['source_data_type']);
            setKeyFigureData(props.keyFigureSingle['key_figure']);
            setMappedKeyFigureData(props.keyFigureSingle['mapped_key_figure']);
            if (props.keyFigureSingle['application_type'] && props.keyFigureSingle['source_data_type']) {
                props.onLoadingDefaultAttributeName(props.keyFigureSingle['application_type'], props.keyFigureSingle['source_data_type']);
            }
            if (props.keyFigureSingle.multiple_attribute_filters) {
                setAllDataNew(props.keyFigureSingle.multiple_attribute_filters)
            }
        }
    }, [props.keyFigureSingle]);
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.keyFigureFormData && props.keyFigureFormData.records && props.keyFigureFormData.records.length > 0) {
            props.keyFigureFormData.records.forEach(function (item) {
                if (item.drop_down_value_keys)
                    tempKeys.push(...item.drop_down_value_keys);
                if (item.drop_down_values)
                    tempValues.push(...item.drop_down_values);
            });
            setNewArrayOfKeys2(tempKeys);
            setNewArrayOfValues2(tempValues);
        }
    }, [props.keyFigureFormData])
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && newArrayOfKeys2 && newArrayOfKeys2.length > 0) {
            newArrayOfKeys2.map((item, index) => {
                if (props.labelNewAPIData.field_label_attributes[item]) {
                    setKeyValuePairObject((keyValuePairObject) => ({
                        ...keyValuePairObject,
                        [item]: props.labelNewAPIData.field_label_attributes[item]['current']
                    }));
                } else {
                    setKeyValuePairObject((keyValuePairObject) => ({
                        ...keyValuePairObject,
                        [item]: newArrayOfValues2[index]
                    }));
                }
            })
        } else {
            setKeyValuePairObject([])
        }
    }, [newArrayOfKeys2, props.labelNewAPIData])
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {
            props.formFieldsAllData.records.forEach(function (item) {
                if (item.drop_down_value_keys)
                    tempKeys.push(...item.drop_down_value_keys);
                if (item.drop_down_values)
                    tempValues.push(...item.drop_down_values);
            });
            setNewArrayOfKeys(tempKeys);
            setNewArrayOfValues(tempValues);
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && newArrayOfKeys && newArrayOfKeys.length > 0) {
            newArrayOfKeys.map((item, index) => {
                if (props.labelNewAPIData.field_label_attributes[item]) {
                    setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
                        ...keyValuePairObjectNew,
                        [item]: props.labelNewAPIData.field_label_attributes[item]['current']
                    }));
                } else {
                    setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
                        ...keyValuePairObjectNew,
                        [item]: newArrayOfValues[index]
                    }));
                }
            })
        } else {
            setKeyValuePairObjectNew([])
        }
    }, [newArrayOfKeys, props.labelNewAPIData])

    function handleAttributeName(newValue, index) {
        allDataNew[index]['attribute_name'] = (newValue.target.value)
    }
    function handleAttributeValues(newValue, index) {
        allDataNew[index]['attribute_values'] = (newValue)
    }
    function handleOptions(newValue, index) {
        allDataNew[index]['options'] = (newValue.target.value)
    }
    function handleOnAdd() {
        // attributeName[addMember] = ''
        // attributeValue[addMember] = ''
        // optionData[addMember] = ''
        setAllDataNew([...allDataNew, format])
        setAddMember(addMember + 1)
    }
    const handleDeleteLocally = (e, index) => {
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
        setAllDataNew(allDataNew.filter((item, i) => i != index));
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        setAttributeName([])
        setKeyFigureData('')
        setMappedKeyFigureData('')
        setAttributeValue([])
        props.onLoadingDefaultAttributeName(applicationType, e.target.value);
        props.getDefaultValuesAllData('attribute_name', e.target.value, applicationType);
        props.getDefaultKeyFigureData('key_figure_name', e.target.value, applicationType);
        var data = { "data_source": e.target.value }
        props.getLblDispDesFieldValue(data, 'post');
    }
    const handleApplicationType = (e) => {
        setApplicationType(e.target.value);
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setSourceDataType('')
    }
    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>View Key Figure Mapping</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} >
                                            Application Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                disabled
                                                value={applicationType}
                                                onChange={handleApplicationType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {allowedApps
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formDiv} >
                                            Source Data Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disabled
                                                disableUnderline
                                                value={sourceDataType}
                                                onChange={handleSourceDataType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {sourceDataTypeDataFromAPI
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formDiv}>
                                            Source Key Figure
                                        </FormLabel>
                                        <Select
                                            disabled
                                            value={keyFigure}
                                            onChange={(e) => setKeyFigureData(e.target.value)}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {keyValuePairObject && Object.keys(keyValuePairObject).length > 0 &&
                                                Object.entries(keyValuePairObject)
                                                    .sort()
                                                    ?.map(([key, value]) => {
                                                        return (
                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                {value}
                                                            </MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formDiv}>
                                            Target Key Figure
                                        </FormLabel>
                                        <Select
                                            disabled
                                            value={mappedKeyFigure}
                                            onChange={(e) => setMappedKeyFigureData(e.target.value)}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {keyValuePairObject && Object.keys(keyValuePairObject).length > 0 &&
                                                Object.entries(keyValuePairObject)
                                                    .sort()
                                                    ?.map(([key, value]) => {
                                                        return (
                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                {value}
                                                            </MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                                {(() => {
                                    let td = [];
                                    for (let i = 0; i < 5; i++) {
                                        td.push(<>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel variant="h5" className={classes.formDiv} >
                                                        Attribute Name
                                                    </FormLabel>
                                                    <div className={classes.dropdownContainer}>
                                                        <Select
                                                            disabled
                                                            disableUnderline
                                                            value={allDataNew[i] && allDataNew[i]['attribute_name']}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            onChange={(e) => handleAttributeName(e, i)}
                                                            displayEmpty
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                        >
                                                            {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                                                Object.entries(keyValuePairObjectNew)
                                                                    .sort()
                                                                    .map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                                {value}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formDiv}>
                                                        Option
                                                    </FormLabel>
                                                    <Select
                                                        disabled
                                                        value={allDataNew[i] && allDataNew[i]['options']}
                                                        onChange={(e) => handleOptions(e, i)}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        menuPlacement="auto"
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        {optionDataArray && optionDataArray.length > 0 &&
                                                            optionDataArray

                                                                ?.map((item) => {
                                                                    return (
                                                                        <MenuItem key={item} value={item} style={{ textTransform: 'capitalize' }}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                        }
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formDiv}>
                                                        Attribute Value
                                                    </FormLabel>
                                                    <ChipInput
                                                        disabled
                                                        classes={{
                                                            root: classes.rootContainer,
                                                            chip: classes.chip,
                                                            input: classes.input,
                                                            inputRoot: classes.inputRoot,
                                                            label: classes.chipLabel,
                                                            chipContainer: classes.chipContainer,
                                                        }}
                                                        // defaultValue={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                        value={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                        // onAdd={(chips) => handleAttributeValue(chips,i)}
                                                        // onDelete={(chip) => handleDeleteAttributeValue(chip,i)}
                                                        onChange={(e) => handleAttributeValues(e, i)}
                                                        disableUnderline={true}
                                                    />
                                                </div>
                                            </Grid>
                                        </>)
                                    }
                                    return td;
                                })()}
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        dropdownDataAll: state.addMultipleConfigurationData.multipleConfigurationData,
        keyFigureSingle: state.AddKeyFigureData.keyFigureDataSingle,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        keyFigureFormData: state.addMultipleConfigurationData.formFieldsAllDataER,
        allowedAppsData: state.initialData.allowedApps,
        labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, edit, id) => dispatch(addKeyFigureDefaultDesigner(data, edit, id)),
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        onLoadingDefaultAll: () => dispatch(getDefaultValues('noKeyWords')),
        getKeyFigureDataSingle: (id) => dispatch(getSingleKeyFigure(id)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        onLoadingDefaultAttributeName: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        getDefaultKeyFigureData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE', 'ER')),
        allowedApps: () => dispatch(allowedApps()),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewKeyFigure);