
export const accessInnerChildren = (obj, childArray = {}, relationshipArray = {}) => {
    // Add the current relationship to the relationship array
    var key = [...Object.values(obj.relationship), ...(obj.fields?.product_line ? obj.fields.product_line : [])].join("_")

    relationshipArray = { ...relationshipArray, [key]: {} }
    // If the object has no children, return the accumulated arrays
    if (!obj.children || obj.children.length === 0) {
        var productLine = obj.fields?.product_line ? obj.fields.product_line : ''
        if (productLine)
            childArray = { ...childArray, [key]: { ...obj.relationship, 'product_line': productLine } };
        else
            childArray = { ...childArray, [key]: { ...obj.relationship } };
        return { children: { ...childArray }, relationships: { ...relationshipArray } };
    }

    // Use reduce to accumulate results from recursive calls
    return obj.children.reduce((acc, child) => {
        const result = accessInnerChildren(child, acc.children, acc.relationships);
        return {
            children: result.children,
            relationships: result.relationships
        };
    }, { children: childArray, relationships: relationshipArray });
};

export const generateCombinations = (arr, productLine) => {
    if (productLine)
        return arr.map((_, index) => arr.slice(0, index + 1).join('_') + `_${productLine.join('_')}`);
    else
        return arr.map((_, index) => arr.slice(0, index + 1).join('_'));
};