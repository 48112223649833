import React, { useRef, useState, useEffect } from "react";
import {
  Chip, Grid, Button, Typography, Paper,
  Breadcrumbs,
  FormLabel, OutlinedInput, Select, MenuItem,
  TextField, Checkbox, Input, ListItemText,
  Dialog, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import SimpleDialog from "../../components/Dialog";
import {
  fetchDropdownConfig, postCollaborationPortal, getDefaultValues,
  runDynamicQuery, getContractsTableDataList, getCustomerMasterData,
  getMaterialMasterData, getSupplierMasterData, getAttributeListData,
  getDynamicQueryFieldsNew, downloadDynamicQuery, getQueryRefById,
  updateQueryRefData, getEmployeeData, runCancelRequest, getLblDispDesFieldValue
} from "../../redux/actions";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import RingLoader from 'react-spinners/RingLoader';
import BeatLoader from "react-spinners/BeatLoader";
import { GetApp } from '@mui/icons-material';
import { Email } from "@mui/icons-material";
import {
  AllOtherAttributeMasterComponent,
  ContractNumberAC,
  MasterDataComponent,
  SearchDropDownMasterDataNew,
  ChipInput
} from "../../components/Inputs";
import DynamicQueryFormRef from "./DynamicQueryFormRef";
import FilterDialoge from "./FilterDialoge";
import { templateAnimate } from "@syncfusion/ej2-react-charts";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  // row: {
  //   flexDirection: "row",
  //   display: "flex",
  //   justifyContent: "space-between",
  //   marginBottom: 10,
  // },
  container: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 10,
    padding: '1rem'
  },
  gridContainer: {
    padding: "0px 15px 0px 10px",
  },
  gridContainer1: {
    padding: "0px 15px 0px 10px",
    height: 42
  },
  gridContainerOuter: {
    padding: "0px 0px 0px 0px",
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 28,
    paddingBottom: 30,
  },
  button: {
    marginRight: 25,
    width: 160
  },
  rootContainer: {
    border: '1px solid',
    borderColor: theme.palette.border.main,
    //borderRadius: 3,
    marginTop: 10,
    height: 37,
    padding: 0,
    width: '100%'
    // marginBottom: 14
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: '#E1E4F3',
    '&&:hover': {
      backgroundColor: '#E1E4F3',
      color: theme.palette.black
    },
    fontFamily: 'ProximaNova',
    padding: 0
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: 'ProximaNova',
    fontSize: 13
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'overlay',
    flexFlow: 'unset'
  },
  deleteIcon: {
    height: 13,
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
  contractContiner: {
    marginTop: 17,
  },
  spinner: {
    height: "80vh",
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  inputTwoLine: {
    marginTop: 15,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  },
  inputTwoLine1: {
    // marginTop: 15,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  },
  select: {
    width: "100%",
    backgroundColor: "white",
    // marginTop: -10,
    // height: 38,
    textTransform: "capitalize",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
    textTransform: "capitalize",
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ddd",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
  fontSetup: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
    borderRadius: '1rem',
    // backgroundColor: theme.palette.primary.main,
    height: '36px'
  },
  chips: {
    display: "flex",
    flexWrap: "nowrap",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
  borderRad0: {
    borderRadius: "0px !important",
    height: "35px",
    padding: "3px !important"
  },
}));

const DynamicQueryForm = (props) => {
  const classes = useStyles();
  const { className } = props;
  const history = useHistory();
  const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
  const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
  const [dynamicFieldTemp, setDynamicFieldTemp] = useState([]);
  const [dynamicFieldValuesFormTemp, setDynamicFieldValuesFormTemp] = useState([]);
  const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
  const [description, setDescription] = React.useState([]);
  const [internalDescriptionArray, setInternalDescriptionArray] =
    React.useState([]);
  const [optionsList, setOptionsList] = React.useState([]);
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [customerNumbersList, setCustomerNumbersList] = React.useState([]);
  const [materialNumbersList, setMaterialNumbersList] = React.useState([]);
  const [supplierNumbersList, setSupplierNumbersList] = React.useState([]);
  const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
  const [employeeMasterArray, setEmployeeMasterArray] = React.useState([]);
  const [employeeNameListArray, setEmployeeNameListArray] = React.useState([]);
  const [materialDesc, setmaterialDesc] = React.useState([]);
  const [supplierNameArray, setSupplierNameArray] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [listArray, setListArray] = React.useState([]);
  const [allSelect, setAllSelect] = React.useState(false);
  const [attributeData, setAttributeData] = React.useState([]);
  const [dynamicFieldValuesTemp, setDynamicFieldValuesTemp] = React.useState(
    []
  );
  const [dateFields, setDateFields] = React.useState([]);
  const [trigger, setTigger] = React.useState(false);
  const [numericFields, setNumericFields] = React.useState([]);
  const [openConfirmEmail, setOpenConfirmEmail] = React.useState(false);
  const [emailId, setEmailId] = React.useState(sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '');
  const options = ["range", "not equal", 'greater than', 'less than', 'greater than equal to', 'less than equal to', "pattern"];
  const rangeFields = ['customer_number', 'material_number', 'supplier_number', 'employee_number', 'contract_number']
  const postingTypeArray = [
    "Accrual",
    "Accrual Reversal",
    "Payment",
    "Payment Reversal",
    "Delta Payment",
    "Delta Accrual",
  ];
  const calculationTypeArray = ["Tier", "Rebate"];
  const customerList = [
    "Top Parent Customer Number",
    "Sold To Party",
    "Bill To Party",
    "Payer",
  ];
  const [loaderStatus, setLoaderStatus] = React.useState(false)
  const [cancelOpen, SetCancelOpen] = React.useState(false);
  const attributesSortFields = [
    "accrual_credit_profit_center",
    "accrual_debit_profit_center",
    "accrual_document_type",
    "accrual_gl_account_credit",
    "accrual_gl_account_debit",
    "currency",
    "payment_document_type",
    "payment_terms",
    "customer_classification",
    "class_of_trade",
    "account_group",
    "customer_chain",
    "customer_group",
    "customer_group1",
    "customer_group2",
    "customer_group3",
    "customer_group4",
    "customer_group5",
    "customer_industry",
    "material_type",
    "base_uom",
    "external_material_group",
    "product_hierarchy",
    "material_group",
    "material_group1",
    "material_group2",
    "material_group3",
    "material_group4",
    "material_group5",
    "supplier_type",
    "supplier_industry",
    "billed_uom",
    "billing_type",
    "company_code",
    "controlling_area",
    "cost_center",
    "delivery_type",
    "distribution_channel",
    "division",
    "document_category",
    "item_category",
    "order_reason",
    "plant",
    "profit_center",
    "sales_district",
    "sales_group",
    "sales_office",
    "sales_order_type",
    "sales_org",
    "contract_owner",
    "customer_id_type",
    "customer_type",
    "channel_partner_type",
    "supplier_id_type",
    "material_id_type",
    "price_list",
    "action_code",
    "member_status",
    "current_tier_status",
    "accounts_type",
    "price_group",
    "exception_reason"
  ];
  const [UUID, setUUID] = React.useState('');
  var dataType = history.location.type;
  useEffect(() => {
    if (dataType == 'email')
      setOpenConfirmEmail(true)
  }, [dataType])
  const handleDynamicFieldValues = (e, fieldName, type, page) => {
    //console.log("test" + e);
    if (!dynamicFieldValuesFormSubmit[fieldName]) {
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: { filter_name: fieldName },
      });
    }
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          var value = page == 'master' ? e.key : e
          setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: value });
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: {
                from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName]) : dynamicFieldValues[fieldName],
                to: numericFields.includes(fieldName) ? Number(value) : value
              },
            },
          });
        } else {
          setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: '' });
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
            },
          });
        }
      } else {
        // setDynamicFieldValuesRange("");
      }
    } else if (type === "option") {
      const updatedField = {
        ...dynamicFieldValuesFormSubmit[fieldName],
        filter_name: fieldName,
        filter_option: e,
      };
      // Remove filter_value if it exists
      if (updatedField.filter_value) {
        delete updatedField.filter_value;
      }
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: updatedField,
      });
      const { [fieldName]: _, ...remainingDynamicFieldValues } = dynamicFieldValues;
      setDynamicFieldValues(remainingDynamicFieldValues);
      // setDynamicFieldValuesFormSubmit({
      //   ...dynamicFieldValuesFormSubmit,
      //   [fieldName]: {
      //     ...dynamicFieldValuesFormSubmit[fieldName],
      //     filter_name: fieldName,
      //     filter_option: e,
      //   },
      // });
    } else {
      //console.log(e);
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
      if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
        && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
        dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(e) : e
      }
      else {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          [fieldName]: {
            ...dynamicFieldValuesFormSubmit[fieldName],
            filter_name: fieldName,
            // filter_value: numericFields.includes(fieldName) ? e.map(Number) : e,
            filter_value: numericFields.includes(fieldName) ? Number(e) : e,
          },
        });
      }
    }
  };

  const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
            },
          });
        } else {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_name: fieldName,
              filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
            },
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    } else {
      var temp = dynamicFieldValues[fieldName]
        ? dynamicFieldValues[fieldName]
        : [];
      if (type === "pastedItem") {
        e.map((arg) => {
          if (arg) temp.push(arg.trim());
        });
      } else {
        temp = e;
      }
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
      if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
        && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
        dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(temp) : temp
      }
      else {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          [fieldName]: {
            ...dynamicFieldValuesFormSubmit[fieldName],
            filter_name: fieldName,
            filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
          },
        });
      }
    }
  };
  const handleDynamicFieldValuesDelete = (e, fieldName, type) => {
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
            },
          });
        } else {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
            },
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    } else {
      var temp = dynamicFieldValues[fieldName].filter((item) => item !== e);
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: {
          ...dynamicFieldValuesFormSubmit[fieldName],
          filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
        },
      });
    }
  };
  const [billingDateStart, setBillingDateStart] = React.useState(null);
  const [billingDateEnd, setBillingDateEnd] = React.useState(null);

  const handleBillingDate = (e, type, fieldName) => {
    if (type == "start") {
      setBillingDateStart(e);
      setDynamicFieldValues({
        ...dynamicFieldValues,
        [fieldName]: Moment(e).format("YYYY-MM-DD"),
      });
      if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
        && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
        dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = Moment(e).format("YYYY-MM-DD")
      }
      else {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          [fieldName]: {
            ...dynamicFieldValuesFormSubmit[fieldName],
            filter_name: fieldName,
            filter_value: Moment(e).format("YYYY-MM-DD")
          }
        });
      }
    } else {
      setBillingDateEnd(e);
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: { from: dynamicFieldValues[fieldName], to: Moment(e).format("YYYY-MM-DD") },
            },
          });
        } else {
          setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: {
              ...dynamicFieldValuesFormSubmit[fieldName],
              filter_value: dynamicFieldValues[fieldName],
            },
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    }
  };

  useEffect(() => {
    if (secureLocalStorage.getItem("dYtz").collaboration_id != "0") {
      props.postCollaborationPortal(
        null,
        secureLocalStorage.getItem("dYtz").collaboration_id,
        "get"
      );
    }
  }, []);
  useEffect(() => {
    var temp;
    var range
    if (props.predefinedFilter && props.predefinedFilter.filter_fields && Object.entries(props.predefinedFilter.filter_fields).length > 0 && props.predefinedFilter.filter_fields && filterName == props.predefinedFilter.filter_name) {
      setDynamicFieldValuesFormSubmit(props.predefinedFilter.filter_fields)
      setFilterName(props.predefinedFilter.filter_name)
      Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
        temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
        if (item.filter_value && item.filter_value.to)
          range = { ...range, [item.filter_name]: item.filter_value.to }
      })
      setDynamicFieldValues(temp)
      setDynamicFieldValuesRange(range)
    }
    else {
      setDynamicFieldValues({})
      setDynamicFieldValuesFormSubmit({})
      setDynamicFieldValuesRange({})
    }
  }, [props.predefinedFilter])
  useEffect(() => {
    if (props.dynamicQueryFieldsData) {
      if (props.dynamicQueryFieldsData.numeric_fields) {
        var data = props.dynamicQueryFieldsData.numeric_fields
          .filter(item => item.key !== 'contract_number')
          .map((item, i) => item.key)
        setNumericFields(data)
      }
      if (props.dynamicQueryFieldsData.date_fields) {
        var data = props.dynamicQueryFieldsData.date_fields.map((item, i) => item.key)
        setDateFields(data)
      }
    }
  }, [props.dynamicQueryFieldsData])
  useEffect(() => {
    var contracts = [];
    var tempNameArray = [];
    if (props.contractTabledataComplete) {
      props.contractTabledataComplete.map((item) => {
        contracts.push(item.contract_number.toString());
        tempNameArray.push(item.internal_description);
      });
      setContractNumbersList(contracts);
      setDescription(tempNameArray);
    }
  }, [props.contractTabledataComplete]);
  const location = useLocation();
  const [queryListID, setQueryListID] = React.useState(1);
  const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
  const [currentQueryID, setCurrentQueryID] = React.useState("");
  const [sortOrderObj, setSortOrderObj] = React.useState({});
  const [sortOrderArray, setSortOrderArray] = React.useState([]);
  const [level, setLevel] = React.useState("");
  const [postingType, setPostingType] = React.useState([]);
  const [calculationType, setCalculationType] = React.useState([]);
  const [contractData, setContractData] = React.useState([]);
  const [contractGroup, setContractGroup] = React.useState([]);
  const [contractSubGroup, setContractSubGroup] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [activeDropdown, setActiveDropdown] = React.useState(false);
  const [tableDesc, setTableDesc] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterID, setFilterID] = React.useState('');
  useEffect(() => {
    if (props.dropdownConfig && props.dropdownConfig[0])
      setActiveDropdown(props.dropdownConfig[0].default_configuration);
  }, [props.dropdownConfig]);
  useEffect(() => {
    if (
      props.dynamicQueryDetailsData &&
      Object.keys(props.dynamicQueryDetailsData).length > 0 &&
      props.dynamicQueryDetailsData.filter_fields
    ) {
      setSortOrderObj(props.dynamicQueryDetailsData.filter_fields);
      setTableDesc(props.dynamicQueryDetailsData.table_description);
      props.getDynamicQueryFields(sessionStorage.getItem("application"), props.dynamicQueryDetailsData.table_description);
    }
  }, [props.dynamicQueryDetailsData]);
  useEffect(() => {
    props.onLoadingDefault(sessionStorage.getItem("dynamic_filter_fields"));
    if (sessionStorage.getItem("dynamic_filter_fields")) {
      var object = JSON.parse(sessionStorage.getItem("dynamic_filter_fields"));
      setFilterFieldsArray(object.fields);
      setCurrentQueryID(object.id);
    }
    props.getdropdownConfig();
    props.onCheckContractsTable();
    props.getAttributeListData({ attribute_name: "" });
    props.getLblDispDesFieldValues({ "data_source": "contract_header", "application_type": sessionStorage.getItem('application') }, 'post')
  }, []);
  useEffect(() => {

    if (status && filterFieldsArray) {
      if (dynamicFieldValues['customer_number'] && dynamicFieldValues['customer_number'].length > 0 &&
        filterFieldsArray.includes('customer_number')) {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          ['customer_number']: {
            ...dynamicFieldValuesFormSubmit['customer_number'],
            filter_name: 'customer_number',
            filter_option: 'equal',
            filter_value: dynamicFieldValues['customer_number']
          }
        })
      }
      if (dynamicFieldValues['employee_number'] && dynamicFieldValues['employee_number'].length > 0 &&
        filterFieldsArray.includes('employee_number')) {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          ['employee_number']: {
            ...dynamicFieldValuesFormSubmit['employee_number'],
            filter_name: 'employee_number',
            filter_option: 'equal',
            filter_value: dynamicFieldValues['employee_number']
          }
        })
      }
      if (dynamicFieldValues['supplier_number'] && dynamicFieldValues['supplier_number'].length > 0 &&
        filterFieldsArray.includes('supplier_number')) {
        setDynamicFieldValuesFormSubmit({
          ...dynamicFieldValuesFormSubmit,
          ['supplier_number']: {
            ...dynamicFieldValuesFormSubmit['supplier_number'],
            filter_name: 'supplier_number',
            filter_option: 'equal',
            filter_value: dynamicFieldValues['supplier_number']
          }
        })
      }
    }

  }, [status, filterFieldsArray])
  useEffect(() => {
    if (activeDropdown) {
      if (
        filterFieldsArray.includes("customer_number") &&
        activeDropdown["customer_number"]
      )
        props.onCheckCustomerTable();
      if (
        filterFieldsArray.includes("material_number") &&
        activeDropdown["material_number"]
      )
        props.onCheckMaterialTable();
      if (
        filterFieldsArray.includes("supplier_number") &&
        activeDropdown["supplier_number"]
      )
        props.onCheckSupplierTable();
      if (
        filterFieldsArray.includes("employee_number") &&
        activeDropdown["employee_number"]
      )
        props.getEmployeeData();
    }
  }, [filterFieldsArray, activeDropdown]);

  useEffect(() => {
    if (props.attributeData && props.attributeData.length > 0)
      setAttributeData(props.attributeData);
  }, [props.attributeData]);
  useEffect(() => {
    var tempArray = [];
    var tempNameArray = [];
    var temp1 = [];
    if (
      props.collaborationPortalData &&
      props.collaborationPortalData.customer_number
    ) {
      temp1 = props.collaborationPortalData.customer_number;
    }
    if (
      props.customerMasterData &&
      props.customerMasterData.records &&
      props.customerMasterData.records.length > 0
    ) {
      props.customerMasterData.records
        .filter((item) => temp1.includes(item.customer_number))
        .map((item) => {
          tempArray.push(item.customer_number);
          tempNameArray.push(item.customer_name);
        });
      // tempArray.push('Top Parent Customer Number', 'Sold To Party', 'Bill To Party', 'Payer')
      setCustomerNameListArray(tempNameArray);
      setCustomerNumbersList(tempArray);
    }
  }, [props.customerMasterData, props.collaborationPortalData]);
  useEffect(() => {
    var tempArray = [];
    var tempNameArray = [];
    if (
      props.customerMasterData &&
      props.customerMasterData.records &&
      props.customerMasterData.records.length > 0
    ) {
      props.customerMasterData.records.map((item) => {
        tempArray.push(item.customer_number);
        tempNameArray.push(item.customer_name);
      });
      // tempArray.push('Top Parent Customer Number', 'Sold To Party', 'Bill To Party', 'Payer')
      setCustomerNameListArray(tempNameArray);
      setCustomerNumbersList(tempArray);
    }
  }, [props.customerMasterData]);
  useEffect(() => {
    if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
      var tempArray = []
      var tempNameArray = []
      props.employeeMasterData.records.map((item) => {
        tempArray.push(item.employee_number);
        tempNameArray.push(item.employee_name);

      })
      setEmployeeMasterArray(tempArray)
      setEmployeeNameListArray(tempNameArray);

    }
  }, [props.employeeMasterData])
  useEffect(() => {
    if (props.collaborationPortalData) {
      setDynamicFieldValues({
        ...dynamicFieldValues,
        ["customer_number"]: props.collaborationPortalData.customer_number,
        ["employee_number"]: props.collaborationPortalData.employee_number,
        ["supplier_number"]: props.collaborationPortalData.supplier_number,
      });
      setStatus(true);
    }
  }, [props.collaborationPortalData]);
  useEffect(() => {
    if (
      props.materialMasterData &&
      props.materialMasterData.records &&
      props.materialMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.materialMasterData.records.map((item) => {
        tempArray.push(item.material_number);
        tempNameArray.push(item.customer_name);
      });
      setMaterialNumbersList(tempArray);
      setmaterialDesc(tempArray);
    }
  }, [props.materialMasterData]);
  useEffect(() => {
    if (
      props.supplierMasterData &&
      props.supplierMasterData.records &&
      props.supplierMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.supplierMasterData.records.map((item) => {
        tempArray.push(item.supplier_number);
        tempNameArray.push(item.supplier_name);
      });
      setSupplierNumbersList(tempArray);
      setSupplierNameArray(tempNameArray);
    }
  }, [props.supplierMasterData]);
  useEffect(() => {
    if (
      props.dropdownData && props.dropdownData.field_label_attributes) {
      setContractData(props.dropdownData.field_label_attributes);
    }
  }, [props.dropdownData]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        menuPlacement: "top"
      }
    }, getContentAnchorEl: null,
  }
  const [contractType, setContractType] = useState([]);
  const [calculationMethod, setCalculationMethod] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [SelectOpen, setSelectOpen] = useState(false);
  const [classOfTrade, setClassOfTrade] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [customerChain, setCustomerChain] = useState([]);
  const [materialGroup, setMaterialGroup] = useState([]);
  const [productHierarchy, setProductHierarchy] = useState([]);
  const [memberOwner, setMemberOwner] = useState([]);
  const [currentPage, setCurrentPage] = React.useState("");
  const [filterName, setFilterName] = React.useState("");
  useEffect(() => {
    setCurrentPage(
      location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    );
  }, [location]);

  useEffect(() => {
    if (currentPage) {
      if (currentPage === "partner-statement-form") {
        setQueryListID(3);
      } else if (currentPage === "collaboration-portal-form") {
        setQueryListID(3);
      } else {
        setQueryListID(1);
      }
    }
  }, [currentPage]);
  const handleFilterdata = () => {
    if (dynamicFieldValuesFormSubmit) {
      for (var propName in dynamicFieldValuesFormSubmit) {
        if (!dynamicFieldValuesFormSubmit[propName]['filter_value'] || dynamicFieldValuesFormSubmit[propName]['filter_value'] == '' ||
          dynamicFieldValuesFormSubmit[propName]['filter_value'].length == 0 || !dynamicFieldValuesFormSubmit[propName]['filter_option'] ||
          dynamicFieldValuesFormSubmit[propName]['filter_option' == ''])
          delete dynamicFieldValuesFormSubmit[propName]
      }
    }
  }
  const handleRun = () => {
    //saving for download sheet
    setLoaderStatus(true)
    if (dynamicFieldValuesFormSubmit) {
      // Loop through each property in the object
      for (var key in dynamicFieldValuesFormSubmit) {
        if (dynamicFieldValuesFormSubmit.hasOwnProperty(key)) {
          // Add or update filter_option to "Equal" if it doesn't exist or is an empty string
          if (!dynamicFieldValuesFormSubmit[key].filter_option || dynamicFieldValuesFormSubmit[key].filter_option === "") {
            dynamicFieldValuesFormSubmit[key].filter_option = "equal";
          }
          // Sort the object properties as filter_name, filter_option, filter_value
          var sortedProperties = {};
          Object.keys(dynamicFieldValuesFormSubmit[key]).sort().forEach(function (prop) {
            sortedProperties[prop] = dynamicFieldValuesFormSubmit[key][prop];
          });
          dynamicFieldValuesFormSubmit[key] = sortedProperties;
        }
      }
      var formData = {
        id: currentQueryID.toString(),
        filter_fields: dynamicFieldValuesFormSubmit,
      };
      sessionStorage.setItem("dyamicQueryDownloadData", JSON.stringify(formData));

    } else {
      var formData = {
        id: currentQueryID.toString(),
      };
      sessionStorage.setItem("dyamicQueryDownloadData", JSON.stringify(formData));
    }
    props.getDynamicQueryFields(sessionStorage.getItem("application"), tableDesc);
    if (dynamicFieldValuesFormSubmit.length === 0) {
      var data = {
        id: currentQueryID.toString(),
      };
    } else {
      handleFilterdata()
      var data = {
        id: currentQueryID.toString(),
        filter_fields: dynamicFieldValuesFormSubmit,
      };
    }
    const dataUUID = crypto.randomUUID();
    props.runDynamicQuery(data, dataType, dataUUID);
    SetCancelOpen(true)
    setUUID(dataUUID)
  };
  const onStopHandler = () => {
    let data = {
      "request_id": UUID //crypto.randomUUID()
    }
    props.onStop(data)
    setUUID('')
    SetCancelOpen(false)
    setLoaderStatus(false)
  }
  const handleSaveAndRun = (name, type) => {
    setOpenFilter(false)
    for (var key in dynamicFieldValuesFormSubmit) {
      if (dynamicFieldValuesFormSubmit.hasOwnProperty(key)) {
        // Add or update filter_option to "Equal" if it doesn't exist or is an empty string
        if (!dynamicFieldValuesFormSubmit[key].filter_option || dynamicFieldValuesFormSubmit[key].filter_option === "") {
          dynamicFieldValuesFormSubmit[key].filter_option = "equal";
        }

        // Sort the object properties as filter_name, filter_option, filter_value
        var sortedProperties = {};
        Object.keys(dynamicFieldValuesFormSubmit[key]).sort().forEach(function (prop) {
          sortedProperties[prop] = dynamicFieldValuesFormSubmit[key][prop];
        });
        dynamicFieldValuesFormSubmit[key] = sortedProperties;
      }
    }
    if (dynamicFieldValuesFormSubmit) {
      handleFilterdata()
      var formData = {
        query_id: (currentQueryID).toString(),
        filter_name: name,
        filter_fields: dynamicFieldValuesFormSubmit,
      };
      var formData1 = {
        id: currentQueryID.toString(),
        filter_fields: dynamicFieldValuesFormSubmit,
      };
      if (type == 'edit')
        formData['ID'] = filterID
      props.saveAndRun(type, formData, currentPage, dataType);
      if (trigger) {
        setLoaderStatus(true)
        sessionStorage.setItem("dyamicQueryDownloadData", JSON.stringify(formData));
        props.getDynamicQueryFields(sessionStorage.getItem("application"), tableDesc);
        const dataUUID = crypto.randomUUID();
        props.runDynamicQuery(formData1, dataType, dataUUID);
        SetCancelOpen(true)
        setUUID(dataUUID)
      }
    }
  }
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      internalDescriptionArray[optionsList.indexOf(option)] + option,
  });

  const handleOpen = (item, type) => {
    if (type == "select_box") {
      setSelectOpen(true);
      setLevel(item);
      if (item === "posting_type") {
        setSelected(postingType);
        setListArray(postingTypeArray);
        setAllSelect(
          postingTypeArray &&
          postingTypeArray.length > 0 &&
          postingType.length === postingTypeArray.length
        );
      }
      if (item === "calculation_type") {
        setSelected(calculationType);
        setListArray(calculationTypeArray);
        setAllSelect(
          calculationTypeArray &&
          calculationTypeArray.length > 0 &&
          calculationType.length === calculationTypeArray.length
        );
      }
      if (item === "contract_type") {
        const contractTypeObj = contractData.contract_type;
        const dropDownValues = contractTypeObj.drop_down_values || [];
        setListArray(dropDownValues);

        setSelected(contractType);
        setAllSelect(
          contractData.hasOwnProperty("contract_type") &&
          contractType.length === dropDownValues.length
        );
        // setSelected(contractType);
        // setListArray(
        //   contractData.filter((item) => item.field_id == "contract_type")[0][
        //   "drop_down_value_keys"
        //   ]
        // );
        // setAllSelect(
        //   contractData.filter((item) => item.field_id == "contract_type") &&
        //   contractData
        //     .filter((item) => item.field_id == "contract_type")
        //     .map((e) => e.drop_down_value_keys).length > 0 &&
        //   contractType.length ===
        //   contractData
        //     .filter((item) => item.field_id == "contract_type")
        //     .map((e) => e.drop_down_value_keys).length
        // );
      }
      if (item === "calculation_method") {
        const contractTypeObj = contractData.calculation_method;
        const dropDownValues = contractTypeObj.drop_down_reference_value || [];
        const keyValues = dropDownValues.map(value => value.key || '');

        setListArray(keyValues);

        setSelected(calculationMethod);
        setAllSelect(
          contractData.hasOwnProperty("calculation_method") &&
          calculationMethod.length === keyValues.length
        );
        // setSelected(calculationMethod);
        // setListArray(
        //   contractData.filter((item) => item.field_id == "calculation_method")[0][
        //   "drop_down_value_keys"
        //   ]
        // );
        // setAllSelect(
        //   contractData.filter((item) => item.field_id == "calculation_method") &&
        //   contractData
        //     .filter((item) => item.field_id == "calculation_method")
        //     .map((e) => e.drop_down_value_keys).length > 0 &&
        //   calculationMethod.length ===
        //   contractData
        //     .filter((item) => item.field_id == "calculation_method")
        //     .map((e) => e.drop_down_value_keys).length
        // );
      }
      if (item === "contract_group") {
        const contractTypeObj = contractData.contract_group;
        const dropDownValues = contractTypeObj.drop_down_values || [];
        setListArray(dropDownValues);

        setSelected(contractGroup);
        setAllSelect(
          contractData.hasOwnProperty("contract_group") &&
          contractGroup.length === dropDownValues.length
        );
        // setSelected(contractGroup);
        // setListArray(
        //   contractData.filter((item) => item.field_id == "contract_group")[0][
        //   "drop_down_value_keys"
        //   ]
        // );
        // setAllSelect(
        //   contractData.filter((item) => item.field_id == "contract_group") &&
        //   contractData
        //     .filter((item) => item.field_id == "contract_group")
        //     .map((e) => e.drop_down_value_keys).length > 0 &&
        //   contractGroup.length ===
        //   contractData
        //     .filter((item) => item.field_id == "contract_group")
        //     .map((e) => e.drop_down_value_keys).length
        // );
      }
      if (item === "contract_sub_group") {
        // setSelected(contractSubGroup);
        // setListArray(
        //   contractData.filter(
        //     (item) => item.field_id == "contract_sub_group"
        //   )[0]["drop_down_value_keys"]
        // );
        // setAllSelect(
        //   contractData.filter(
        //     (item) => item.field_id == "contract_sub_group"
        //   ) &&
        //   contractData
        //     .filter((item) => item.field_id == "contract_sub_group")
        //     .map((e) => e.drop_down_value_keys).length > 0 &&
        //   contractSubGroup.length ===
        //   contractData
        //     .filter((item) => item.field_id == "contract_sub_group")
        //     .map((e) => e.drop_down_value_keys).length
        // );
        const contractTypeObj = contractData.contract_sub_group;
        const dropDownValues = contractTypeObj.drop_down_values || [];
        setListArray(dropDownValues);

        setSelected(contractSubGroup);
        setAllSelect(
          contractData.hasOwnProperty("contract_sub_group") &&
          contractSubGroup.length === dropDownValues.length
        );
      }
      if (item === "class_of_trade") {
        setSelected(classOfTrade);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "class_of_trade"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          classOfTrade.length ===
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "customer_group") {
        setSelected(customerGroup);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "customer_group"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          customerGroup.length ===
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "customer_chain") {
        setSelected(customerChain);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "customer_chain"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          customerChain.length ===
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "material_group") {
        setSelected(materialGroup);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "material_group"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          materialGroup.length ===
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "product_hierarchy") {
        setSelected(productHierarchy);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "product_hierarchy"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          productHierarchy.length ===
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "membership_owner_id") {
        setSelected(memberOwner);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "membership_owner_id"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          memberOwner.length ===
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.attribute_value).length
        );
      }
    } else {
      if (item === "contract_number") {
        setOptionsList(contractNumbersList);
        setInternalDescriptionArray(description);
      }
      if (item === "material_number") {
        setOptionsList(materialNumbersList);
        setInternalDescriptionArray(materialDesc);
      }
      if (item === "customer_number") {
        setOptionsList(customerNumbersList);
        setInternalDescriptionArray(customerNameListArray);
      }
      if (item === "supplier_number") {
        setOptionsList(supplierNumbersList);
        setInternalDescriptionArray(supplierNameArray);
      }
      if (item === "employee_number") {
        setOptionsList(employeeMasterArray);
        setInternalDescriptionArray(employeeNameListArray);
      }
    }
  };
  const handleSelectValues = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (value === "all") {
      if (level === "posting_type") {
        setSelected(
          selected.length === postingTypeArray.length ? [] : postingTypeArray
        );
        setAllSelect(
          selected.length === postingTypeArray.length ? false : true
        );
      } else if (level === "calculation_type") {
        setSelected(
          selected.length === calculationTypeArray.length
            ? []
            : calculationTypeArray
        );
        setAllSelect(
          selected.length === calculationTypeArray.length ? false : true
        );
      } else {
        setSelected(selected.length === listArray.length ? [] : listArray);
        setAllSelect(selected.length === listArray.length ? false : true);
      }
    } else {
      setSelected(newSelected);
      setAllSelect(newSelected.length === listArray.length ? true : false);
    }
  };
  function onAdd() {
    setSelectOpen(false);
    if (level == "posting_type") {
      setPostingType(selected);
    }
    if (level == "calculation_type") {
      setCalculationType(selected);
    }
    if (level == "contract_type") {
      setContractType(selected);
    }
    if (level == "calculation_method") {
      setCalculationMethod(selected);
    }
    if (level == "contract_group") {
      setContractGroup(selected);
    }
    if (level == "contract_sub_group") {
      setContractSubGroup(selected);
    }
    if (level == "class_of_trade") {
      setClassOfTrade(selected);
    }
    if (level == "customer_group") {
      setCustomerGroup(selected);
    }
    if (level == "customer_chain") {
      setCustomerChain(selected);
    }
    if (level == "material_group") {
      setMaterialGroup(selected);
    }
    if (level == "product_hierarchy") {
      setProductHierarchy(selected);
    }
    if (level == "membership_owner_id") {
      setMemberOwner(selected);
    }
    setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
    setDynamicFieldValuesFormSubmit({
      ...dynamicFieldValuesFormSubmit,
      //[level]: selected,
      [level]: {
        ...dynamicFieldValuesFormSubmit[level],
        filter_name: level,
        filter_value: selected
      }
    });
  }
  function onCancel() {
    setSelectOpen(false);
    setSelected([]);
    setLevel("");
  }
  function handleDownloadExcel() {
    var data = {
      "id": currentQueryID.toString(),
    }
    if (dynamicFieldValuesFormSubmit && Object.entries(dynamicFieldValuesFormSubmit).length > 0) {
      handleFilterdata()
      data['filter_fields'] = dynamicFieldValuesFormSubmit
    }
    props.downloadDynamicQuery(data, dataType, emailId, currentPage);
  }
  function onAddValues(field) {
    setDynamicFieldTemp({ ...dynamicFieldTemp, [field]: dynamicFieldValues[field] })
    setDynamicFieldValuesFormTemp({ ...dynamicFieldValuesFormTemp, [field]: dynamicFieldValuesFormSubmit[field] })
  }
  function onCancelDropdown(field) {
    setDynamicFieldValues({ ...dynamicFieldValues, [field]: dynamicFieldTemp[field] })
    setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [field]: dynamicFieldValuesFormTemp[field] })

  }
  const handleFilter = (item) => {
    setFilterID(item.id)
    setFilterName(item.filter_name)
    props.getPredefinedFilter(item.id)
  }
  const handleSearch = (type) => {
    if (type === 'Save Search') {
      setOpenFilter(true)
    }
    if (type === 'Save Search & Run') {
      setOpenFilter(true)
      setTigger(true)
    }
  }

  return (
    <LoadingOverlay
      active={props.loading || loaderStatus}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#045FB4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}
      // text="Loading results..."
      className={classes.spinner}
    >

      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="h1" color="primary">Filter Screen
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.container} style={{ width: '80%' }}>
            <Grid container>
              {sortOrderObj &&
                sortOrderObj.length > 0 &&
                sortOrderObj
                  .filter((item) => filterFieldsArray.includes(item.sort_field) && item.display)
                  .map((item, ix) => {
                    return (
                      <>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          classes={{ root: classes.gridContainerOuter }}
                          style={{ display: "flex" }}
                        >
                          <Grid
                            item
                            md={3}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{
                              position: 'relative'
                            }}
                          >
                            <FormLabel classes={{ root: classes.fontSetting }}
                              style={{
                                marginTop: ix === 0 ? '0.7rem' : '.8rem'
                              }}>
                              <Typography variant="h3" color='primary' className={ix == 0 && classes.fontSetup} style={{
                                marginTop: '-0.6rem',
                                width: '14.5rem',
                              }}>{ix == 0 && "Filter Name"}</Typography>
                              {
                                ix == 0 ? <div style={{ marginTop: '6px' }} > {item.label_name} </div> : item.label_name
                              }
                            </FormLabel>
                            {/* <div className={classes.row} >
                              <Typography variant="h3" color='primary'>{ix == 0 && "Filter Name"}</Typography>
                            </div> 
                             <FormLabel classes={{ root: classes.formLabel }} style={{ marginTop: ix == 0 ? 12 : 0 }} >  {item.label_name} </FormLabel> */}
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            classes={{ root: classes.gridContainer1 }}
                          >
                            <FormLabel
                              classes={{ root: classes.fontSetting }}
                            >
                              <Typography variant="h3" color='primary' style={{ width: '10.2rem' }} className={ix == 0 && classes.fontSetup}>{ix === 0 && "Option"}</Typography>
                            </FormLabel>
                            <Select
                              value={
                                dynamicFieldValuesFormSubmit[
                                  item.sort_field
                                ] &&
                                  dynamicFieldValuesFormSubmit[item.sort_field][
                                  "filter_option"
                                  ]
                                  ? dynamicFieldValuesFormSubmit[
                                  item.sort_field
                                  ]["filter_option"]
                                  : ""
                              }
                              onChange={(e) =>
                                handleDynamicFieldValues(
                                  e.target.value,
                                  item.sort_field,
                                  "option"
                                )
                              }
                              MenuProps={{ ...MenuProps, autoFocus: true }}
                              displayEmpty
                              className={clsx({
                                [classes.select]: true,
                              })}
                              classes={{
                                selectMenu: classes.selectedItem,
                              }}
                              style={{ marginTop: ix == 0 ? 0 : -10 }}
                              disabled={(item.sort_field == "customer_number" || item.sort_field == "supplier_number" || item.sort_field == "employee_number") ? status : false}
                            >
                              <MenuItem value=""></MenuItem>
                              {options &&
                                options
                                  .filter(itemX => (numericFields.includes(item.sort_field) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                    ((!numericFields.includes(item.sort_field) && !dateFields.includes(item.sort_field) && !rangeFields.includes(item.sort_field)) ? itemX != 'range' : itemX) && (dateFields.includes(item.sort_field) ? itemX != 'pattern' : itemX) &&
                                    (item.sort_field === 'contract_number' ?
                                      itemX != 'pattern' :
                                      itemX))

                                  .map((item, index) => {
                                    return (
                                      <MenuItem
                                        value={item}
                                        key={item}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            md={7}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                          >
                            <FormLabel classes={{ root: classes.fontSetting }}>
                              <Typography
                                variant="h3"
                                color="primary"
                                style={{ width: '23.8rem' }}
                                className={ix === 0 && classes.fontSetup}
                              >
                                {ix === 0 && "Value"}
                              </Typography>
                            </FormLabel>
                            {dateFields.includes(item.sort_field) ? (
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: '60%', marginRight: 5 }}>
                                  <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                      disableToolbar
                                      clearable
                                      InputProps={{
                                        disableUnderline: true,
                                        style: {
                                          padding: '0px 11px',
                                          border: '1px solid #E0E0E0',
                                          width: '100%',
                                          height: 37,
                                          marginTop: ix === 0 ? 1 : -10,
                                        },
                                      }}
                                      value={dynamicFieldValues[item.sort_field] || null}
                                      onChange={(e) => handleBillingDate(e, 'start', item.sort_field)}
                                      renderInput={(props) => <TextField {...props} size="small" helperText={null} />}
                                      format="MM/DD/YYYY"
                                    />
                                  </LocalizationProvider>
                                </div>
                                {dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'range' && (
                                  <div style={{ width: '38%', marginLeft: 5 }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <DatePicker
                                        disableToolbar
                                        clearable
                                        InputProps={{
                                          disableUnderline: true,
                                          style: {
                                            padding: '0px 11px',
                                            border: '1px solid #E0E0E0',
                                            width: '100%',
                                            height: 36,
                                            marginTop: ix === 0 ? 1 : -10,
                                          },
                                        }}
                                        value={dynamicFieldValuesFormSubmit[item.sort_field]?.filter_value?.to || null}
                                        onChange={(e) => handleBillingDate(e, 'end', item.sort_field)}
                                        renderInput={(props) => <TextField {...props} size="small" helperText={null} />}
                                        format="MM/DD/YYYY"
                                        disabled={dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option !== 'range'}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                )}
                              </div>
                            ) : item.sort_field === 'posting_type' ||
                              item.sort_field === 'calculation_type' ||
                              item.sort_field === 'contract_type' ||
                              item.sort_field === 'calculation_method' ||
                              item.sort_field === 'contract_group' ||
                              item.sort_field === 'contract_sub_group' ? (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Select
                                  labelId="demo-mutiple-name-label"
                                  id="demo-mutiple-name"
                                  multiple
                                  value={dynamicFieldValues[item.sort_field] || []}
                                  style={{ height: '37px', width: '60%', textTransform: 'capitalize', marginTop: ix === 0 ? 1 : -10 }}
                                  input={<OutlinedInput />}
                                  MenuProps={{ autoFocus: true }}
                                  onOpen={() => handleOpen(item.sort_field, 'select_box')}
                                  onClose={() => setSelectOpen(false)}
                                  open={SelectOpen && level === item.sort_field}
                                  renderValue={(appType) => appType.join(', ')}
                                  className={classes.multiSelect}
                                >
                                  <MenuItem
                                    value="all"
                                    onClick={(event) => handleSelectValues(event, 'all')}
                                    style={{ textTransform: 'capitalize' }}
                                  >
                                    <Checkbox color="primary" checked={allSelect} />
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                  {listArray?.map((listItem) => (
                                    <MenuItem
                                      onClick={(event) => handleSelectValues(event, listItem)}
                                      value={listItem}
                                      key={listItem}
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      <Checkbox color="primary" checked={selected.includes(listItem)} />
                                      <ListItemText primary={listItem} />
                                    </MenuItem>
                                  ))}
                                  <div className={classes.dropdownAction}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                      Cancel
                                    </Button>
                                    <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd}>
                                      Apply
                                    </Button>
                                  </div>
                                </Select>
                              </div>
                            ) : attributesSortFields.includes(item.sort_field) ? (
                              <div style={{ display: 'flex', marginTop: '-15px', marginBottom: '-5px' }}>
                                {dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'pattern' ? (
                                  <div style={{ marginRight: 5, marginTop: '5px', width: '60%' }}>
                                    {/* <ChipInput
                                      paste
                                      data={dynamicFieldValues[item.sort_field] || []}
                                      onChange={(newValue) => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)}
                                    /> */}
                                    <SearchDropDownMasterDataNew
                                      id={item.sort_field}
                                      table="attributes_masters"
                                      keyName={item.sort_field}
                                      multiple
                                      description={true}
                                      onChange={(e) => handleDynamicFieldValuesAdd(e, item.sort_field, null, ix)}
                                      prevalue={dynamicFieldValues[item.sort_field] || []}
                                    />
                                  </div>
                                )
                                  :
                                  <div style={{ width: '60%', marginRight: 5 }}>
                                    <SearchDropDownMasterDataNew
                                      id={item.sort_field}
                                      table="attributes_masters"
                                      keyName={item.sort_field}
                                      multiple
                                      description={true}
                                      onChange={(e) => handleDynamicFieldValues(e, item.sort_field)}
                                      prevalue={dynamicFieldValues[item.sort_field] || []}
                                    />
                                  </div>
                                }
                                {dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'range' && (
                                  <div style={{ width: '38%', marginLeft: 5 }}>
                                    <SearchDropDownMasterDataNew
                                      id={item.sort_field}
                                      table="attributes_masters"
                                      keyName={item.sort_field}
                                      prevalue={dynamicFieldValues[item.sort_field] || ''}
                                      description={true}
                                      onChange={(e, newValue) => handleDynamicFieldValues(e, item.sort_field)}
                                    />
                                  </div>
                                )}

                              </div>
                            ) : item.sort_field === "contract_number" ||
                              item.sort_field === "material_number" ||
                              item.sort_field === "customer_number" ||
                              item.sort_field === "employee_number" ||
                              item.sort_field === "supplier_number" ? (
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: '60%', marginRight: 5, marginTop: ix === 0 ? 0 : -13, marginBottom: -3 }}>
                                  {dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'range' ? (
                                    <div style={{ marginTop: '-5px' }}>
                                      <SearchDropDownMasterDataNew
                                        id={item.sort_field}
                                        // table={`${item.sort_field}}
                                        table={item.sort_field === 'contract_number' ? 'contract_header' :
                                          item.sort_field === 'material_number' ? 'material_master' :
                                            item.sort_field === 'customer_number' ? 'customer_master' :
                                              item.sort_field === 'supplier_number' ? 'supplier_master' : 'employee_header'}
                                        keyName={item.sort_field}
                                        prevalue={dynamicFieldValues[item.sort_field] || ''}
                                        description={true}
                                        onChange={(e, newValue) => handleDynamicFieldValues(e, item.sort_field)}
                                      // applicationType={sessionStorage.getItem('application')}
                                      />
                                    </div>
                                  ) : dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'pattern' ? (
                                    <div style={{ marginRight: 5, marginTop: '5px', width: '100%' }}>
                                      <SearchDropDownMasterDataNew
                                        id={item.sort_field}
                                        table={item.sort_field === 'contract_number' ? 'contract_header' :
                                          item.sort_field === 'material_number' ? 'material_master' :
                                            item.sort_field === 'customer_number' ? 'customer_master' :
                                              item.sort_field === 'supplier_number' ? 'supplier_master' : 'employee_header'}
                                        keyName={item.sort_field}
                                        multiple
                                        description={true}
                                        onChange={(e) => handleDynamicFieldValuesAdd(e, item.sort_field, null, ix)}
                                        prevalue={dynamicFieldValues[item.sort_field] || []}
                                      />
                                      {/* <ChipInput
                                        paste
                                        data={dynamicFieldValues[item.sort_field] || []}
                                        onChange={(newValue) => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)}
                                      /> */}
                                    </div>
                                  ) : (
                                    <SearchDropDownMasterDataNew
                                      id={item.sort_field}
                                      table={item.sort_field === 'contract_number' ? 'contract_header' :
                                        item.sort_field === 'material_number' ? 'material_master' :
                                          item.sort_field === 'customer_number' ? 'customer_master' :
                                            item.sort_field === 'supplier_number' ? 'supplier_master' : 'employee_header'}
                                      keyName={item.sort_field}
                                      multiple
                                      description={true}
                                      onChange={(e) => handleDynamicFieldValues(e, item.sort_field)}
                                      prevalue={dynamicFieldValues[item.sort_field] || []}
                                    />
                                  )}
                                </div>
                                {dynamicFieldValuesFormSubmit[item.sort_field]?.filter_option === 'range' && (
                                  <div style={{ width: '38%', marginLeft: 5, marginTop: ix === 0 ? -13 : -18 }}>
                                    <SearchDropDownMasterDataNew
                                      id={item.sort_field}
                                      table={item.sort_field === 'contract_number' ? 'contract_header' :
                                        item.sort_field === 'material_number' ? 'material_master' :
                                          item.sort_field === 'customer_number' ? 'customer_master' :
                                            item.sort_field === 'supplier_number' ? 'supplier_master' : 'employee_header'}
                                      keyName={item.sort_field}
                                      prevalue={dynamicFieldValues[item.sort_field] || ''}
                                      description={true}
                                      onChange={(e, newValue) => handleDynamicFieldValues(e, item.sort_field, 'range')}
                                    />
                                  </div>
                                )}
                              </div>
                            ) :
                              //  (
                              //   <div style={{ display: 'flex', flexDirection: 'column', width: '60%', marginRight: 5, marginTop: ix === 0 ? 0 : -9, marginBottom: -3 }}>
                              //     <ChipInput
                              //       paste
                              //       data={dynamicFieldValues[item.sort_field] || []}
                              //       onChange={(newValue) => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)}
                              //     />
                              //   </div>

                              // )
                              (
                                <div style={{ display: 'flex', marginTop: ix === 0 ? '-9px' : '-1.3rem' }}>
                                  <div style={{ width: "60%", marginRight: 5, marginTop: "11px" }}>
                                    {dynamicFieldValuesFormSubmit[
                                      item.sort_field
                                    ] &&
                                      dynamicFieldValuesFormSubmit[
                                      item.sort_field
                                      ]["filter_option"] &&
                                      dynamicFieldValuesFormSubmit[
                                      item.sort_field
                                      ]["filter_option"] == "range" ? (
                                      <OutlinedInput
                                        classes={{ root: classes.inputTwoLine1 }}
                                        type={props.type}
                                        value={
                                          dynamicFieldValues[item.sort_field]
                                            ? dynamicFieldValues[item.sort_field]
                                            : " "
                                        }
                                        onChange={(e) =>
                                          handleDynamicFieldValues(
                                            e.target.value,
                                            item.sort_field,
                                          )
                                        }
                                      />) : (
                                      <ChipInput paste data={
                                        dynamicFieldValues[item.sort_field]
                                          ? dynamicFieldValues[item.sort_field]
                                          : []
                                      }
                                        onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.sort_field, null, ix)}
                                      />
                                    )
                                    }
                                  </div>
                                  {dynamicFieldValuesFormSubmit[
                                    item.sort_field
                                  ] &&
                                    dynamicFieldValuesFormSubmit[
                                    item.sort_field
                                    ]["filter_option"] &&
                                    dynamicFieldValuesFormSubmit[
                                    item.sort_field
                                    ]["filter_option"] == "range" &&
                                    <div style={{ width: "38%", marginLeft: 5, marginTop: "-3px" }}>
                                      <OutlinedInput
                                        classes={{ root: classes.inputTwoLine }}
                                        type={props.type}
                                        value={
                                          dynamicFieldValues[item.sort_field] &&
                                            dynamicFieldValues[item.sort_field]
                                              .length > 0
                                            ? dynamicFieldValuesRange[
                                            item.sort_field
                                            ]
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleDynamicFieldValues(
                                            e.target.value,
                                            item.sort_field,
                                            "range"
                                          )
                                        }
                                        disabled={
                                          dynamicFieldValuesFormSubmit[
                                            item.sort_field
                                          ] &&
                                            dynamicFieldValuesFormSubmit[
                                            item.sort_field
                                            ]["filter_option"] &&
                                            dynamicFieldValuesFormSubmit[
                                            item.sort_field
                                            ]["filter_option"] == "range"
                                            ? false
                                            : true
                                        }
                                      />
                                    </div>}
                                </div>
                              )
                            }
                          </Grid>

                        </Grid >

                      </>
                    );
                  })}
            </Grid >
            <div className={classes.buttonContainer}>
              {(dataType == 'download' || dataType == 'email') ?
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleDownloadExcel}
                  twoline="true"
                  startIcon={dataType == 'download' ? <GetApp /> : <Email />}
                >
                  {props.loadingExcel ?
                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingExcel} size={6} />
                    :
                    (dataType == 'download') ? 'Download' : 'Email'
                  }
                </Button>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleRun}
                    twoline="true"
                  >
                    {props.loading || loaderStatus ?
                      <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                      :
                      'Run'
                    }
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleSearch('Save Search')}
                    twoline="true"
                  >
                    {props.loading || loaderStatus ?
                      <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                      :
                      'Save Search'
                    }
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleSearch('Save Search & Run')}
                    twoline="true"
                  >
                    {props.loading || loaderStatus ?
                      <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                      :
                      'Save Search & Run'
                    }
                  </Button>
                </>
              }
            </div >
          </div >
          <div style={{ width: '20%' }} className={classes.container}>
            <DynamicQueryFormRef id={currentQueryID} onChange={handleFilter} />
          </div>
        </div >
      </div >
      {
        openFilter &&
        <FilterDialoge open={openFilter} filterName={filterName}
          action={filterID ? 'edit' : 'create'}
          onChange={handleSaveAndRun}
          onCancel={() => setOpenFilter(false)}
        />

      }
      <Dialog open={openConfirmEmail}>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Typography variant="h4">
            <DialogContentText>
              <FormLabel classes={{ root: classes.fontSetting }}>Email Id</FormLabel>
              <OutlinedInput
                classes={{ root: classes.inputTwoLine }}
                type={'email'}
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialog,
          }}
        >
          <Button
            onClick={() => history.push('/dynamic-query')}
            autoFocus
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => setOpenConfirmEmail(false)}
            color="primary"
            autoFocus
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cancelOpen}
        style={{ marginTop: '10rem' }}
      >
        <DialogActions>
          <Button variant="contained" color="primary" style={{ width: 160 }} onClick={onStopHandler}
          >
            Stop
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadingDefault: () => dispatch(getDefaultValues()),
    runDynamicQuery: (data, type, UUID) =>
      dispatch(runDynamicQuery(data, null, "dq", "dynamicQuery", type, UUID)),
    onCheckContractsTable: () => dispatch(getContractsTableDataList()),
    onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
    onCheckMaterialTable: () => dispatch(getMaterialMasterData("NoLimit")),
    onCheckSupplierTable: () => dispatch(getSupplierMasterData()),
    getAttributeListData: (data) => dispatch(getAttributeListData(data)),
    postCollaborationPortal: (data, editId, type) =>
      dispatch(postCollaborationPortal(data, editId, type)),
    getdropdownConfig: () => dispatch(fetchDropdownConfig()),
    getDynamicQueryFields: (applicationType, tableName) =>
      dispatch(getDynamicQueryFieldsNew(applicationType, tableName)),
    downloadDynamicQuery: (data, type, email, page) => dispatch(downloadDynamicQuery(data, type, email, page)),
    getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
    saveAndRun: (type, data, page, dataType) => dispatch(updateQueryRefData(type, data, null, page, dataType, null, "OnDemand")),
    getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
    onStop: (data) => dispatch(runCancelRequest(data)),
    getLblDispDesFieldValues: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    // loading: state.operationalReportsData.loading,
    // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    contractTabledataComplete: state.addContractData.contractAllListData,
    customerMasterData: state.customerData.customerMasterData,
    materialMasterData: state.customerData.materialMasterData,
    supplierMasterData: state.customerData.supplierMasterData,
    dynamicQueryDetailsData:
      state.operationalReportsData.dynamicQueryDetailsData,
    attributeData: state.attributeListData.AttributeData,
    collaborationPortalData:
      state.addMultipleConfigurationData.collaborationPortalData,
    dropdownConfig: state.financialCloseData.dropdownConfig,
    loadingExcel: state.operationalReportsData.loading,
    dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
    predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
    employeeMasterData: state.customerData.employeeMasterData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryForm);
