import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, FormLabel, MenuItem, Select } from "@mui/material";
import { Chart } from "react-google-charts";
import Moment from "moment";
import { connect } from "react-redux";
import {
  loadValuesBasedOnAppTypeAndField,
  loadAccrualVsPaymentGraph,
} from "../../../../redux/actions";
import Chart1 from "react-apexcharts";
import PieChart from "../../../Analytics/Charts/PieChart";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: 10,
  },
  select: {
    fontSize: 13,
  },
  chartContainer: {
    marginTop: 30,
    height: 250,
  },
  chartHead: {
    width: "100%",
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h2.fontSize,
    display: "flex",
    justifyContent: "center",
  },
}));

const MonthlyIncentiveGraph = (props) => {
  const classes = useStyles();
  const [avssGraphData, setAvssGraphData] = React.useState({});

  useEffect(() => {
    const months = [];
    var i;
    var label = ["Month", "Accrual", "Payment"];
    var accrualData = props.accrualPaymentGraphData.accrual_result;
    var paymentData = props.accrualPaymentGraphData.payment_result;
    const dateEnd = Moment();
    const dateStart = Moment().subtract(11, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push([dateStart.format("MMM")]);
      dateStart.add(1, "month");
    }
    months.forEach((e) => {
      e.push(0, 0);
    });
    months.unshift(label);
    setAvssGraphData(months);
    if (
      props.accrualPaymentGraphData &&
      props.accrualPaymentGraphData.accrual_result &&
      props.accrualPaymentGraphData.accrual_result.length > 0
    ) {
      for (i = 0; i < 12; i++) {
        accrualData.forEach((e) => {
          if (Moment(e.Date).format("MMM") === months[i][0]) {
            months[i][1] = e.Total;
          }
        });
        paymentData.forEach((e) => {
          if (Moment(e.Date).format("MMM") === months[i][0]) {
            months[i][2] = e.Total;
          }
        });
      }
    }
  }, [props.accrualPaymentGraphData]);
  const [sourceDataType, setSourceDataType] = React.useState("");
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] =
    React.useState([]);
  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
    props.loadAccrualVsPaymentGraph(
      sessionStorage.getItem("application"),
      e.target.value
    );
  };
  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue) &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);
  useEffect(() => {
    props.loadSourceDataType(
      "Customer Rebate",
      "source_data_type",
      "load_first_value"
    );
  }, []);

  const [claimError, setClaimError] = React.useState([]);
  const [claimErrorLib, setClaimErrorLib] = React.useState([]);
  const [k, setK] = React.useState([]);
  const [v, setV] = React.useState([]);
  const [options, setOptions] = React.useState({});
  const [series, setSeries] = React.useState([]);
  const [errorData, setErrorData] = React.useState([]);
  useEffect(() => {
    var dummyData = [
      {
        error: " Claim Amount Discrepancy",
        count: 2,
      },
      {
        error: "Contract Error",
        count: 5,
      },
    ];

    var err = [];
    var count = [];
    if (props.claimError) {
      if (
        props.claimError.length > 0 &&
        props.claimError.filter((item) => item.error !== "No Error").length > 0
      ) {
        props.claimError
          .filter((item) => item.error !== "No Error")
          .map((item) => {
            err.push(item["error"]);
            count.push(item["count"]);
          });
        var dimension = {
          xName: "error",
          yName: "count",
          tooltipMappingName: "error",
        };

        setErrorData(
          props.claimError.filter((item) => item.error !== "No Error")
        );
      } else setErrorData([]);
      setSeries(dimension);
      // setSeries(count);
      // setOptions({
      //   chart: {
      //     type: "pie",
      //     height: "auto",
      //   },
      //   colors: [
      //     "#FF6633",
      //     "#FFB399",
      //     "#FF33FF",
      //     "#00B3E6",
      //     "#E6B333",
      //     "#3366E6",
      //     "#999966",
      //     "#99FF99",
      //     "#B34D4D",
      //     "#80B300",
      //     "#809900",
      //     "#E6B3B3",
      //     "#6680B3",
      //     "#66991A",
      //     "#FF99E6",
      //     "#CCFF1A",
      //     "#FF1A66",
      //     "#E6331A",
      //     "#33FFCC",
      //     "#66994D",
      //     "#B366CC",
      //     "#4D8000",
      //     "#B33300",
      //     "#CC80CC",
      //     "#66664D",
      //     "#991AFF",
      //     "#E666FF",
      //     "#4DB3FF",
      //     "#1AB399",
      //     "#E666B3",
      //     "#33991A",
      //     "#CC9999",
      //     "#B3B31A",
      //     "#00E680",
      //     "#4D8066",
      //     "#809980",
      //     "#E6FF80",
      //     "#1AFF33",
      //     "#999933",
      //     "#FF3380",
      //     "#CCCC00",
      //     "#66E64D",
      //     "#4D80CC",
      //     "#9900B3",
      //     "#E64D66",
      //     "#4DB380",
      //     "#FF4D4D",
      //     "#99E6E6",
      //     "#6666FF",
      //   ],
      //   labels: err,
      //   animations: {
      //     enabled: true,
      //     easing: "easeinout",
      //     speed: 800,
      //     animateGradually: {
      //       enabled: true,
      //       delay: 150,
      //     },
      //     dynamicAnimation: {
      //       enabled: true,
      //       speed: 350,
      //     },
      //   },
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           height: 250,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // });
    } else setErrorData([]);
  }, [props.claimError]);

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.chartHead}>
        {" "}
        {sessionStorage.getItem("application") === "Supplier Chargeback" ||
        sessionStorage.getItem("application") === "Customer Chargeback"
          ? "Claim Error"
          : "Accrual vs Payment "}
      </Typography>
      <div className={classes.chartContainer}>
        {sessionStorage.getItem("application") === "Supplier Chargeback" ||
        sessionStorage.getItem("application") === "Customer Chargeback" ? (
          <>
            <PieChart data={errorData} dimension={series} />
            {/* <div className={classes.chartContainer}>
            
             {props.claimError && props.claimError.length > 0 ? (
              // <Chart1
              //   options={options}
              //   series={series}
              //   type="pie"
              //   height={250}
              // />
              
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  fontSize: 13,
                  color: "grey",
                }}
              >
                No Data to Display !
              </div>
            )} 
          </div>*/}
          </>
        ) : sessionStorage.getItem("application") === "Customer Rebate" ||
          sessionStorage.getItem("application") === "Supplier Rebate" ||
          sessionStorage.getItem("application") === "Royalty" ? (
          <div className={classes.chartContainer}>
            {avssGraphData && avssGraphData.length > 0 ? (
              <Chart
                width={"100%"}
                height={"175"}
                chartType="Bar"
                loader={<div>Loading Chart</div>}
                data={avssGraphData}
                rootProps={{ "data-testid": "2" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  fontSize: 13,
                  color: "grey",
                }}
              >
                No Data to Display !
              </div>
            )}
          </div>
        ) : avssGraphData && avssGraphData.length > 0 ? (
          <Chart
            width={"100%"}
            height={"150"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={avssGraphData}
            rootProps={{ "data-testid": "2" }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 300,
              fontSize: 13,
              color: "grey",
            }}
          >
            No Data to Display !
          </div>
        )}
      </div>
    </div>
  );
};

MonthlyIncentiveGraph.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType) =>
      dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyIncentiveGraph);
