import React from 'react';
import { FinancialPostingsTableAccrual } from './components';

const FinancialPostingsTableContainer = props => {

    return (
        <div>
            <FinancialPostingsTableAccrual />
        </div>
    );
};

export default FinancialPostingsTableContainer;