import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import { connect } from 'react-redux';
import { LibraryAdd } from '@mui/icons-material';
import { getIntegrationMappingData, createIntegrationMapping, getIntegrationSetupData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MaterialTable from 'material-table';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { NavLink as RouterLink } from 'react-router-dom';
import { createTheme } from "@mui/material/styles";
import { tableIcons } from '../../components/Icons/TableIcons';
import secureLocalStorage from 'react-secure-storage';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const IntegrationMapping = props => {
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        props.getIntegraionData();
        props.getIntegrationSetupData(1, 1000);
    }, []);
    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const handleConfirmDelete = () => {
        props.onDelete(selectedRow, selectedRow.id);
        setOpenDialog(false);
    };
    const columns = [
        {
            field: 'integration_name',
            title: 'Integration Name',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'activity',
            title: 'Activity',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            filtering: false,
            render: rowData =>
                <div>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_mapping-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => viewIntegration(rowData, rowData.id)}
                            size="large">
                            <Visibility color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_mapping-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editIntegration(rowData, rowData.id)}
                            size="large">
                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_integration_mapping-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleDeleteClick(rowData)}
                            size="large">
                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];
    function editIntegration(e, id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/integration-mapping/edit/' + id,
            id: id,
            state: {
                datasource: e.datasource
            }
        });
    }
    function viewIntegration(e, id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/integration-mapping/view/' + id,
            id: id,
            state: {
                datasource: e.datasource
            }
        });
    }
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        var tempRows = [];
        if (props.integrationData && props.integrationList && props.integrationList.records && props.integrationData.records.length > 0) {
            props.integrationData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        integration_name: e.name,
                        activity: e.activity,
                        datasource: e.datasource,
                    });
                })
            setDataRows(tempRows);
        }
    }, [props.integrationData, props.integrationList]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading ... Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary'>Integration Mapping</Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_integration_mapping-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/integration-mapping/add'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                        </div>}
                </div>
                {props.integrationData && props.integrationData.records && props.integrationData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this integration mapping?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        integrationData: state.customerData.integrationMappingData,
        integrationList: state.customerData.integrationSetupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getIntegraionData: () => dispatch(getIntegrationMappingData()),
        onDelete: (data, id) => dispatch(createIntegrationMapping(data, id, 'delete')),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMapping);