import React, { useEffect } from 'react';
import '../../../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, FormLabel, FormControl, Select, MenuItem, Typography, InputLabel, Button, TextField } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { loadPaymentGraph, loadValuesBasedOnAppTypeAndField, allowedApps } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';
import clsx from 'clsx';
import AccrualCharts from './AccrualCharts';
import PaymentCharts from './PaymentCharts';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white, //secondary.main,
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        transform: 'scale(0.486)',
        transformOrigin: 'top left',
        width: "204%",
        height: "480px",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: -9,
        transform: 'scale(0.75)',
        transformOrigin: 'top left',
        width: "131.5%",
        // marginTop: 25,
        boxShadow: "rgba(50, 50, 93, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.15) 0px 2px 4px -2px",
        height: "70px",
        padding: "7px 5px 5px 5px"
    },
    noBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "1px solid",
                borderColor: theme.palette.border.main,
            },
        },
    },
    selectBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: "1px solid",
            borderColor: theme.palette.border.main,
        },
    },
    noBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "1px solid",
                borderColor: theme.palette.border.main,
            },
        },
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px',
        alignItems: 'center',
        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        transform: 'scale(0.586)',
        transformOrigin: 'top left',
        width: "170%",
        height: "513px"
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 32px 0px 20px',
        alignItems: 'center'
    },
    graphContainer: {
        width: '80%',
        height: '100%',
        marginTop: 10,
        display: 'flex',
        alignSelf: 'center'

    },
    select: {
        width: "100%",
        color: "#1675e0",
    },
    selectedItem: {
        borderRadius: 5,
    },
    fontSetting: {
        // fontSize: theme.typography.h3.fontSize
        fontSize: "13px",
        lineHeight: 1.3
    },
    spinner: {
        height: '100%'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 24,
        paddingBottom: 5,
    },
    button: {
        marginRight: 10,
        // width: 80,
    },
    formControlStyle: {
        marginRight: "-3px"
    },
}));
const Analytics = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [paymentCalculationSchemaData, setPaymentCalculationSchemaData] = React.useState([]);
    const [paymentContractTypeData, setPaymentContractTypeData] = React.useState([]);
    const [paymentContractGroupData, setPaymentContractGroupData] = React.useState([]);
    const [paymentCalculationSchemaAccrualData, setPaymentCalculationSchemaAccrualData] = React.useState([]);
    const [paymentContractTypeAccrualData, setPaymentContractTypeAccrualData] = React.useState([]);
    const [paymentContractGroupAccrualData, setPaymentContractGroupAccrualData] = React.useState([]);
    const [dateRangeValue, setDateRangeValue] = React.useState([new Date('2020-06-01T05:30:00'), new Date('2020-10-01T05:30:00')]);

    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
    const [postingType, setPostingType] = React.useState('accrual');
    const [aggregationField, setAggregationField] = React.useState('Contract Group')
    const [paymentContractSubGroupData, setPaymentContractSubGroupData] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [showLoaderPostingSummaryContractGrouping, setShowLoaderPostingSummaryContractGrouping] = React.useState(true);
    const [showGraph3, setShowGraph3] = React.useState(true);

    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Postings Summary by Contract Groupings" && item.dashboard_name === "Postings Summary Analysis"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
                setShowGraph3(false);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setAggregationField(allowedTilesFilter[0].groupBy)
            setSourceDataType(allowedTilesFilter[0].data_source_type)
            setPostingType(allowedTilesFilter[0].posting_type)
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (postingType && sourceDataType && startDate && endDate) {
            setShowLoaderPostingSummaryContractGrouping(true);
            var date = 'start_date=' + startDate + '&end_date=' + endDate
            props.loadPaymentGraph('contract_type', date, postingType, applicationType, sourceDataType);
            props.loadPaymentGraph('calculation_method', date, postingType, applicationType, sourceDataType);
            props.loadPaymentGraph('contract_group', date, postingType, applicationType, sourceDataType);
            props.loadPaymentGraph('contract_sub_group', date, postingType, applicationType, sourceDataType);
        }
    }, [allowedTilesFilter]);
    const handleStartDate = (e) => {
        setStartDate(e);
        // if (endDate && e && e.isValid()) {
        //     var date = 'start_date=' + Moment(e).format('YYYY-MM-DDTHH:mm:ss') + '&end_date=' + Moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
        //     props.loadPaymentGraph('contract_type', date, postingType, applicationType, sourceDataType);
        //     props.loadPaymentGraph('calculation_method', date, postingType, applicationType, sourceDataType);
        //     props.loadPaymentGraph('contract_group', date, postingType, applicationType, sourceDataType);
        //     props.loadPaymentGraph('contract_sub_group', date, postingType, applicationType, sourceDataType);
        // }
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }

    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
    }

    useEffect(() => {
        props.allowedApps();
        props.loadSourceDataType(applicationType, 'source_data_type');
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])

        }
    }, [props.sourceDataTypeValue])


    useEffect(() => {
        const months = [];
        var i;
        var label = ['Month', 'Accrual', 'Payment'];
        var accrualData = props.accrualPaymentGraphData.accrual_result;
        var paymentData = props.accrualPaymentGraphData.payment_result;
        const dateEnd = Moment();
        const dateStart = Moment().subtract(11, 'month');
        while (dateEnd.diff(dateStart, 'months') >= 0) {
            months.push([dateStart.format('MMM YYYY')])
            dateStart.add(1, 'month')
        }
        months.forEach(e => {
            e.push(0, 0)
        })
        months.unshift(label);
        setAvssGraphData(months);
        if (props.accrualPaymentGraphData &&
            props.accrualPaymentGraphData.accrual_result && props.accrualPaymentGraphData.accrual_result.length > 0
        ) {
            for (i = 0; i < 12; i++) {
                accrualData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][1] = e.Total
                    }
                })
                paymentData.forEach(e => {
                    if (Moment(e.Date).format('MMM YYYY') === months[i][0]) {
                        months[i][2] = e.Total
                    }
                })
            }
        }
    }, [props.accrualPaymentGraphData]);

    //rename pie keys
    useEffect(() => {
        if (props.paymentGraphCalculationSchemaSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphCalculationSchemaSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentCalculationSchemaData(newArray);
        }

    }, [props.paymentGraphCalculationSchemaSuccessData]);
    useEffect(() => {
        if (props.paymentGraphContractGroupSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractGroupData(newArray);
        }
    }, [props.paymentGraphContractGroupSuccessData]);

    useEffect(() => {
        if (props.paymentGraphContractTypeSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphContractTypeSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractTypeData(newArray);
        }
    }, [props.paymentGraphContractTypeSuccessData]);


    //accrual
    useEffect(() => {
        setShowLoaderPostingSummaryContractGrouping(false);
        if (props.paymentGraphCalculationSchemaAccrualSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphCalculationSchemaAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentCalculationSchemaAccrualData(newArray);
        }

    }, [props.paymentGraphCalculationSchemaAccrualSuccessData]);
    useEffect(() => {
        setShowLoaderPostingSummaryContractGrouping(false);
        if (props.paymentGraphContractGroupAccrualSuccessData) {
            var label = ['Contract group', 'Percentage'];
            var output = props.paymentGraphContractGroupAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractGroupAccrualData(newArray);
        }
    }, [props.paymentGraphContractGroupAccrualSuccessData]);

    useEffect(() => {
        setShowLoaderPostingSummaryContractGrouping(false);
        if (props.paymentGraphContractSubGroupSuccessData) {
            var label = ['Contract sub group', 'Percentage'];
            var output = props.paymentGraphContractSubGroupSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractSubGroupData(newArray);
        }
    }, [props.paymentGraphContractSubGroupSuccessData]);

    useEffect(() => {
        setShowLoaderPostingSummaryContractGrouping(false);
        if (props.paymentGraphContractTypeAccrualSuccessData) {
            var label = ['Contract type', 'Percentage'];
            var output = props.paymentGraphContractTypeAccrualSuccessData.map(function (obj) {
                return Object.keys(obj).sort().map(function (key) {
                    return obj[key];
                });
            });
            const newArray = [label].concat(output)
            setPaymentContractTypeAccrualData(newArray);
        }
    }, [props.paymentGraphContractTypeAccrualSuccessData]);

    // useEffect(() => {
    //     setStartDate(Moment().subtract(1, 'months').startOf('month'));
    //     setEndDate(Moment().subtract(1, 'months').endOf('month'));
    // }, [])


    /*const handleDateRange = (value) => {
        setDateRangeValue(value);
        var date = 'start=' + Moment.utc(value[0]).local().format('YYYY-MM-DDTHH:mm:ss') + '&end=' + Moment.utc(value[1]).local().format('YYYY-MM-DDTHH:mm:ss')
        props.loadPaymentGraph('contract_type', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('calculation_method', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('contract_group', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('contract_sub_group', date, postingType, applicationType, sourceDataType);
 
    }*/

    const handlePostingTypeChange = (e) => {
        setPostingType(e.target.value);
    }
    const handleAggregationField = (e) => {
        setAggregationField(e.target.value)
    }
    function handleSubmit() {
        setShowGraph3(true);
        setShowLoaderPostingSummaryContractGrouping(true);
        var date = 'start_date=' + Moment(startDate).format('YYYY-MM-DDTHH:mm:ss') + '&end_date=' + Moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
        props.loadPaymentGraph('contract_type', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('calculation_method', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('contract_group', date, postingType, applicationType, sourceDataType);
        props.loadPaymentGraph('contract_sub_group', date, postingType, applicationType, sourceDataType);
    }
    return (
        <LoadingOverlay
            active={showLoaderPostingSummaryContractGrouping}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >

            <div>
                <div className={classes.bodyContainer} style={{ display: "flex" }}>
                    {/* <Grid container className={classes.root} style={{ justifyContent: 'space-between' }}> */}
                    {/* <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}> */}
                    {/* <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Application Type</Typography>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps
                                        .filter(item => item !== 'Customer Chargeback' && item !== 'Promotions' && item !== 'Supplier Chargeback' && item !== 'Pricing' && item !== 'Profit Optimization')
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid> */}
                    {/* <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Source Data Type</Typography>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid> */}
                    <FormControl sx={{ m: 1, maxWidth: 115 }} size="small" style={{ marginLeft: "1px" }} className={classes.formControlStyle}>
                        <InputLabel>Posting Type</InputLabel>
                        <Select
                            className={classes.selectBorder}
                            style={{ border: 'none' }}
                            // labelId="posting-type"
                            // id="posting-type-select"
                            value={postingType}
                            label="Posting Type"
                            onChange={handlePostingTypeChange}
                        >
                            <MenuItem value={'accrual'} >
                                Accruals
                            </MenuItem>
                            <MenuItem value={'payment'}>
                                Payment
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 200 }} size="small" className={classes.formControlStyle}>
                        <InputLabel> Attributes</InputLabel>
                        <Select
                            className={classes.selectBorder}
                            style={{ border: 'none' }}
                            label="Attributes"
                            value={aggregationField}
                            onChange={handleAggregationField}
                            // className={clsx({
                            //     [classes.select]: true
                            // })}
                            classes={{
                                selectMenu: classes.selectedItem
                            }}
                        >
                            <MenuItem value={'Contract Group'} >
                                Contract Group
                            </MenuItem>
                            <MenuItem value={'Sub Contract Group'}>
                                Sub Contract Group
                            </MenuItem>
                            <MenuItem value={'Contract Type'} >
                                Contract Type
                            </MenuItem>
                            <MenuItem value={'Calculation Method'}>
                                Calculation Method
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" className={classes.formControlStyle}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                labelId="start_date"
                                id="start_date-select"
                                label="Start Date"
                                disableToolbar
                                clearable
                                InputProps={{
                                    padding: 0,
                                    disableUnderline: true,
                                    style: {
                                        padding: '1px 10 1px 11px',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #E0E0E0',
                                        width: '100%',
                                        height: '2.3rem',
                                        border: 'none'
                                    }
                                }}
                                value={startDate}
                                onChange={handleStartDate}
                                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                                format="MM/DD/YYYY"
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 140 }} size="small" className={classes.formControlStyle}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                labelId="end_date"
                                id="end_date-select"
                                label="End Date"
                                disableToolbar
                                clearable
                                InputProps={{
                                    padding: 0,
                                    disableUnderline: true,
                                    style: {
                                        // padding: '1px 0 1px 11px',
                                        padding: '1px 10 1px 11px',
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #E0E0E0',
                                        width: '100%',
                                        height: '2.3rem',
                                        border: 'none'
                                    }
                                }}
                                value={endDate}
                                onChange={handleEndDate}
                                renderInput={(props) => <TextField  {...props} size='small' className={classes.noBorder} helperText={null} />}
                                format="MM/DD/YYYY"
                                minDate={startDate ? startDate : false}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ m: 1, maxWidth: 80 }} size="small" className={classes.formControlStyle}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleSubmit}
                            style={{ marginRight: 3 }}
                            twoline="true"
                            disabled={postingType && sourceDataType && startDate && endDate ? false : true}
                        >
                            <DirectionsRunIcon />
                        </Button>
                    </FormControl>
                </div>
                {postingType == 'payment' &&
                    <div className={classes.root}>
                        <PaymentCharts data={aggregationField} show={showGraph3} />
                    </div>
                }
                {postingType == 'accrual' &&
                    <div className={classes.root}>
                        <AccrualCharts data={aggregationField} show={showGraph3} />
                    </div>
                }
            </div>
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
        paymentGraphContractTypeSuccessData: state.initialData.paymentGraphContractTypeSuccessData,
        paymentGraphCalculationSchemaSuccessData: state.initialData.paymentGraphCalculationSchemaSuccessData,
        paymentGraphContractGroupSuccessData: state.initialData.paymentGraphContractGroupSuccessData,
        paymentGraphContractTypeAccrualSuccessData: state.initialData.paymentGraphContractTypeAccrualSuccessData,
        paymentGraphCalculationSchemaAccrualSuccessData: state.initialData.paymentGraphCalculationSchemaAccrualSuccessData,
        paymentGraphContractGroupAccrualSuccessData: state.initialData.paymentGraphContractGroupAccrualSuccessData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        paymentGraphContractSubGroupSuccessData: state.initialData.paymentGraphContractSubGroupSuccessData,
        allowedAppsData: state.initialData.allowedApps,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadPaymentGraph: (item, dateRangeValue, type, applicationType, sourceDataType) => dispatch(loadPaymentGraph(item, dateRangeValue, type, applicationType, sourceDataType)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);