import React, { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { Accrual, Payment, FlexFields, AdditionalData } from './containers'
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },

}));
const FinancialPostings = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    var accrualData = [];
    var paymentData = [];
    var additionalData = [];
    var flexData = [];
    const [externalReference, setExternalReference] = React.useState('');
    const [formFieldData, setFormFieldData] = React.useState([]);
    const [mandatoryStatus, setMandatoryStatus] = React.useState([]);
    const [mandatoryStatus2, setMandatoryStatus2] = React.useState([]);
    const [mandatoryStatus3, setMandatoryStatus3] = React.useState([]);
    useImperativeHandle(ref, () => ({
        validationCheck() {
            childRef1.current.validationCheck();
            childRef2.current.validationCheck();
        },
        getValues() {
            var accuralValues = childRef1.current.getValues();
            var paymentValues = childRef2.current.getValues();
            var flexValues = childRef3.current.getValues();
            var combined = Object.assign({}, accuralValues, paymentValues, flexValues, additionalData);
            return combined;
        }
    }));
    // useEffect(()=>{
    //     if (props.tableData && props.tableData.records.length > 0) {
    //         setFormFieldData( props.tableData.records.filter((item)=>item.contract_display))
    //     }
    // },[props.tableData && props.tableData.records])
    function handleChangeAccrual(newValue, mandatory) {
        accrualData = newValue;
        // setMandatoryStatus(checkDependency(mandatory, newValue))
        setMandatoryStatus(mandatory)
        concatinated();
    }
    function handleChangePayment(newValue, mandatory) {
        paymentData = newValue;
        setMandatoryStatus2(mandatory)
        // setMandatoryStatus2(checkDependency(mandatory, newValue))
        concatinated();
    }
    function concatinated() {

        if (props.postingSchema === 'Payment Only') {
            props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus2, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'Accrual Only') {
            props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'No Postings') {
            props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), []);
        }
        if (props.postingSchema === 'Accrual then Payment') {
            props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]);
        }
        if (props.postingSchema === 'Payment then Amortization') {
            props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]);

        }
        // props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]
        // (
        // props.postingSchema === 'Accrual then Payment' && mandatoryStatus && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Accrual Only' && mandatoryStatus && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Payment Only' && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Payment then Amortization' && mandatoryStatus && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // )
        // props.onChange(Object.assign({}, accrualData, paymentData, flexData, additionalData), [...mandatoryStatus, ...mandatoryStatus2, ...mandatoryStatus3]
        // (
        // props.postingSchema === 'Accrual then Payment' && mandatoryStatus && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Accrual Only' && mandatoryStatus && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Payment Only' && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // || (props.postingSchema === 'Payment then Amortization' && mandatoryStatus && mandatoryStatus2 && mandatoryStatus3 ? true : false)
        // )
    }
    function changeInternalReference(value) {
        setExternalReference(value)
    }
    function handleAdditionalData(newValue, mandatory) {
        additionalData = newValue;
        setMandatoryStatus3(mandatory)
        // setMandatoryStatus3(checkDependency(mandatory, newValue))
        concatinated();
    }

    return (
        <div className={clsx(classes.root, className)}>
            {(props.postingSchema != 'Payment Only' && props.postingSchema != 'No Postings') &&
                <Accrual onChange={handleChangeAccrual} savetriggered={props.savetriggered} ref={childRef1}
                    contractDefaultData={props.contractDefaultData ? props.contractDefaultData : ''} postingSchema={props.postingSchema}
                    changeInternalReference={changeInternalReference}
                    fieldData={props.fieldData}
                />
            }
            {(props.postingSchema != 'Accrual Only' && props.postingSchema != 'No Postings') &&
                <Payment onChange={handleChangePayment} savetriggered={props.savetriggered}
                    contractDefaultData={props.contractDefaultData ? props.contractDefaultData : ''}
                    ref={childRef2}
                    externalReference={externalReference}
                    fieldData={props.fieldData} activeDropdown={props.activeDropdown}
                />
            }
            {props.postingSchema != 'No Postings' && <AdditionalData onChange={handleAdditionalData} fieldData={props.fieldData} />
            }
        </div>
    );

});
const mapStateToProps = state => ({
    //tableData: state.addMultipleConfigurationData.multipleConfigurationData,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(FinancialPostings);