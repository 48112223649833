import React from 'react';
import { Autocomplete, TextField, Popper, Paper } from '@mui/material';
import { styled } from '@mui/system';
const CustomPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1,
}));

const CustomPaper = styled(Paper)({
    width: 'calc(100% + 220px)',
    overflow: 'auto',
});

const FormulaLabAutoComplete = ({ data, id, onChange, prevalue }) => {
    return (
        <Autocomplete
            id={id}
            options={data}
            getOptionLabel={(option) => option.desc || ''}
            value={data.find((option) => option.key === prevalue) || null}
            onChange={(event, newValue) => {
                onChange(newValue ? newValue.key : '');
            }}
            PopperComponent={CustomPopper}
            PaperComponent={(props) => <CustomPaper {...props} />}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{
                        borderRadius: '0px !important', paddingBottom: '4px !important', maxHeight: '2.25rem !important'
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '2.25rem',
                        },
                        '& .MuiInputBase-input': {
                            padding: '0.75rem 0.75rem',
                        }
                    }}
                />
            )}
            style={{ width: "100%", textTransform: 'capitalize' }}
            classes={{
                inputRoot: { borderRadius: '0px !important' }
            }}
        />
    );
};

export default FormulaLabAutoComplete;