import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs, Typography, Link, useMediaQuery, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Button, Paper, TablePagination
} from '@mui/material';
import { SearchInput } from '../../components';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { downloadDynamicQuery, getDefaultValuesAllDataNew } from '../../redux/actions';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
    sumContainer: {
        backgroundColor: theme.palette.primary.main
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
const DynamicQueryResults = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [tableHeaders, setTableHeaders] = React.useState([]);
    const [sortedTableHeader, setSortedTableHeader] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleSheetDownload = () => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        var data = {
            "id": appId
        }
        if (appId)
            props.downloadDynamicQuery(data);
    }
    useEffect(() => {
        if (props.queryResultsFinalData && props.queryResultsFinalData[1] && props.queryResultsFinalData[1].app_type && props.queryResultsFinalData[1].data_source_type) {
            var lastElement = props.queryResultsFinalData[1].app_type
            props.onLoadingLabelDesignerNew('field-label-designer', props.queryResultsFinalData[1].app_type, props.queryResultsFinalData[1].data_source_type)
        }
    }, [])
    useEffect(() => {
        if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
            var singleValue = props.queryResultsFinalData[0][0];
            setTableHeaders(Object.keys(singleValue));
            var lastElement = props.queryResultsFinalData[0][props.queryResultsFinalData[0].length - 1].sortOrder
            setSortedTableHeader(lastElement);
        }
    }, [props.queryResultsFinalData]);
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })

    }
    const [sumField, setSumField] = React.useState({});
    const [headingsToConvertToDollor, setHeadingToConvertToDollor] = React.useState(['flex_key_figure1', 'flex_key_figure2', 'flex_key_figure3', 'flex_key_figure4'
        , 'flex_key_figure5', 'flex_key_figure6', 'flex_key_figure7', 'flex_key_figure8', 'flex_key_figure9', 'flex_key_figure10', 'flex_key_figure11', 'flex_key_figure12', 'flex_key_figure13', 'flex_key_figure14', 'flex_key_figure15'
        , 'flex_key_figure16', 'flex_key_figure17', 'flex_key_figure18', 'flex_key_figure19', 'flex_key_figure20', 'net_value',
        'incentive_basis', 'tier_basis', 'incentive_amount', 'quantity', 'unit_price', 'incentive_basis1', 'incentive_basis2',
        'incentive_basis3', 'incentive_basis4', 'incentive_basis5', 'maximum_incentive_amount', 'incentive_calculated'
    ]);
    useEffect(() => {
        if (props.queryResultsFinalData) {
            var sum = props.queryResultsFinalData.map(e => {
                if (e.total_sum) {
                    setSumField(e.total_sum);
                }
            })
        }
    }, [props.queryResultsFinalData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/partner-statement')}
                >
                    Partner Statement
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div className={clsx(classes.root, className)} >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Partner Statement Results </Typography>
                    <div className={classes.buttonContainer}>
                        <SearchInput />
                        <Button variant="outlined" color="primary" className={classes.button}
                            onClick={handleSheetDownload}> Download </Button>
                    </div>
                </div>
                {sortedTableHeader && sortedTableHeader.length > 0 && props.queryResultsFinalData && props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0 &&
                    <Paper className={classes.root}>
                        <div style={{ margin: 15 }}>
                            <TableContainer style={{ overflowX: "initial" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow >
                                            {sortedTableHeader && sortedTableHeader.length > 0 ? sortedTableHeader
                                                .map(item => {
                                                    return (
                                                        <TableCell style={{ textTransform: 'Capitalize' }}>
                                                            {item && checkLabel(item) && Array.isArray(checkLabel(item)) && checkLabel(item).filter(item => item).length === 0 ? item.replace(/_/g, ' ') : checkLabel(item)}
                                                        </TableCell>
                                                    );
                                                })
                                                :
                                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                            }

                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0 && props.queryResultsFinalData[0]
                                            .filter((element, index) => index < props.queryResultsFinalData[0].length - 1)
                                            .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                            .map((row) => (
                                                <StyledTableRow>
                                                    {sortedTableHeader && sortedTableHeader.length > 0 && sortedTableHeader
                                                        .map((heading) =>
                                                            <TableCell>{
                                                                row[heading].indexOf('Z') > -1 && row[heading].indexOf('T') > -1 && Moment(row[heading], true).isValid()
                                                                    ? Moment.utc(row[heading]).format('MM/DD/YYYY') :
                                                                    (headingsToConvertToDollor.includes(heading)) ? parseFloat(row[heading]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : row[heading]
                                                            }</TableCell>)}
                                                </StyledTableRow>
                                            ))}
                                        <TableRow className={classes.sumContainer}>
                                            {sortedTableHeader && sortedTableHeader.length > 0 && sortedTableHeader.map(sh => {
                                                if (sumField[sh]) {
                                                    return (<TableCell style={{ color: '#E0F2F7', border: '1px solid #445AC7' }}>
                                                        {sumField[sh] ? parseFloat(sumField[sh]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>)
                                                } else {
                                                    return (<TableCell style={{ border: '1px solid #445AC7' }}></TableCell>)
                                                }
                                            })}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    component="div"
                                    count={props.queryResultsFinalData[0].length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    </Paper>
                }
            </div >
        </div>
    );

};

const mapStateToProps = state => {
    return {
        queryResultsFinalData: state.operationalReportsData.queryResultsFinalData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        downloadDynamicQuery: (data) => dispatch(downloadDynamicQuery(data)),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryResults);