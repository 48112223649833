import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import { getHelpDeskData, addHelpDesk } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { AddBoxOutlined } from '@mui/icons-material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { SmartDisplay } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const HelpDeskMainCP = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 10);
    }, []);
    // const handleDelete = (id) => {
    //     props.delete(null, id);
    //   };
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [selectedUrl, setSelectedUrl] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const allowedApps = [sessionStorage.getItem("application")];
    const columns = [
        { title: 'Application Type', field: 'application_type' },
        { title: 'Business Process', field: 'business_process' },
        { title: 'Topic', field: 'topic' },
        { title: 'Video Link', field: 'link' },
        {
            field: "actions",
            title: "Actions",
            render: item => (
                <div>
                    {sessionStorage.getItem("application") === 'Customer Rebate' &&
                        secureLocalStorage.getItem("dYtz").role.rights &&
                        secureLocalStorage.getItem("dYtz").role.rights.includes(
                            "put_on_demand_videos-" + sessionStorage.getItem("application")
                        ) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editAttribute(item.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => setLink(item.link)}
                        size="large">
                        <SmartDisplay color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    {sessionStorage.getItem("application") &&
                        secureLocalStorage.getItem("dYtz").role.rights &&
                        secureLocalStorage.getItem("dYtz").role.rights.includes(
                            "put_on_demand_videos-" + sessionStorage.getItem("application")
                        ) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                             onClick={() => handleDelete(item.ID)}
                             size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
            )
        }
    ]
    function handleDelete(id) {
        setDeleteId(id);
        setOpenConfirmDialog(true);
    }

    function confirmDelete() {
        props.delete(null, deleteId);
        setOpenConfirmDialog(false);
        setDeleteId(null);
    }

    function cancelDelete() {
        setOpenConfirmDialog(false);
        setDeleteId(null);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    useEffect(() => {
        var newArray = []
        if (props.HelpDeskData && props.HelpDeskData.total_record > 0) {
            props.HelpDeskData.records.map((item) => {
                if (allowedApps.includes(item.application_type)) {
                    newArray.push(item);
                }
            })
            setAttributeData(newArray);
        }
        else{
            setAttributeData([]);
        }
    }, [props.HelpDeskData]);

    function editAttribute(id) {
        history.push({
            pathname: '/help-desk-cp/edit-help-cp/' + id,
            id: id
        });
    }
    function setLink(link) {
        setSelectedUrl(link)
        setOpenModal(true)
    }
    const arrayItem = [];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > On Demand Videos </Typography>
                    {sessionStorage.getItem("application") === 'Customer Rebate' &&
                        secureLocalStorage.getItem("dYtz").role.rights &&
                        secureLocalStorage
                            .getItem("dYtz")
                            .role.rights.includes(
                                "post_on_demand_videos-" + sessionStorage.getItem("application")
                            )
                        //  sessionStorage.getItem("application") ===
                        //    "Sales Commission"
                        && <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/help-desk-cp/add-help-cp')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}
                </div>
                {attributeData && attributeData.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={attributeData}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            pageSize: rowsPerPage,
                            pageSizeOptions: rowsPerPageOptions,
                            emptyRowsWhenPaging: true,
                            toolbarButtonAlignment: 'left',
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: {
                                fontSize: '.90rem',
                                textAlign: "center",
                                borderRight: "1px solid #EEEEEE",
                                color: "black !important",
                                padding: '5px 0px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 800,
                                width: 30,
                                minWidth: 5,
                        },
                        }}
                        className={classes.tabHead}
                        onChangePage={(page) => handleChangePage(null, page)}
                        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(null, event.target.value)}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <TablePagination
                    classes={{ root: classes.paginationRoot }}
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={props.HelpDeskData && props.HelpDeskData.total_record}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </LoadingOverlay>
            <Dialog open={openModal} fullScreen>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                {/* {currentPage === 'customer-master' ?
                                    <iframe src="https://www.loom.com/embed/73ec272073984a91b6dc826ff0251041" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                                    :
                                    <iframe src="https://www.loom.com/embed/076f1d86a11f46d2b803c0622d2da740" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>

                                } */}
                                <iframe src={selectedUrl} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                            </div>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                }}>
                    <Button onClick={() => setOpenModal(false)} color="primary" autoFocus variant="contained"  >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} variant="contained" color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        HelpDeskData: state.HelpDeskData.HelpDeskData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getHelpDeskData(pagination, limit)),
        delete: (data, id) => dispatch(addHelpDesk(data, 'delete', id, 'cp')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskMainCP);