import React, { useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,IconButton,
} from '@mui/material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getMembershipData
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { MembershipToolbar } from './';
import Moment from 'moment';
import HashLoader from "react-spinners/HashLoader";
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, PdfExport, Search ,Reorder } from '@syncfusion/ej2-react-grids';
import {DataUtil} from '@syncfusion/ej2-data';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 20
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    spinner: {
        height: '100vh'
    },
    customcss :{
        color:'black',
        fontSize: '1em',
    }
}));

const MembershipByMemeber = props => {
    const { className } = props;
    let grid;
    const method=props.type
    const memberID=props.memberId
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    useEffect(() => {
        if(history.location.state!=='displaybyFilter'){
        props.getMembership(1,0);
        }
    }, []);
    useEffect(() => {
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            setDataRows(props.membershipData.records);
            setDataCount(props.membershipData.total_record)
        }
    }, [props.membershipData]);

    function viewMembership(id) {
        // props.getMembership(id);
        history.push({
            pathname: '/membership/view-member/' + id.data.ID,
            id: id
        });
    }


    const columns = [
       
        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'customer_name',
            title: 'Customer Name',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'street',
            title: 'Street',
            type: 'string',
            width:150,
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE',whiteSpace: 'nowrap',width:'40px'
            },
       
        },
        {
            field: 'city',
            title: 'City',
            editable: 'never',
            type: 'string',
            width:100
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            editable: 'never',
            width:80
        }
        ,
        {
            field: 'postal_code',
            title: 'Postal Code',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'GPO_name',
            title: 'GPO Name',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'GPO_member_number',
            title: 'Member Number',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'GPO_member_name',
            title: 'Member Name',
            type: 'string',
            editable: 'never',
            width:150,
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE',whiteSpace: 'nowrap',width:'40px'
            },
        },
        {
            field: 'action_code',
            title: 'Action Code',
            type: 'string',
            editable: 'never',
            width:90
        },
        {
            field: 'action_effective_date',
            title: 'Action Effective Date',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'member_start_date',
            title: 'Start Date',
            type: 'string',
            width:115
        },
        {
            field: 'member_status',
            title: 'Member Status',
            editable: 'never',
            type: 'string',
            width:100
        },
        {
            field: 'IDGLN',
            title: 'ID GLN',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'ID_HIN',
            title: 'ID HIN',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'class_of_trade',
            title: 'Class of Trade',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'direct_parent_member_number',
            title: 'Direct Parent Member Numbere',
            type: 'string',
            editable: 'never',
            width:150
        },
        {
            field: 'direct_parent_member_name',
            title: 'Direct Parent Member Name',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'direct_parent_customer_number',
            title: 'Direct Parent Customer Number',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'top_parent_member_number',
            title: 'Top Parent Member Number',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'top_parent_member_name',
            title: 'Top Parent Member Name',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'top_parent_customer_number',
            title: 'Top Parent Customer Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'contract_description',
            title: 'Contract Description',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'contract_type',
            title: 'Contract Type',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'contract_start_date',
            title: 'Contract Start Date',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'contract_end_date',
            title: 'Contract End Date',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'tier_number',
            title: 'Tier Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'tier_description',
            title: 'Tier Description',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'current_tier_status',
            title: 'Current Tier Status',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'pricebook_id',
            title: 'Pricebook ID',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'pricebook_name',
            title: 'Pricebook Name',
            type: 'string',
            editable: 'never',
            width:150
        },
        {
            field: 'price_list_code',
            title: 'Price List Code',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'price_list_name',
            title: 'Price List Name',
            type: 'string',
            editable: 'never',
            width:150
        },
        {
            field: 'restocking_fee_exempt',
            title: 'Restocking Fee Exempt',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'priority',
            title: 'Priority',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'member_end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'organization_status',
            title: 'Organization Status',
            type: 'string',
            editable: 'never',
            width:130
        },
        {
            field: 'ima_class_of_trade',
            title: 'IMA Class of Trade',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field: 'relationship_to_top_parent',
            title: 'Relationship to Top Parent',
            type: 'string',
            editable: 'never',
            width:120,
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE',cellWidth:'10px'
            },
        },
        {
            field: 'reference',
            title: 'Reference',
            type: 'string',
            editable: 'never',
            width:130
        },
        {
            field: 'secondary_class_of_trade',
            title: 'Secondary Class of Trade',
            type: 'string',
            editable: 'never',
            width:115
        },{
            field: 'member_id_type',
            title: 'Member ID Type',
            type: 'string',
            editable: 'never',
            width:100
        },
        {
            field: 'member_id_number',
            title: 'Member ID Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        
        {
            field:'membership_owner_id',
            title: 'Membership Owner ID',
            type: 'string',
            editable: 'never',
            width:120
        },
        {
            field:'membership_owner_id_number',
            title: 'Membership Owner ID Number',
            type: 'string',
            editable: 'never',
            width:115
        },
        {
            field:'membership_owner_id_type',
            title: 'Membership Owner ID Type',
            type: 'string',
            editable: 'never',
            width:120
        },
        {
            field:'membership_owner_name',
            title: 'Membership Owner Name',
            type: 'string',
            editable: 'never',
            width:120
        },
    ];
    const dataBound = () => {
        if (grid) {
          grid.autoFitColumns([
            'customer_name',
            'street',
            'GPO_member_name',
            'action_code',
            'city',
          ])
    
        }
      };
      const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
          grid.pdfExport();
        }
        if (grid && args.item.id === 'grid_excelexport') {
          grid.excelExport();
        }
      };
      const formatOption = { type: 'date', format: 'dd/MM/yyyy' };
      const customAttributes = { class: classes.customcss };
    return (
        
        <div className={clsx(classes.root, className)}>
            {console.log(method)}
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner = {<HashLoader />}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h2"> Members Details </Typography>
                    {/* <MembershipToolbar /> */}
                </div>
                {props.membershipData && props.membershipData.records && props.membershipData.records.length > 0 ?
                    <GridComponent id='grid'
                        // allowGrouping={true}
                        gridLines='Both'
                        rowSelected={viewMembership.bind(dataRows)}
                        allowReordering={true}
                        dataSource={DataUtil.parse.parseJson(JSON.stringify(dataRows))} height={600} toolbar={['PdfExport', 'ExcelExport', 'Search']} allowPdfExport={true} allowExcelExport={true} 
                        dataBound={dataBound} 
                        allowTextWrap={true}
                        allowResizing={true}
                        toolbarClick={toolbarClick} ref={g => grid = g} >
                        <ColumnsDirective>
                        {columns.map(item => {
                            return <ColumnDirective headerText={item.title} field={item.field} customAttributes={customAttributes}
                           width={item.width} 
                            format={item.field === 'member_start_date' ? 'MM/dd/yyyy' : item.field === 'member_end_date' ? 'MM/dd/yyyy': item.field === 'action_effective_date' ? 'MM/dd/yyyy': item.field === 'contract_start_date' ? 'MM/dd/yyyy' : item.field === 'contract_end_date' ? 'MM/dd/yyyy': null}
                            type={item.field === 'member_start_date' ? 'dateTime' : item.field === 'member_end_date' ? 'dateTime': item.field === 'action_effective_date' ? 'dateTime': item.field === 'contract_start_date' ? 'dateTime': item.field === 'contract_end_date' ? 'dateTime': null }
                            />
                        })}
                        </ColumnsDirective>
                        <Inject services={[Toolbar, PdfExport, ExcelExport, Page, Sort, Filter, Search, Group, Resize,Reorder]} />
                    </GridComponent>
                    :
                    <Typography variant='h4'>
                         There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading:state.customerData.loading,
        membershipData: state.customerData.membershipData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembership: (pagination,limit) => dispatch(getMembershipData(pagination,limit,'displaybyMember')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MembershipByMemeber);