import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  OutlinedInput,
} from "@mui/material";
import { createMuiTheme } from '@mui/material';
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { DropdownArray, Dropdown, ChipInput } from "../../components/Inputs";
import { ContractNumberAC } from "../../components/Inputs";
import SimpleDialog from "../../components/Dialog";
import {
  runCalculationSimulation,
  getDefaultValues,
  getContractsTableDataList, addBatchJobSetup
} from "../../redux/actions";
import { connect } from "react-redux";
// import ChipInput from "material-ui-chip-input";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader, RotateLoader } from "react-spinners";
import { history } from "../../components/Helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "10px 0px 0px 0px",
    marginTop: 5,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  container: {
    padding: 10,

  },
  gridContainer: {
    padding: "0px 10px 0px 10px",
    marginTop: 0
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 28,
    paddingBottom: 30,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: 3,
    marginTop: 10,
    height: 37,
    padding: 0,
    marginBottom: 14,
    width: "100%"
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
  contractContiner: {
    padding: "15px 0px 18px",
  },
  spinner: {
    // height: "100vh",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
    //fontSize:16
  },
  paper: { minWidth: window.screen.width * .50 },
  inputTwoLine: {
    marginTop: 10,
    width: '100%',
    paddingLeft: 10
  },
}));
const CustomerRebateForecast = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { className } = props;
  const contractCustomisationUpdation = useRef();
  const [contractTypeArray, setContractTypeArray] = React.useState([]);
  const [calculationMethodArray, setCalculationMethodArray] = React.useState(
    []
  );
  const [calculationMethod, setCalculationMethod] = React.useState("");
  const [internalDescriptionArray, setInternalDescriptionArray] =
    React.useState([]);
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [calculationPeriodStart, setCalculationPeriodStart] = useState(null);
  const [calculationPeriodEnd, setCalculationPeriodEnd] = useState(null);

  useEffect(() => {
    props.onLoadingDefault();
    props.onCheckContractsTable();
  }, []);
  useEffect(() => {
    setCalculationPeriodStart(
      Moment.utc().subtract(1, "months").startOf("month")
    );
    setCalculationPeriodEnd(Moment.utc().subtract(1, "months").endOf("month"));
  }, []);
  useEffect(() => {
    var contracts = [];
    var tempNameArray = [];
    if (props.contractTabledataComplete) {
      props.contractTabledataComplete.map((item) => {
        contracts.push(item.contract_number.toString());
        tempNameArray.push(item.internal_description);
      });
      setContractNumbersList(contracts);
      setInternalDescriptionArray(tempNameArray);
    }
  }, [props.contractTabledataComplete]);

  useEffect(() => {
    if (!contractCustomisationUpdation.current) {
      {
        if (
          props.dropdownData &&
          props.dropdownData.records &&
          props.dropdownData.records.length > 0
        ) {
          props.dropdownData.records.map((item) => {
            if (item.field_id === "contract_type") {
              setContractTypeArray(item);
            }
            if (item.field_id === "calculation_method") {
              setCalculationMethodArray(item);
            }
          });
        }
      }
    }
  });
  const [contractNumber, setContractNumber] = useState([]);
  const [contractType, setContractType] = useState("");
  const [contractSubtype, setContractSubtype] = useState("");
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [startValid, setStartValid] = useState(true);
  const [endValid, setEndValid] = useState(true);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const typeRef = useRef(null);
  const subTypeRef = useRef(null);
  const [batchJobName, setBatchJobName] = React.useState('');
  const [open2, setOpen2] = useState(false);
  var sd = calculationPeriodStart ? Moment.utc(calculationPeriodStart).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
  var ed = calculationPeriodEnd ? Moment.utc(calculationPeriodEnd).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
  const formData = {
    application_type: 'Customer Rebate',
    contract_number: contractNumber,
    contract_type: contractType,
    calculation_method: calculationMethod,
    calculation_period_start: sd,
    calculation_period_end: ed,
    tags: tags,
    "forecast": true
  };
  const format2 = {
    "app_type": 'Customer Rebate',
    "batch_job_type": "Calculation Simulation",
    "batch_job_name": batchJobName,
    "planned_date_type": "static",
    "planned_start_date": Moment.utc(new Date()).add(1, 'minute').local().format('YYYY-MM-DDTHH:mm:ssZ').toString(),
    "planned_start_calendar": "",
    "planned_start_time": Moment.utc(new Date()).add(1, 'minute').local().format('HH:mm:00').toString(),
    "frequency": "One Time",
    "batch_filter_criteria": [
      {
        "filters": {
          ...formData, "calculation_date_type": 'static', 'calculation_start_date': sd, 'calculation_end_date': ed
        }
      }]
  }
  const handleDialog = (bool) => {
    if (bool) {
      setOpen(false);
      formData['contract_number'] = contractNumber.map(Number)
      props.onSubmit(formData);
      // handleClear();
    } else {
      setOpen(false);
    }
  };
  const handleClear = () => {
    startRef.current.reset();
    endRef.current.reset();
    typeRef.current.reset();
    subTypeRef.current.reset();
  };
  const handleClickOpen = () => {
    setOpen(true)
    // if (contractNumber.length > 0) {
    //   if (calculationPeriodEnd && calculationPeriodStart) {
    //     setOpen(true);
    //   } else {
    //     if (!calculationPeriodEnd) setCalculationPeriodEnd(false);
    //     if (!calculationPeriodStart) setCalculationPeriodStart(false);
    //   }
    // } else if (
    //   calculationPeriodEnd &&
    //   calculationPeriodStart &&
    //   contractSubtype &&
    //   contractType
    // )
    //   setOpen(true);
    // else {
    //   if (!calculationPeriodEnd) setCalculationPeriodEnd(false);
    //   if (!calculationPeriodStart) setCalculationPeriodStart(false);
    //   if (!contractSubtype) setContractSubtype(false);
    //   if (!contractType) setContractType(false);
    // }
  };
  const handleClickOpen2 = () => {
    setOpen2(true)
  };
  function handleCalculationPeriodStart(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodStart(newValue);
      setStartValid(true);
    } else {
      setCalculationPeriodStart(newValue);
      if (!newValue) setCalculationPeriodEnd(null);

      setStartValid(false);
    }
  }
  function handleCalculationPeriodEnd(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodEnd(newValue);
      setEndValid(true);
    } else {
      setCalculationPeriodEnd(newValue);
      setEndValid(false);
    }
  }
  function handleContractNumber(value) {
    let newSelected = value;
    if (value.length > 0) {
      setSelected(newSelected);
      setContractNumber(newSelected.map(Number));
    } else {
      setSelected([]);
      setContractNumber([]);
    }
  }
  function handleContractNumberAC(value) {
    let newSelected = value;
    if (value.length > 0) {
      setSelected(newSelected);
      setContractNumber(newSelected);
    } else {
      setSelected([]);
      setContractNumber([]);
    }
  }
  function handleTags(newValue, fn) {
    if (fn === "add") {
      // setTags([...tags, newValue]);
      setTags(newValue)
    } else {
      var delTags = tags.filter((item) => item !== newValue);
      setTags(delTags);
    }
  }
  function handleContractType(newValue) {
    setContractType(newValue);
  }
  function handleCalculationMethod(newValue) {
    setCalculationMethod(newValue);
  }
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      internalDescriptionArray[contractNumbersList.indexOf(option)] + option,
  });
  const handlePaste = (event) => {
    event.preventDefault();
  };
  const handleBatchJobName = (e) => {
    setBatchJobName(e.target.value);
  }
  function handleBatchOnSubmit() {
    setOpen2(false)
    setBatchJobName('');
    delete format2.batch_filter_criteria[0].filters['calculation_period_start'];
    delete format2.batch_filter_criteria[0].filters['calculation_period_end'];
    props.onSubmitBatchJob(format2)
  }
  function handleCancelDialog() {
    setOpen2(false)
    setBatchJobName('');
    history.push('/calculation-simulation');
  }
  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="h1" color="primary" style={{ marginLeft: 16 }}>
            Customer Rebate Forecast
          </Typography>
        </div>
        <form autoComplete="off" noValidate>
          <div className={classes.container}>
            <Grid container>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <Dropdown
                  style={{ marginTop: 5 }}
                  heading={"Application Type"}
                  twoline="true"
                  {...props}
                  disabled={true}
                  classes={{ root: classes.fontSetting }}
                  placeholder={'Customer Rebate'}
                />
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                {calculationMethodArray && calculationMethodArray.name && (
                  <DropdownArray
                    classes={{ root: classes.fontSetting }}
                    required={contractNumber.length > 0 ? false : true}
                    heading={
                      calculationMethodArray.name
                        ? calculationMethodArray.name
                        : "Please configure calculation method"
                    }
                    data={calculationMethodArray.drop_down_values}
                    // placeholder={
                    //   calculationMethodArray.drop_down_values &&
                    //   calculationMethodArray.drop_down_values.length > 0
                    //     ? "Select calculation method"
                    //     : "- Please configure calculation method -"
                    // }
                    twoline="true"
                    onChange={handleCalculationMethod}
                    error={
                      typeof calculationMethod === "boolean" &&
                        !calculationMethod
                        ? true
                        : false
                    }
                    ref={subTypeRef}
                    style={{ padding: '0px 5px 10px 5px' }}
                  />
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                {contractTypeArray && contractTypeArray.name && (
                  <DropdownArray
                    style={{ paddingBottom: 5 }}
                    classes={{ root: classes.fontSetting }}
                    required={contractNumber.length > 0 ? false : true}
                    heading={
                      contractTypeArray.name
                        ? contractTypeArray.name
                        : "Please configure contract type"
                    }
                    data={contractTypeArray.drop_down_values}
                    // placeholder={
                    //   contractTypeArray.drop_down_values &&
                    //   contractTypeArray.drop_down_values.length > 0
                    //     ? "Select contract type"
                    //     : "- Please configure contract type -"
                    // }
                    twoline="true"
                    onChange={handleContractType}
                    error={
                      typeof contractType === "boolean" && !contractType
                        ? true
                        : false
                    }
                    ref={typeRef}
                  />
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <ContractNumberAC

                  multiple
                  required
                  onChange={handleContractNumberAC}
                  prevalue={contractNumber}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation Start Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: "0px 11px",
                          height: 37,
                          alignSelf: "center",
                          alignItems: "center",
                          marginTop: 10,
                          border: "1px solid #E0E0E0",
                          width: "100%",
                        },
                      }}
                      value={calculationPeriodStart}
                      onChange={handleCalculationPeriodStart}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation End Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: "0px 11px",
                          height: 37,
                          alignSelf: "center",
                          alignItems: "center",
                          marginTop: 10,
                          border: "1px solid #E0E0E0",
                          width: "100%",
                        },
                      }}
                      value={calculationPeriodEnd}
                      onChange={handleCalculationPeriodEnd}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      minDate={
                        calculationPeriodStart ? calculationPeriodStart : ""
                      }
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ marginTop: '1rem' }}
              >
                <FormLabel classes={{ root: classes.fontSetting }}>Tags</FormLabel>
                {/* <ChipInput
                  classes={{
                    root: classes.rootContainer,
                    chip: classes.chip,
                    input: classes.input,
                    inputRoot: classes.inputRoot,
                    chipContainer: classes.chipContainer,
                    label: classes.chipLabel,
                  }}
                  onPaste={handlePaste}
                  value={tags}
                  onAdd={(chips) => handleTags(chips, "add")}
                  onDelete={(chips) => handleTags(chips, "delete")}
                  disableUnderline={true}
                  blurBehavior="add"
                /> */}
                <ChipInput data={tags} onChange={handleTags} />
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                twoline="true"
                onClick={handleClear}
              >
                Clear
              </Button>
              <Button
                disabled={
                  (contractType && startValid && endValid ? false : true) && (calculationMethod && startValid && endValid ? false : true)
                  && (contractNumber.length > 0 && startValid && endValid ? false : true)
                }
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickOpen}
                twoline="true"
              >
                {props.loading ? (
                  <RotateLoader
                    color={"rgb(54, 215, 183)"}
                    loading={props.loading}
                    size={2}
                  />
                ) : (
                  "Run Online"
                )}
              </Button>
              <Button
                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen2} twoline='true'
                disabled={(contractType && startValid && endValid ? false : true) && (calculationMethod && startValid && endValid ? false : true)
                  && (contractNumber.length > 0 && startValid && endValid ? false : true)
                }>
                {
                  props.loading ?
                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                    :
                    'Schedule Batch Job'
                }
              </Button>
            </div>
          </div>
        </form>
        <SimpleDialog
          open={open}
          content="Do you want to simulate the Customer Rebate Forecast?"
          handleDialog={handleDialog}
        />
      </div>
      <Dialog
        onClose={() => setOpen2(false)}
        aria-labelledby="simple-dialog-title"
        open={open2}
        disableEscapeKeyDown
        classes={{ paper: classes.paper }}>
        <div style={{ padding: 20 }}>
          <FormLabel className={classes.formLabel} required>
            Batch Job Name
          </FormLabel>
          <OutlinedInput
            value={batchJobName}
            classes={{ root: classes.inputTwoLine }}
            onChange={handleBatchJobName} />
        </div>
        <DialogActions>
          <Button color="primary" onClick={handleCancelDialog}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => dispatch(runCalculationSimulation(formData, 'CustomerRebateForecast')),
    onSubmitBatchJob: (data) => dispatch(addBatchJobSetup(data, null, null, 'CustomerRebateForecast')),
    onLoadingDefault: () => dispatch(getDefaultValues(null, null, 'Profit Optimization', null, null)),
    onCheckContractsTable: () => dispatch(getContractsTableDataList('Profit Optimization', null, null, null)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    contractTabledataComplete: state.addContractData.contractAllListData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRebateForecast);