import React, { useState, useEffect, useRef, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  Typography, Breadcrumbs, Fab
} from '@mui/material';
import { submitExcel } from '../../redux/actions';
import {
  SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective,
  RangesDirective, RangeDirective, RowsDirective, RowDirective, CellsDirective,
  CellDirective, ColumnDirective,getRangeIndexes
} from '@syncfusion/ej2-react-spreadsheet';
import { Link } from 'react-router-dom';
import { getRangeAddress } from '@syncfusion/ej2-spreadsheet';
import Moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: 'none',
    border: 0,
    fontSize: 16,
    paddingRight: 10
  },
  spdsht: { width: '100%', overflow: 'scroll', writingMode: 'vertical-rl' },
  header: {
  },

}));

const SpreadsheetCreator = (props) => {
  const { className } = props;
  let spreadsheet;
  const options = {
    data: [[]],
    minDimensions: [13, 100],
    columns: [
      {
        title: 'Material Number',
        width: '100',
        options: { style: 'font-size: 12px' }
      },
      {
        title: 'Material Description',
        width: '100'
      },
      {
        title: 'Start Date',
        width: '100'
      },
      {
        title: 'End Date',
        width: '100'
      },
      {
        title: 'Calendar Year Month',
        width: '100'
      },
      {
        title: 'Competitor Name',
        width: '100'
      },
      {
        title: 'Competitor Product',
        width: '100'
      },
      {
        title: 'Customer Group',
        width: '100'
      },
      {
        title: 'Customer Chain',
        width: '100'
      },
      {
        title: 'Customer Number',
        width: '80'
      },
      {
        title: 'Material Group',
        width: '80'
      },
      {
        title:'Territory Name',
        width:'80'
      },
      {
        title: 'Net Value',
        width: '80'
      },
      {
        title: 'Quantity',
        width: '80'
      },
      {
        title:'Unit Price',
        width:'80'
      },
      {
        title: 'Currency',
        width: '80'
      }
      
    ],
    rowResize: true,
    search: true,
    columnSorting: true,
    filters: true,
    allowComments: true,
    columnDrag: true,
    // tableOverflow: true,
    tableWidth: "100%",
  }; 
  const classes = useStyles();
  const scrollSettings = { isFinite: true };
  const [apiFields, setApiFields] = React.useState([])
  const rowStyle =
  {
    verticalAlign: 'middle', textAlign: 'center', fontSize: '16pt', fontWeight: 'bold',
    border: '1px solid #e0e0e0', backgroundColor: '#EEEEEE', color: '#279377'

  }
  {options.columns.map(item => {
    apiFields.push(((item.title.toLowerCase())).replace(" ",'_'))
  })}
  function ExcelDateToJSDate(serial) {
    var hours = Math.floor((serial % 1) * 24);
    var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
}
  const handleSubmitExcel = () => {
        var json = [];
        var obj = {};
        var ssObj = spreadsheet;
        var usedRange = ssObj.getActiveSheet().usedRange;
        var selIndex = [2, 0, usedRange.rowIndex, usedRange.colIndex];
        var range =
            ssObj.getActiveSheet().name + '!' + getRangeAddress([2, 0, selIndex[2], selIndex[3]]);
        ssObj.getData(range).then((value) => {
            value.forEach((cell, key) => {
                var indexes = getRangeIndexes(key);
                if (cell && selIndex[3] >= indexes[1]) {
                    var charCurrent = 'A2';
                    apiFields.map(item => {
                        if (key.indexOf(charCurrent) > -1) {
                          if (item == 'start_date' || item == 'end_date')
                                obj[item] = cell.value ? Moment(ExcelDateToJSDate(cell.value)).format('YYYY-MM-DD').concat('T00:00:00Z') : '';
                          else if (item == 'net_value'|| item=='quantity'|| item == 'unit_price')
                                obj[item] = cell.value ? parseFloat(cell.value) : 0;
                          else
                               obj[item] = cell.value ? cell.value.toString() : '';
                        }
                        charCurrent = String.fromCharCode(charCurrent.charCodeAt() + 1)
                    })
                    if (indexes[1] === selIndex[3]) {
                        json.push(obj);
                        obj = {};
                    }
                }
            });
      props.submitExcel(json);
  });
  }

  const onCellEdit = (args) => {
    if (args.address.includes('1')) {
      args.cancel = true;
    }
  }
  let grid;
  const dataBound = () => {
    if (grid) {
      grid.autoFitColumns();
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link variant='h1' classes={{
          root: classes.link
        }}
          to='/competitor-data'
        >
          Competitor Data
        </Link>
        <Typography color="textPrimary" variant='h1'>&ensp;Create Competitor Data</Typography>
      </Breadcrumbs>
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <SpreadsheetComponent
          id='spreadsheet'
          dataBound={dataBound}
          scrollSettings={scrollSettings}
          ref={(ssObj) => { spreadsheet = ssObj; }}
          // showSheetTabs={false}
          enableVirtualization={true}
          showRibbon={false}
          // allowFormulaBar={false}
          showPager={false}
          isFinite={true}
          // enableContextMenu={false}
          cellEdit={onCellEdit}>
          <SheetsDirective>
            <SheetDirective
              name="Gross Salary"
              colCount={options.columns && options.columns?.length}
            >
              <RowsDirective>
                <RowDirective height={35} customHeight={true}>
                  <CellsDirective>
                    <CellDirective value={'Create Competitor Data'} colSpan={16} style={rowStyle}></CellDirective>
                  </CellsDirective>
                </RowDirective>
                <RowDirective >
                  <CellsDirective >
                    {options.columns.map((item, index) => {
                      return (
                        <CellDirective
                          index={index}
                          value={item.title}

                        />
                      )
                    })}

                  </CellsDirective>
                </RowDirective>
              </RowsDirective>

            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>

      </div>
      <Fab variant="extended" color="primary" onClick={handleSubmitExcel} style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      }}>
        Create
      </Fab>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitExcel: (data) => dispatch(submitExcel(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpreadsheetCreator);
