import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    TableRow,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions,
    Checkbox,
    Chip,
    Input,
    MenuItem,
    Select,
    ListItemText,
    TextField
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { getAllForecastLevelDropDwon, createForecastLevel } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { runAnamoly, getAnamolyasList } from '../../redux/actions/AnamolyConfig/AuthAction';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey,
        paddingTop: 0,
        paddingBottom: 11
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 15,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formDiv: {
        fontSize: 15
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const AnamolyRun = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [anamolyName, setAnamolyName] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);


    const handleAnamolyName = (e) => {
        setAnamolyName(e.target.value);
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,
    }
    const [listOpen, setListOpen] = React.useState('');
    const [type, setType] = React.useState('');

    const [newArray, setNewArray] = React.useState([]);



    useEffect(() => {
        props.getAnamolyLevelData();
    }, [])
    useEffect(() => {
        if (props.queryListData && props.queryListData.length > 0) {
            props.queryListData.map(item => {
                newArray.push(item.KeyValueAttrs['anamoly_name'] + -+item.ID);
            })
        }
        setNewArray(newArray)
    }, [])
    function handleOnRun() {
        var [name, id] = anamolyName.split('-')
        var data = {
            // "anamoly_name":anamolyName.trim(),
            "start_date": Moment(startDate).format('MM-DD-YYYY'),
            "end_date": Moment(endDate).format('MM-DD-YYYY'),

        }
        props.onRun(data, parseInt(id));
    }
    function handleCancelDialog() {
        history.push('/anomaly-config');
    }
    function humanize(str) {
        var i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    return (
        <div className={classes.root}>

            <Typography color="Primary" variant='h1'>Run Anomaly</Typography>


            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >

                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel} required >
                                        Anomaly Name
                                    </FormLabel>

                                    <Select
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        value={anamolyName}
                                        onChange={e => handleAnamolyName(e)}
                                    >
                                        {newArray?.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}

                                            </MenuItem>
                                        ))}
                                    </Select>

                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel} required >
                                            Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 37,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            // marginTop: 10
                                                        }
                                                    }}
                                                    value={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel classes={{ root: classes.fontSetting }} className={classes.formLabel} required >
                                            End Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '0px 11px',
                                                            height: 37,
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            // marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={startDate ? startDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnRun}
                        disabled={anamolyName.length && startDate && endDate > 0
                            ? false : true}
                    >
                        Run
                    </Button>
                </div>
            </div>



        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onRun: (data, id) => dispatch(runAnamoly(data, id)),
        getAnamolyLevelData: (pagination, limit, ID) => dispatch(getAnamolyasList(pagination, limit, ID))
    }
}
const mapStateToProps = state => {
    return {
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        tableData: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        queryListData: state.AnamolyData.anamolyListData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AnamolyRun);