import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        // marginTop: 10,
        borderRadius: 10,
        padding: '5px 5px 5px 5px'
    }
}));
const GPORosterAddressSearchResult = props => {
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const [dataRows, setDataRows] = React.useState([]);
    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.records && props.configData.records.length > 0)
            props.configData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        GPO_name: e.GPO_name,
                        GPO_member_number: e.GPO_member_number,
                        GPO_member_name: e.GPO_member_name,
                        direct_parent_member_number: e.direct_parent_member_number,
                        direct_parent_member_name: e.direct_parent_member_name,
                        top_parent_member_number: e.top_parent_member_number,
                        top_parent_member_name: e.top_parent_member_name,
                        standard_address: e.standard_address,
                        ID_GLN: e.ID_GLN,
                        ID_HIN: e.ID_HIN

                    });
                })
        setDataRows(tempRows);
    }, [props.configData]);
    const columns = [
        {
            field: 'GPO_name',
            title: 'GPO Name',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover}> {rowData.GPO_name}</a>
        },
        {
            field: 'GPO_member_number',
            title: 'GPO Member Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.GPO_member_number}</a>
        },
        {
            field: 'GPO_member_name',
            title: 'GPO Member Name',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.GPO_member_name}</a>
        },
        {
            field: 'direct_parent_member_number',
            title: 'Direct Parent Member Number',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.direct_parent_member_number}</a>
        },
        {
            field: 'direct_parent_member_name',
            title: 'Direct Parent Member Mame',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.direct_parent_member_name}</a>
        },
        {
            field: 'top_parent_member_number',
            title: 'Top Parent Member Number',
            editable: 'never',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.top_parent_member_number}</a>
        },
        {
            field: 'top_parent_member_name',
            title: 'Top Parent Member Name',
            type: 'number',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.top_parent_member_name}</a>
        },
        {
            field: 'standard_address',
            title: 'Standard Address',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.standard_address}</a>
        },
        {
            field: 'ID_GLN',
            title: 'GLN ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.ID_GLN}</a>
        },
        {
            field: 'ID_HIN',
            title: 'HIN ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.ID_HIN}</a>
        },
    ];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 22 }}>GPO Address Search Results</div>

                </div>
                {props.configData && props.configData.records && props.configData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    /> :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        configData: state.customerData.gpoAddressSearchData,
    }
};
export default connect(mapStateToProps, null)(GPORosterAddressSearchResult);