import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader, IconButton, FormLabel, Select,
    MenuItem, Button, Typography, Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody, TextField,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { AllOtherAttributeMasterComponent, TextInputWithLabel } from '../../../../components/Inputs';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { getDefaultValuesSupplier } from '../../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    //new styles
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const GroupingIdentifers = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        props.onLoadingDefault()
    }, []);
    const [supplierGroup, setSupplierGroup] = React.useState('');
    const [supplierGroup1, setSupplierGroup1] = React.useState('');
    const [supplierGroup2, setSupplierGroup2] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [FlexAttribute2, setFlexAttribute2] = React.useState('');
    const [supplierIDTypeArray, setSupplierIDTypeArray] = React.useState([]);
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'supplier_id_type' && item.type === 'DROPDOWN.STRING') {
                    setSupplierIDTypeArray(item);
                }
            })
        }
    });
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    const [identifiersStore, setIdentifiersStore] = React.useState([]);
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    //options
    const [customerIdType, setCustomerIdType] = React.useState('');
    const [customerIdNumber, setCustomerIdNumber] = React.useState('');
    const [finalCustomerIDType, setFinalCustomerIDType] = React.useState([]);
    const [finalCustomerIDNumber, setFinalCustomerIDNumber] = React.useState([]);
    function handleCustomerIDType(newValue) {
        if (newValue && newValue.length > 0) {
            setCustomerIdType(newValue);
        } else setCustomerIdType("");
        props.onChange(formData);
    }
    function handleCustomerIDNumber(event) {
        setCustomerIdNumber(event);
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        handleCustomerIDType(item.customer_id_type);
        handleCustomerIDNumber(item.customer_id_number);
    }
    useEffect(() => {
        var tempIDType = [];
        var tempIDNumber = [];
        for (var i = 0; i < identifiersStore.length; i++) {
            tempIDType.push(identifiersStore[i].customer_id_type)
            tempIDNumber.push(identifiersStore[i].customer_id_number)
        }
        setFinalCustomerIDType(tempIDType)
        setFinalCustomerIDNumber(tempIDNumber)
    }, [identifiersStore]);
    function handleSubmit() {
        var data = {
            "customer_id_type": customerIdType,
            "customer_id_number": customerIdNumber,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...identifiersStore];
            editedArray[editIndex] = data;
            setIdentifiersStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setIdentifiersStore([...identifiersStore, data]);
            props.onChange([...identifiersStore, data])
        }
        setCustomerIdType('');
        setCustomerIdNumber('');
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setCustomerIdType('');
        setCustomerIdNumber('');
    }
    const handleDeleteLocally = (index) => {
        setIdentifiersStore(item => item.filter((item, i) => i !== index));
        props.onChange(identifiersStore.filter((item, i) => i !== index))
    }
    useEffect(() => {
        props.onChange(formData);
    });
    var formData = {
        supplier_id_type: finalCustomerIDType,
        supplier_id_number: finalCustomerIDNumber,
        supplier_group: supplierGroup,
        supplier_group_1: supplierGroup1,
        supplier_group_2: supplierGroup2,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        // flex_attribute_1: flexAttribute1,
        // flex_attribute_2: FlexAttribute2
    }

    // function handleFlexAttribute1(newValue) {
    //     setFlexAttribute1(newValue);
    // }
    // function handleFlexAttribute2(newValue) {
    //     setFlexAttribute2(newValue);
    // }
    function handleSupplierGroup1(newValue) {
        setSupplierGroup1(newValue.target.value);
    }
    function handleSupplierGroup(newValue) {
        setSupplierGroup(newValue.target.value);
    }
    function handleSupplierGroup2(newValue) {
        setSupplierGroup2(newValue.target.value);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue.target.value);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue.target.value);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue.target.value);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue.target.value);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue.target.value);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue.target.value);
    }

    return (
        <div>
            <div
                className={classes.root}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <CardHeader
                                title="GROUPINGS"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <Grid container spacing={2}
                                style={{ marginBottom: 16 }}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group'] ? fieldLabel['supplier_group']['mandatory'] : false} heading={fieldLabel['supplier_group'] ? fieldLabel['supplier_group']['current'] : 'Supplier Group'} twoline='true' onChange={handleSupplierGroup} /> */}
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group'] ? fieldLabel['supplier_group']['mandatory'] : false} style={{ marginTop: '10px' }} >
                                        {fieldLabel['supplier_group'] ? fieldLabel['supplier_group']['current'] : 'Supplier Group'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={supplierGroup}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleSupplierGroup} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group1'] ? fieldLabel['supplier_group1']['mandatory'] : false} heading={fieldLabel['supplier_group1'] ? fieldLabel['supplier_group1']['current'] : 'Supplier Group 1'} twoline='true' onChange={handleSupplierGroup1} /> */}
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group1'] ? fieldLabel['supplier_group1']['mandatory'] : false} style={{ marginTop: '6px' }}>
                                        {fieldLabel['supplier_group1'] ? fieldLabel['supplier_group1']['current'] : 'Supplier Group1'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={supplierGroup1}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleSupplierGroup1} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group2'] ? fieldLabel['supplier_group2']['mandatory'] : false} heading={fieldLabel['supplier_group2'] ? fieldLabel['supplier_group2']['current'] : 'Supplier Group 2'} twoline='true' onChange={handleSupplierGroup2} /> */}
                                    <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['supplier_group2'] ? fieldLabel['supplier_group2']['mandatory'] : false} style={{ marginTop: '6px' }}>
                                        {fieldLabel['supplier_group2'] ? fieldLabel['supplier_group2']['current'] : 'Supplier Group2'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={supplierGroup2}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleSupplierGroup2} />
                                </Grid>
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.formLabel }} heading={'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.formLabel }} heading={'Flex Attribute 2'} twoline='true' onChange={handleFlexAttribute2} />
                                </Grid> */}
                            </Grid>

                        </div>
                    </form>
                </Card>
            </div >
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false} heading={fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'ID Number 1'} twoline='true' onChange={handleIdNumber1} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'Id Number01'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber1}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber1} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false} heading={fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'ID Number 2'} twoline='true' onChange={handleIdNumber2} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'Id Number02'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber2}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber2} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false} heading={fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'ID Number 3'} twoline='true' onChange={handleIdNumber3} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'Id Number03'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber3}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber3} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false} heading={fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'ID Number 4'} twoline='true' onChange={handleIdNumber4} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'Id Number04'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber4}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber4} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false} heading={fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'ID Number 5'} twoline='true' onChange={handleIdNumber5} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'Id Number05'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber5}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber5} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    >
                                        {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false} heading={fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'ID Number 6'} twoline='true' onChange={handleIdNumber6} /> */}
                                        <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false} style={{ marginTop: 7 }}>
                                            {fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'Id Number06'}
                                        </FormLabel>
                                        <OutlinedInput
                                            value={idNumber6}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIdNumber6} />
                                    </Grid>

                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <AllOtherAttributeMasterComponent
                                            classes={{ root: classes.fontSetting }}
                                            attributeName="supplier_id_type"
                                            value={customerIdType}
                                            onChange={handleCustomerIDType}
                                            prevalue={customerIdType}
                                        /> */}
                                    {/* <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                    Supplier ID Type
                                        </FormLabel>
                                         <Select
                                        value={customerIdType}
                                        onChange={(e)=>handleCustomerIDType(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                       {supplierIDTypeArray.drop_down_values && supplierIDTypeArray.drop_down_values.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </div> */}
                                    {/* </Grid> */}
                                    {/* <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "0.3rem" }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}>
                                                Supplier Identifier Number
                                            </FormLabel>

                                            <TextField
                                                value={customerIdNumber}
                                                onChange={(e) => handleCustomerIDNumber(e.target.value)}
                                                className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid> */}
                                    {/* <Grid item md={1} classes={{ root: classes.centerButton }}>
                                        <Grid container style={{ paddingTop: 26 }} >
                                            <Grid item md={6} >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={customerIdType && customerIdNumber ? false : true}
                                                        onClick={() => handleSubmit()}
                                                    >
                                                    </Button>
                                                }
                                            </Grid>
                                            <Grid item
                                                md={6}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={handleRemove}
                                                >
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </div>
                        </form>

                        {/* <div className={classes.container2}>
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {identifiersStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Supplier ID Type</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Supplier ID number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {identifiersStore.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.customer_id_type}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.customer_id_number}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                                    <IconButton classes={{ root: classes.IconButton }} onClick={() => handleEditRow(item, index)}>
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleDeleteLocally(index)} classes={{ root: classes.IconButton }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div> */}

                    </Card>
                </div >
            </div>
        </div>
    );

};
const mapStateToProps = state => ({
    dropdownData: state.customerData.dropdownDataSupplier
});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesSupplier())
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GroupingIdentifers);
