import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Card,
  FormLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Input,
  OutlinedInput,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  getDefaultValues,
  createAggregationLevel,
  getAggregationLevelById,
  loadValuesBasedOnAppTypeAndField,
  allowedApps,
  getLblDispDesFieldValue
} from "../../redux/actions";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory, Link } from "react-router-dom";
var editableId;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: 'none',
    border: 0,
    fontSize: 16,
    paddingRight: 10
  },
  container: {
    padding: 10,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  formDiv: {
    fontSize: theme.typography.h3.fontSize
  },
  dropdownContainer: {
    maxHeight: 50,
    overflowY: "scroll",
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ddd",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
}));

const EditCalculationAnalysis = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentId, setCurrentId] = React.useState("");
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] =
    React.useState([]);
  const [applicationType, setApplicationType] = React.useState("");
  const [sourceDataType, setSourceDataType] = React.useState("");
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [secondLevel, setSecondLevel] = React.useState([]);
  const [thirdLevel, setThirdLevel] = React.useState([]);
  const [fourthLevel, setFourthLevel] = React.useState([]);
  const [fifthLevel, setFifthLevel] = React.useState([]);
  const [secondLevelName, setSecondLevelName] = React.useState("");
  const [firstLevelName, setFirstLevelName] = React.useState("");
  const [thirdLevelName, setThirdLevelName] = React.useState("");
  const [fourthLevelName, setFourthLevelName] = React.useState("");
  const [fifthLevelName, setFifthLevelName] = React.useState("");
  const [contractType, setContractType] = React.useState([]);
  const [contractTypeArray, setContractTypeArray] = React.useState([]);
  const [formFields, setFormFields] = React.useState({});

  const [open2, setOpen2] = React.useState("");
  const [selected2, setSelected2] = React.useState([]);
  const [open3, setOpen3] = React.useState("");
  const [selected3, setSelected3] = React.useState([]);
  const [open4, setOpen4] = React.useState("");
  const [selected4, setSelected4] = React.useState([]);
  const [open5, setOpen5] = React.useState("");
  const [selected5, setSelected5] = React.useState([]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
  };
  useEffect(() => {
    if (props.newdropdownData && props.newdropdownData.field_label_attributes && Object.entries(props.newdropdownData.field_label_attributes).length > 0) {
      const tempObject = Object.entries(props.newdropdownData.field_label_attributes).
        filter(([k, v]) => v.display)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      setFormFields(tempObject)
    }
  }, [props.newdropdownData && props.newdropdownData.field_label_attributes])
  useEffect(() => {
    if (applicationType) {

      var data = {
        "data_source": "contract_header",
        "application_type": applicationType
      }
      props.fieldDesigner(data, 'post');
    }
  }, [applicationType])

  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
      setApplicationType(props.allowedAppsData[0])
      props.onLoadingDefaultContract(props.allowedAppsData[0])
      var data = {
        "data_source": "contract_header",
        "application_type": props.allowedAppsData[0]
      }
      props.fieldDesigner(data, 'post');
    }
  }, [props.allowedAppsData])

  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);
  useEffect(() => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
    editableId = appId;
    if (appId) props.getAggregationLevelById(appId);
    props.allowedApps();
  }, []);
  useEffect(() => {
    if (
      props.dropdownData &&
      props.dropdownData.records &&
      props.dropdownData.records.length > 0
    ) {
      props.dropdownData.records.map((item) => {
        if (
          item.field_id === "contract_type" &&
          item.app_source_type === applicationType
        ) {
          setContractTypeArray(item);
        }
      });
    }
  }, [props.dropdownData]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);
  useEffect(() => {
    if (props.aggregationLevelSingleData) {
      props.loadSourceDataType(
        props.aggregationLevelSingleData.application_type,
        "source_data_type"
      );
      props.onLoadingDefaultContract(
        props.aggregationLevelSingleData.application_type
      );
      setCurrentId(props.aggregationLevelSingleData.id);
      setApplicationType(props.aggregationLevelSingleData.application_type);
      setSourceDataType(props.aggregationLevelSingleData.data_source_type);
      setContractType(props.aggregationLevelSingleData.contract_type);
      setSecondLevelName(props.aggregationLevelSingleData.second_level_name);
      setFirstLevelName(props.aggregationLevelSingleData.first_level_name);
      setThirdLevelName(props.aggregationLevelSingleData.third_level_name);
      setFourthLevelName(props.aggregationLevelSingleData.fourth_level_name);
      setFifthLevelName(props.aggregationLevelSingleData.fifth_level_name);
      setSecondLevel(
        props.aggregationLevelSingleData.second_level
          ? props.aggregationLevelSingleData.second_level
          : []
      );
      setSelected2(props.aggregationLevelSingleData.second_level);
      setThirdLevel(
        props.aggregationLevelSingleData.third_level
          ? props.aggregationLevelSingleData.third_level
          : []
      );
      setSelected3(props.aggregationLevelSingleData.third_level);
      setFourthLevel(
        props.aggregationLevelSingleData.fourth_level
          ? props.aggregationLevelSingleData.fourth_level
          : []
      );
      setSelected4(props.aggregationLevelSingleData.fourth_level);
      setFifthLevel(
        props.aggregationLevelSingleData.fifth_level
          ? props.aggregationLevelSingleData.fifth_level
          : []
      );
      setSelected5(props.aggregationLevelSingleData.fifth_level);
    }
  }, [props.aggregationLevelSingleData]);
  const AggregationLevelArray = [
    "customer_number",
    "customer_group",
    "customer_chain",
    "flex_attribute1",
    "material_number",
    "material_group",
    "product_hierarchy",
    "profit_center",
    "plant",
    "supplier_number",
    "parent_customer_number",
    "top_parent_customer_number",
    "employee_number",
    "territory_id",
  ];
  const AggregationLevelArray2 = ["sold_to_party", "bill_to_party", "payer"];
  const [isAllSelected2, setIsAllSelected2] = React.useState(false)
  const [isAllSelected3, setIsAllSelected3] = React.useState(false)
  const [isAllSelected4, setIsAllSelected4] = React.useState(false)
  const [isAllSelected5, setIsAllSelected5] = React.useState(false)
  // const isAllSelected2 =
  //   AggregationLevelArray &&
  //   AggregationLevelArray.length > 0 &&
  //   (sourceDataType && sourceDataType == "Direct - Sales Data"
  //     ? selected2 &&
  //     selected2.length === AggregationLevelArray &&
  //     AggregationLevelArray.length + AggregationLevelArray2 &&
  //     AggregationLevelArray2.length
  //     : selected2 &&
  //     selected2.length === AggregationLevelArray &&
  //     AggregationLevelArray.length);
  function handleOpen2() {
    setSelected2(secondLevel);
    setOpen2(true);
  }
  function handleOpen3() {
    setSelected3(thirdLevel);
    setOpen3(true);
  }
  function handleOpen4() {
    setSelected4(fourthLevel);
    setOpen4(true);
  }
  function handleOpen5() {
    setSelected5(fifthLevel);
    setOpen5(true);
  }
  const handleSelectValues2 = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected2.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected2, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected = newSelected.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1)
      );
    }
    if (value === "all") {
      let newArray = AggregationLevelArray.concat(AggregationLevelArray2)
      if (sourceDataType == "Direct - Sales Data") {
        setIsAllSelected2(selected2.length === newArray.length ? false : true)
        setSelected2(
          selected2.length ===
            AggregationLevelArray.length + AggregationLevelArray2.length
            ? []
            : newArray //AggregationLevelArray + AggregationLevelArray2
        );
      }
      else {
        setIsAllSelected2(selected2.length === AggregationLevelArray.length ? false : true)
        setSelected2(selected2.length === AggregationLevelArray.length ? [] : AggregationLevelArray);
      }
    } else {
      setSelected2(newSelected);
      setIsAllSelected2(newSelected.length === AggregationLevelArray.length ? true : false)
    }
  };
  const handleSelectValues3 = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected3.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected3, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected3.slice(1));
    } else if (selectedIndex === selected3.length - 1) {
      newSelected = newSelected.concat(selected3.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected3.slice(0, selectedIndex),
        selected3.slice(selectedIndex + 1)
      );
    }
    if (value === "all") {
      let newArray = AggregationLevelArray.concat(AggregationLevelArray2)
      if (sourceDataType == "Direct - Sales Data") {
        setIsAllSelected3(selected3.length === newArray.length ? false : true)
        setSelected3(
          selected3.length ===
            AggregationLevelArray.length + AggregationLevelArray2.length
            ? []
            : newArray //AggregationLevelArray + AggregationLevelArray2
        );
      }
      else {
        setIsAllSelected3(selected3.length === AggregationLevelArray.length ? false : true)
        setSelected3(selected3.length === AggregationLevelArray.length ? [] : AggregationLevelArray);
      }
    } else {
      setSelected3(newSelected);
      setIsAllSelected3(newSelected.length === AggregationLevelArray.length ? true : false)
    }
  };
  const handleSelectValues4 = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected4.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected4, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected4.slice(1));
    } else if (selectedIndex === selected4.length - 1) {
      newSelected = newSelected.concat(selected4.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected4.slice(0, selectedIndex),
        selected4.slice(selectedIndex + 1)
      );
    }
    if (value === "all") {
      let newArray = AggregationLevelArray.concat(AggregationLevelArray2)
      if (sourceDataType == "Direct - Sales Data") {
        setIsAllSelected4(selected4.length === newArray.length ? false : true)
        setSelected4(
          selected4.length ===
            AggregationLevelArray.length + AggregationLevelArray2.length
            ? []
            : newArray //AggregationLevelArray + AggregationLevelArray2
        );
      }
      else {
        setIsAllSelected4(selected4.length === AggregationLevelArray.length ? false : true)
        setSelected4(selected4.length === AggregationLevelArray.length ? [] : AggregationLevelArray);
      }
    } else {
      setSelected4(newSelected);
      setIsAllSelected4(newSelected.length === AggregationLevelArray.length ? true : false)
    }
  };
  const handleSelectValues5 = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected5.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected5, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected5.slice(1));
    } else if (selectedIndex === selected5.length - 1) {
      newSelected = newSelected.concat(selected5.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected5.slice(0, selectedIndex),
        selected5.slice(selectedIndex + 1)
      );
    }
    if (value === "all") {
      let newArray = AggregationLevelArray.concat(AggregationLevelArray2)
      if (sourceDataType == "Direct - Sales Data") {
        setIsAllSelected5(selected5.length === newArray.length ? false : true)
        setSelected5(
          selected5.length === AggregationLevelArray.length + AggregationLevelArray2.length ? [] : newArray // AggregationLevelArray + AggregationLevelArray2
        );
      }
      else {
        setIsAllSelected5(selected5.length === AggregationLevelArray.length ? false : true)
        setSelected5(selected5.length === AggregationLevelArray.length ? [] : AggregationLevelArray
        );
      }
    } else {
      setSelected5(newSelected);
      setIsAllSelected5(newSelected.length === AggregationLevelArray.length ? true : false)
    }
  };
  function onAdd2() {
    setSecondLevel(selected2);
    setOpen2(false);
    // setSelected2([]);
  }
  function onCancel2() {
    setOpen2(false);
    setSelected2([]);
  }
  function onAdd3() {
    setThirdLevel(selected3);
    setOpen3(false);
    // setSelected3([]);
  }
  function onCancel3() {
    setOpen3(false);
    setSelected3([]);
  }
  function onAdd4() {
    setFourthLevel(selected4);
    setOpen4(false);
    // setSelected4([]);
  }
  function onCancel4() {
    setOpen4(false);
    setSelected4([]);
  }
  function onAdd5() {
    setFifthLevel(selected5);
    setOpen5(false);
    // setSelected5([]);
  }
  function onCancel5() {
    setOpen5(false);
    setSelected5([]);
  }
  function handleOnSubmit() {
    if (Array.isArray(contractTypeArray)) {
      setContractTypeArray([...contractTypeArray, contractType]);
    } else {
      setContractTypeArray([contractType]);
    }

    var data = {
      application_type: applicationType,
      data_source_type: sourceDataType,
      contract_type: contractType,
      second_level: secondLevel,
      third_level: thirdLevel,
      fourth_level: fourthLevel,
      fifth_level: fifthLevel,
      second_level_name: secondLevelName,
      first_level_name: firstLevelName,
      third_level_name: thirdLevelName,
      fourth_level_name: fourthLevelName,
      fifth_level_name: fifthLevelName,
    };
    props.onSubmit(data, "edit", currentId);
  }
  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
  };
  const handleApplicationType = (e) => {
    setApplicationType(e.target.value);
    props.loadSourceDataType(e.target.value, "source_data_type");
    props.onLoadingDefaultContract(e.target.value);
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          to="/calculation-analysis"
        >
          Calculation Analysis
        </Link>
        <Typography color="textPrimary" variant="h4">
          Edit Calculation Analysis
        </Typography>
      </Breadcrumbs>
      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Application Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={applicationType}
                        onChange={handleApplicationType}
                        displayEmpty
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {allowedApps.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Source Data Type
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={sourceDataType}
                        onChange={handleSourceDataType}
                        displayEmpty
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {sourceDataTypeDataFromAPI.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </Grid>
                {
                  formFields && formFields['contract_type'] &&
                  <Grid item md={3} xs={12}>
                    <div className={classes.selectRoot}>
                      <FormLabel className={classes.formDiv}>
                        Contract Type
                      </FormLabel>
                      <Select
                        value={contractType}
                        onChange={(e) => setContractType(e.target.value)}
                        displayEmpty
                        className={clsx({
                          [classes.select]: true,
                        })}
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                      >
                        {formFields['contract_type']['drop_down_values'] && formFields.contract_type.drop_down_values.length > 0 &&
                          formFields.contract_type.drop_down_values.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            }
                          )}
                      </Select>
                    </div>
                  </Grid>
                }

                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    First Level Name
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <OutlinedInput
                      value={firstLevelName}
                      style={{ width: "100%", textTransform: "capitalize", marginTop: 0, height: 36 }}
                      onChange={(e) => setFirstLevelName(e.target.value)}
                      placeholder={props.placeholder}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Second Level
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={secondLevel}
                      style={{ width: "100%", textTransform: "capitalize", height: 34 }}
                      input={<OutlinedInput />}
                      onOpen={() => handleOpen2()}
                      onClose={() => setOpen2(false)}
                      open={open2}
                      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                      renderValue={(appType) => {
                        var priceType = [];
                        appType.map((item) => {
                          priceType.push(item.replace(/_/g, " "));
                        });
                        return priceType.join(", ");
                      }}
                      className={classes.multiSelect}
                    >
                      <MenuItem
                        value="all"
                        onClick={(event) => handleSelectValues2(event, "all")}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox color="primary" checked={isAllSelected2} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {AggregationLevelArray &&
                        AggregationLevelArray.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues2(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected2?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      {sourceDataType &&
                        sourceDataType == "Direct - Sales Data" &&
                        AggregationLevelArray2 &&
                        AggregationLevelArray2.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues2(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected2?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      <div className={classes.dropdownAction}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.btn}
                          onClick={onCancel2}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={onAdd2}
                        >
                          Apply
                        </Button>
                      </div>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Second Level Name
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <OutlinedInput
                      value={secondLevelName}
                      style={{ width: "100%", textTransform: "capitalize" }}
                      onChange={(e) => setSecondLevelName(e.target.value)}
                      placeholder={props.placeholder}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Third Level
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={thirdLevel}
                      style={{ width: "100%", textTransform: "capitalize", height: 34 }}
                      input={<OutlinedInput />}
                      onOpen={() => handleOpen3()}
                      onClose={() => setOpen3(false)}
                      open={open3}
                      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                      renderValue={(appType) => {
                        var priceType = [];
                        appType.map((item) => {
                          priceType.push(item.replace(/_/g, " "));
                        });
                        return priceType.join(", ");
                      }}
                      className={classes.multiSelect}
                    >
                      <MenuItem
                        value="all"
                        onClick={(event) => handleSelectValues3(event, "all")}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox color="primary" checked={isAllSelected3} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {AggregationLevelArray &&
                        AggregationLevelArray.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues3(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected3?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      {sourceDataType &&
                        sourceDataType == "Direct - Sales Data" &&
                        AggregationLevelArray2 &&
                        AggregationLevelArray2.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues3(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected3?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      <div className={classes.dropdownAction}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.btn}
                          onClick={onCancel3}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={onAdd3}
                        >
                          Apply
                        </Button>
                      </div>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Third Level Name
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <OutlinedInput
                      value={thirdLevelName}
                      style={{ width: "100%", textTransform: "capitalize", height: 34 }}
                      onChange={(e) => setThirdLevelName(e.target.value)}
                      placeholder={props.placeholder}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Fourth Level
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={fourthLevel}
                      style={{ width: "100%", textTransform: "capitalize", height: "34px" }}
                      input={<OutlinedInput />}
                      onOpen={() => handleOpen4()}
                      onClose={() => setOpen4(false)}
                      open={open4}
                      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                      renderValue={(appType) => {
                        var priceType = [];
                        appType.map((item) => {
                          priceType.push(item.replace(/_/g, " "));
                        });
                        return priceType.join(", ");
                      }}
                      className={classes.multiSelect}
                    >
                      <MenuItem
                        value="all"
                        onClick={(event) => handleSelectValues4(event, "all")}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox color="primary" checked={isAllSelected4} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {AggregationLevelArray &&
                        AggregationLevelArray.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues4(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected4?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      {sourceDataType &&
                        sourceDataType == "Direct - Sales Data" &&
                        AggregationLevelArray2 &&
                        AggregationLevelArray2.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues4(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected4?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      <div className={classes.dropdownAction}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.btn}
                          onClick={onCancel4}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={onAdd4}
                        >
                          Apply
                        </Button>
                      </div>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Fourth Level Name
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <OutlinedInput
                      value={fourthLevelName}
                      style={{ width: "100%", textTransform: "capitalize", height: 34 }}
                      onChange={(e) => setFourthLevelName(e.target.value)}
                      placeholder={props.placeholder}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Fifth Level
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={fifthLevel}
                      style={{ width: "100%", textTransform: "capitalize", height: "34px" }}
                      input={<OutlinedInput />}
                      onOpen={() => handleOpen5()}
                      onClose={() => setOpen5(false)}
                      open={open5}
                      MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' }, getContentAnchorEl: null, PaperProps: { style: { maxHeight: 300 } }, autoFocus: true }}
                      renderValue={(appType) => {
                        var priceType = [];
                        appType.map((item) => {
                          priceType.push(item.replace(/_/g, " "));
                        });
                        return priceType.join(", ");
                      }}
                      className={classes.multiSelect}
                    >
                      <MenuItem
                        value="all"
                        onClick={(event) => handleSelectValues5(event, "all")}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox color="primary" checked={isAllSelected5} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {AggregationLevelArray &&
                        AggregationLevelArray.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues5(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected5?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      {sourceDataType &&
                        sourceDataType == "Direct - Sales Data" &&
                        AggregationLevelArray2 &&
                        AggregationLevelArray2.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues5(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={
                                  selected5?.includes(item) ? true : false
                                }
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      <div className={classes.dropdownAction}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.btn}
                          onClick={onCancel5}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={onAdd5}
                        >
                          Apply
                        </Button>
                      </div>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} error={false}>
                    Fifth Level Name
                  </FormLabel>
                  <div className={classes.dropdownContainer}>
                    <OutlinedInput
                      value={fifthLevelName}
                      style={{ width: "100%", textTransform: "capitalize" }}
                      onChange={(e) => setFifthLevelName(e.target.value)}
                      placeholder={props.placeholder}
                    />
                  </div>
                </Grid>
              </Grid >
            </div >
          </form >
        </Card >
        <div className={classes.buttonRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
            disabled={applicationType && sourceDataType ? false : true}
          >
            {props.loadingAPI ? (
              <BeatLoader
                color={"rgb(54, 215, 183)"}
                loading={props.loadingAPI}
                size={6}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div >
    </div >
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    loadingAPI: state.customerData.loading,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
    aggregationLevelSingleData:
      state.calculationAnalysisData.aggregationLevelSingleData,
    newdropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, type, id) =>
      dispatch(createAggregationLevel(data, type, id)),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    allowedApps: () => dispatch(allowedApps()),
    onLoadingDefaultContract: (applicationType) =>
      dispatch(
        getDefaultValues(applicationType, null, null, null, "Contract Designer")
      ),
    getAggregationLevelById: (id) => dispatch(getAggregationLevelById(id)),
    fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCalculationAnalysis);
