import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import Moment from "moment";
import {
  loadClaimValueAndError,
  loadClaimCountCCBSCB,
} from "../../../../redux/actions";
import StackedColumn from "../../../Analytics/Charts/StackedColumn";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: 10,
  },
  formLabel: {
    fontSize: 13, //theme.typography.h4.fontSize,
    textTransform: "capitalize",
  },
  chartHead: {
    width: "100%",
    paddingLeft: 20,
    paddingTop: 10,
    fontSize: theme.typography.h2.fontSize,
    display: "flex",
    justifyContent: "center",
  },
}));
const ChargebackClaim = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [load, setLoad] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    Moment.utc().subtract(5, "months").startOf("month").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = React.useState(
    Moment.utc().subtract(0, "months").endOf("month").format("MM/DD/YYYY")
  );
  const [attribute, setAttribute] = React.useState("claim_value");
  const data = {
    minimum: Moment.utc()
      .subtract(5, "months")
      .startOf("month")
      .format("MM/DD/YYYY"),
    maximum: Moment.utc()
      .subtract(0, "months")
      .endOf("month")
      .format("MM/DD/YYYY"),
    valueType: "DateTime",
    intervalType: "Months",
    interval: 1,
    labelFormat: "MMM",
    title: "Months - " + new Date().getFullYear().toString(),
  };
  useEffect(() => {
    if (attribute == "claim_count") props.loadClaimCount(startDate, endDate);
    else props.loadClaimValueAndError(startDate, endDate);
  }, [attribute]);
  useEffect(() => {
    if (attribute == "claim_value") {
      if (
        props.claimValueAndErrorCB &&
        ((props.claimValueAndErrorCB.error &&
          props.claimValueAndErrorCB.error !== null &&
          props.claimValueAndErrorCB.error.length > 0) ||
          (props.claimValueAndErrorCB.no_error &&
            props.claimValueAndErrorCB.no_error != null &&
            props.claimValueAndErrorCB.no_error.length > 0))
      ) {
        var data = [
          {
            data:
              props.claimValueAndErrorCB && props.claimValueAndErrorCB.error
                ? props.claimValueAndErrorCB.error
                : [{claim_value:0, month: Moment.utc().subtract(0, "months").endOf("month")}],
            xName: "month",
            yName: "claim_value",
            legend: "Error",
          },
          {
            data:
              props.claimValueAndErrorCB && props.claimValueAndErrorCB.no_error
                ? props.claimValueAndErrorCB.no_error
                : [{claim_value:0, month: Moment.utc().subtract(0, "months").endOf("month")}],
            xName: "month",
            yName: "claim_value",
            legend: "No Error",
          },
        ];
        //setGraphData()
        setGraphData(data);
      } else {
        setGraphData({});
      }
      setLoad(false);
    }
  }, [props.claimValueAndErrorCB]);
  useEffect(() => {
    if (attribute == "claim_count") {
      if (
        props.claimCountCCBSCB &&
        ((props.claimCountCCBSCB.error &&
          props.claimCountCCBSCB.error !== null &&
          props.claimCountCCBSCB.error.length > 0) ||
          (props.claimCountCCBSCB.no_error &&
            props.claimCountCCBSCB.no_error !== null &&
            props.claimCountCCBSCB.no_error.length > 0))
      ) {
        var data = [
          {
            data:
              props.claimCountCCBSCB && props.claimCountCCBSCB.error
                ? props.claimCountCCBSCB.error
                : [{claim_value:0, month: Moment.utc().subtract(0, "months").endOf("month")}],
            xName: "month",
            yName: "claim_value",
            legend: "Error",
          },
          {
            data:
              props.claimCountCCBSCB && props.claimCountCCBSCB.no_error
                ? props.claimCountCCBSCB.no_error
                : [{claim_value:0, month: Moment.utc().subtract(0, "months").endOf("month")}],
            xName: "month",
            yName: "claim_value",
            legend: "No Error",
          },
        ];
        setGraphData(data);
      } else {
        setGraphData({});
      }
      setLoad(false);
    }
  }, [props.claimCountCCBSCB]);

  const handleAttribute = (e, value) => {
    setAttribute(value);
    setLoad(true);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.chartHead}>
        Claim Analytics
      </Typography>
      <div style={{ padding: "10px 0px" }}>
        <ToggleButtonGroup
          color="primary"
          value={attribute}
          exclusive
          onChange={handleAttribute}
          aria-label="Platform"
          size="small"
        >
          <ToggleButton
            className={classes.formLabel}
            size="small"
            value="claim_value"
            style={{ textTransform: "capitalize" }}
          >
            Claim Value
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            style={{ textTransform: "capitalize" }}
            value="claim_count"
          >
            Claim Lines
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div>
        <StackedColumn
          id="chargeback_chart"
          loading={load}
          data={graphData}
          xAxisData={data}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    incentiveData: state.initialData.incentiveData,
    claimValueAndErrorCB: state.initialData.claimValueAndErrorCB,
    claimCountCCBSCB: state.initialData.claimCountCCBSCB,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadClaimValueAndError: (sd, ed) =>
      dispatch(loadClaimValueAndError(sd, ed)),
    loadClaimCount: (sd, ed) => dispatch(loadClaimCountCCBSCB(sd, ed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargebackClaim);
