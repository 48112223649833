import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import Chart from "react-apexcharts";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10
    },
    chartContainer: {
        marginTop: 30,
        height: 250
    },
    formLabel: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: theme.typography.h1.fontSize,
        display: 'flex',
        justifyContent: 'center',

    },
}));

const PromotionAnalyticsCost = props => {
    const classes = useStyles();
    const [series, setSeries] = React.useState([]);
    const [options, setOptions] = React.useState({});
    useEffect(() => {
        var categories = [];
        var costAvg = [];
        var costExtraRebate = [];
        var costPromoFinance = [];
        var costRebate = [];
        var costSpiff = [];
        var costStdFinance = [];
        console.log('props.promoAnalyCost', props.promoAnalyCost)
        if (props.promoAnalyCost && props.promoAnalyCost.length > 0) {
            props.promoAnalyCost.map(item => {
                costAvg.push(item['cost_coverage'] ? item['cost_coverage'] : 0);
                costExtraRebate.push(item['cost_extra_rebate'] ? item['cost_extra_rebate'] : 0);
                costPromoFinance.push(item['cost_promo_finance'] ? item['cost_promo_finance'] : 0);
                costRebate.push(item['cost_rebate'] ? item['cost_rebate'] : 0);
                costSpiff.push(item['cost_spiff'] ? item['cost_spiff'] : 0);
                costStdFinance.push(item['cost_std_finance'] ? item['cost_std_finance'] : 0);
                categories.push(item['month'] ? item['month'] : 0);
            })

            setSeries([{
                name: 'Coverage',
                data: costAvg
            },
            {
                name: 'Extra Rebate',
                data: costExtraRebate
            },
            {
                name: 'Promo Finance',
                data: costPromoFinance
            },
            {
                name: 'Rebate',
                data: costRebate
            },
            {
                name: 'Spiff',
                data: costSpiff
            },
            {
                name: 'Standard Finance',
                data: costStdFinance
            }]);

            setOptions(
                {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    fill: {
                        opacity: 0.6
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: categories,
                        labels: {
                            formatter: function (v) {
                                return v ? v : false
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return val.toLocaleString()
                            }
                        }
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },

                }
            )
        }

    }, [props.promoAnalyCost])

    return (

        <div className={classes.root}>
            <Typography color="primary" className={classes.formLabel}> Monthly Trend by Promotion Type </Typography>
            <div className={classes.chartContainer}>
                {
                    series && series.length > 0 && options && Object.keys(options) && Object.keys(options).length > 0 &&
                    <Chart options={options} type="bar" series={series} height={250} />
                }

            </div>
        </div>
    );
};

export default PromotionAnalyticsCost;