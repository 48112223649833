import React from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { addContractXRefUser, getDefaultValuesCustomerXRef } from '../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    }
}));

const AddContractXREF = props => {
    const classes = useStyles();
    const [organizationId, setOrganizationId] = React.useState('');
    const [channelPartnerId, setChannelPartnerId] = React.useState('');
    const [channelPartnerContractId, setChannelPartnerContractId] = React.useState('');
    const [imaContractId, setImaContractId] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    function handleChannelPartnerId(event) {
        setChannelPartnerId(event.target.value);
    }
    function handleChannelPartnerContractId(event) {
        setChannelPartnerContractId(event.target.value);
    }
    function handleImaContractId(event) {
        setImaContractId(event.target.value);
    }
    function handleClear() {
        // startRef.current.reset();
        setOrganizationId('');
        setChannelPartnerId('');
        setChannelPartnerContractId('');
        setImaContractId('');
    }
    function handleOnSubmit() {
        var formData = {
            "channel_partner_id": channelPartnerId,
            "channel_partner_contract_id": channelPartnerContractId,
            "ima_contract_id": imaContractId,
        }
        props.onSubmit(formData);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/contract-xref'
                >
                    Contract XREF
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Contract XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Channel Partner Contract ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={channelPartnerContractId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleChannelPartnerContractId} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Contract ID
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaContractId}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleImaContractId} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={channelPartnerId && channelPartnerContractId
                            && imaContractId
                            ? false : true}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        loadingAPI: state.customerData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addContractXRefUser(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddContractXREF);