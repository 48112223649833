import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, OutlinedInput, FormLabel, MenuItem, Select
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getWorkFlowAnchors, addApprovalDesigner, getDefaultValues, getAllApprovalDesigner, getApprovalGroupData, allowedApps, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 19,
        height: 34
    },
    inputTwoLine: {
        marginTop: 18,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'

    },
    errorMsg: {
        color: '#f44336',
        fontSize: 12,
        paddingTop: 5
    }
}));

const AddApprovalDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [currencyArray, setCurrencyArray] = React.useState([]);
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    const [applicationTypeValue, setApplicationTypeValue] = React.useState('');
    const [functionality, setFunctionality] = React.useState('');
    const [primaryAnchor, setPrimaryAnchor] = React.useState('');
    const [primaryAnchorValue, setPrimaryAnchorValue] = React.useState('');
    const [secondaryAnchor, setSecondaryAnchor] = React.useState('');
    const [secondaryAnchorValue, setSecondaryAnchorValue] = React.useState('');
    const [stepNumber, setStepNumber] = React.useState('');
    const [stepDescription, setStepDescription] = React.useState('');
    const [incentiveThresholdType, setIncentiveThresholdType] = React.useState('');
    const [incentiveAmount, setIncentiveAmount] = React.useState([]);
    const [currency, setCurrency] = React.useState('');
    const [notificationGroup, setNotificationGroup] = React.useState('');
    const [externalApproval, setExternalApproval] = React.useState('');
    const [negativeThresholdValue, setNegativeThresholdValue] = React.useState('');
    const [workflowAnchors, setWorkflowAnchors] = React.useState([]);
    const [primaryAnchorArr, setPrimaryAnchorArr] = React.useState([]);
    const [secondaryAnchorArr, setSecondaryAnchorArr] = React.useState([]);
    const [incentiveThresholdTypeArr, setTncentiveThresholdTypeArr] = React.useState([]);
    const [positiveValid, setPositiveValid] = React.useState(false);
    const [positiveErrorMessage, setPositiveErrorMessage] = React.useState('');
    const [stepValid, setStepValid] = React.useState(false);
    const [stepErrorMessage, setStepErrorMessage] = React.useState('');
    const [negativeErrorMessage, setNegativeErrorMessage] = React.useState('');
    const [dynamicField, setDynamicField] = React.useState([]);
    const [dynamicField2, setDynamicField2] = React.useState([]);
    const [flag, setFlag] = React.useState(false)
    const [flag2, setFlag2] = React.useState(false)
    useEffect(() => {
        props.getAllApprovalDesigner(1, 130);
        props.getApprovalGroupData();
        props.getWorkFlowAnchors();
    }, []);
    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationTypeValue(props.allowedAppsData[0])
            props.onLoadingDefault(props.allowedAppsData[0])
            var data = {
                "data_source": "contract_header",
                "application_type": props.allowedAppsData[0]
            }
            props.fieldDesigner(data, 'post');
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records && props.approvalGroupData.records.length > 0) {
            setApprovalGroupData(props.approvalGroupData)
        }
        else {
            let data = JSON.parse(sessionStorage.getItem('approvalGroupDropdonw'))
            setApprovalGroupData(data)
        }
    }, [props.approvalGroupData]);
    const handleApplicationtype = (e) => {
        setApplicationTypeValue(e.target.value);
        props.onLoadingDefault(e.target.value);
    }
    useEffect(() => {
        if (props.dropdownData2?.records?.length) {
            const currencyItem = props.dropdownData2.records.find(item => item.field_id === 'currency');
            if (currencyItem) {
                setCurrencyArray(currencyItem);
            }
        }
        else {
            const currencyItems = JSON.parse(sessionStorage.getItem('currencyDropdown'))
            const currencyItemFromSession = currencyItems?.records?.find(item => item.field_id === 'currency');
            setCurrencyArray(currencyItemFromSession);
        }
    }, [props.dropdownData2]);
    function handleClear() {
        setApplicationTypeValue('')
        setFunctionality('')
        setPrimaryAnchor('')
        setSecondaryAnchor('')
        setPrimaryAnchorValue('')
        setSecondaryAnchorValue('')
        setStepNumber('')
        setStepDescription('')
        setIncentiveThresholdType('')
        setIncentiveAmount([])
        setCurrency([])
        setNotificationGroup('')
        setExternalApproval('')
        setNegativeThresholdValue('')
        setApprovalGroup('')
        setFlag(false)
        setFlag2(false)
    }
    function handleOnSubmit() {
        var data = {
            "application_type": applicationTypeValue,
            "functionality": functionality,
            "primary_anchor": primaryAnchor,
            "primary_anchor_value": primaryAnchorValue,
            "secondary_anchor": secondaryAnchor,
            "secondary_anchor_value": secondaryAnchorValue,
            "step_number": parseInt(stepNumber),
            "step_description": stepDescription,
            "incentive_threshold_type": incentiveThresholdType,
            "incentive_amount": parseFloat(incentiveAmount) > 0 ? parseFloat(incentiveAmount) : 0,
            "negative_threshold_value": parseFloat(negativeThresholdValue) < 0 ? parseFloat(negativeThresholdValue) : 0,
            "currency": currency,
            "approval_group": parseInt(approvalGroup),
            "notification_group": notificationGroup,
            "external_approval": externalApproval
        };
        for (var propName in data) {
            if (data[propName] === '' || data[propName] === null ||
                data[propName] === undefined ||
                data[propName].length === 0 || !data[propName]
            ) {
                delete data[propName];
            }
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }
    useEffect(() => {
        if (props.workflowAnchors && props.workflowAnchors.length > 0) {
            const changesFiltered = props.workflowAnchors.filter(item => {
                return item.functionality !== "contract_header" && item.functionality !== "document_header";
            })
            setWorkflowAnchors(changesFiltered);
        }
        else {
            let data = JSON.parse(sessionStorage.getItem('functionalityDropdown'))
            if (data && data.length > 0) {
                const changesFiltered = data.filter(item => {
                    return item.functionality !== "contract_header" && item.functionality !== "document_header";
                })
                setWorkflowAnchors(changesFiltered)
            }
        }
    }, [props.workflowAnchors])
    useEffect(() => {
        primaryAnchorArr && Object.values(primaryAnchorArr)
            .map(item => {
                if (item.key == primaryAnchor)
                    setDynamicField(item)
            })
    }, [dynamicField, primaryAnchor])
    useEffect(() => {
        secondaryAnchorArr && Object.values(secondaryAnchorArr)
            .map(item => {
                if (item.key == secondaryAnchor)
                    setDynamicField2(item)
            })
    }, [dynamicField2, secondaryAnchor])
    useEffect(() => {
        var newArray = []
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.values(props.dropdownData.field_label_attributes).length > 0) {
            Object.values(props.dropdownData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    // if (item.mandatory || item.sort_details) {

                    if (item.key == 'contract_type' || item.key == 'posting_type' || item.key == 'contract_group' || item.key == 'contract_sub_group' || item.key == 'calculation_method') {
                        newArray.push(item)
                    }
                })
            newArray.push({
                current: "Posting Type",
                data_type: "string",
                default: "Posting Type",
                display: true,
                drop_down_reference: "",
                drop_down_reference_value: [],
                drop_down_table: "",
                ignore_dropdown: false,
                ignore_fields: false,
                key: "posting_type",
                mandatory: false,
                required: false,
                type: "STRING",
            })
            setPrimaryAnchorArr(newArray.sort((a, b) => {
                return a.sort_details - b.sort_details;
            }))
            setSecondaryAnchorArr(newArray.sort((a, b) => {
                return a.sort_details - b.sort_details;
            }))
        }
    }, [props.dropdownData]);
    const handleFunctionality = (e) => {
        setFunctionality(e.target.value);
        workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.filter(function (v) {
            if (v.functionality === e.target.value) {
                // setPrimaryAnchorArr(v.primary_anchors)
                // setSecondaryAnchorArr(v.secondary_anchors)
                setTncentiveThresholdTypeArr(v.incentive_threshold_type)
            }
        })
    }
    const handlePrimaryAnchor = (e) => {
        setPrimaryAnchor(e.target.value);
        setFlag(true)
        setPrimaryAnchorValue('')
    }
    const handleSecondaryAnchor = (e) => {
        setSecondaryAnchor(e.target.value);
        setFlag2(true)
        setSecondaryAnchorValue('')
    }
    const handleIncentiveAmount = (e) => {
        if (e.target.value) {
            if (e.target.value <= 0) {
                setIncentiveAmount(e.target.value)
                setPositiveValid(true)
                setPositiveErrorMessage('Value should be positive')
            }
            else {
                setPositiveValid(false)
                setPositiveErrorMessage('')
                setIncentiveAmount(e.target.value)
            }
        }
        else {
            setPositiveValid(true)
            setPositiveErrorMessage('')
            setIncentiveAmount(e.target.value)
        }
    }
    const handleNegativeThresholdValue = (e) => {
        if (e.target.value && e.target.value >= 0) {
            setNegativeThresholdValue(e.target.value)
            setNegativeErrorMessage('Value should be negative')
        }
        else {
            setNegativeThresholdValue(e.target.value)
            setNegativeErrorMessage('')
        }
    }
    const handleStepNumber = (e) => {
        if (e.target.value) {
            if (e.target.value <= 0) {
                setStepNumber(parseInt(e.target.value))
                setStepValid(true)
                setStepErrorMessage('Value should be positive')
            }
            else {
                setStepValid(false)
                setStepErrorMessage('')
                setStepNumber(parseInt(e.target.value))
            }
        }
        else {
            setStepValid(true)
            setStepErrorMessage('')
            setStepNumber(e.target.value)
        }
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/approval-limits'
                    >
                        Approval Levels
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Add Approval</Typography>
                </Breadcrumbs>
                <div className={classes.bodyContainer}>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Application Type
                                    </FormLabel>
                                    <Select
                                        value={applicationTypeValue}
                                        onChange={handleApplicationtype}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ textTransform: 'capitalize', height: 36, marginTop: 27 }}
                                    >
                                        {allowedApps.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Functionality
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={functionality}
                                            onChange={handleFunctionality}
                                            style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.map(item => {
                                                return (
                                                    <MenuItem value={item['functionality']} key={item['functionality']} style={{ textTransform: 'capitalize' }}>
                                                        {item['functionality'].replace(/_/g, ' ')}
                                                    </MenuItem>
                                                );
                                            })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Primary Anchor
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={primaryAnchor}
                                            onChange={handlePrimaryAnchor}
                                            style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                            disabled={!functionality ? true : false}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {primaryAnchorArr && primaryAnchorArr.length > 0 &&
                                                Object.values(primaryAnchorArr)
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.key} key={index}>
                                                                {item.current}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                {dynamicField &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        style={{ display: flag ? 'block' : 'none' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}
                                                required={primaryAnchor ? true : false}
                                            >
                                                {dynamicField.current}
                                            </FormLabel>
                                            {dynamicField.drop_down_reference_value && dynamicField.drop_down_reference_value.length > 0 ?
                                                <Select
                                                    value={primaryAnchorValue}
                                                    onChange={(e) => setPrimaryAnchorValue(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    {dynamicField.drop_down_reference_value.map((dynamicField, index) => {
                                                        return (
                                                            <MenuItem value={dynamicField.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                {dynamicField.desc}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                :
                                                <OutlinedInput
                                                    value={primaryAnchorValue}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setPrimaryAnchorValue(e.target.value)}
                                                    style={{ height: 36, marginTop: 18 }} />
                                            }
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Secondary Anchor
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                            value={secondaryAnchor}
                                            // onChange={(e) => setSecondaryAnchor(e.target.value)}
                                            onChange={handleSecondaryAnchor}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                            disabled={!functionality ? true : false}

                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {secondaryAnchorArr && secondaryAnchorArr.length > 0 &&
                                                Object.values(secondaryAnchorArr)
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.key} key={index}>
                                                                {item.current}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                {dynamicField2 &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        style={{ display: flag2 ? 'block' : 'none' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel}
                                                required={secondaryAnchor ? true : false}
                                            >
                                                {dynamicField2.current}
                                            </FormLabel>
                                            {dynamicField2.drop_down_reference_value && dynamicField2.drop_down_reference_value.length > 0 ?
                                                <Select
                                                    value={secondaryAnchorValue}
                                                    onChange={(e) => setSecondaryAnchorValue(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    {dynamicField2.drop_down_reference_value.map((dynamicField2, index) => {
                                                        return (
                                                            <MenuItem value={dynamicField2.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                {dynamicField2.desc}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                :
                                                <OutlinedInput
                                                    value={secondaryAnchorValue}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setSecondaryAnchorValue(e.target.value)} />
                                            }
                                        </div>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Step Number
                                        </FormLabel>
                                        <OutlinedInput
                                            value={stepNumber}
                                            style={{ textTransform: 'capitalize' }}
                                            type={'number'}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleStepNumber} />
                                        <FormLabel style={{ display: stepErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                            {stepErrorMessage}
                                        </FormLabel>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Step Description
                                        </FormLabel>
                                        <OutlinedInput
                                            value={stepDescription}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setStepDescription(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required={(functionality != 'contract' && functionality != 'contract document') ? true : false}>
                                            Threshold Type
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            style={{ textTransform: 'capitalize' }}
                                            value={incentiveThresholdType}
                                            onChange={(e) => setIncentiveThresholdType(e.target.value)}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                            disabled={!functionality ? true : false}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {incentiveThresholdTypeArr && incentiveThresholdTypeArr.length > 0 &&
                                                incentiveThresholdTypeArr
                                                    .filter(item => (functionality === 'accrual' || functionality === 'payment') ? item != 'incentive_adjustment' : true)
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item.replace(/_/g, ' ')}
                                                            </MenuItem>
                                                        );
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} >
                                            Positive Threshold Value
                                        </FormLabel>
                                        <OutlinedInput
                                            value={incentiveAmount}
                                            type={'number'}
                                            inputProps={{ min: 0 }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleIncentiveAmount} />
                                        <FormLabel style={{ display: positiveErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                            {positiveErrorMessage}
                                        </FormLabel>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Negative Threshold Value
                                        </FormLabel>
                                        <OutlinedInput
                                            value={negativeThresholdValue}
                                            type={'number'}
                                            inputProps={{ max: 0 }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleNegativeThresholdValue} />
                                        <FormLabel style={{ display: negativeErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                            {negativeErrorMessage}
                                        </FormLabel>
                                    </div>
                                </Grid>
                                {currencyArray &&
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel className={classes.formLabel} required={functionality != 'contract' ? true : false}>
                                            Threshold Unit
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={currency}
                                            onChange={(e) => setCurrency(e.target.value)}
                                            displayEmpty
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                            style={{ marginTop: 26, textTransform: 'capitalize' }}
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            {currencyArray && currencyArray.drop_down_values && currencyArray.drop_down_values
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            <MenuItem value='%'>
                                                %
                                            </MenuItem>
                                        </Select>

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Approval Group
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        onChange={(e) => setApprovalGroup(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        value={approvalGroup}
                                        style={{ marginTop: 27, textTransform: 'capitalize' }}
                                    >
                                        <MenuItem value={''} key={''}>
                                        </MenuItem>
                                        {approvalGroupData && approvalGroupData.records
                                            && approvalGroupData.records.length > 0
                                            && approvalGroupData.records.map((item) => {
                                                return (
                                                    <MenuItem value={item.ID} key={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>

                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Notification Group
                                        </FormLabel>
                                        <OutlinedInput
                                            value={notificationGroup}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setNotificationGroup(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            External Approval
                                        </FormLabel>
                                        <OutlinedInput
                                            value={externalApproval}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setExternalApproval(e.target.value)} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                            disabled={!functionality || !applicationTypeValue || !stepNumber || !approvalGroup || (primaryAnchor && !primaryAnchorValue)
                                || (secondaryAnchor && !secondaryAnchorValue)
                                ? true : (functionality != 'contract' && functionality != 'contract document') ? !incentiveThresholdType || positiveValid || !currency ? true : false : false}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>

                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addApprovalDesigner(data)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        getWorkFlowAnchors: () => dispatch(getWorkFlowAnchors()),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData2: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        workflowAnchors: state.customerData.workflowAnchors,
        allowedAppsData: state.initialData.allowedApps,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddApprovalDesigner);