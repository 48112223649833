import React, { useEffect } from 'react';
import {
    Grid, Button, Typography,
    FormLabel, Select, MenuItem, Checkbox, Input, ListItemText, Table,
    TableBody, OutlinedInput,
    TableContainer,
    TableHead,
    TableRow,
    AppBar, Toolbar, CssBaseline, Drawer, IconButton
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles, useTheme } from '@mui/styles';
import { getAnaylsisFormula, runMarginTrending, getAttributesConfig } from '../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import { BeatLoader, HashLoader } from "react-spinners";
import MomentUtils from '@date-io/moment';
import { useHistory } from "react-router-dom";
import { tableIcons } from '../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { Menu, ChevronLeft, ChevronRight } from '@mui/icons-material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    contractContiner: {
        marginTop: 17
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    hover: {
        cursor: 'pointer'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 51,
        paddingBottom: 30,
    },
    button: {
        marginRight: 10,
        width: 80,
    },
    drawer: {
        position: 'absolute',
        flexShrink: 0,
        zIndex: 998
    },
    drawerPaper: {
        marginTop: 65
    },
    drawerIcon: {
        marginRight: 10
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        fontSize: theme.typography.h2.fontSize
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        fontSize: theme.typography.h2.fontSize
    },
}));

const MarginTrending = props => {
    const classes = useStyles();
    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.table.row,
                height: 46,
            },
            "&:nth-of-type(even)": {
                height: 40,
            },
        },
    }))(TableRow);
    const { className } = props;
    const history = useHistory();
    const theme = useTheme();
    const [priceCalcFormula, setPriceCalcFormula] = React.useState('');
    const [priceCalCForArray, setPriceCalCForArray] = React.useState([]);
    const [period1DateStart, setPeriod1DateStart] = React.useState(null);
    const [period2DateStart, setPeriod2DateStart] = React.useState(null);
    const [period1DateEnd, setPeriod1DateEnd] = React.useState(null);
    const [period2DateEnd, setPeriod2DateEnd] = React.useState(null);
    const [attributeName, setAttributeName] = React.useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [open2, setOpen2] = React.useState('');
    const [columns, setColumns] = React.useState([]);
    const [columns2, setColumns2] = React.useState([]);
    const [dataRow, setDataRow] = React.useState([]);
    const [dataRow2, setDataRow2] = React.useState([]);
    const [showData, setShowData] = React.useState(false);
    const isAllSelected2 = attributeNameArray && attributeNameArray.length > 0 && selected2.length === attributeNameArray.length;
    const [open, setOpen] = React.useState(true);
    const [analysisType, setAnalysisType] = React.useState('margin_level');
    const [allData, setAllData] = React.useState([]);
    const [shortOrder, setShortOrder] = React.useState([]);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.getConfigData(1, 10);
        props.getAttributeName(1, 0);
    }, []);
    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem('payload') !== null) && history.location.data == 'Yes') {
            let data = JSON.parse(sessionStorage.getItem('payload'))
            setShowData(true)
            setOpen(false)
            props.onSubmit(data)
            props.onSubmit(data)
        }
    }, [history])
    useEffect(() => {
        let newArray = []
        let newArray2 = []
        let tempColum = []
        let tempColum2 = []
        let allDataTemp = []
        if (props.tableData && props.tableData.length > 0) {
            setShortOrder(props.tableData.splice(props.tableData.length - 1, props.tableData.length)[0]['driver'])
            props.tableData.splice(0, props.tableData.length - 1).map((item, index) => {
                allDataTemp.push(item)
                newArray.push({ ...item.attributes, ...item.margin })
                newArray2.push(item.driver_name)
            })
            setAllData(allDataTemp)
            if (newArray.length > 0) {
                Object.keys(newArray[0]).map(item => {
                    tempColum.push({
                        'field': item && item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item,
                        'title': item && item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item,
                        render: (rowData) => <a className={classes.hover} onClick={() => { handleMarginTrending(rowData) }} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}  > {rowData[item && item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item]}</a>
                    })

                })
            }
            if (newArray2.length > 0) {
                Object.keys(newArray2[0]).map(item => {
                    tempColum2.push({
                        'field': item && item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item,
                        'title': item && item.includes('_') ? item.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', ' ') }) : item,
                    })

                })
            }
            setColumns(tempColum)
            setColumns2(tempColum2)
            setDataRow(newArray)
            setDataRow2(newArray2)


        }
    }, [props.tableData])
    useEffect(() => {
        var newArray = [];
        if (props.configData) {
            props.configData.map(e => {
                newArray.push({ 'formula': e.formula_name, 'analysis_level_id': e.analysis_level_id })
            })
            setPriceCalCForArray(newArray);
        }
    }, [props.configData])
    useEffect(() => {
        let newArray = []
        if (props.attributeNameData && props.attributeNameData.length > 0) {
            props.attributeNameData.map(item => {
                newArray.push(item.qualifier_key)
            })
            setAttributeNameArray(newArray)
        }
    }, [props.attributeNameData]);
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected2(selected2.length === attributeNameArray.length ? [] : attributeNameArray);
        }
        else
            setSelected2(newSelected)
    }
    function onAdd2() {
        setAttributeName(selected2)
        setOpen2(false)
        setSelected2([])
    }
    function onCancel2() {
        setOpen2(false)
        setSelected2([])
    }
    function handlePriceCalFormula(newValue) {
        setPriceCalcFormula(newValue)
    }
    function handlePeroid1StartDate(newValue) {
        setPeriod1DateStart(newValue);
    }
    function handlePeroid1EndDate(newValue) {
        setPeriod1DateEnd(newValue);
    }
    function handlePeriod2StartDate(newValue) {
        setPeriod2DateStart(newValue);
    }
    function handlePeroid2EndDate(newValue) {
        setPeriod2DateEnd(newValue);
    }
    function handleOpen2() {
        setSelected2(attributeName)
        setOpen2(true)
    }
    function handleMarginTrending(item) {
        if (item) {
            history.push({ pathname: '/margin-trending-result', data: allData[item.tableData.id] })
        }
    }
    const formData = {
        "period1": {
            "from": Moment.utc(period1DateStart).format("YYYY-DD-MM"),
            "to": Moment.utc(period1DateEnd).format("YYYY-DD-MM")
        },
        "period2": {
            "from": Moment.utc(period2DateStart).format("YYYY-DD-MM"),
            "to": Moment.utc(period2DateEnd).format("YYYY-DD-MM")
        },
        "formula_name": priceCalcFormula,
        "attributes": attributeName
    };
    function handleSubmit() {
        setShowData(true)
        setOpen(false)
        sessionStorage.setItem('payload', JSON.stringify(formData))
        props.onSubmit(formData)
        props.onSubmit(formData)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}

                >
                    <Toolbar style={{ position: 'absolute', marginTop: 65, display: open && 'none' }}>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                            size="large">
                            <Menu />
                        </IconButton>
                        <Typography style={{ marginLeft: '1rem' }} variant="h3" color='primary'> Gross to Net Trend </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="top"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h3" color='primary'> Gross to Net Trend </Typography>
                        <IconButton onClick={() => setOpen(false)} size="large">
                            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </div>
                    <div style={{
                        margin: "-8px 10px 10px 10px",
                        padding: 5,
                        border: "1px solid #E6E6E6",
                        borderRadius: 5,
                    }} >
                        <Grid container
                            // md={12}
                            // xs={12}
                            style={{
                                // margin: 10,
                                // padding: 5,
                                // border: "1px solid #E6E6E6",
                                // borderRadius: 5,
                            }} >
                            <Grid container
                                item
                                md={5}
                                xs={12}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                        Formula
                                    </FormLabel>
                                    <Select
                                        value={priceCalcFormula}
                                        onChange={(e) => handlePriceCalFormula(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {priceCalCForArray && priceCalCForArray.map((item, index) => {
                                            return (
                                                <MenuItem value={item.formula} key={item.formula}>
                                                    {item.formula + "-" + item.analysis_level_id}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required>
                                        Attributes Name
                                    </FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={attributeName}
                                        style={{ maxHeight: "50px", width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen2()}
                                        onClose={() => setOpen2(false)}
                                        open={open2}
                                        renderValue={(appType) => {
                                            var priceType = []
                                            appType
                                                .map(item => {
                                                    priceType.push(item.replace(/_/g, ' '))

                                                })
                                            return priceType.join(", ")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues2(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected2} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {attributeNameArray && attributeNameArray
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues2(event, item)} value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected2.indexOf(item) > -1} />
                                                        <ListItemText primary={item.replace(/_/g, ' ')} />
                                                    </MenuItem>
                                                );
                                            })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd2} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} className={classes.chargeBackLabel} required >Analysis Type</FormLabel>
                                    <Select
                                        value={analysisType}
                                        onChange={(e) => setAnalysisType(e.target.value)}
                                        label='analysis Type'
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}

                                    >
                                        <MenuItem value={"margin_level"}>
                                            Margin Level
                                        </MenuItem>
                                        <MenuItem value={"driver_level"}>
                                            Driver Level
                                        </MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container
                                item
                                md={3}
                                xs={12}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Historical Start Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                border: !period1DateStart && period1DateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={period1DateStart}
                                                        onChange={handlePeroid1StartDate}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Historical End Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                border: '1px solid #E0E0E0',
                                                                width: '96%',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={period1DateEnd}
                                                        onChange={handlePeroid1EndDate}
                                                        format="MM/DD/YYYY"
                                                        minDate={period1DateStart ? period1DateStart : ''}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container
                                item
                                md={3}
                                xs={12}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Evaluation Start Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                border: !period2DateStart && period2DateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={period2DateStart}
                                                        onChange={handlePeriod2StartDate}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.contractContiner}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Evaluation End Date</FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid container justifyContent="space-around">
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '1px 0 1px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                border: '1px solid #E0E0E0',
                                                                width: '96%',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={period2DateEnd}
                                                        onChange={handlePeroid2EndDate}
                                                        format="MM/DD/YYYY"
                                                        minDate={period2DateStart ? period2DateStart : ''}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>



                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleSubmit}
                                    twoline="true"
                                    disabled={priceCalcFormula && period1DateStart && period1DateEnd && period2DateStart && period2DateEnd && attributeName.length > 0 && analysisType ? false : true}
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                        </Grid>
                    </div>
                </Drawer>
                <Grid item md={12} xs={6} className={classes.container}>
                    {showData && analysisType === 'margin_level' &&
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRow}
                            enablePagination={false}
                            enableRowVirtualization
                            style={{ marginTop: '3rem' }}
                            options={{
                                maxBodyHeight: '66vh',
                                search: false,
                                filtering: true,
                                paging: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                            }}
                        />
                    }
                    {showData && analysisType === 'driver_level' &&
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns2.sort(function (a, b) {
                                return shortOrder.indexOf(a.field) - shortOrder.indexOf(b.field);
                            })}
                            data={dataRow2}
                            enablePagination={false}
                            enableRowVirtualization
                            style={{ marginTop: '3rem' }}
                            options={{
                                maxBodyHeight: '66vh',
                                search: false,
                                filtering: true,
                                paging: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                            }}
                        />
                    }
                </Grid>
            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tableData: state.profitOptimizationData.marginTrendingData,
        configData: state.profitOptimizationData.priceCalForListData,
        attributeNameData: state.profitOptimizationData.qualifierConfigData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        onSubmit: (formData) => dispatch(runMarginTrending(formData)),
        getAttributeName: (pagination, limit) => dispatch(getAttributesConfig(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarginTrending);