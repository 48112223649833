import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, Link, useMediaQuery, Fab, IconButton,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { MessageDialogue } from '../MessageDialogue';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import Estimator from './Estimator';
import NextTierChart from './NextTierChart';
import StackedBarChartSharpIcon from '@mui/icons-material/StackedBarChartSharp';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitaize'
    },
    spinner: {
        height: '100vh'
    },
    subCell: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        // borderColor: theme.palette.primary.main,
    },
    estimateRow: {
        //borderColor: theme.palette.primary.main,
        paddingLeft: 18,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitaize'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const NextTierResult = props => {
    const classes = useStyles();
    const history = useHistory();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });



    const [errorList, setErrorList] = React.useState([]);
    const [dialog, setDialog] = React.useState(false)
    const [tableHeads, setTableHeads] = React.useState([]);
    const [keyFigures, setKeyFigures] = React.useState([]);
    const [openEstimator, setOpenEstimator] = React.useState(false);
    const [estimatorData, setEstimatorData] = React.useState([]);
    const [tierFields, setTierFields] = React.useState([]);
    const [activeEstimator, setActiveEstimator] = React.useState(false);
    const [nextTierData, setNextTierData] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState([]);
    const [selectedKey, setSelectedKey] = React.useState('');
    const [openChart, setOpenChart] = React.useState(false);

    const formData = history.location.formData
    const heading = ['Tier Basis', 'Tier Rate', 'Required Tier Basis', 'keyFigure',
        'Required Tier Basis %', 'Incentive Basis', 'Incentive Amount', 'Impact', 'Impact %', 'Action']


    useEffect(() => {
        if (props.tierData) {
            var temp = []
            setNextTierData(props.tierData)
            if (props.tierData.next_tier_data && Object.values(props.tierData.next_tier_data).length > 0 && Object.values(props.tierData.next_tier_data)[0][0]) {
                if (Object.values(props.tierData.next_tier_data)[0][0]['tier_evaluation_fields']) {
                    temp = [...Object.values(props.tierData.next_tier_data)[0][0]['tier_evaluation_fields'], ...heading]
                    setTierFields(Object.values(props.tierData.next_tier_data)[0][0]['tier_evaluation_fields'])
                }
                if (Object.values(props.tierData.next_tier_data)[0][0]['tier_evaluation_fields'] == null) {
                    temp = [...heading]
                }
                var data = Object.values(props.tierData.next_tier_data)[0] && Object.values(props.tierData.next_tier_data)[0].filter(item => item.key_figures_affected && item.key_figures_affected.length > 0)
                if (data && data.length > 0) {
                    if (data[0]['key_figures_affected'] && typeof (data[0]['key_figures_affected']) != 'string')
                        setKeyFigures(data[0]['key_figures_affected'])
                    else
                        setKeyFigures([data[0]['key_figures_affected']])
                }
            }
            setTableHeads(temp)
            if (!activeEstimator)
                setEstimatorData({})
        }
    }, [props.tierData])
    useEffect(() => {
        if (props.estimateData && props.estimateData.next_tier_data && activeEstimator) {
            setEstimatorData(props.estimateData.next_tier_data)
        }
        if (props.estimateError && props.estimateError.length > 0 && activeEstimator) {
            setErrorList({ 'messageLog': props.estimateError })
        }
    }, [props.estimateData, props.estimateError])

    useEffect(() => {
        if (props.tierError) {
            setErrorList({ 'messageLog': props.tierError })
        }
    }, [props.tierError])
    function handleError() {
        setDialog(false)
    }
    function handleActive() {
        setOpenEstimator(true)
        setActiveEstimator(true)
    }
    const viewChart = (item, key) => {
        setSelectedItem(item)
        setSelectedKey(key)
        setOpenChart(true)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div>
                <div className={clsx(classes.root, className)} >
                    <div className={clsx({
                        [classes.row]: isDesktop,
                    })} >
                        <Typography variant="h1" color='primary'>
                            Rebate Estimator Results
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                onClick={handleActive}
                            >
                                {/* <CloudUpload   color='primary' />  */}
                                <Typography color='primary' style={{ marginLeft: 3 }}>Rebate Estimator</Typography>
                            </Fab>
                            <div onClick={() => { setDialog(true) }} style={{
                                cursor: 'pointer', color: 'red', fontSize: theme.typography.h4.fontSize, display: 'flex',
                                alignItems: 'center',
                                border: '1px solid red',
                                padding: 4,
                                marginLeft: 10
                            }}>Show Message Summary</div>
                        </div>
                    </div>
                    {dialog &&

                        <MessageDialogue open={dialog} messageList={errorList} onChange={handleError} />
                    }
                    {nextTierData ?
                        <div>
                            <div style={{ height: '100%' }}>
                                <TableContainer style={{ overflowY: "scroll", marginTop: '-18px' }}>
                                    <Table stickyHeade>
                                        <TableHead >
                                            <TableRow >
                                                {tableHeads.map(item => {
                                                    return (
                                                        <TableCell align={'center'} className={classes.tableCell} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item.toString().replace(/_/g, ' ')}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {nextTierData && nextTierData.next_tier_data && Object.entries(nextTierData.next_tier_data).map(([key, value]) => {

                                                return (
                                                    <Fragment>
                                                        {key != 'contract' &&
                                                            <TableRow>
                                                                <TableCell align='left' className={classes.tableCell}
                                                                    rowSpan={estimatorData && estimatorData[key] ? estimatorData[key].length + value.length + 1 : value.length + 1}>
                                                                    {key}
                                                                </TableCell>
                                                            </TableRow>}

                                                        {value && [...value, ...(estimatorData && estimatorData[key] ? estimatorData[key] : [])]
                                                            .sort((a, b) => a['next_tier_basis'] > b['next_tier_basis'] ? 1 : -1)
                                                            .map((itemX, i) => (
                                                                <StyledTableRow style={{ backgroundColor: itemX.estimated_data == true && '#cce6ff' }}>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {parseFloat(itemX['next_tier_basis']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {itemX['next_tier_rate']}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {itemX['required_tier'] && typeof (itemX['required_tier']) != 'string' ? itemX['required_tier'].join(",") : itemX['required_tier']}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ textTransform: 'capitalize', color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {itemX['display_key_figures'] && itemX['display_key_figures'].join(",")}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {itemX['percentage_of_change']}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {parseFloat(itemX['incentive_basis']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {parseFloat(itemX['incentive_amount']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {parseFloat(itemX['impact']).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                    </TableCell>
                                                                    <TableCell align='left' className={classes.tableCell} style={{ color: itemX.estimated_data == true ? 'black' : itemX.current_data == true ? 'blue' : 'black' }}>
                                                                        {itemX['impact_percentage'] ? itemX['impact_percentage'].toFixed(2) : 0.00}
                                                                    </TableCell>
                                                                    {i == 0 &&
                                                                        <TableCell align='left' className={classes.tableCell} style={{ backgroundColor: 'white' }}
                                                                            rowSpan={estimatorData && estimatorData[key] ? estimatorData[key].length + value.length + 1 : value.length + 1}
                                                                        >
                                                                            <IconButton
                                                                                classes={{ root: classes.IconButton }}
                                                                                size={"small"}
                                                                                onClick={() => viewChart(value, key)}
                                                                            >
                                                                                <StackedBarChartSharpIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    }
                                                                </StyledTableRow>

                                                            ))
                                                        }


                                                    </Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                    {openEstimator &&
                        <Estimator keyFigures={keyFigures} openDialog={openEstimator}
                            tierData={formData} onChange={() => setOpenEstimator(!openEstimator)}
                        />

                    }
                    {openChart &&
                        <NextTierChart data={selectedItem} estimateData={estimatorData && estimatorData[selectedKey] ? estimatorData[selectedKey] : null} tierField={selectedKey}
                            openDialog={openChart} onClose={() => setOpenChart(!openChart)} />
                    }
                </div >
            </div>
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tierData: state.simulationData.nextTierData,
        tierError: state.simulationData.nexTierError,
        estimateData: state.simulationData.rebateEstimateData,
        estimateError: state.simulationData.rebateEstimateError,
    }
};
export default connect(mapStateToProps, null)(NextTierResult);
