import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    useMediaQuery, Button,
    Select, MenuItem, TableRow, Grid, FormLabel
} from '@mui/material';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import { TextInputWithLabel } from '../../components/Inputs';
import { nextTier } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18,
        fontSize: theme.typography.h4.fontSize,
        textTransform: 'capitaize'
    },
    spinner: {
        height: '100vh'
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const Estimator = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });



    const [errorList, setErrorList] = React.useState([]);
    const [dialog, setDialog] = React.useState(false)
    const [tableHeads, setTableHeads] = React.useState([]);
    const [keyFigures, setKeyFigures] = React.useState([]);
    const [openEstimator, setOpenEstimator] = React.useState(false);
    const [estimatorData, setEstimatorData] = React.useState([]);
    const [forecastData, setForeCastData] = React.useState({});
    const [valueType, setValueType] = React.useState('');
    const [nextTierData, setNextTierData] = React.useState([]);
    const typeData = [{key:'$',value:'Absolute Amount'}, {key:'%', value:'percentage Increase/Decrease'}];
    useEffect(() => {
        setOpenEstimator(props.openDialog)
    }, [props.openDialog])
    // useEffect(() => {
    //     if (props.tierData) {
    //         var temp = []
    //         setNextTierData(props.tierData)
    //         console.log(Object.values(props.tierData.next_tier_data && Object.values(props.tierData.next_tier_data)[0]))
    //         if (props.tierData.next_tier_data && Object.values(props.tierData.next_tier_data).length > 0 && Object.values(props.tierData.next_tier_data)[0][0])
    //             temp = [...Object.values(props.tierData.next_tier_data)[0][0]['tier_evaluation_fields'], ...heading]
    //         setTableHeads(temp)
    //     }

    // }, [props.tierData])
    const handleRebateEstimate = (value, item) => {
        setForeCastData(prevState => ({
            ...prevState,
            [item]: value ? Number(value) : 0
        }))
    }
    function handleSubmit() {
        var data = {
            ...props.tierData,
            "rebate_estimator": {
                "forecast_data": forecastData,
                "value_type": valueType
            }
        }
        props.onSubmit(data)
        setOpenEstimator(false)
        props.onChange()
    }
    function handleCancel() {
        setOpenEstimator(false)
        props.onChange()
    }
    return (
        <div>
            <div className={clsx(classes.root, className)} >

                <Dialog onClose={handleCancel} aria-labelledby="simple-dialog-title" open={openEstimator}
                    fullWidth={true}
                    maxWidth={'lg'}
                    scroll='paper'
                >
                    <DialogTitle id="scroll-dialog-title">Rebate Estimator Tool</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={12}>
                                <div style={{ padding: 20, width: '100%' }}>
                                    <FormLabel required className={classes.chargeBackLabel}>
                                        Estimate Type
                                    </FormLabel>
                                    <Select
                                        value={valueType}
                                        onChange={(e) => setValueType(e.target.value)}
                                        displayEmpty

                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <MenuItem value="" style={{ height: 30 }}>
                                        </MenuItem>
                                        {typeData.map((item) => {
                                            return (
                                                <MenuItem value={item.key} key={item.key}>
                                                    {item.value}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            {props.keyFigures && props.keyFigures.length > 0 &&
                                props.keyFigures.map(item => (
                                    <Grid item md={3} xs={12}>
                                        <TextInputWithLabel heading={item.replace(/_/g, ' ')} type='Number'
                                            twoline key={item} onChange={(value) => handleRebateEstimate(value, item)}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button color="primary" variant='contained' onClick={handleSubmit}
                        >
                            Run
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </div>
    );

};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (formData) => dispatch(nextTier(formData, 'estimate')),
    };
};
export default connect(null, mapDispatchToProps)(Estimator);
