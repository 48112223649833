import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormLabel, MenuItem, Chip } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { getAttributeListData, getCustomerMasterData, getEmployeeData, getMaterialMasterData, getSupplierMasterData } from '../../../redux/actions';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(theme => ({
    tag: {
        "& .MuiOutlinedInput-root": {
            padding: 0,
            border: '0px'
        },
        "&.MuiAutocomplete-tag": {
            margin: 0,
            height: 21,
            backgroundColor: "#E1E4F3",
            border: 'none'
        },
        '&.MuiChip-deleteIcon': {
            color: '#445AC7'
        }
    },
    tagfree: {
        "& .MuiOutlinedInput-root": {
            // border:'0px'
        },
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    contractContiner: {
        padding: '15px 0px 18px',
        width: '100%'
    },
    container: {
        padding: '5px 0px 18px',
        width: '100%'
    },
    borderRad0: {
        borderRadius: '0px !important',
        height: '40px',
    },
    textFieldStyle: {
        marginTop: 6,
        width: '100%'
    },
    chargeBackLabel: {
        fontSize: theme.typography.h3.fontSize
    },
    textFormat: {
        color: 'black'
    },
    chip: {
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        '&.MuiChip-deleteIcon': {
            color: '#445AC7'
        },
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        padding: 0,
        border: 'none',
        borderRadius: 3,

    },
}));
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        color: 'black !important',
        border: '0px'
    };

    return (
        <MenuItem component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {`${dataSet[1].desc} - ${dataSet[1].key}`}
        </MenuItem>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} style={{ border: 0 }} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        return itemSize;
    };

    const getHeight = () => {
        /* if (itemCount > 8) {
             return 8 * itemSize;
         }
        // console.log(itemData.map(itemSize).reduce((a, b) => a + b, 0))
         return itemData.map(itemSize).reduce((a, b) => a + b, 0);*/
        return itemCount * itemSize
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref} style={{ border: 'none' }}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};



const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        border: 'none',
        '& ul': {
            padding: 0,
            margin: 0,
            color: 'black',
            border: '0px'
        },
    },
});

const MasterDataComponent = forwardRef((props, ref) => {
    const classes = useStyles();
    const [attributeNumbersList, setAttributeNumbersList] = React.useState([]);
    const [attributeValueArray, setattributeValueArray] = React.useState([]);
    const [contractNumber, setContractNumber] = React.useState([]);
    // const [data, setData] = React.useState({});
    const [data, setData] = React.useState({ key: '', desc: '' });
    const [label, setLabel] = React.useState(props.withOutLabel ? false : true);
    const [attributeName, setAttributeName] = React.useState('');
    const [attributeValuesArray, setAttributeValuesArray] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [attributeValuesArrayMaterialNumber, setAttributeValuesArrayMaterialNumber] = React.useState([]);
    const [employeeMasterArray, setEmployeeMasterArray] = React.useState([]);
    const [supplierMasterArray, setSupplierMasterArray] = React.useState([]);
    const [employeeNameListArray, setEmployeeNameListArray] = React.useState([]);

    const filter = createFilterOptions()
    useEffect(() => {
        if (props.attributeName) {
            setAttributeName(props.attributeName)
            if (props.attributeList && props.attributeList.length > 0) {
                setattributeValueArray(props.attributeList)
            }
            else if (props.attributeName == 'customer_number' || props.attributeName == 'sold_to_party' || props.attributeName == 'bill_to_party')
                props.getCustomerMasterData();
            else if (props.attributeName == 'material_number')
                props.getMaterialMasterData();
            else if (props.attributeName == 'supplier_number')
                props.getSupplierMasterData();
            else
                props.getEmployeeData();
        }
    }, [props.attributeName, props.attributeList])
    useEffect(() => {
        if (props.prevalue) {
            if (props.multiple)
                setContractNumber(props.prevalue)
            else
                if (Object.keys(props.prevalue).length > 0)
                    setData(props.prevalue)
        }
        else {
            //setData({ 'key': '', 'desc': '' })
        }
    }, [props.prevalue])

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var customerTempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
                customerTempArray.push({ 'key': item.customer_number, 'desc': item.customer_name })
            })
            setAttributeValuesArray(tempArray);
            setCustomerNameListArray(tempNameArray);
            if (attributeName == 'customer_number' || attributeName == 'sold_to_party' || attributeName == 'bill_to_party') {
                setAttributeNumbersList(tempArray);
                setattributeValueArray(customerTempArray)
            }
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var employeeArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
                employeeArray.push({ 'key': item.employee_number, 'desc': item.employee_name })

            })
            setEmployeeMasterArray(tempArray)
            setEmployeeNameListArray(tempNameArray);
            if (attributeName == 'employee_number') {
                setAttributeNumbersList(tempArray);
                setattributeValueArray(employeeArray)
            }
        }
    }, [props.employeeMasterData])

    const [materialNameListArray, setMaterialNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var materialTempArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
                materialTempArray.push({ 'key': item.material_number, 'desc': item.material_description })
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            setMaterialNameListArray(tempNameArray);
            if (attributeName == 'material_number') {
                setAttributeNumbersList(tempArray);
                //setattributeValueArray(tempNameArray)
                setattributeValueArray(materialTempArray)
            }
        }
    }, [props.materialMasterData])
    const [supplierNameArray, setSupplierNameArray] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var supplierArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
                supplierArray.push({ 'key': item.supplier_number, 'desc': item.supplier_name })

            })
            setSupplierMasterArray(tempArray)
            setSupplierNameArray(tempNameArray)
            if (attributeName == 'supplier_number') {
                setAttributeNumbersList(tempArray);
                setattributeValueArray(supplierArray)
            }
        }
    }, [props.supplierMasterData])


    const handleContractNumber = (value, type) => {
        setContractNumber(value)
        props.onChange(value)
    };
    const handleSingleData = (value) => {
        console.log(value)
        setData(value)
        props.onChange(value)
    };
    function humanize(str) {
        var i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
    return (
        <div className={props.withOutLabel ? classes.container : classes.contractContiner}>
            {label &&
                <FormLabel className={classes.chargeBackLabel}> {humanize(props.attributeName)} </FormLabel>
            }
            <div className={classes.textFieldStyle}>{console.log(data)}
                {props.multiple ?
                    <Autocomplete
                        id={props.id}
                        disableListWrap
                        limitTags={3}
                        multiple
                        freeSolo={props.freeSolo ? true : false}
                        PopperComponent={StyledPopper}
                        ListboxComponent={ListboxComponent}
                        options={attributeValueArray}
                        value={props.prevalue ? props.prevalue : contractNumber}
                        onChange={(event, value) => {
                            if (value && value.inputValue) {
                                handleContractNumber(value.inputValue, 'basic')
                            } else {
                                handleContractNumber(value, 'basic')
                            }
                        }}
                        classes={{
                            inputRoot: classes.borderRad0
                        }}
                        getOptionLabel={(option) => {
                            return option?.key
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option?.key === value?.key
                        }
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" size="small" classes={{ root: classes.chip }} label={option.key} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" classes={{ root: props.freeSolo ? classes.tag : classes.tagfree }} style={{ borderRadius: '0px !important', paddingTop: '0px !important' }} />
                        }
                        filterOptions={(option, params) => {
                            const filtered = filter(option, params);;
                            var list = []
                            const { inputValue } = params;
                            const isExisting = option ? attributeNumbersList.includes(inputValue) ? true : false : false
                            if (inputValue !== '' && !isExisting) {

                                list = [{ 'key': inputValue, 'desc': '' }, ...filtered]
                            }
                            else {
                                list = filtered
                            }

                            return list;
                        }}
                        renderOption={(props, option, state) => [props, option, state.index]}

                    />
                    :
                    <Autocomplete
                        id={props.id}
                        disabled={props.disabled ? props.disabled : false}
                        disableListWrap
                        freeSolo={props.freeSolo ? true : false}
                        PopperComponent={StyledPopper}
                        ListboxComponent={ListboxComponent}
                        options={attributeValueArray}
                        value={data}
                        defaultValue={data}
                        style={{ width: '100%' }}
                        onChange={(event, value) => {
                            if (value && value.inputValue) {
                                handleSingleData(value.inputValue, 'basic')
                            } else {
                                handleSingleData(value, 'basic')
                            }
                        }}
                        classes={{
                            inputRoot: classes.borderRad0
                        }}
                        getOptionLabel={(option) => {
                            return option?.key
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option?.key === value?.key
                        }
                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" classes={{ root: props.freeSolo ? classes.tag : classes.tagfree }} style={{ borderRadius: '0px !important', paddingTop: '0px !important' }} />
                        }
                        filterOptions={(option, params) => {
                            const filtered = filter(option, params);;
                            var list = []
                            const { inputValue } = params;
                            const isExisting = option ? attributeNumbersList.includes(inputValue) ? true : false : false
                            if (inputValue !== '' && !isExisting && props.freeSolo) {

                                list = [{ 'key': inputValue, 'desc': '' }, ...filtered]
                            }
                            else {
                                list = filtered
                            }

                            return list;
                        }}
                        renderOption={(props, option, state) => [props, option, state.index]}

                    />
                }
            </div>
        </div>
    );
})
const mapDispatchToProps = dispatch => {
    return {
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
    }
}
const mapStateToProps = state => {
    return {
        // attributeData: state.attributeListData.AttributeData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        employeeMasterData: state.customerData.employeeMasterData,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(MasterDataComponent);