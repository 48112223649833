import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, Link, useMediaQuery, Paper,
} from '@mui/material';
import { connect } from 'react-redux';
import { downloadDynamicQuery, getDynamicQuery, } from '../../redux/actions';
import Moment from 'moment';
import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Aggregate,
    ExcelExport, Toolbar, PdfExport, Search, CommandColumn, AggregateDirective, AggregatesDirective, AggregateColumnDirective, AggregateColumnsDirective, ColumnMenu, VirtualScroll
} from '@syncfusion/ej2-react-grids';
import { number } from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        //width:'100%',
        width: 'fit-content',
        overflow: 'scroll'

    },
    gridBox: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginBottom: 10,
        //overflowX: 'hidden',
        overflowY: 'scroll'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
    },
    button: {
        marginLeft: 15
    },
    sumContainer: {
        // backgroundColor: theme.palette.primary.main,
        // color: 'white',
        color: theme.palette.primary.main
    },
    cell: {
        backgroundColor: 'white !important',
        background: 'white !important',
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));


const DynamicQueryResults = props => {
    var grid = useRef(null);

    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [tableHeaders, setTableHeaders] = React.useState([]);
    const [sortedTableHeader, setSortedTableHeader] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [dateField, setDateFields] = React.useState([]);
    const [numericField, setNumericFields] = React.useState([]);
    const [queryName, setQueryName] = React.useState([]);
    useEffect(() => {
        props.getDynamicQuery(props.match.params.id)
    }, [])
    useEffect(() => {
        if (props.dynamicQueryDetailsData && props.dynamicQueryDetailsData.format_name)
            setQueryName(props.dynamicQueryDetailsData.format_name)
    }, [props.dynamicQueryDetailsData])
    useEffect(() => {
        if (props.dynamicQueryFieldsData) {
            if (props.dynamicQueryFieldsData.numeric_fields) {
                var data = props.dynamicQueryFieldsData.numeric_fields.map((item, i) => item.key)
                setNumericFields(data)
            }
            if (props.dynamicQueryFieldsData.date_fields) {
                var data = props.dynamicQueryFieldsData.date_fields.map((item, i) => item.key)
                setDateFields(data)
            }
        }
    }, [props.dynamicQueryFieldsData])
    useEffect(() => {
        var temp = []
        if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
            var singleValue = props.queryResultsFinalData[0][0];
            setTableHeaders(Object.keys(singleValue));

            if (props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1] &&
                props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields &&
                props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields.length > 0) {
                setSortedTableHeader(props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields);
                props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields
                    .map(item => {
                        temp.push({
                            field: item.key, title: item.label,
                        })
                    })
                setColumns(temp)
            }
            setDataRows(
                props.queryResultsFinalData[0].filter((item, i) => !item.sortOrder)
            );
            setCount(props.queryResultsFinalData[0].length)
        }
    }, [props.queryResultsFinalData]);


    //pdf download

    const toolbarOptions = ['PdfExport', 'ExcelExport', 'Search']
    const [sumField, setSumField] = React.useState({});

    useEffect(() => {
        if (props.queryResultsFinalData) {
            var sum = props.queryResultsFinalData.map(e => {
                if (e.total_sum) {
                    setSumField(e.total_sum);
                }
            })
        }
    }, [props.queryResultsFinalData])
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'grid_pdfexport') {
            grid.showSpinner();
            const exportProperties = {
                pageOrientation: 'Landscape',
                pageSize: 'A0',
                fileName: queryName + '_' + Moment.utc(new Date()).format('MM/DD/YYYY') + '.pdf',
                theme: {
                    header: {
                        bold: true,
                        fontSize: 14,
                    },
                    record: {
                        bold: false,
                        fontSize: 14,
                    },
                    caption: {
                        bold: true,
                        fontSize: 14,
                    },
                }
            };
            grid.pdfExport(exportProperties);
        }
        if (grid && args.item.id === 'grid_excelexport') {
            grid.showSpinner();
            const excelExportProperties = {
                dataSource: [...dataRows, sumField],
                fileName: queryName + '_' + Moment.utc(new Date()).format('MM/DD/YYYY') + '.xlsx',
            };
            grid.excelExport(excelExportProperties);
        }
    };

    const pdfExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const excelExportComplete = () => {
        if (grid) {
            grid.hideSpinner();
        }
    };
    const filterSettings = { type: 'CheckBox' };
    const groupOptions = { showGroupedColumn: true }
    const valueFormat = (field, data, column) => {
        if (numericField && numericField.includes(field) && field != 'contract_number') {
            return parseFloat(data[field]).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).toString()

        }
        else if (dateField && dateField.includes(field)) {
            return Moment(data[field], true).isValid()
                ? Moment.utc(data[field]).format('MM/DD/YYYY').toString() : ''
        }
        else
            return data[field];
    };
    const sortComparer = (reference, comparer) => {

        if (grid && grid.sortModule.direction == 'Ascending') {
            if (numericField.includes(grid.sortModule.columnName)) {
                return Number(reference) < Number(comparer) ? -1 : 1
            }
            else {
                return reference < comparer ? -1 : 1
            }
        }
        else {
            if (numericField.includes(grid.sortModule.columnName)) {
                return Number(reference) < Number(comparer) ? 1 : -1
            }
            else {
                return reference < comparer ? 1 : -1
            }
        }
        return 0;
    };
    console.log("data", dataRows)
    return (
        <div>
            <div className={clsx(classes.root, className)} >


                {sortedTableHeader && sortedTableHeader.length > 0 && props.queryResultsFinalData && props.queryResultsFinalData[0] && props.queryResultsFinalData[0].length > 0 &&
                    <Paper className={classes.gridBox} style={{ paddingTop: 0, }}>
                        <div style={{ marginTop: 10, overflow: 'scroll' }}>
                            <GridComponent
                                dataSource={dataRows}
                                id='grid'
                                allowSorting={true} allowGrouping={true} showColumnMenu={true}
                                // allowPaging={true}
                                //pageSettings={{ pageSize: 10000 }}
                                groupSettings={groupOptions}
                                filterSettings={filterSettings}
                                allowFiltering={true}
                                ref={g => grid = g}
                                toolbar={toolbarOptions}
                                toolbarClick={toolbarClick}
                                allowPdfExport={true} allowExcelExport={true}
                                pdfExportComplete={pdfExportComplete} excelExportComplete={excelExportComplete}
                                height={window.screen.height * .75}
                                gridLines='Both' allowResizing={true}
                                enableVirtualization={true}
                                enableColumnVirtualization={false}
                            >

                                <ColumnsDirective>
                                    {columns.map((item, order) => {
                                        return (
                                            <ColumnDirective
                                                field={item.field}
                                                headerText={item.title}
                                                width="200px" minWidth='100px' maxWidth='300px'
                                                valueAccessor={valueFormat}
                                                type={numericField.includes(item.field) ? 'number' : 'string'}
                                                sortComparer={sortComparer}
                                            />
                                        );
                                    })
                                    }
                                </ColumnsDirective>
                                <AggregatesDirective>
                                    <AggregateDirective>
                                        <AggregateColumnsDirective classes={{ root: classes.sumContainer }}>
                                            {sumField && columns.map((item, order) => {
                                                return (
                                                    <AggregateColumnDirective field={item.field} type={'Custom'}
                                                        footerTemplate={() => (<span className={classes.sumContainer}>{sumField[item.field] ? parseFloat(sumField[item.field]).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }).toString()
                                                            : ''}</span>)} />
                                                )
                                            })}
                                        </AggregateColumnsDirective>
                                    </AggregateDirective>
                                </AggregatesDirective>
                                <Inject services={[Toolbar, PdfExport, ExcelExport, Aggregate, Group, Sort, ColumnMenu, Filter, VirtualScroll, Resize, Page]} />
                            </GridComponent>
                        </div>
                    </Paper>
                }

            </div >
        </div >
    );

};

const mapStateToProps = state => {
    return {
        queryResultsFinalData: state.operationalReportsData.queryResultsFinalData,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        downloadDynamicQuery: (data) => dispatch(downloadDynamicQuery(data)),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryResults);