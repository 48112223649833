import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Breadcrumbs,
    IconButton,
    Tooltip, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { uploadMassFile } from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { MessageDialogue } from '../MessageDialogue';
import LoadingOverlay from "react-loading-overlay";
import { Link } from 'react-router-dom';
import { UploadFile } from '@mui/icons-material';
import { CloudUpload } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    IconButton: {
        padding: 0,
        fontSize: 10,
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
}));
const ContractMassUpload = props => {

    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [application, setApplication] = useState(sessionStorage.getItem('application') ? sessionStorage.getItem('application') : '')
    const [dialog, setDialog] = React.useState(false)
    const [errorList, setErrorList] = React.useState([])
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadMassFile(application, data);
        event.target.value = '';
    }
    //dynamic query functions
    function runQuery() {
        inputFile.current.click();
    }
    useEffect(() => {
        setErrorList(props.uploadMessage)
        if (props.uploadMessage && props.uploadMessage.messageLog && props.uploadMessage.messageLog.length > 0) {
            setDialog(true);
        }
    }, [props.uploadMessage])
    function handleError() {
        setDialog(false)
    }
    return (

        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: "50px",
                        "& svg circle": {
                            stroke: "#045FB4",
                        },
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(52, 52, 52, 0)",
                    }),
                    content: (base) => ({
                        ...base,
                        color: "black",
                    }),
                }}
                text="Loading . Please wait ..."
                className={classes.spinner}
            >

                <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 5 }}>
                    <Link variant='h4' classes={{ root: classes.link }} to='/contract-setup'>
                        {sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Setup' : 'Contract Setup'}
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp; Mass Upload</Typography>
                </Breadcrumbs>

                <div className={clsx({
                    [classes.row]: isDesktop
                })}>
                    <Typography variant="h2" />
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => runQuery()}
                    >
                        <CloudUpload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                    </Fab>
                    <input type='file'
                        accept=".xlsx, .xls, .csv"
                        id='file' ref={inputFile} style={{ display: 'none' }}
                        onChange={(e) => handleDrop(e)} />
                </div>
                {dialog &&
                    <MessageDialogue open={dialog} messageList={errorList} onChange={handleError} />
                }
            </LoadingOverlay>
        </div >

    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadMassFile: (appType, data) => dispatch(uploadMassFile(appType, data))
    }
}
const mapStateToProps = state => {
    return {
        uploadMessage: state.addMultipleConfigurationData.massUploadMessage,
        loading: state.addMultipleConfigurationData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractMassUpload);