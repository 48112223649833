import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getBasisMappingData, deleteBasisMapping } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';

import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { BasisArray } from './BasisList'
import secureLocalStorage from 'react-secure-storage';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const BasisMapping = props => {
    useEffect(() => {
        props.getBasisMapData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [basisData, setBasisData] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getBasisMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getBasisMapData(1, event.target.value);
    };
    /*const cloneContract = (id) => {
        props.cloneContract(id);
    }*/
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.basisMapData) {
            setBasisData(props.basisMapData);
        }
    }, [props.basisMapData]);

    function editBasis(id) {
        history.push({
            pathname: '/basis-mapping/edit-basis/' + id,
            id: id
        });
    }

    function viewBasis(id) {

    }
    // const deleteData = (id) => {
    //     props.deleteBasisMapping(id);
    // }

    function check(value) {
        var list = BasisArray.filter(item => item.evalutionTypeKey == value)
        if (list.length > 0)
            return list[0]['evalutionType']
        else
            return value.replace(/_/g, ' ');
    }

    const handleOpenDialog = (id) => {
        setSelectedId(id);
        setOpenDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedId !== null) {
            props.deleteBasisMapping(selectedId);
            setOpenDialog(false);
            setSelectedId(null);
        }
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
        setSelectedId(null);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Basis Mapping </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_basis_mapping-' + sessionStorage.getItem('application')) &&
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/basis-mapping/add-basis')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}
                </div>
                {basisData && basisData.total_record > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Contract Type </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Transaction Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Evaluation Date Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {basisData.records
                                    .sort((a, b) => a.ID > b.ID ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.ID} >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewBasis(item.ID)} style={{ cursor: 'pointer' }}>{item.application_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewBasis(item.ID)} style={{ cursor: 'pointer' }}>{item.source_data_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewBasis(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.contract_type.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewBasis(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.transaction_type.replace(/_/g, ' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewBasis(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{check(item.evaluation_date_type)}</TableCell>
                                                <TableCell align='center'>
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_basis_mapping-' + sessionStorage.getItem('application')
                                                    ) && (
                                                            <>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => editBasis(item.ID)}
                                                                    size="large">
                                                                    <EditIcon color="disabled" />
                                                                </IconButton>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    // onClick={() => deleteData(item.ID)}
                                                                    onClick={() => handleOpenDialog(item.ID)}
                                                                    size="large">
                                                                    <DeleteForeverIcon color="disabled" />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.basisMapData && props.basisMapData.records ? props.basisMapData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                        {props.attributeData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <Dialog
                    open={openDialog}
                    onClose={handleCancelDelete}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} variant='outlined' color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} variant='contained' color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        basisMapData: state.BasisMapData.BasisMapData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBasisMapData: (pagination, limit) => dispatch(getBasisMappingData(pagination, limit)),
        deleteBasisMapping: (id) => dispatch(deleteBasisMapping(id))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasisMapping);