import React, { useEffect } from "react";
import Moment from 'moment';
import { marketShare } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
// import fromKapsule from "react-kapsule";
// import SunburstChart from "sunburst-chart";
import * as d3 from "d3";

// const ReactSunburst = fromKapsule(SunburstChart, {
//     methodNames: ["focusNode"]
// });

const color = d3.scaleOrdinal(d3.schemePastel1);

const MarktShare = (props) => {


    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: 'white',
            padding: '20px 30px 20px 30px',
            borderRadius: 10
        },
        rootDiv: {
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            margin: '16px 0px 16px 0px',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 32px 0px 20px',
            alignItems: 'center'
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        select: {
            width: '100%',
            color: '#1675e0',
            backgroundColor: 'white'
        },
    }));

    const classes = useStyles();
    useEffect(() => {
        var ed = Moment().subtract(0, 'months').endOf('month').format('MM/DD/YYYY');
        var sd = Moment().subtract(5, 'months').startOf('month').format('MM/DD/YYYY');
        var formData = {
            "start_date": sd,
            "end_date": ed,
            "attribute_name": 'material_group'
        }
        // props.onSubmit(formData);
    }, [])
    const [chartOptionsPP, setChartOptionsPP] = React.useState({});
    const [sunBurstData, setSunBurstData] = React.useState({});

    useEffect(() => {
        var typeParent = [];

        props.marketShare && Object.keys(props.marketShare) && Object.keys(props.marketShare).map(function (key) {
            typeParent.push({
                "name": key,
                "children": props.marketShare && props.marketShare[key] && props.marketShare[key].map(x => {
                    if (x.material_group && (x.material_group === key)) {
                        return carParentReturn(x)
                    }
                })
            })
        });
        setSunBurstData({
            name: "main",
            "children": typeParent
        })


    }, [props.marketShare])

    const carParentReturn = (x) => {
        return {
            "name": x.company_name,
            "children": [
                {
                    "name": "Quantity Share",
                    "size": parseFloat(x.quantity_share),
                },
                {
                    "name": "Value Share",
                    "size": parseFloat(x.value_share),
                }
            ]
        }
    }
    return (
        <div className={classes.root}>
            <Typography variant="h2" > {'Market Share'} </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <ReactSunburst
                    width={240}
                    height={285}
                    excludeRoot
                    label="name"
                    size="size"
                    tooltipContent={(d, node) => `${node.value}`}
                    color={((d, parent) => color(parent ? parent.data.name : null))}
                    centerRadius={0.3}
                    minSliceAngle={0}
                    radiusScaleExponent={0.4}
                    data={sunBurstData}
                /> */}
            </div>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        marketShare: state.initialData.marketShare
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(marketShare(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarktShare);