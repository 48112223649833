import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, FormLabel, MenuItem,
    Select, Dialog, DialogActions, Fab, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { getPriceFieldValues, createPriceListData, getDriverFormatFields } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    getRangeAddress, getRangeIndexes,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective,
    RangesDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective,
    CellDirective
} from '@syncfusion/ej2-react-spreadsheet';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 15,


    },
    select: {
        width: '100%',

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"

    },
    fabStyle: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
}));



const AddPriceListData = props => {
    const classes = useStyles();
    let spreadsheet;
    const jRef = useRef(null);
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const [priceFields, setPriceFields] = React.useState([]);
    useEffect(() => {
        setDialogue(true)
        props.getFieldValues('analysis_level_id')

    }, [])
    useEffect(() => {
        if (props.maintenanceFields && props.maintenanceFields.value && props.maintenanceFields.value.length > 0 && priceListID)
            setPriceFields(props.maintenanceFields.value)
    }, [props.maintenanceFields])
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }

    function handlePriceList(value) {
        setPriceListID(value)
        props.getDriverFormatFields(value)

    }

    function handleContinue() {
        setSubmitClicked(true);
        setDialogue(false);
    }

    const [optionsSF, setOptionsSF] = React.useState({})
    const [optionsSFPredefined, setOptionsSFPredefined] = React.useState([])
    const [apiFields, setApiFields] = React.useState([])
    const scrollSettings = { isFinite: true };

    useEffect(() => {
        if (submitClicked && priceFields && priceFields.length > 0) {
            // var columnArray = [];
            // var dataArray = []
            // priceFields.map(h => {
            //     if (h === 'start_date' || h === 'end_date' || h == 'currency' || h == 'scale_from' || h == 'min_value' || h == 'max_value') {
            //         columnArray.push({
            //             title: titleCase(h.replace(/_/g, ' ')),
            //             width: 100,
            //         });
            //     } else if (h === 'per_unit' || h === 'uom') {
            //         columnArray.push({
            //             title: titleCase(h.replace(/_/g, ' ')),
            //             width: 70,
            //         });

            //     } else {
            //         columnArray.push({ title: titleCase(h.replace(/_/g, ' ')), width: 150 });
            //     }
            //     if (h === 'per_unit' || h === 'scale_from')
            //         dataArray.push('1')
            //     else if (h === 'currency')
            //         dataArray.push('USD')
            //     else if (h === 'uom')
            //         dataArray.push('Each')
            //     else
            //         dataArray.push('')
            // })
            // const options = {
            //     data: [dataArray],
            //     columns: columnArray,
            //     rowResize: true,
            //     search: true,
            //     columnSorting: true,
            //     allowComments: true,
            //     columnDrag: true,
            //     tableOverflow: true,
            //     csvHeaders: true,
            //     tableWidth: screenSize.dynamicWidth,
            //     tableHeight: window.innerHeight * .80 + 'px',
            //     minDimensions: [null, 100],
            // };
            // if (!jRef.current.jspreadsheet) {
            //     jspreadsheet(jRef.current, options);
            // }

            var columnArray = [];
            var dataArray = []
            var optionsTemp = {
                data: [dataArray],
                columns: columnArray,
                rowResize: true,
                search: true,
                columnSorting: true,
                allowComments: true,
                columnDrag: true,
                tableOverflow: true,
                csvHeaders: true,
                tableWidth: screenSize.dynamicWidth,
                tableHeight: window.innerHeight * .80 + 'px',
                minDimensions: [null, 100],
            }
            var optionsTempPre = [];
            var tempAPIFields = [];
            priceFields

                .map(h => {
                    if (h === 'start_date' || h === 'end_date' || h == 'currency' || h == 'scale_from' || h == 'min_value' || h == 'max_value') {
                        tempAPIFields.push(h);
                        columnArray.push({
                            title: titleCase(h.replace(/_/g, ' ')),
                            field: h,
                            width: 300,
                        });
                    } else if (h === 'per_unit' || h === 'uom') {
                        tempAPIFields.push(h);
                        columnArray.push({
                            title: titleCase(h.replace(/_/g, ' ')),
                            field: h,
                            width: 300,
                        });
                    }
                    else {
                        tempAPIFields.push(h);
                        columnArray.push({ title: titleCase(h.replace(/_/g, ' ')),field: h, width: 300 });
                    }
                    if (h === 'per_unit' || h === 'scale_from') {
                        optionsTempPre.push({ title: 1 })
                    }
                    else if (h === 'currency')
                        optionsTempPre.push({ title: 'USD' })
                    else if (h === 'uom')
                        optionsTempPre.push({ title: 'Each' })
                    else
                        optionsTempPre.push({ title: '' })
                })
            setOptionsSF(optionsTemp)
            setOptionsSFPredefined(optionsTempPre)
            setApiFields(columnArray);
        }
    }, [priceFields, submitClicked]);
    const history = useHistory();
    const [dialogue, setDialogue] = React.useState(false);
    const [priceListID, setPriceListID] = React.useState('');
    const [priceListArray, setPriceListArray] = React.useState([]);
    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    useEffect(() => {
        if (props.priceListId)
            setPriceListArray(props.priceListId)
    }, [props.priceListId])

    function handleCancelDialog() {
        history.push('/driver-maintenance')
    }

    const handleSubmitExcel = () => {
        var json = [];
        var obj = {};
        var ssObj = spreadsheet;
        var usedRange = ssObj.getActiveSheet().usedRange;
        var selIndex = [1, 0, usedRange.rowIndex, usedRange.colIndex];
        var range =
            ssObj.getActiveSheet().name + '!' + getRangeAddress([1, 0, selIndex[2], selIndex[3]]);
        ssObj.getData(range).then((value) => {
            value.forEach((cell, key) => {
                var indexes = getRangeIndexes(key);
                if (cell && selIndex[2] >= indexes[0]) {
                    var charCurrent = 'A';
                    apiFields.map(item => {
                        if (key.indexOf(charCurrent) > -1) {
                            if (item.field == 'start_date' || item.field == 'end_date')
                                obj[item.field] = cell.value ? Moment(ExcelDateToJSDate(cell.value)).format('MM/DD/YYYY') : '';
                            else if (item.field == 'driver_value')
                                obj[item.field] = cell.value ? parseFloat(cell.value) : 0;
                            else
                                obj[item.field] = cell.value ? cell.value.toString() : '';
                        }
                        charCurrent = String.fromCharCode(charCurrent.charCodeAt() + 1)
                    })
                    if (indexes[1] === selIndex[3]) {
                        json.push(obj);
                        obj = {};
                    }
                }
            });
            props.onSubmit(json);
            setPriceFields([])
            history.push('/driver-maintenance')
        });




        // var flag = 0
        // var dataFromTable = jRef.current.jexcel.getData();
        // var headings = priceFields;
        // var tempArr = [];
        // var tempObj = {};
        // dataFromTable
        //     .filter(e => e)
        //     .map((item, i) => {
        //         if (item.filter(e => e).length > 0) {
        //             headings.map((head, j) => {
        //                 tempObj[head] = item[j] && item[j] ? item[j] : ""
        //             })
        //             tempArr[i] = tempObj;
        //             tempArr[i].analysis_level_id = priceListID
        //             tempObj = {};
        //             item.map((item, index) => {
        //                 if (index != priceFields.indexOf('max_value') && index != priceFields.indexOf('min_value') && item == '')
        //                     flag = 1
        //             })
        //         }
        //     })
        // if (flag == 0) {
        //     props.onSubmit(tempArr);
        //     setPriceFields([])
        //     history.push('/driver-maintenance')
        // }
        // else {
        //     toast.error('Mandatory fields are missing!!')
        // }
    }
    function ExcelDateToJSDate(serial) {
        var hours = Math.floor((serial % 1) * 24);
        var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
        return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Coulmn']);
    };
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>Add Driver Maintenance Data</Typography>
            <Dialog
                classes={{ paper: classes.paperContainer }}
                open={true}
                aria-labelledby="responsive-dialog-title"
                fullScreen
            >
                <DialogContent>
                    <DialogContentText>
                        {optionsSF && Object.keys(optionsSF).length > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'center',height: '100%' }}>
                                <SpreadsheetComponent
                                    scrollSettings={scrollSettings}
                                    ref={(ssObj) => { spreadsheet = ssObj }}
                                    enableVirtualization={true}
                                    allowNumberFormatting={true}
                                    allowDataValidation={true}
                                    showPager={false}
                                    isFinite={true}
                                    width={window.screen.width + 30}
                                    height={window.screen.height-50}
                                    allowEditing={true}
                                    contextMenuBeforeOpen={contextMenuBeforeOpen}
                                >
                                    <SheetsDirective>
                                        <SheetDirective
                                            name="Price List"
                                            colCount={optionsSF.columns && optionsSF.columns?.length}
                                            >
                                            <RowsDirective>
                                                <RowDirective >
                                                    <CellsDirective allowEditing={false}>
                                                        {optionsSF && optionsSF.columns && optionsSF.columns.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                    index={index}
                                                                    value={item.title}
                                                                    width={window.screen.width}
                                                                    style={{
                                                                        color: '#F2F2F2',
                                                                        backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                        textAlign: 'center',
                                                                    }}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective>
                                                <RowDirective >
                                                    <CellsDirective >
                                                        {optionsSFPredefined && optionsSFPredefined.map((item, index) => {
                                                            return (
                                                                <CellDirective
                                                                    index={index}
                                                                    value={item.title}
                                                                />
                                                            )
                                                        })}
                                                    </CellsDirective>
                                                </RowDirective>
                                            </RowsDirective>

                                            <ColumnsDirective>
                                                {optionsSFPredefined.map(item => {
                                                    return (<ColumnDirective width={window.screen.width/optionsSFPredefined.length}></ColumnDirective>)
                                                })}
                                            </ColumnsDirective>
                                        </SheetDirective>
                                    </SheetsDirective>
                                </SpreadsheetComponent>
                            </div>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmitExcel} color="primary" variant="contained">
                        Create
                    </Button>
                    <Button onClick={() => history.push('/driver-maintenance')} color="primary" variant="outlined" >
                        Back
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Dialog
                    onClose={() => setDialogue(false)}
                    aria-labelledby="simple-dialog-title"
                    open={dialogue}
                    disableEscapeKeyDown
                    classes={{ paper: classes.paperContainer }}>
                    <div className={classes.container}>
                        <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>

                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Analysis Level ID
                                </FormLabel>
                                <Select
                                    onChange={(e) => handlePriceList(e.target.value)}
                                    value={priceListID}

                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    className={clsx({
                                        [classes.select]: true,
                                    })}
                                >
                                    {priceListArray && priceListArray.value && priceListArray.value
                                        .map((item) => {
                                            return (
                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>

                            </Grid>

                        </Grid>
                    </div>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained"
                            onClick={handleContinue} disabled={priceListID ? false : true}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPriceListData(data)),
        getFieldValues: (key) => dispatch(getPriceFieldValues(key)),
        getDriverFormatFields: (analysisListId) => dispatch(getDriverFormatFields(analysisListId)),
    }
}

const mapStateToProps = state => {
    return {
        priceListId: state.pricingData.priceTypeNameList,
        maintenanceFields: state.profitOptimizationData.driverFormatFields,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);