import React, {  forwardRef, useRef, useImperativeHandle } from 'react';
import {Grid,Typography,Card,Table,TableCell,TableHead,TableRow,TableContainer,TableBody,CardHeader,IconButton,OutlinedInput,MenuItem,FormLabel,Select
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import Moment from 'moment';
import momentt from 'moment-timezone';

momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26,

    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    container2: {
        padding: 10,
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
}));

const TargetRules = forwardRef((props, ref) => {
    const classes = useStyles();
    const [contractID, setContractID] = React.useState('');
    const [targetType, setTargetType] = React.useState('');
    const [targetValue, setTargetValue] = React.useState('');
    const [validFrom, setValidFrom] = React.useState(null);
    const [validTo, setValidTo] = React.useState(null);

    const targetValues = {
        contract_id: contractID,
        target_type: targetType,
        target_value: targetValue,
        effective_start_date: validFrom,
        effective_end_date: validTo
    };
    useImperativeHandle(ref, () => ({
        submit() {
            props.submitValues(targetValues);
        }
    }));

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    return (
        <div>
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="COMMITMENT RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.data && props.data.target_rules
                            && props.data.target_rules.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Commitment Type</TableCell>
                                                <TableCell align='center'>Commitment Value</TableCell>
                                                <TableCell align='center' >Valid From</TableCell>
                                                <TableCell align='center' >Valid To</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {props.data.target_rules.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'>{item.target_type}</TableCell>
                                                        <TableCell align='center'>{item.target_value}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

});


export default connect(null, null, null, { forwardRef: true })(TargetRules);