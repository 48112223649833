import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { updateOrgDefaults, getOrgDefaults, uploadCompanyLogo } from '../../redux/actions';
import { makeStyles } from "@mui/styles";
import Compress from "browser-image-compression";

const Access = props => {
    const useStyles = makeStyles((theme) => ({
        input: {
            background: "white"
        },
        avatar: {
            height: 200,
            width: 200,
            margin: 20,
            padding: 10,
            border: '1px solid lightgrey'
        },
    }));
    const classes = useStyles();

    const [formData, setFormData] = React.useState({ address: '', headerText: '', footerText: '' });
    const [update,setUpdate]= React.useState(true)
    const handleUpdate = () => {
        setUpdate(true)
        props.updateOrgDefaults(formData);
    }
    async function handleImageUpload(event) {
        event.preventDefault();
        var fileSize = event.target.files[0].size / 1024;
        const file = event.target.files[0]
        const options = {
            maxSizeMB: .5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        Compress(file, options)
            .then(compressedBlob => {
                compressedBlob.lastModifiedDate = new Date()
                const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
                const data = new FormData();
                data.append('image', convertedBlobFile);
                setLogo(data)
                setUpdate(false)
                props.uploadProfileImage(data);
            })
            .catch(e => {
            })
    }
    
    const [logo, setLogo] = React.useState('');

    useEffect(() => {
        props.getOrgDefaults();
    }, [])
    useEffect(() => {
        if (props.orgDefaults && Object.keys(props.orgDefaults).length > 0) {
            if(update)
             setFormData(props.orgDefaults);
            setLogo(props.orgDefaults.organizationProfileURL)
        }
    }, [props.orgDefaults])

    return (
        <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row' }}>

            <Box
                textAlign='center'
                boxShadow={0}
                bgcolor="background.paper"
                m={1}
                p={1}
                style={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', display: 'flex', flexDirection: 'row' }}
            >
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 40 }}>

                    <Avatar
                        className={classes.avatar}
                        src={logo && logo}
                        variant={'square'}
                    />

                </div>
                <div>

                    <TextField id="filled-basic" label="Address" variant="filled" style={{ marginBottom: 15 }}
                        InputProps={{ className: classes.input }}
                        value={formData.address}
                        onChange={(e) => setFormData(prevState => ({
                            ...prevState,
                            address: e.target.value
                        }))}
                    />
                    <TextField id="filled-basic" label="Header Text" variant="filled" style={{ marginBottom: 15 }}
                        InputProps={{ className: classes.input }}
                        value={formData.headerText}
                        onChange={(e) => setFormData(prevState => ({
                            ...prevState,
                            headerText: e.target.value
                        }))}
                    />
                    <TextField id="filled-basic" label="Footer Text" variant="filled" style={{ marginBottom: 15 }}
                        InputProps={{ className: classes.input }}
                        value={formData.footerText}
                        onChange={(e) => setFormData(prevState => ({
                            ...prevState,
                            footerText: e.target.value
                        }))}
                    />
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button
                            small
                            color="primary"
                            fullWidth
                            variant="contained"
                            component="label"
                        >
                            {logo ? 'Update Company Logo' : 'Add Company Logo'}
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleImageUpload}
                                accept="image/x-png,image/jpg,image/jpeg"
                            />
                        </Button>
                    </div>
                    <Button variant="contained" color="primary" center onClick={handleUpdate}>
                        Update
                    </Button>
                </div>
            </Box>

        </Grid>
    );

};

const mapStateToProps = state => {
    return {
        orgDefaults: state.addMultipleConfigurationData.orgDefaults
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateOrgDefaults: (formData) => dispatch(updateOrgDefaults(formData)),
        getOrgDefaults: () => dispatch(getOrgDefaults()),
        uploadProfileImage: (file) => dispatch(uploadCompanyLogo(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Access);