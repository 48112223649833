import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button,
    Typography, Accordion, AccordionDetails, AccordionSummary, AccordionActions,
    Select,
    MenuItem
} from '@mui/material';
import '../../Styles/global.css'
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { ExpandMore, FolderOpenOutlined, ArrowLeft, Circle } from '@mui/icons-material';
import { PreMatureTable, PreMatureBox } from '../Components';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // height: '130vh',
        // maxHeight: '460vh'
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },

    container: {
        backgroundColor: '#f5f5f5',
        marginLeft: 5,
        padding: 20,

    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid #E8E8E8'
    },
    folder: {
        marginRight: theme.spacing(1),
    },
    spanRight: {
        marginLeft: 'auto',
        marginRight: '10px',
        border: '1px solid black',
        borderRadius: '16px',
        padding: '2px 10px',
        maxWidth: 150,
        minWidth: 96,
        height: 28,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },
    topHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 55,
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },
    mainHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 60,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    select: {
        display: 'flex',
        borderRadius: 4,
        marginRight: 30
    },
    title: {
        marginLeft: 20,
        color: 'var(--written-selected, #19170F)',
        fontFamily: 'IBM Plex Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.176px',
        fontFeatureSettings: '"liga" off, "clsg" off',
    },
    headingText: {
        color: 'var(--iconss, #7F7F7F)',
        fontFamily: 'IBM Plex Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.176px',
        fontFeatureSettings: '"liga" off, "clsg" off',
    }
}));


const EndPreMaturely = props => {
    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [promoState, setPromoState] = React.useState({});

    function handlePromoState(e, type) {
        if (type === 'market')
            setPromoState({ ...promoState, market: e })
        else if (type === 'usa')
            setPromoState({ ...promoState, usa: e })
        else
            setPromoState({ ...promoState, main: e })

    }

    return (
        <div className={clsx(classes.root, className)}>

            <div className={classes.topHeader}>
                <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div className={classes.row}>
                        <Button className='promo-font' style={{ marginLeft: 10, marginRight: 20 }} variant="outlined" startIcon={<ArrowLeft />} onClick={() => history.push('/promotion-dashboard')} >
                            Return
                        </Button>
                        <Typography className='promo-font' color='grey'>END PREMATURELY SCENARIO</Typography>
                        <Typography className='promo-font' style={{ fontSize: 13, marginTop: 3 }}>
                            <Circle style={{ marginLeft: 15, height: 10, color: 'red' }} />
                            In Progress
                        </Typography>
                    </div>


                </div>
            </div>
            <div className={classes.mainHeader}>
                <Typography className='promo-font' color='gray' style={{ marginLeft: 30 }}>PWC RETAIL PROMOTION, from April 05 2024 to May 31 2024</Typography>
                <div className={classes.select}>
                    <Select
                        value={promoState && promoState.main ? promoState.main : 'Promo Running'}
                        onChange={(e) => handlePromoState(e.target.value, 'main')}
                        style={{ textTransform: 'capitalize', width: 156, height: 35, backgroundColor: '#f5f5f5' }}
                        className='promo-font'
                    >

                        <MenuItem className='promo-font' value="Promo Running">Promo Running</MenuItem>
                        <MenuItem className='promo-font' value="End Prematurely">End Prematurely</MenuItem>

                    </Select>
                </div>
            </div>
            <div className={classes.container}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMore color='primary' />}
                        aria-controls="table1-content"
                        id="table1-header"
                        className={classes.accordionSummary}
                    >
                        <div style={{ flexGrow: 1, display: 'flex' }}>
                            <FolderOpenOutlined color="primary" className={classes.folder} />
                            <Typography className={classes.headingText}>Market 1</Typography>
                            <Typography className={classes.title} >0,8 M CAD/ Tot Promo Cost</Typography>
                        </div>
                        <div style={{ display: 'flex', marginRight: 5 }}>
                            <Select
                                value={'Promo Running'}
                                // onChange={(e) => handlePromoState(e.target.value, 'market')}
                                style={{ textTransform: 'capitalize', width: 156, height: 35, marginRight: 50, backgroundColor: '#f5f5f5', borderRadius: 4 }}
                                className='promo-font'
                            >

                                <MenuItem className='promo-font' value="Promo Running">Promo Running</MenuItem>
                                <MenuItem className='promo-font' value="End Prematurely">End Prematurely</MenuItem>

                            </Select>
                            <div className={classes.spanRight}>{'16'} States</div>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore color='primary' />}
                                aria-controls="table2-content"
                                id="table2-header"
                                className={classes.accordionSummary}
                            >
                                <div style={{ flexGrow: 1, display: 'flex' }}>
                                    <Typography className={classes.headingText}>USA</Typography>
                                    <Typography className={classes.title}>0,8 M CAD/ Tot Promo Cost</Typography>
                                </div>
                                <div style={{ display: 'flex', marginRight: 5 }}>
                                    <Select
                                        value={'Promo Running'}
                                        // onChange={(e) => handlePromoState(e.target.value, 'usa')}
                                        style={{ textTransform: 'capitalize', width: 156, height: 35, marginRight: 50, backgroundColor: '#f5f5f5', borderRadius: 4 }}
                                        className='promo-font'
                                    >
                                        <MenuItem className='promo-font' value="Promo Running">Promo Running</MenuItem>
                                        <MenuItem className='promo-font' value="End Prematurely">End Prematurely</MenuItem>
                                    </Select>
                                    <div className={classes.spanRight}>{'16'} States</div>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: 'flex', gap: 4 }}>
                                    <div style={{ width: '70%' }}>
                                        <PreMatureTable></PreMatureTable>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <PreMatureBox></PreMatureBox>
                                    </div>
                                </div>

                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getFilters: () => dispatch(getMaterialFilters()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndPreMaturely);