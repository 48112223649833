import React, { useEffect } from 'react';
import {
    MenuItem, Select, Checkbox,
    Grid, Typography, IconButton,
    InputAdornment, OutlinedInput
} from '@mui/material';
import {
    List, ArrowDropDownTwoTone, ArrowDropUpTwoTone,
} from '@mui/icons-material';
import clsx from 'clsx';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { SingleSelectList } from '../../Components';



const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 87,
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    toggleBtnContainer: {
        fontFamily: "IBM Plex Sans",
        border: '0px',
        '&:hover': {
            color: 'black',
        },
        '&:disabled': {
            border: '0px !important'
        }
    },
    country: {
        marginTop: 18,
        // marginLeft: 500,
        marginRight: 20,
        width: 150,
    },
    selectBox: {
        height: 37,
        marginTop: 23,
        marginLeft: 500,
        marginRight: 55,
        paddingLeft: 5,
        border: "1px solid",
        borderRadius: 2,
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
    },
    selectContainer: {
        height: 37,
        marginTop: 23,
        marginRight: 55,
        paddingLeft: 5,
    },
    prefillOutlined: {
        width: '100%',
        height: '37px',
        border: '0px'
    },
}));


const StickerTableHeader = (props) => {
    const classes = useStyles();
    const [productLinesList, setProductLinesList] = React.useState();
    const [productType, setProductType] = React.useState('');
    const [stickerName, setStickerName] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [scenarioList, setScenarioList] = React.useState('');
    const [anchorE1, setAnchorE1] = React.useState(false);
    const [scenarioExpand, setScenarioExpand] = React.useState([]);
    const [scenarios, setScenarios] = React.useState('');
    const [scenariosID, setScenariosID] = React.useState('');
    const countries = ['USA', 'CA'];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    };
    useEffect(() => {
        if (props.productLineData && props.productLineData.length > 0) {
            setProductLinesList(props.productLineData)
            // setProductType(props.productLineData[0])
        }
    }, [props.productLineData]);

    useEffect(() => {
        setStickerName(props.stickerName);
    }, [props.stickerName]);

    useEffect(() => {
        if (props.defaultDivison)
            setProductType(props.defaultDivison);
    }, [props.defaultDivison]);

    useEffect(() => {
        if (props.country) {
            setSelectedCountry(props.country);
        }
    }, [props.country]);

    useEffect(() => {
        if (props.data) {
            setScenarioList(props.data);
        }
    }, [props.data]);

    function handleChangeProduct(e) {
        props.onChange(e);
    }

    function handleCountry(e) {
        setSelectedCountry(e.target.value)
        props.onCountryChange(e.target.value);
    }

    function handleScenario(checked, id) {
        if (id) {
            setScenarios(checked)
            setScenariosID(id)
        }
        else {
            setScenarios('')
            setScenariosID('')
        }
        setScenarioExpand([])
        setAnchorE1(false)
        props.handlePrefill(id)
    }

    const handleClick = () => {
        setAnchorE1(!anchorE1)
        const isExpanded = !anchorE1
        setScenarioExpand(isExpanded ? [...scenarioExpand, 'scenario_filter'] : []);
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.mainHeader} style={{ border: props.bg && 'none', marginLeft: props.bg && 10 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={productType}
                        exclusive
                        onChange={(e) => handleChangeProduct(e)}
                        aria-label="Platform"
                        style={{ width: '48%' }}
                        disabled={props.isDisabled ? true : false}
                    >
                        {productLinesList && productLinesList.length > 0 && productLinesList.map(item => (
                            <>
                                <ToggleButton value={item} className={classes.toggleBtnContainer}
                                    disabled={props.enabledList ? props.enabledList.includes(item) ? false : true : false}>
                                    {item}
                                </ToggleButton>
                                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 3.5 }} />
                            </>
                        ))}
                    </ToggleButtonGroup>
                    {/* <div>
                        <Typography style={{ marginLeft: 5, marginTop: 23 }}>{stickerName}</Typography>
                    </div> */}
                    {selectedCountry &&
                        <div className={classes.country}>
                            <Select
                                id={'selected-country'}
                                value={selectedCountry}
                                onChange={handleCountry}
                                style={{ marginBottom: 10, backgroundColor: 'white', borderRadius: 5 }}
                                className='promo-font'
                            //readOnly={props.page === "Scenario" ? true : false}

                            >
                                {countries && countries
                                    .filter(item => props.page === "Scenario" && props.countryList ? props.countryList.includes(item) : item)
                                    .map(item =>
                                        <MenuItem className='promo-font' value={item}>{item == 'CA' ? 'CANADA' : item}</MenuItem>
                                    )
                                }
                                {/* <MenuItem value={'USA'}>USA</MenuItem>
                                <MenuItem value={'CA'}>CANADA</MenuItem> */}
                            </Select>
                        </div>
                    }
                    {scenarioList && props.page == 'review' &&
                        <div className={classes.selectContainer}>
                            <Select
                                disableUnderline
                                value={scenariosID}
                                onChange={(e) => handleScenario(null, e.target.value)}
                                displayEmpty
                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true
                                })}
                                style={{ textTransform: 'capitalize', width: 300 }}
                            >

                                {scenarioList && scenarioList.length > 0 && scenarioList
                                    .map((item) => {
                                        return (
                                            <MenuItem value={item.scenario_id} key={item.scenario_id} style={{ textTransform: 'capitalize' }}>
                                                {item.scenario_name}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </div>
                    }
                    {scenarioList && props.page != 'review' && <>
                        <div className={classes.selectBox}>
                            <List style={{ marginTop: 4 }} />
                            <OutlinedInput
                                twoline='true'
                                value={scenarios}
                                readOnly
                                placeholder='None Selected'
                                onClick={() => handleClick('scenario')}
                                className={classes.prefillOutlined}
                                disabled={props.isDisabled ? true : false}
                                endAdornment={
                                    !(props.isDisabled) && < InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClick('scenario')}
                                            disabled={props.isDisabled ? true : false}
                                        >
                                            {anchorE1 ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        {anchorE1 &&
                            <div >
                                <SingleSelectList head='Prefill' id={'prefill'} data={scenarioList} expand={scenarioExpand} onSubmit={handleScenario}
                                    prevalue={scenariosID} type='scenario_filter' />
                            </div>
                        }
                    </>
                    }
                </div>
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerTableHeader);