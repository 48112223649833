import React from 'react';
import { SupplierMasterUserTable } from './components/SupplierMaster';

const MaterialMaster = () => {
    return (
        <div>
            <SupplierMasterUserTable />
        </div >
    );
};

export default MaterialMaster;