import React, { useEffect, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Card,
    Typography, Fab, useMediaQuery
} from '@mui/material';
import clsx from 'clsx';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/Icons/TableIcons';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import 'react-edit-text/dist/index.css';
import { NavLink as RouterLink } from 'react-router-dom';
import { Send, CloudDownload } from '@mui/icons-material';
import { updateOutgoingClaimData, getClaimConfiguration, downloadClaimSubmissions } from '../../redux/actions';
import FileDownload from '@mui/icons-material/FileDownload';
const useStyles = makeStyles(theme => ({
    rootMain: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    header: {
        marginRight: 50
    },
    root: {
        '& .super-app-theme--cell': {
            textDecoration: 'underline',
            textAlign: 'center',
            textDecorationColor: '#848484'
        },
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
}));

const ClaimSubmissionsResult = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [claimAdjustmentCodes, setClaimAdjustmentCodes] = React.useState([]);
    const tableRef = useRef()
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    useEffect(() => {
        props.getClaimData();
    }, []);
    useEffect(() => {
        if (props.claimData && props.claimData.length > 0) {
            setClaimAdjustmentCodes(props.claimData[0].claim_adjustment_codes)
        }
    }, [props.claimData])
    useEffect(() => {
        var tempRows = [];
        if (props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0)
            props.outgoingClaimDetailData
                // .filter(e => (e.claim_status != 'Fully Reconciled' && e.claim_status != 'Approved'))
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        supplier_number: e.supplier_number,
                        supplier_name: e.supplier_name,
                        customer_number: e.customer_number,
                        customer_name: e.customer_name,
                        material_number: e.material_number,
                        material_description: e.material_description,
                        claim_number: e.claim_number,
                        unique_identifier: e.unique_identifier,
                        contract_number: e.contract_number,
                        claim_amount: e.claim_amount,
                        claim_status: e.claim_status,
                    });
                })
        setDataRows(tempRows);
    }, [props.outgoingClaimDetailData]);
    const columns = [
        {
            field: 'supplier_number',
            title: 'Supplier Number',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.supplier_number}</div>
        },
        {
            field: 'supplier_name',
            title: 'Supplier Name',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.supplier_name}</div>
        },
        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.customer_number}</div>
        },
        {
            field: 'customer_name',
            title: 'Customer Name',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.customer_name}</div>
        },
        {
            field: 'material_number',
            title: '  Material Number',
            type: 'string',
            render: rowData => rowData.material_number ? rowData.material_number : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'material_description',
            title: '  Material Description',
            type: 'string',
            editable: 'never'
        },
        {
            field: 'claim_number',
            title: 'Claim Number',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.claim_number}</div>
        },
        {
            field: 'unique_identifier',
            title: 'Unique Identifier',
            editable: 'never',
            type: 'numeric',
            render: rowData => rowData.unique_identifier ? rowData.unique_identifier : <div style={{ color: 'white' }}>-</div>
        },
        {
            field: 'contract_number',
            title: '  Contract Number',
            type: 'string',
            render: rowData => rowData.contract_number ? rowData.contract_number : <div style={{ color: 'white' }}>-</div>
        },

        {
            field: 'claim_amount',
            title: 'Claim Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.claim_amount}</div>
        },

        {
            field: 'claim_status',
            title: 'Claim Status',
            type: 'string',
            editable: 'never',
            render: rowData => <div style={{ whiteSpace: 'nowrap' }}>{rowData.claim_status}</div>
        },

    ];

    const handleReconcile = (type) => {
        var temp = [];
        var claimN = []
        var uniqueID = []
        selectedRows.map((item1) => {
            props.outgoingClaimDetailData.map((item2) => {
                if (item2.ID === item1.id) {
                    temp.push(item2)
                    claimN.push((item2.claim_number).toString())
                    uniqueID.push((item2.supplier_number).toString())
                }

            })
        })
        var claimNum = [...new Set(claimN)];
        var uniq = [...new Set(uniqueID)];
        var data = {
            "claim_number": claimNum,
            "supplier_number": uniq
        }
        props.downloadClaimSubmissions(data, type);
        setSelectedRows([]);
    }
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider className={clsx(classes.rootMain, className)}>
                <div className={clsx(classes.rootMain, className)}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h1" color='primary'> Claim Submissions Result </Typography>
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                onClick={() => { handleReconcile('EDI') }}
                                disabled={Object(selectedRows).length > 0 ? false : true}
                            >
                                <CloudDownload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>EDI File</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                onClick={() => { handleReconcile('email') }}
                                disabled={Object(selectedRows).length > 0 ? false : true}
                            >
                                <Send color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Email</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                onClick={handleReconcile}
                                disabled={Object(selectedRows).length > 0 ? false : true}
                            >
                                <CloudDownload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Download</Typography>
                            </Fab>
                        </div>
                    </div>
                    {props.outgoingClaimDetailData && props.outgoingClaimDetailData.length > 0 ?
                        <div>
                            <Card>
                                <form
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div>
                                        <MaterialTable
                                            tableRef={tableRef}
                                            title={' '}
                                            editable={true}
                                            icons={tableIcons}
                                            columns={columns}
                                            data={dataRows}
                                            style={{ marginTop: '-4.5rem' }}
                                            options={{
                                                paging: false,
                                                filtering: true,
                                                selection: true,
                                                headerStyle: theme.mixins.MaterialHeader,
                                                cellStyle: theme.mixins.MaterialCell,
                                                maxBodyHeight: '650px',

                                                searchFieldStyle: {
                                                    fontSize: 13
                                                },
                                            }}
                                            onSelectionChange={(rows) => setSelectedRows(rows)}
                                        />
                                    </div>
                                </form>
                            </Card>
                        </div>
                        :
                        <Typography>There is no data to show</Typography>
                    }
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        updateOutgoingClaimData: (id, formData) => dispatch(updateOutgoingClaimData(id, formData)),
        getClaimData: () => dispatch(getClaimConfiguration()),
        downloadClaimSubmissions: (data, type, email) => dispatch(downloadClaimSubmissions(data, type, email)),
    }
}

const mapStateToProps = state => {
    return {
        outgoingClaimDetailData: state.initialData.disputedClaims,
        claimData: state.calculationAnalysisData.claimData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSubmissionsResult);