import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Typography, useMediaQuery,
  TablePagination, Button, IconButton
} from "@mui/material";
import { Edit, DeleteForever, AddBoxOutlined } from "@mui/icons-material";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../components/Icons/TableIcons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { createExternalConfig, getExternalConfig } from "../../../../redux/actions/ProfitOptimizationConfig/AuthAction";
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
}));
const ExternalPricingConfigMain = (props) => {
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [dataRows, setDataRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [configData, setConfigData] = React.useState([]);
  useEffect(() => {
    props.getConfigData(1, 10);
  }, []);
  useEffect(() => {
    var tempRows = [];
    if (props.configData && props.configData.records) {
      setConfigData(props.configData.records);

      props.configData.records.map((e) => {
        tempRows.push({
          id: e.ID,
          application_type: e.application_type,
          analysis_level: e.analysis_level,
          driver_name: e.driver_name,
          allocation_type: e.allocation_type,
          source_data_type: e.source_data_type,
          date_type: e.date_type,
          source_data_attributes: e.source_data_attributes,
          external_attribute_names: e.external_attribute_names,
          allocation_key_figure: e.allocation_key_figure
        });
      });
      setCount(props.configData.total_record);
      setDataRows(tempRows);
    }
  }, [props.configData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getConfigData(newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    props.getConfigData(1, event.target.value);
  };
  function editConfig(id) {
    history.push({
      pathname: "/external-config/edit-external/" + id,
      id: id,
    });
  }
  const deleteConfig = (id) => {
    props.onDelete(id);
  };
  const columns = [
    {
      field: "application_type",
      title: "Application Type",
      type: "string",
    },
    {
      field: "analysis_level",
      title: "Analysis Level",
      type: "string",
    },
    {
      field: "driver_name",
      title: "Driver Name",
      type: "string",
    },
    {
      field: "allocation_type",
      title: "Allocation Type",
      type: "string",
    },
    {
      field: "source_data_type",
      title: "Source Data Type",
      type: "string",
    },
    {
      field: "date_type",
      title: "Date Type",
      type: "string",
    },
    {
      field: "source_data_attributes",
      title: "Source Data Attributes",
      type: "string",
      render: (rowData) => <a>{rowData.source_data_attributes && rowData.source_data_attributes.length > 0 ? rowData.source_data_attributes + ',' : ''}</a>
    },
    {
      field: "external_attribute_names",
      title: "External Attribute Names",
      type: "string",
      render: (rowData) => <a>{rowData.external_attribute_names && rowData.external_attribute_names.length > 0 ? rowData.external_attribute_names + ',' : ''}</a>
    },
    {
      field: "allocation_key_figure",
      title: "Allocation Key Figure",
      type: "string",
    },
    {
      field: "Actions",
      title: "Actions",
      type: "string",
      editable: "never",
      render: (rowData) => (
        <div>
          {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('put_external_config-'+localStorage.getItem('application')) && */}
          <IconButton
            classes={{ root: classes.IconButton }}
            onClick={() => editConfig(rowData.id)}
            size="large">
            <Edit color="disabled" style={{ fontSize: 20 }} />
          </IconButton>
          {/* } */}
          {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('put_external_config-'+localStorage.getItem('application')) && */}
          <IconButton
            classes={{ root: classes.IconButton }}
            onClick={() => deleteConfig(rowData.id)}
            size="large">
            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
          </IconButton>
          {/* } */}
        </div>
      ),
    },
  ];
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        text="Loading . Please wait ..."
        className={classes.spinner}
      >
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h2"> External Config </Typography>
          {/* { secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&  secureLocalStorage.getItem('dYtz').role.rights.includes('post_external_config-'+localStorage.getItem('application')) && */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxOutlined />}
            classes={{
              startIcon: !isDesktop && classes.startIcon,
              root: !isDesktop && classes.container,
            }}
            onClick={() => history.push("/external-config/add-external")}
          >
            {isDesktop ? "Add External Config" : ""}
          </Button>
          {/* } */}
        </div>

        {configData ? (
          <MaterialTable
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[5, 10, 20]}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ),
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                ></div>
              ),
            }}
            title={" "}
            editable={true}
            icons={tableIcons}
            columns={columns}
            data={dataRows}
            options={{
              search: false,
              filtering: true,
              pageSize: rowsPerPage,
              count: count,
              page: page,
              headerStyle: theme.mixins.MaterialHeader,
              cellStyle: theme.mixins.MaterialCell,
            }}
          />
        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
      </LoadingOverlay>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    configData: state.profitOptimizationData.externalConfigData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getConfigData: (pagination, limit) => dispatch(getExternalConfig(pagination, limit)),
    onDelete: (id) => dispatch(createExternalConfig(null, id, "delete")),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExternalPricingConfigMain);