import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel, Paper,
    Select, MenuItem, OutlinedInput,
    Table, TableCell, TableHead, TableRow, TableContainer, ListItemText,
    TableBody, IconButton, Button, Typography, Checkbox, Input,
} from '@mui/material';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import { HashLoader, RotateLoader } from 'react-spinners'
import { Dropdown, Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown, AutoCompleteDropdown } from '../../../components/Inputs';
import { connect } from 'react-redux';
import { addDocumentLevels, allowedApps, getWorkFlowAnchors, getLblDispDesFieldValue, getApprovalGroupData, getDefaultValues } from '../../../redux/actions';
import * as moment from 'moment';
import { targetDataUploadSuccess } from '../../../redux/actions/DataSetup/DataSetupTransaction/AuthAction';
import { keyCodes } from '@syncfusion/ej2-react-spreadsheet';
import { func } from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
    },
    container: {
        padding: '10px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 5px 0px 5px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 15,
        height: 75,
        maxHeight: 75
    },
    error: {
        color: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 150
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 5,
        width: '100%',
        alignItems: 'center',
        height: 36,
        border: '1px solid #E0E0E0',
        display: 'flex',
        padding: '8px 10px 9px 10px'
    },
    boxRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    boxGrid: {
        width: '100%'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'

    },
    formlabelContainer: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        marginBottom: 0

    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        // paddingBottom: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    selectedItem: {
        // color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        //fontFamily: 'ProximaNova',
        //fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8,
    },
    rootContainer: {
        border: '1px solid',
        width: "24rem",
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 20,
        marginRight: 40,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',

    },
    btn: {
        marginRight: 25,
        padding: '10px,25px'
    },
    newChip: {
        width: 697,
        borderRadius: 0,
        marginTop: 8

    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);
const DocLevel = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;
    const [approvalStatuses, setApprovalStatuses] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState('');
    const [allowedApps, setAllowedApps] = React.useState([]);
    // const [documentType, setDocumentType] = React.useState('');
    const [functionality, setFunctionality] = React.useState('');
    const [workflowName, setworkflowName] = React.useState('');
    const [currentStatus, setCurrentStatus] = React.useState(1);
    const [deletedDoc, setDeletedDoc] = React.useState([]);
    const [workflowAnchors, setWorkflowAnchors] = React.useState([]);
    const [mandatoryfields, setManadatoryFields] = React.useState([]);
    const [documentLevels, setDocumentLevels] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [selected1, setSelected1] = React.useState([]);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [selectOpen1, setSelectOpen1] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState('');
    const [currentIndex1, setCurrentIndex1] = React.useState('');
    const [approvalgroupArray, setApprovalGroupArray] = React.useState([]);
    const [fieldData, setFieldData] = React.useState({});
    const [workflowStatus, setworkflowStatus] = React.useState([]);
    const [statusDescriptions, setStatusDescriptions] = React.useState({});
    const [possibleStatuses, setPossibleStatuses] = React.useState({});
    const [currentIndex2, setCurrentIndex2] = React.useState('');
    const [selected2, setSelected2] = React.useState([]);
    const [selectOpen2, setSelectOpen2] = React.useState(false);



    const isAllSelected =
        approvalgroupArray.length > 0 && selected.length === approvalgroupArray.length;
    const isAllSelected1 =
        approvalgroupArray.length > 0 && selected.length === approvalgroupArray.length;
    const isAllSelected2 =
        workflowStatus.length > 0 && selected2.length === workflowStatus.length;


    const doc = {
        'application_type': applicationType,
        'functionality': functionality,
        'workflow_name': workflowName,
        'status_number': currentStatus,
        'approval_status': '',
        'status_description': '',
        'possible_statuses': [],
        'approval_group': 0,
        'notification_group': [],
        'external_approval': 'false'
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }

    function handleOpen2(index) {
        setCurrentIndex2(index)
        setSelectOpen2(true)
        setSelected2(documentLevels[index] && documentLevels[index]['possible_statuses'])
    }
    function handleClose2() {
        setCurrentIndex2('')
        setSelectOpen2(false)
        setSelected2([])
    }
    function onAdd2(event, index) {
        documentLevels[index]['possible_statuses'] = selected2
        onCancel2()
    }
    function onCancel2() {
        setSelectOpen2(false)
        setCurrentIndex2('')
    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1),
            )
        }
        if (value === "all") {
            setSelected2(selected2.length === workflowStatus.length ? [] : workflowStatus.map(item => item));
            return;
        }
        setSelected2(newSelected);
    }


    useEffect(() => {
        props.allowedApps();
        props.getWorkFlowAnchors();
        props.getApprovalGroupData();
        props.onLoadingFormField(['approval_status'])
        if (props.mode == 'create') {
            setDocumentLevels([doc])
            setCurrentStatus(currentStatus + 1)
        }
    }, []);
    useEffect(() => {
        if (props.workflowAnchors) {
            const filteredAnchors = props.workflowAnchors.filter(item => {
                return item.functionality !== "accrual" && item.functionality !== "payment";
            });
            setWorkflowAnchors(filteredAnchors)
        }
    }, [props.workflowAnchors])
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records.length > 0) {
            setApprovalGroupArray(props.approvalGroupData.records);
        }
    }, [props.approvalGroupData])
    useEffect(() => {
        if (props.formFields && props.formFields.records && props.formFields.records[0]
            && props.formFields.records[0].field_id == 'approval_status') {
            setApprovalStatuses(props.formFields.records[0].label_names)

        }
    }, [props.formFields])
    useEffect(() => {
        if (props.levelData && props.levelData.length > 0) {
            setApplicationType(props.levelData[0].application_type)
            setFunctionality(props.levelData[0].functionality)
            setworkflowName(props.levelData[0].workflow_name)
            setDocumentLevels(props.levelData)
            setCurrentStatus(props.levelData[props.levelData.length - 1]['status_number'])
            var data = { "data_source": props.levelData[0].functionality, "application_type": props.levelData[0].application_type }
            props.getLblDispDesFieldValue(data, 'post');
        }
        if (props.docStatuses) {
            setworkflowStatus(props.docStatuses)
        }
    }, [props.levelData, props.docStatuses]);
    useEffect(() => {
        if (documentLevels && documentLevels.length > 0 && (props.mode == 'create' || props.mode == 'update')) {
            documentLevels.map((item, index) => {
                documentLevels[index] = {
                    ...documentLevels[index], 'application_type': applicationType, 'functionality': functionality, 'workflow_name': workflowName,

                    // 'primary_anchor': primaryAnchor, 'primary_anchor_value': primaryAnchorValue, 'secondary_anchor': secondaryAnchor, 'secondary_anchor_value': secondaryAnchorValue
                }

            })
        }
    }, [applicationType, functionality, workflowName])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            if (props.mode == "create") {
                setApplicationType(props.allowedAppsData[0])
            }

        }
    }, [props.allowedAppsData])


    function handleOpen(index) {
        setCurrentIndex(index)
        setSelectOpen(true)
        setSelected(documentLevels[index] && documentLevels[index]['approval_group'])
    }
    function handleOpen1(index) {
        setCurrentIndex1(index)
        setSelectOpen1(true)
        setSelected1(documentLevels[index] && documentLevels[index]['notification_group'])
    }
    function handleClose() {
        setCurrentIndex('')
        setSelectOpen(false)
        setSelected([])
    }
    function handleClose1() {
        setCurrentIndex1('')
        setSelectOpen1(false)
        setSelected1([])
    }

    const handleDocumentLevels = (key, value, index) => {
        var docLevel = [...documentLevels]
        docLevel[index][key] = (key == 'status_number') ? parseInt(value) : value;
        // documentLevels[index][key] = (key == 'external_approval') ? String(value) : value;
        setDocumentLevels(docLevel)
    }

    var executed = false;
    useEffect(() => {
        if (props.tableData && props.tableData.field_label_attributes) {
            setFieldData(props.tableData.field_label_attributes)
        }
    }, [props.tableData]);

    function handleSubmit() {
        var data = documentLevels
            .filter((item, i) => item.status_number != '' && item.status_description != '')
        setDocumentLevels(data);
        if (props.mode == 'update') {
            var formData = {
                "document_workflow": data,
                "deleted_ids": deletedDoc
            }
            props.onSubmit(formData, props.mode)
        }
        else
            props.onSubmit(data, props.mode)
    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === approvalgroupArray.length ? [] : approvalgroupArray.map(item => item.ID));
            return;
        }

        setSelected(newSelected)
    }

    const handleSelectValues1 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected1.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected1, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected1.slice(1));
        } else if (selectedIndex === selected1.length - 1) {
            newSelected = newSelected.concat(selected1.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected1.slice(0, selectedIndex),
                selected1.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected1(selected1.length === approvalgroupArray.length ? [] : approvalgroupArray.map(item => item.ID));
            return;
        }

        setSelected1(newSelected)
    }
    function onCancel() {
        setSelectOpen(false)
        setCurrentIndex('')
    }
    function onCancel1() {
        setSelectOpen1(false)
        setCurrentIndex1('')
    }
    function onAdd(event, index) {
        documentLevels[index]['approval_group'] = selected
        onCancel()

    }
    function onAdd1(event, index) {
        documentLevels[index]['notification_group'] = selected1
        onCancel1()

    }

    const handleOnAdd = () => {
        const nextStatusNumber = documentLevels.length + 1;
        const newRow = {
            ...doc,
            status_number: nextStatusNumber
        };
        setDocumentLevels([...documentLevels, newRow]);
    };

    const handleDeleteLocally = (index) => {
        if (documentLevels.length == 1)
            setDocumentLevels([doc])
        else {
            var docLevel = [...documentLevels]
            var docLevel = docLevel.filter((v, i) => i !== index)
            setDocumentLevels([...docLevel])
        }
        setDeletedDoc([...deletedDoc, documentLevels[index]['ID']]);

    }
    const handleDeleteFilterData = (newValue, index, key) => {
        documentLevels[index][key] = documentLevels[index][key].filter(item => item !== newValue)
        //
        //setDocumentLevels({ ...documentLevels, [key]: deleteFilterData })
        //
    }
    const handleFunctionality = (e) => {
        var data = { "data_source": e.target.value, "application_type": applicationType }
        props.getLblDispDesFieldValue(data, 'post');
        setFunctionality(e.target.value);
        // setPrimaryAnchor('');
        // setPrimaryAnchorValue('');
        // setSecondaryAnchor('');
        // setSecondaryAnchorValue('');
        // workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.filter(function (v) {
        //     if (v.functionality === e.target.value) {
        //         setPrimaryAnchorArr(v.primary_anchors)
        //         setSecondaryAnchorArr(v.secondary_anchors)
        //     }
        // })
    }
    const handleWorkflowName = (e) => {
        setworkflowName(e.target.value)
    }
    const handleWorkflowStatus = (newValue, type) => {
        // let temp = [...workflowStatus];
        // temp.push(newValue)
        setworkflowStatus(newValue);
    }
    const handleDeleteWorkflowStatus = (value) => {
        var deletedWorkflowData = workflowStatus.filter(item => item !== value)
        setworkflowStatus(deletedWorkflowData)
    };
    useEffect(() => {
        const uniqueDescriptions = Array.from(new Set(workflowStatus));
        setStatusDescriptions(prevState => {
            const newState = { ...prevState };
            documentLevels.forEach((_, index) => {
                newState[index] = uniqueDescriptions;
            });
            return newState;
        });
    }, [workflowStatus, documentLevels]);


    return (
        <div className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16 }}
                        >
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={(e) => setApplicationType(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    disabled={props.mode != 'create' ? true : false}
                                >
                                    {allowedApps.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: -10 }}
                            >
                                <FormLabel {...props} classes={{ root: classes.fontSetting }} required>
                                    Functionality
                                </FormLabel>
                                <Select
                                    disableUnderline
                                    // disabled={props.mode != 'create' ? true : false}
                                    disabled={props.mode === 'view' ? true : false}
                                    value={functionality}
                                    onChange={handleFunctionality}
                                    displayEmpty
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    className={clsx({
                                        [classes.select]: true,
                                        [classes.error]: props.error
                                    })}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    {workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.map(item => {
                                        return (
                                            <MenuItem value={item['functionality']} key={item['functionality']} style={{ textTransform: 'capitalize' }}>
                                                {item['functionality'].replace(/_/g, ' ')}
                                            </MenuItem>
                                        );
                                    })
                                    }
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ paddingTop: -10 }}>
                                <div className={classes.selectRoot}>
                                    <FormLabel {...props} classes={{ root: classes.fontSetting }} required>
                                        Workflow Name
                                    </FormLabel>
                                    <OutlinedInput
                                        style={{ height: 36, paddingLeft: 8, marginTop: 9 }}
                                        disabled={props.mode != 'create' ? true : false}
                                        value={workflowName}
                                        onChange={handleWorkflowName}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}

                                    />
                                </div>
                            </Grid>
                            {props.mode !== 'view' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: -10 }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel {...props} classes={{ root: classes.fontSetting }} required>
                                            Workflow Status
                                        </FormLabel>
                                        <div className={classes.newChip} >
                                            <ChipInput data={workflowStatus} onChange={handleWorkflowStatus} />
                                        </div>
                                        {/* <ChipInput
                                            
                                            disabled={props.mode != 'create' ? true : false}
                                            value={workflowStatus}
                                            onAdd={(chips) => handleWorkflowStatus(chips)}
                                            onDelete={(chip) => handleDeleteWorkflowStatus(chip)}
                                            classes={{
                                                root: classes.rootContainer,
                                                chip: classes.chip,
                                                input: classes.input,
                                                inputRoot: classes.inputRoot,
                                                label: classes.chipLabel,
                                                chipContainer: classes.chipContainer,
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                                [classes.error]: props.error
                                            })}
                                            disableUnderline={true}
                                        /> */}
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </div>

                </form>
            </Card>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <TableContainer>
                            <Table>
                                <TableHead >
                                    <TableRow>
                                        <TableCell align='center' style={{ width: '100px' }}>Status Number <span style={{ color: 'red', }}>*</span></TableCell>
                                        <TableCell align='center'>Status Description<span style={{ color: 'red' }}>*</span></TableCell>
                                        <TableCell align='center' >Approval Status<span style={{ color: 'red' }}>*</span></TableCell>
                                        <TableCell align='center'>Next Possible Status<span style={{ color: 'red' }}>*</span></TableCell>
                                        <TableCell align='center'>Approval Group</TableCell>
                                        <TableCell align='center'>External Approval</TableCell>
                                        {/* <TableCell align='center'>Notification Group</TableCell>
                                        <TableCell align='center'>Change Approval</TableCell> */}

                                        {props.mode != 'view' &&
                                            <TableCell align='center' style={{ width: '100px' }}>Actions</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {documentLevels && documentLevels.map((item, index) => {
                                        return <>
                                            <StyledTableRow>
                                                <TableCell>
                                                    {props.mode == 'view' ?
                                                        item.status_number
                                                        :
                                                        <OutlinedInput
                                                            defaultValue={item.status_number}
                                                            type={'Number'}
                                                            id={index}
                                                            style={{ width: "8rem" }}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => handleDocumentLevels('status_number', parseInt(e.target.value), index)}
                                                        />

                                                    }
                                                </TableCell>
                                                <TableCell key={index}>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['status_description']
                                                        :
                                                        <Select
                                                            value={item.status_description}
                                                            onChange={(e) => handleDocumentLevels('status_description', e.target.value, index)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true,
                                                                [classes.error]: props.error
                                                            })}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            {statusDescriptions[index] && statusDescriptions[index].map((description, i) => (
                                                                <MenuItem key={i} value={description}>
                                                                    {description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {props.mode == 'view' ?
                                                        approvalStatuses && approvalStatuses[documentLevels[index]['approval_status']]
                                                        :
                                                        <Select
                                                            id={index}
                                                            value={documentLevels[index]['approval_status']}
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            onChange={(e) => handleDocumentLevels('approval_status', e.target.value, index)}

                                                        >
                                                            {approvalStatuses &&
                                                                Object.entries(approvalStatuses)
                                                                    .filter(([key, value]) => key != 'changes_acc')
                                                                    .map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={key}
                                                                                key={key}
                                                                                style={{ textTransform: "capitalize" }}
                                                                                disabled={key == 'acc' && (documentLevels && documentLevels.filter(item => item.approval_status == 'acc').length > 0 ? true : false)}
                                                                            >
                                                                                {value}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                            }
                                                        </Select>
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['possible_statuses'].join(",")
                                                        :
                                                        // <ChipInput
                                                        //     classes={{
                                                        //         root: classes.rootContainer,
                                                        //         chip: classes.chip,
                                                        //         input: classes.input,
                                                        //         inputRoot: classes.inputRoot,
                                                        //         label: classes.chipLabel,
                                                        //         chipContainer: classes.chipContainer,
                                                        //     }}
                                                        //     id={index}
                                                        //     defaultValue={documentLevels[index]['possible_statuses']}
                                                        //     onChange={(chips) => handleDocumentLevels('possible_statuses', chips, index)}
                                                        //     // onDelete={(chip,i) => handleDeleteFilterData(chip,index,item)}
                                                        //     disableUnderline={true}
                                                        // />
                                                        <Select
                                                            id={index}
                                                            multiple
                                                            value={documentLevels[index] && documentLevels[index]['possible_statuses'] ? documentLevels[index]['possible_statuses'] : []}
                                                            onOpen={() => handleOpen2(index)}
                                                            onClose={() => handleClose2()}
                                                            open={selectOpen2 && currentIndex2 == index}
                                                            // onChange={(event) => handleDocumentLevels('possible_statuses', event.target.value, index)}

                                                            // renderValue={(value) => {
                                                            //     return value.map((val) => {
                                                            //         const selectedStatus = workflowStatus.find((status) => status.ID === val);
                                                            //         return selectedStatus ? selectedStatus.name : '';
                                                            //     }).join(', ');
                                                            // }}
                                                            renderValue={(selected2) => selected2.join(', ')}
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            disabled={documentLevels[index]['approval_status'] === 'acc'}
                                                        >
                                                            <MenuItem
                                                                value="all"
                                                                onClick={(event) => handleSelectValues2(event, "all")}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                <Checkbox color="primary" checked={isAllSelected2} />
                                                                <ListItemText primary="Select All" />
                                                            </MenuItem>
                                                            {workflowStatus && workflowStatus.length > 0 &&
                                                                workflowStatus.map((item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            onClick={(event) => handleSelectValues2(event, item)}
                                                                            value={item}
                                                                            key={item}
                                                                            style={{ textTransform: "capitalize" }}
                                                                        >
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={selected2.indexOf(item) > -1}
                                                                            />
                                                                            <ListItemText primary={item.replace(/_/g, " ")} />
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                            <div className={classes.dropdownAction}>
                                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel2}>
                                                                    Cancel
                                                                </Button>
                                                                <Button variant="contained" color="primary" className={classes.btn} onClick={(e) => onAdd2(e, index)}>
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </Select>
                                                    }

                                                </TableCell>

                                                <TableCell>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['approval_group_details'] && documentLevels[index]['approval_group_details'].name
                                                        // .map(item => item.name).join(',')
                                                        :
                                                        <Select

                                                            id={index}
                                                            value={documentLevels[index] && documentLevels[index]['approval_group']}
                                                            // onOpen={() => handleOpen(index)}
                                                            // onClose={() => handleClose()}
                                                            // open={selectOpen && currentIndex == index}
                                                            // renderValue={(value) =>
                                                            //     approvalgroupArray.filter(item => value.includes(item.ID)).map(item => item.name).join(",")
                                                            // }
                                                            onChange={(e) => handleDocumentLevels('approval_group', e.target.value, index)}
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            disabled={documentLevels[index]['approval_status'] === 'acc'}
                                                        >
                                                            <MenuItem value={0}> </MenuItem>
                                                            {approvalgroupArray &&
                                                                approvalgroupArray.map((item) => {
                                                                    return (
                                                                        <MenuItem

                                                                            value={item.ID}
                                                                            key={item.ID}
                                                                            style={{ textTransform: "capitalize" }}
                                                                        >
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    );
                                                                })}


                                                        </Select>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['external_approval'] && documentLevels[index]['external_approval'] === "true" ? 'Yes' : 'No'
                                                        :
                                                        <Select
                                                            value={documentLevels[index]['external_approval']}
                                                            onChange={(e) => handleDocumentLevels('external_approval', e.target.value, index)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            defaultValue={"false"}
                                                        >
                                                            <MenuItem value={"true"}>Yes</MenuItem>
                                                            <MenuItem value={"false"}>No</MenuItem>
                                                        </Select>
                                                    }
                                                </TableCell>


                                                {/* <TableCell>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['notification_group'].join(',')
                                                        :
                                                        <Select
                                                            multiple
                                                            id={index}
                                                            value={documentLevels[index] && documentLevels[index]['notification_group'] ? documentLevels[index]['notification_group'] : []}
                                                            onOpen={() => handleOpen1(index)}
                                                            onClose={() => handleClose1()}
                                                            open={selectOpen1 && currentIndex1 == index}
                                                            renderValue={(value) =>
                                                                approvalgroupArray.filter(item => value.includes(item.ID)).map(item => item.name).join(",")
                                                            }
                                                            style={{ width: '100%', marginTop: 10, textTransform: 'capitalize' }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem
                                                                value="all"
                                                                onClick={(event) => handleSelectValues1(event, "all")}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                <Checkbox color="primary" checked={isAllSelected1} />
                                                                <ListItemText primary="Select All" />
                                                            </MenuItem>
                                                            {approvalgroupArray &&
                                                                approvalgroupArray.map((item, i) => {
                                                                    return (
                                                                        <MenuItem
                                                                            onClick={(event) =>
                                                                                handleSelectValues1(event, item.ID)
                                                                            }
                                                                            value={item.ID}
                                                                            key={item.ID}
                                                                            style={{ textTransform: "capitalize" }}
                                                                        >
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={selected1.indexOf(item.ID) > -1}
                                                                            />
                                                                            <ListItemText primary={item.name} />
                                                                        </MenuItem>
                                                                    );
                                                                })}

                                                            <div className={classes.dropdownAction}>
                                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={(e) => onCancel1(e, index)}>
                                                                    Cancel
                                                                </Button>

                                                                <Button variant="contained" color="primary" className={classes.btn} onClick={(e) => onAdd1(e, index)} >
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </Select>
                                                    }
                                                </TableCell> */}

                                                {/* <TableCell>
                                                    {props.mode == 'view' ?
                                                        documentLevels[index]['change_approval'] ? 'Yes' : 'No'
                                                        :
                                                        <Select
                                                            value={documentLevels[index]['change_approval']}
                                                            onChange={(e) => handleDocumentLevels('change_approval', e.target.value, index)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>
                                                        </Select>
                                                    }
                                                </TableCell> */}



                                                {props.mode != 'view' &&
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() => handleDeleteLocally(index)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ visibility: index == documentLevels.length - 1 ? 'visible' : 'hidden', padding: 0 }}
                                                            onClick={handleOnAdd}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <AddBoxOutlined />
                                                        </IconButton>

                                                    </TableCell>
                                                }
                                            </StyledTableRow>

                                        </>;
                                    })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>

                </form>
            </Card>
            {
                props.mode != 'view' &&
                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                        disabled={applicationType && functionality && workflowName && workflowStatus && (documentLevels.filter(item => (item.approval_status == '' || item.status_description == ''))).length == 0 ? false : true}>

                        {props.loading ?
                            <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            }
        </div >
    );

});

const mapStateToProps = state => ({
    allowedAppsData: state.initialData.allowedApps,
    workflowAnchors: state.customerData.workflowAnchors,
    tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
    formFields: state.addMultipleConfigurationData.multipleConfigurationData,
});

const mapDispatchToProps = dispatch => {
    return {
        allowedApps: () => dispatch(allowedApps()),
        getWorkFlowAnchors: () => dispatch(getWorkFlowAnchors()),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        onSubmit: (data, mode) => dispatch(addDocumentLevels(data, mode)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        onLoadingFormField: (fields) => dispatch(getDefaultValues('Customer Rebate', null, null, fields, "filter-fields")),


    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DocLevel);