import React, { useEffect, useRef } from "react";
import {
    Input, Grid, Button, Typography, Breadcrumbs, Card, FormLabel, OutlinedInput, Select, MenuItem, Dialog,
    DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { getMembershipData, createPriceListDataAll, getPriceMaintenanceFields, getPriceListData, getPriceFilterData } from '../../../redux/actions/Pricing/AuthAction';
import Moment from 'moment';
import { getMaterialMasterData, getAttributeListData, getCustomerMasterData } from '../../../redux/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import '../../../../node_modules/jsuites/dist/jsuites.css';
import '../../../../node_modules/jsuites/dist/jsuites.js';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import {
    RangeDirective, getRangeIndexes, getRangeAddress,
    SpreadsheetComponent, getCell, SheetsDirective, SheetDirective, ColumnsDirective as ColumnsDirectiveS,
    RangesDirective, RowsDirective, RowDirective, CellsDirective,
    CellDirective
} from '@syncfusion/ej2-react-spreadsheet';
import { Link } from 'react-router-dom';

import {
    Edit, ColumnDirective, ColumnsDirective, GridComponent,
    Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, PdfExport, Search, Filter, InfiniteScroll
} from '@syncfusion/ej2-react-grids';
import { MultiSelectDropdown } from "../../../components/Inputs";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        height: '100%'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: '0px 15px 15px 15px',
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        // padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',

    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    formlabel: {
        paddingTop: 15,
        marginBottom: 12,
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"

    },
    paperContainer: {
        minWidth: window.screen.width * .80,
        padding: "30px 30px 10px 30px"
    },
    input: {
        width: 42,
    },
    addRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'
    }
}));

const AddPriceListData = props => {
    const classes = useStyles();
    const jRef = useRef(null);
    const history = useHistory();
    useEffect(() => {
        props.getCustomerMasterData();
        props.getMaterialMasterData();

    }, [])
    const scrollSettings = { isFinite: true, height: '100%' };
    const [customerMasterDataArray, setCustomerMasterDataArray] = React.useState([])
    const [materialMasterDataArray, setMaterialMasterDataArray] = React.useState([])
    let spreadsheet;
    let gridInstance;
    let dropDownInstance;
    var grid;
    const [configDataAPI, setConfigDataAPI] = React.useState([]);
    const [priceListID, setPriceListID] = React.useState('');
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const [listDataArray, setListDataArray] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [priceListData, setPriceListData] = React.useState([]);
    const [priceTypeNameArray, setPriceTypeNameArray] = React.useState([]);
    const [excelDialog, setExcelDialog] = React.useState(false);
    const [addNRows, setAddNRows] = React.useState(5);
    const [columns, setColumns] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const [dataRowsNew, setDataRowsNew] = React.useState([]);
    const [optionsList, setOptionsList] = React.useState([]);
    const toolbarOptions = ['Edit', 'Update', 'Cancel', 'PdfExport', 'ExcelExport', 'Search'];
    const [rowCount, setRowCount] = React.useState(1000);
    const [filterList, setFilterList] = React.useState({});
    var name = history.location.data?.price_type_name;
    var priceType = history.location.data?.price_type;
    const [colCount, setColCount] = React.useState(0);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
            })

            setCustomerMasterDataArray(tempArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
            })

            setMaterialMasterDataArray(tempArray);
        }
    }, [props.materialMasterData])
    function titleCase(str) {
        if (str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
    const [gridModeEdit, setGridModeEdit] = React.useState(false);
    function handleOnSubmit(type) {
        if (type === 'batch') {
            setGridModeEdit(false)
        } else {
            setGridModeEdit(true)
        }
        setSubmitClicked(true)

        var data = {
            "price_fields": listDataArray.price_type_fields,
            "filters": formData
        }
        props.getConfigData(data);
        // props.getConfigData(null, null, { ...formData, 'price_list_id': priceListID, 'price_type_name': name });
        setFormData({})
        setExcelDialog(true)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    useEffect(() => {
        if (props.maintenanceFields) {
            setListDataArray(props.maintenanceFields)
            if (props.maintenanceFields.filter_fields) {
                var filter = props.maintenanceFields.filter_fields.filter(item => item != 'start_date' && item != 'end_date')
                var data = {
                    'filter_fields': filter,
                    "price_fields": props.maintenanceFields.price_type_fields,
                }
                props.getFilterdata(data)
            }
        }
    }, [props.maintenanceFields])
    useEffect(() => {
        if (props.filterArray)
            setFilterList(props.filterArray)

    }, [props.filterArray])

    useEffect(() => {
        if (props.priceListData && props.priceListData.records && props.priceListData.records[0]) {
            setPriceListData(props.priceListData.records);
            setPriceTypeNameArray(props.priceListData.records[0].price_type_name)
        }
    }, [props.priceListData]);
    const updatedSheet = () => {
        var json = [];
        var obj = {};
        var ssObj = spreadsheet;
        // 
        if (gridModeEdit) {
            props.onSubmitAll(dataRowsNew);
            setExcelDialog(false);
        }
        else {
            var usedRange = ssObj.getActiveSheet().usedRange;
            var selIndex = [1, 0, usedRange.rowIndex, usedRange.colIndex];
            var range =
                ssObj.getActiveSheet().name + '!' + getRangeAddress([1, 0, selIndex[2], selIndex[3]]);
            ssObj.getData(range).then((value) => {
                value.forEach((cell, key) => {
                    var indexes = getRangeIndexes(key);
                    if (cell && selIndex[2] >= indexes[0]) {
                        var charCurrent = 'A';
                        columns.map(item => {
                            if (key.indexOf(charCurrent) > -1) {
                                if (item.field == 'start_date' || item.field == 'end_date')
                                    obj[item.field] = cell.value ? Moment(ExcelDateToJSDate(cell.value)).format('MM/DD/YYYY') : '';
                                else if (item.field == 'price_type_value')
                                    obj[item.field] = cell.value ? parseFloat(cell.value) : 0;
                                else
                                    obj[item.field] = cell.value ? cell.value.toString() : '';
                            }
                            obj['price_list_id'] = priceListID
                            obj['price_type_name'] = sessionStorage.getItem('pricetypename')
                            charCurrent = String.fromCharCode(charCurrent.charCodeAt() + 1)
                        });
                        if (indexes[1] === selIndex[3]) {
                            json.push(obj);
                            obj = {};
                        }
                    }
                });
                props.configData.map((item, ix) => {
                    if (json[ix])
                        json[ix]['ID'] = item.ID
                })
                var data = {
                    "price_list_data_request": json,
                    "price_fields": listDataArray.price_type_fields//[{'price_list_id': priceListID, 'price_type_name': name,price_type:priceType}]
                }

                props.onSubmitAll(data);

            });
        }
    }
    function ExcelDateToJSDate(serial) {
        var hours = Math.floor((serial % 1) * 24);
        var minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
        return new Date(Date.UTC(0, 0, serial, hours - 17, minutes));
    }


    useEffect(() => {
        if (submitClicked && props.configData && props.configData.length > 0 && listDataArray && listDataArray.qualifier_labels && listDataArray.qualifier_labels.length > 0) {
            setConfigDataAPI(props.configData);
            if (jRef.current && jRef.current.jexcel)
                jRef.current.jexcel.destroy();
            var temp = [];
            var tempSingle = [];
            var columnArray = [];
            var options = []
            var rec = props.configData;
            rec.map(item => {
                listDataArray.qualifier_labels
                    .filter(h => priceTypeNameArray ? h.key !== 'price_type_name' : h)
                    .filter(h => props.configData[0].calculation_type && props.configData[0].calculation_type != 'Analytical' ? h.key != 'max_value' && h.key != 'min_value' : h)
                    .filter(h => (props.configData[0].calculation_type && props.configData[0].calculation_type == '%' || props.configData[0].calculation_type == 'Fixed') ? h.key != 'per_unit'/* && h.key != 'scale_from' */ && h.key != 'pricing_uom' && (props.configData[0].calculation_type == '%' ? h.key != 'currency' : h) && (props.configData[0].calculation_type == 'Fixed' ? h.key != 'scale_from' : h) : h)
                    .map(h => {
                        if (item[h.key]) {
                            if (h.key === 'start_date' || h.key === 'end_date') {
                                tempSingle.push(Moment.utc(item[h.key]).format('MM/DD/YYYY'))
                            } else {
                                tempSingle.push(item[h.key])
                            }
                        } else {
                            tempSingle.push('0');
                        }
                    })
                temp.push(tempSingle);
                tempSingle = [];
            })
            listDataArray.qualifier_labels
                .filter(h => priceTypeNameArray ? h.key !== 'price_type_name' : h)
                .filter(h => props.configData[0].calculation_type && props.configData[0].calculation_type != 'Analytical' ? h.key != 'max_value' && h.key != 'min_value' : h)
                .filter(h => (props.configData[0].calculation_type && props.configData[0].calculation_type == '%' || props.configData[0].calculation_type == 'Fixed') ? h.key != 'per_unit'/* && h.key != 'scale_from' */ && h.key != 'pricing_uom' && (props.configData[0].calculation_type == '%' ? h.key != 'currency' : h) && (props.configData[0].calculation_type == 'Fixed' ? h.key != 'scale_from' : h) : h)
                .map(h => {
                    if (h.key === 'start_date' || h.key === 'end_date' || h.key === 'per_unit') {
                        columnArray.push({
                            field: h.key,
                            title: h.value,//titleCase(h.replace(/_/g, ' ')),
                            width: 100
                        });
                        options.push({
                            title: '',
                            width: 100
                        });

                    } else {
                        columnArray.push({
                            field: h.key,
                            title: h.value,//titleCase(h.replace(/_/g, ' ')),
                            width: 150
                        });
                        options.push({
                            title: '',
                            width: 100
                        });
                    }
                })
            var headers = []
            columnArray.map(item => {
                headers.push(item.field)
            })
            setColumns(columnArray);
            setColCount(columnArray.length)
            setOptionsList(options)
            var tempRows = [];
            var tempObj = {};
            var tempRowsNew = [];
            var tempObjNew = {};
            props.configData.map((item, ix) => {
                //tempObj=item
                // headers
                columnArray.map((h, ix2) => {
                    if (h.field === 'start_date' || h.field === 'end_date') {
                        tempObj[h.title] = Moment.utc(item[h.field]).format('M/D/YYYY')
                        tempObjNew[h.field] = Moment(item[h.field]).format('MM/DD/YYYY')
                    }
                    else {
                        tempObj[h.title] = item[h.field]
                        tempObjNew[h.field] = item[h.field]
                    }
                })
                tempRows['price_list_id'] = priceListID
                tempRows['price_type_name'] = sessionStorage.getItem('pricetypename')
                tempRows[ix] = tempObj;
                tempObj = {};
                tempRowsNew['price_list_id'] = priceListID
                tempRowsNew['price_type_name'] = sessionStorage.getItem('pricetypename')
                tempRowsNew[ix] = tempObjNew;
                tempObjNew = {};
            })
            setDataRows(tempRows);
            setDataRowsNew(tempRowsNew);
            if (tempRows && tempRows.length > rowCount)
                setRowCount(tempRows.length)
        }
    }, [props.configData, listDataArray]);
    function handleFormData(value, key) {
        if ((key == 'start_date' || key == 'end_date') && value) {
            setFormData({ ...formData, [key]: Moment(value).local().format('YYYY-MM-DD') })
        }
        else {
            if (value && value.length > 0)
                setFormData({ ...formData, [key]: value })
        }
    }
    function addRow() {
        setRowCount(rowCount + addNRows)
    }
    const filterOptions = {
        type: 'Excel'
    };
    const editSettings = { mode: 'Batch', allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };
    const actionBegin = (args) => {
    }
    const handleActionUpdate = (args) => {
        var updatedData = []
        var data = configDataAPI;
        var newDataRow = args.rows;
        var prevDataRow = configDataAPI[args.rowIndex];
        if (newDataRow && newDataRow.length > 0) {
            var updatedData = newDataRow.map(item => item.data)
            //newDataRow && newDataRow.length > 0 && newDataRow.data
            updatedData && updatedData.map(([key, value]) => {
                if (typeof data[args.rowIndex][key] === 'number') {
                    if (Number.isInteger(data[args.rowIndex][key])) {
                        prevDataRow[key] = value ? parseInt(value) : 0;
                    } else {
                        prevDataRow[key] = value ? parseFloat(value) : 0;
                    }
                } else if (typeof data[args.rowIndex][key] === 'string') {
                    prevDataRow[key] = value ? value.toString() : '';
                } else {
                    prevDataRow[key] = value;
                }

                prevDataRow['price_list_id'] = priceListID
                prevDataRow['price_type_name'] = sessionStorage.getItem('pricetypename')
            })
            data[args.rowIndex] = prevDataRow;
        }
        setDataRowsNew(data);
    }
    function contextMenuBeforeOpen() {
        spreadsheet.removeContextMenuItems(["Insert Column", 'Delete Column', 'Hide Column']); //Items that needs to be removed, Set `true` if the given `text` is a unique id.
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/pricing-maintenance'
                >
                    Price Maintenance
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Price Maintenance Data</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {listDataArray && listDataArray.filter_fields && listDataArray.filter_fields.length > 0 && listDataArray.qualifier_labels && listDataArray.qualifier_labels.length > 0 &&
                                    listDataArray.qualifier_labels
                                        //.filter(item => item != 'price_type_name' && item != 'uom' && item != 'per_unit' && item != 'min_value' && item != 'max_value')
                                        .filter(item => listDataArray.filter_fields.includes(item.key))
                                        .map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>

                                                        {item.key == 'start_date' || item.key == 'end_date' ?
                                                            <>
                                                                <FormLabel className={classes.formlabel}>
                                                                    {item.value}
                                                                </FormLabel>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '3px 0 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%',
                                                                                    marginTop: 6
                                                                                }
                                                                            }}
                                                                            value={formData[item.key] ? formData[item.key] : null}
                                                                            onChange={(e) => handleFormData(e, item.key)}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Grid>
                                                                </MuiPickersUtilsProvider>
                                                            </>
                                                            :
                                                            <>
                                                                <MultiSelectDropdown classes={{ root: classes.fontSetting }} heading={item.value} listArray={filterList && filterList[item.key]} data={formData[item.key] ? formData[item.key] : []} id={item.key} onChange={(e) => handleFormData(e, item.key)} capitalize />
                                                            </>
                                                        }
                                                    </div>
                                                </Grid>
                                            );
                                        })}


                            </Grid>

                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => handleOnSubmit('batch')}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Update'
                        }
                    </Button>

                </div>
            </div >
            <Dialog
                classes={{ paper: classes.paperContainer }}
                open={excelDialog}
                onClose={() => setExcelDialog(false)}
                aria-labelledby="responsive-dialog-title"
                fullScreen
            >
                <DialogContent style={{ justifyContent: 'center', display: 'flex' }}>
                    <DialogContentText >
                        {dataRows && dataRows.length > 0 && columns &&
                            <div style={{ height: '100%' }}>
                                <SpreadsheetComponent

                                    scrollSettings={scrollSettings}
                                    ref={(ssObj) => { spreadsheet = ssObj }}
                                    enableVirtualization={true}
                                    allowNumberFormatting={true}
                                    allowDataValidation={true}
                                    showPager={false}
                                    isFinite={true}
                                    width={window.screen.width + 30}
                                    contextMenuBeforeOpen={contextMenuBeforeOpen}
                                >
                                    <SheetsDirective>
                                        <SheetDirective
                                            name="Price List"
                                            colCount={colCount}
                                            rowCount={rowCount}
                                        >

                                            <RowsDirective>
                                                <RowDirective  >
                                                    <CellsDirective allowEditing={false}>
                                                        {columns
                                                            .map((item, index) => {
                                                                return (

                                                                    <CellDirective
                                                                        index={index}
                                                                        headerText={item.title}
                                                                        value={item.title}
                                                                        field={item.field}
                                                                        width={window.screen.width / (columns.length)}
                                                                        style={{
                                                                            color: '#F2F2F2',
                                                                            backgroundColor: '#095eb5',//localStorage.getItem('appTheme')!='Light'&&localStorage.getItem('appTheme'), 
                                                                            textAlign: 'center',
                                                                        }}

                                                                    />
                                                                )
                                                            })}
                                                    </CellsDirective>

                                                </RowDirective>
                                            </RowsDirective>
                                            <ColumnsDirectiveS>
                                                {columns && columns
                                                    .map(item => {
                                                        return <ColumnDirective
                                                            width={window.screen.width / (columns.length)}
                                                            // hidden={item.field === 'ID'?true:false}
                                                            // editType={(item.field === 'end_date' || item.field === 'start_date') ? 'datetimepickeredit' : 'stringedit'}
                                                            headerText={item.title} field={item.field}
                                                            format={(item.field === 'end_date' || item.field === 'start_date') ? 'mm-dd-yyyy' : null}
                                                            type={(item.field === 'end_date' || item.field === 'start_date') ? 'shortDate' : null}
                                                            validation={
                                                                item.field === 'customer_number' ?
                                                                    { type: 'List', value1: customerMasterDataArray.join(', ') } :
                                                                    item.field === 'material_number' ?
                                                                        { type: 'List', value1: materialMasterDataArray.join(', ') }

                                                                        : null}
                                                        />
                                                    })}
                                            </ColumnsDirectiveS>
                                            <RangesDirective>
                                                <RangeDirective dataSource={dataRows}></RangeDirective>
                                            </RangesDirective>
                                        </SheetDirective>
                                    </SheetsDirective>
                                </SpreadsheetComponent>



                                {/* <div ref={jRef} /> */}
                            </div>
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className={classes.addRow} style={{ width: '100%' }}>
                        <div style={{ display: 'flex' }}>

                            <Input
                                className={classes.input}
                                value={addNRows}
                                margin="dense"
                                onChange={(e) => setAddNRows(e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    //'aria-labelledby': 'input-slider',
                                }}
                            />
                            <Button variant="string" onClick={addRow}> Add More Rows </Button>
                        </div>
                    </div>
                    <Button onClick={updatedSheet} color="primary" variant="contained">
                        Update
                    </Button>
                    <Button onClick={() => setExcelDialog(false)} color="primary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmitAll: (data) => dispatch(createPriceListDataAll(data)),
        getPriceMaintenanceFields: (priceListId) => dispatch(getPriceMaintenanceFields(priceListId)),
        //getConfigData: (pagination, limit, appId) => dispatch(getPriceListData(pagination, limit, appId, 'editPriceList')),
        getConfigData: (formData) => dispatch(getPriceListData(null, null, formData, 'bulkEdit')),
        getFilterdata: (data) => dispatch(getPriceFilterData(data)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData(1, 10000)),
    }
}

const mapStateToProps = state => {
    return {
        loadingAPI: state.pricingData.loading,
        priceListId: state.pricingData.priceTypeNameList,
        // maintenanceFields: state.pricingData.priceMaintenanceFields,
        maintenanceFields: state.pricingData.pricingFields,
        configData: state.pricingData.priceListData,
        priceListData: state.pricingData.priceListMasterData,
        filterArray: state.pricingData.priceFilterData,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPriceListData);

