import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Select,
    FormLabel,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getCountryData, updatePromotionTerritoryData } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 36
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const PromotionTerritoryEdit = props => {
    const classes = useStyles();
    const [belt, setBelt] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [state, setState] = React.useState('');

    function handleBelt(newValue) {
        setBelt(newValue.target.value);
    }
    function handleCountry(newValue) {
        setCountry(newValue.target.value);
    }
    function handleRegion(newValue) {
        setRegion(newValue.target.value);
    }
    function handleState(newValue) {
        setState(newValue.target.value);
    }

    function handleOnClear() {
        setBelt('')
        setCountry('')
        setRegion('')
        setState('')
    }
    const handleSubmit = () => {
        var formData = {
            belt: belt,
            country: country,
            region: region,
            state: state,

        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(formData, appId);
    };
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getSingleData(appId);
    }, []);
    useEffect(() => {
        if (props.promotionTerritoryDataSingle && props.promotionTerritoryDataSingle[0]) {
            if (props.promotionTerritoryDataSingle[0]) {
                setBelt(props.promotionTerritoryDataSingle[0].belt);
                setCountry(props.promotionTerritoryDataSingle[0].country);
                setRegion(props.promotionTerritoryDataSingle[0].region);
                setState(props.promotionTerritoryDataSingle[0].state);
            }
        }
    }, [props.promotionTerritoryDataSingle]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promotion-territory'
                >
                    Promotion Territory
                </Link>
                <Typography color="textPrimary" variant='h4'>Promotion Territory Edit </Typography>
            </Breadcrumbs>
            {
                <div className={classes.bodyContainer}>
                    <Card
                        style={{ marginTop: 15 }}>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container
                                    style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ marginTop: "15px" }}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Belt
                                        </FormLabel>
                                        <OutlinedInput
                                            value={belt}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleBelt}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Country
                                        </FormLabel>
                                        <OutlinedInput
                                            value={country}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCountry} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            Region
                                        </FormLabel>
                                        <OutlinedInput
                                            value={region}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleRegion}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel classes={{ root: classes.formLabel }} required>
                                            State
                                        </FormLabel>
                                        <OutlinedInput
                                            value={state}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleState}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>

                    <div className={classes.submitContainer}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleOnClear}>
                            Clear
                        </Button>
                        <Button color="primary" variant="contained" className={classes.button} onClick={handleSubmit}
                            disabled={belt && country && region && state ? false : true}>
                            Submit
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        promotionTerritoryDataSingle: state.promotionData.territoryIdData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(updatePromotionTerritoryData(data, id)),
        getSingleData: (id) => dispatch(getCountryData(null, id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromotionTerritoryEdit);