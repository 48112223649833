import React, { useEffect } from 'react';
import {
    Typography,Breadcrumbs,
    Table,TableBody,TableContainer, TableHead, TableRow, Paper, TableCell
} from '@mui/material';
import { makeStyles, withStyles} from '@mui/styles';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { getContractDetailsDataCompare,changeRequestDetails } from '../../redux/actions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    container: {
        flex: 1
    }
}));



const ChangeRequestDetail = props => {
    const classes = useStyles();
    const [oldDataAPI, setOldDataAPI] = React.useState({});
    const [newDataAPI, setNewDataAPI] = React.useState({});
    const [changedObject, setChangedObject] = React.useState({});
    const navParams = useLocation();
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId){
            props.getcontractViewdetails(appId);
            props.changeRequestDetails(appId)
        }
    }, [])

    useEffect(() => {
        if (props.oldData && Object.keys(props.oldData).length > 0) {
            var data = { ...props.oldData };
            setOldDataAPI(data);
        }
    }, [props.oldData])
    useEffect(() => {
        if (props.changedData && Object.keys(props.changedData).length > 0) {
            setNewDataAPI({ ...props.changedData })
        }
    }, [props.changedData])

    useEffect(() => {
        if (Object.keys(oldDataAPI).length != 0 && Object.keys(newDataAPI).length > 0) {
            delete oldDataAPI['calculation_rules'];
            delete oldDataAPI['eligibility_rules'];
            delete oldDataAPI['target_rules'];
            delete oldDataAPI['chargeback_calculation_rules'];
            delete oldDataAPI['created_by'];
            delete oldDataAPI['updated_by'];
            delete oldDataAPI['notes'];
            delete oldDataAPI['owned_by'];
            delete oldDataAPI['search_tokens'];
            delete oldDataAPI['Prototype'];
            delete oldDataAPI['created_at'];
            delete oldDataAPI['updated_at'];
            delete oldDataAPI['id'];
            delete newDataAPI['calculation_rules'];
            delete newDataAPI['eligibility_rules'];
            delete newDataAPI['target_rules'];
            delete newDataAPI['chargeback_calculation_rules'];
            delete newDataAPI['created_by'];
            delete newDataAPI['updated_by'];
            delete newDataAPI['notes'];
            delete newDataAPI['owned_by'];
            delete newDataAPI['search_tokens'];
            delete newDataAPI['Prototype'];
            delete newDataAPI['created_at'];
            delete newDataAPI['updated_at'];
            delete newDataAPI['id'];
            Object.keys(oldDataAPI).forEach(key => {
                if (oldDataAPI[key] !== newDataAPI[key]) {
                    console.log(newDataAPI[key])
                    setChangedObject(state => ({
                        ...state,
                        [key]: newDataAPI[key]
                    }))
                }
            });
        }
    }, [oldDataAPI, newDataAPI])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to={navParams.fromPage === 'Operational Reports' ? '/operational-reports' : '/contract-setup'}
                >
                    {navParams.fromPage ? 'Operational Reports' : 'Contract Setup'}
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Change Contract</Typography>
            </Breadcrumbs>

            <div className={classes.container}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Field Name</TableCell>
                                <TableCell align='center'>Changed Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(changedObject).map(([key, value]) => {
                                return (
                                    <StyledTableRow>
                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>  {key.replace(/_/g, ' ')}</TableCell>
                                        <TableCell align='center'>{value}</TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        oldData: state.addContractData.contractCompareData,
        changedData: state.addContractData.changedContractData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getcontractViewdetails: (id) => dispatch(getContractDetailsDataCompare(id)),
        changeRequestDetails: (id) => dispatch(changeRequestDetails(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestDetail);