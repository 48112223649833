import React, { useEffect } from 'react';
import {
    Typography, Button
} from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
//import { createPromotionScenario, getMaterialFilters, getModels, getPrefillScenarioList, getPromoStickers, runCostingSimulation } from '../../../../../redux/actions';
import { useHistory, useLocation } from "react-router-dom";
import { getMaterialFilters, getPrefillScenarioList, getPreviousScenarioList } from '../../../../redux/actions';
import FinanceApprove from './FinanceApprove';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
        height: '138vh'
    },

}));


const CostingApproval = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [previousScenarios, setPreviousScenarios] = React.useState([]);
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoStickerName, setPromoStickerName] = React.useState([])
    const [promoModelFilter, setPromoModelFilter] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [prefillId, setPrefillId] = React.useState('');

    const [promoData, setPromoData] = React.useState([])
    const [country, setCountry] = React.useState([]);
    const [colorPicker, setColorPicker] = React.useState({});
    const [models, setModels] = React.useState([]);
    const [promoLoc, setPromoLoc] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);
    const [ID, setID] = React.useState(0)
    const [activeStep, setActiveStep] = React.useState(0);
    const [stickernames, setStickerNames] = React.useState({});

    useEffect(() => {
        // const data = {
        //     table_name: "prefill_scenario"
        // }
        // props.getScenarioList(data);

        props.getFilters();
    }, []);
    useEffect(() => {
        if (productType) {
            var data = {
                "product_line": productType
            }
            props.getPreviousList(data, 'all')
        }
    }, [productType]);
    useEffect(() => {
        if (props.scenarioList)
            setPreviousScenarios(props.scenarioList)

    }, [props.scenarioList]);
    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setPromoDivisionArray(props.filterData['material_master'][0]['material_group'])
            setProductType(props.filterData['material_master'][0]['material_group'][0])
        }
    }, [props.filterData]);
    function handleGrid(value, field) {
        if (field == 'sticker_names') {
            setStickerNames(value)
            getRandomColor(value)
        }
        else
            setProductType(value)
    }
    function getRandomColor(stickers) {
        const letters = '0123456789ABCDEF';
        var temp = { ...colorPicker }
        stickers && stickers.map(item => {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            temp = { ...temp, [item]: color }
        }
        )
        setColorPicker(temp)

    }
    function handlePrefill() {

    }
    function handleChange() {

    }
    function handleReturn() {

    }
    return (
        <div>
            <div className={classes.root}>

                <div className={classes.instructions}>
                    <FinanceApprove
                        data={previousScenarios}
                        productLine={promoData.product_lines}
                        promoProductLines={promoDivisionArray}
                        handleGrid={handleGrid}
                        region={promoLoc}
                        definedData={defineMarket}
                        colorPicker={colorPicker}
                        stickers={promoStickerData}
                        modelYears={promoData.material_group1}
                        promoData={promoData}
                        page={'review-menu'}
                    />
                </div>
            </div>
        </div>
    );

};


const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        // runSimulation: (data) => dispatch(runCostingSimulation(data)),
        getScenarioList: (data) => dispatch(getPrefillScenarioList(data)),
        getPreviousList: (data, type) => dispatch(getPreviousScenarioList(data, type)),
        // getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        // onSubmit: (data, id, type) => dispatch(createPromotionScenario(data, id, type)),
        // onUpdate: (data, id, type) => dispatch(createPromotionScenario(data, id, 'edit')),
        // fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        // getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id, null, null, 'list')),
    }
}

const mapStateToProps = state => {
    return {
        scenario: state.promotionData.scenarioData,
        modelData: state.promotionData.modelFilters,
        //scenarioList: state.promotionData.prefillScenarioList,
        filterData: state.promotionData.promoFilters,
        stickersData: state.promotionData.promoStickers,
        loading: state.promotionData.loading,

        scenarioList: state.promotionData.currentScenario,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CostingApproval);