import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    Breadcrumbs, IconButton,
    Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { tableIcons } from '../../components/Icons/TableIcons';
import { HashLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import { runContractTrends, getCompareRebateCalculationData } from '../../redux/actions';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 10px 0px 10px',
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    buttonRoot: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    scrollableContent: {
        maxHeight: 100,
        overflowY: 'auto',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    }
}));

const ExpandableCell = ({ content }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const truncatedContent = content.length > 20 ? content.substring(0, 20) + '...' : content;
    const classes = useStyles();

    return (
        <div>
            <div>
                {expanded ? (
                    <div className={classes.scrollableContent}>
                        {content}
                    </div>
                ) : (
                    truncatedContent
                )}
                {content.length > 20 && (
                    <IconButton size="small" onClick={toggleExpanded}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                )}
            </div>
        </div>
    );
};

const CompareRebateCalculationResultTab = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([])
    const [count, setCount] = React.useState(0);
    const columns = [
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.contract_number}</a>
        },
        {
            field: 'billing_date',
            title: 'Billing Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {Moment.utc(rowData.billing_date).format('MM/DD/YYYY')}</a>
        },
        {
            field: 'billing_doc_number',
            title: 'Billing Doc Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.billing_doc_number}</a>
        },
        {
            field: 'billing_doc_line',
            title: 'Billing Doc Line',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.billing_doc_line}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.incentive_amount}</a>
        },
        {
            field: 'difference_type',
            title: 'Difference Type Data',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.difference_type}</a>
            // render: rowData => <ExpandableCell content={rowData.difference_type} />
        },

    ];
    // useEffect(() => {
    //     props.getCompareRebateCalculationData(JSON.parse(localStorage.getItem('compareRebateCalculationDatafilter')))
    // }, [])
    useEffect(() => {
        if (props.financialApprovalData && props.financialApprovalData.length > 0) {
            setDataRows(props.financialApprovalData)
            setCount(props.financialApprovalData.length)
        }
    }, [props.financialApprovalData])


    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/compare-rebate-calculation'
                >
                    Compare Rebate Calculation
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >


                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        // style={{marginTop:'-18px'}}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            // headerStyle: theme.mixins.MaterialHeader,
                            // cellStyle: theme.mixins.MaterialCell,
                            headerStyle: {
                                fontSize: '.90rem',
                                whiteSpace: 'nowrap',
                                padding: '5px 0px 5px 10px',
                                textAlign: 'center',
                                color: 'white',
                                borderRight: "1px solid #EEEEEE",
                            },
                            cellStyle: {
                                fontSize: '.90rem',
                                textAlign: "center",
                                borderRight: "1px solid #EEEEEE",
                                color: "black !important",
                                padding: '5px 0px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 400,
                                width: 80,
                                minWidth: 50,

                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addContractData.loading,
        financialApprovalData: state.addMultipleConfigurationData.compareRebateCalculationData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getCompareRebateCalculationData: (data) => dispatch(getCompareRebateCalculationData(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompareRebateCalculationResultTab);