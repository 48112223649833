import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Card,
    CardContent,
    CardActions,
    Button,
    Grid,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
    Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {
    runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports,
    runInvoiceReports, getQueryListAdmin, loadTargetData
} from '../../redux/actions';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 10,
        marginTop: 16,
        padding: theme.spacing(3),
    },
    title: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    faqWrapper: {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    accordionSummary: {
        backgroundColor: theme.palette.action.hover,
        borderBottom: '1px solid #e0e0e0',
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        }
    },
    accordionDetails: {
        backgroundColor: theme.palette.background.default,
    },
    question: {
        fontWeight: 'bold',
    },
    answer: {
        lineHeight: 1.6,
    }

}));
const FAQs = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedDescription, setSelectedDescription] = React.useState('');
    const [documentName, setDocumentName] = React.useState('');
    const faqSections = [
        {
            title: "Contract Setup FAQ",
            faqs: [
                {
                    question: "From what calculation period can I enter agreements in production?",
                    answer: "Sales and Master data is loaded into IMA360 from May 2024. An agreement cannot be calculated prior to this date."
                },
                {
                    question: "Can I enter any new agreement in IMA360?",
                    answer: "Not all functionality is available yet in IMA360, so new agreements should be reviewed with the project team before entering in IMA360."
                }
            ]
        },
        {
            title: "Rebate Calculation FAQ",
            faqs: [
                {
                    question: "What if I need to process a PPA for an agreement in IMA360?",
                    answer: `1) PPAs can be processed in IMA360 from go-live period forward. 
2) If the PPA is required prior to this time, the change to the agreement must also be completed in SAP/Vistex. SAP/Vistex will post the PPAs for evaluation periods prior to go-live.`
                }
            ]
        },
        {
            title: "Financial Postings FAQ",
            faqs: [
                {
                    question: "Will accruals and payments still be posted in SAP?",
                    answer: "Yes, this process will remain the same. Instead of Vistex posting the accrual and payments, IMA360 will post the accruals and payments to SAP."
                },
                {
                    question: "Can I reverse a payment once it has been sent to SAP?",
                    answer: "No, once the payment has been sent to SAP, an adjustment posting would be required."
                },
                {
                    question: "Can I still block check/EFT payments from going to the customer?",
                    answer: "Yes, you can still enter a K block on the payment to prevent the payment from being sent."
                }
            ]
        },
        // {
        //     title: "Reporting FAQ",
        //     faqs: [
        //         {
        //             question: "",
        //             answer: ``
        //         }
        //     ]
        // },
        // {
        //     title: "Security & Access FAQ",
        //     faqs: [
        //         {
        //             question: "",
        //             answer: ``
        //         }
        //     ]
        // },
        {
            title: "Cutover FAQ",
            faqs: [
                {
                    question: "What is required to move an agreement from SAP to IMA360?",
                    answer: `
1) Project team will do the initial set up of the agreement in IMA360.
2) Project team will run simulation results in IMA360 and PRD and share the results.
3) The business team will review agreement set up and simulation results prior to go-live.
4) Change status of agreement in IMA360 to Pending Manager Review.
5) Change valid To Date on cutover agreement to last day of active period.
6) Manager approves agreement change in SAP.
7) Manager approves agreement in IMA360.
                    `
                }
            ]
        },
        {
            title: "Support FAQ",
            faqs: [
                {
                    question: "How do I get help post-go live?",
                    answer: "You can contact our support team via the Help Desk or by emailing support@IMA360.com."
                },
                {
                    question: "What if I have a suggestion for an enhancement?",
                    answer: "You can submit your suggestions through the 'Suggestions' form available on the IMA360 portal."
                }
            ]
        }
    ];

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    
    const truncateDescription = (description) => {
        const words = description.split(' ');
        if (words.length <= 20) return description;
        return `${words.slice(0, 20).join(' ')}...`;
    };
    const handleReadMore = (item) => {
        setDocumentName(item.documentName);
        setSelectedDescription(item.reportDescription);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    

    return (
        <div className={classes.root}>
            <Typography variant="h1" color='primary' className={classes.title}>Frequently Asked Questions</Typography>
            <div className={classes.faqWrapper}>
                <Container maxWidth="false" style={{ padding: 0 }}>
                    {faqSections.map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h1" gutterBottom>
                                        {section.title}
                                    </Typography>
                                    {section.faqs.map((item, index) => (
                                        <Accordion key={index} className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                                                <Typography variant="h6" className={classes.question}>
                                                    {item.question}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Typography className={classes.answer} component="pre">
                                                    {item.answer}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </CardContent>
                            </Card>
                            {sectionIndex < faqSections.length - 1 && (
                                <Divider className={classes.sectionDivider} />
                            )}
                        </React.Fragment>
                    ))}
                </Container>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    // return {
    //     loading: state.operationalReportsData.loading,
    //     queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
    //     targetData: state.operationalReportsData.targetData
    // }
};
const mapDispatchToProps = dispatch => {
    // return {
    //     runQueryWithoutFilters: (data) => dispatch(runDynamicQuery(data)),
    //     loadContractDetails: (start, end, keyword) => dispatch(runContractReports(start, end, keyword)),
    //     loadContractOverlap: (start, end, keyword) => dispatch(runContractReports(start, end, keyword, 'overlap')),
    //     loadCalculationSimulation: () => dispatch(runCalculationReports(1, 10, '')),
    //     loadFinancialReports: (start, end, keyword) => dispatch(runFinancialReports(start, end, keyword)),
    //     loadInvoiceDetails: (start, end, keyword) => dispatch(runInvoiceReports(start, end, keyword)),
    //     // getQueryListAdmin: () => dispatch(getQueryListAdmin()),
    //     runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id))
    // }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);