import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Breadcrumbs,
} from '@mui/material';
import { connect } from 'react-redux';
import Moment from 'moment';
import { Paper } from '@mui/material';
import LoadingOverlay from "react-loading-overlay";
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));

const DuplicatePaymentTable = props => {
    let grid;
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const columns = [
        {
            field: 'functionality',
            title: 'Functionality',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.functionality}</a>
        },
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.contract_number}</a>
        },
        {
            field: 'description',
            title: 'Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.description}</a>
        },
        {
            field: 'start_date',
            title: 'Start Date',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.start_date ? Moment(rowData.start_date).format('MM/DD/YYYY') : ''}</a>

        },
        {
            field: 'end_date',
            title: 'End Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.end_date ? Moment(rowData.end_date).format('MM/DD/YYYY') : ''}</a>
        },
        {
            field: 'incentive_amount',
            title: 'Incentive Amount',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.incentive_amount}</a>

        },
        {
            field: 'currency',
            title: 'Currency',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.currency}</a>
        },
        {
            field: 'incentive_amount_variance',
            title: 'Incentive Amount Variance',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.incentive_amount_variance}</a>
        },
        {
            field: 'approval_group',
            title: 'Approval Group',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.approval_group}</a>
        },
        {
            field: 'approval_status',
            title: 'Approval Status',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.approval_status}</a>

        },
        {
            field: 'step_number',
            title: 'Step Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.step_number}</a>
        },
        {
            field: 'step_description',
            title: 'Step Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.step_description}</a>
        },
        {
            field: 'approved_by',
            title: 'Approved By',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.approved_by}</a>

        },
        {
            field: 'approved_date',
            title: 'Approved Date',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.approved_date}</a>
        },
        {
            field: 'approved_time',
            title: 'Approved Time',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}>{rowData.approved_time}</a>
        },
        // {
        //     field: 'application_type',
        //     title: 'Application Type',
        //     editable: 'never',
        //     type: 'string',
        //     render: (rowData) => <a className={classes.hover}> {rowData.application_type}</a>
        // },
        // {
        //     field: 'primary_anchor',
        //     title: 'Primary Anchor',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}> {rowData.primary_anchor}</a>
        // },
        // {
        //     field: 'primary_anchor_value',
        //     title: 'Primary Anchor Value',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}> {rowData.primary_anchor_value}</a>
        // },
        // {
        //     field: 'secondary_anchor',
        //     title: 'Secondary Anchor',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}> {rowData.secondary_anchor}</a>
        // },
        // {
        //     field: 'secondary_anchor_value',
        //     title: 'Secondary Anchor Value',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover}> {rowData.secondary_anchor_value}</a>

        // },
        // {
        //     field: 'transaction_id',
        //     title: 'Transaction ID',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover}> {rowData.transaction_id}</a>

        // },
        // {
        //     field: 'threshold_value',
        //     title: 'Threshold Value',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover}> {rowData.threshold_value}</a>

        // },
        // {
        //     field: 'incentive_threshold_type',
        //     title: 'Incentive Threshold Type',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}>{rowData.incentive_threshold_type}</a>
        // },
        // {
        //     field: 'negative_threshold_value',
        //     title: 'Negative Threshold Value',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}>{rowData.negative_threshold_value}</a>
        // },
        // {
        //     field: 'contract_type',
        //     title: 'Contract Type',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}>{rowData.contract_type}</a>
        // },

        // {
        //     field: 'calculation_start_date',
        //     title: 'Calculation Start Date',
        //     type: 'string',
        //     render: rowData => <a className={classes.hover}> {rowData.calculation_start_date ? Moment(rowData.calculation_start_date).format('MM/DD/YYYY') : ''}</a>

        // },
        // {
        //     field: 'calculation_end_date',
        //     title: 'Calculation End Date',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover}>{rowData.calculation_end_date ? Moment(rowData.calculation_end_date).format('MM/DD/YYYY') : ''}</a>
        // },
    ];
    useEffect(() => {
        if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
            setDataRows(props.queryResultsFinalData);
        }
    }, [props.queryResultsFinalData]);
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link variant='h4' classes={{
                            root: classes.link
                        }}
                            to='/approval-posting-history-resports'
                        >
                            Approval History
                        </Link>
                        <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
                    </Breadcrumbs>
                </div>
                {props.queryResultsFinalData && props.queryResultsFinalData.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );

};

const mapStateToProps = state => {
    return {
        queryResultsFinalData: state.operationalReportsData.approvalPostingHistoryReportsData,
        loading: state.operationalReportsData.loading
    }
};
export default connect(mapStateToProps, null)(DuplicatePaymentTable);