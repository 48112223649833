import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
    Button, IconButton,
    Typography,
    Tooltip
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { Edit, ContentCopy, History, ArrowLeft, ViewListOutlined } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import Moment from 'moment';
import '../Styles/custom.css'
import { StickerTableHeader } from '../GlobalSetup/Components';
import { getCustomGroups, getMaterialFilters } from '../../../redux/actions';
import { cloneCustomGroups, createArchive, getArchiveList } from '../../../redux/actions/Promotion/AuthAction';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    container: {
        backgroundColor: '#f5f5f5',
        marginLeft: 5,
        padding: 20,

    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },
    count: {
        border: '.7px solid #E8E8E8',
        backgroundColor: 'white',
        borderRadius: 18,
        padding: '0px 10px',
        marginBottom: 8,
    },
    historyButton: {
        cursor: 'pointer',
        border: '1px solid #D8D8D8',
        margin: '0px 10px',
        padding: 5,
        width: 35,
        height: 35,
    },
    header: {
        height: 50,
        marginLeft: 5,
        border: '1px solid #E8E8E8',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));


const CustomGroup = props => {
    const { className } = props;
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const passedProductLine = location && location.productLine;
    const isVisited = location && location.page;
    const [dataRows, setdataRows] = React.useState([]);
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [totalCount, setTotalCount] = React.useState(0);
    const [archive, setArchive] = React.useState(false);
    const columns = [
        {
            field: 'custom_group_name',
            title: 'CUSTOM GROUP NAME',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.custom_group_name}</a>,
            cellStyle: { minWidth: 320 },
        },
        {
            field: 'custom_group_description',
            title: 'CUSTOM GROUP DESCRIPTION',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.custom_group_description}</a>,
            cellStyle: { minWidth: 320 },
        },
        {
            field: 'custom_group_type',
            title: 'CUSTOM GROUP TYPES',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.custom_group_type && rowData.custom_group_type.replace(/\b\w/g, char => char.toUpperCase())}</a>,
        },
        {
            field: 'creator',
            title: 'CREATOR',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.creator}</a>,
        },
        {
            field: 'product_line',
            title: 'PRODUCT LINE',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.product_line}</a>

        },
        {
            field: 'update_at',
            title: 'LAST MODIFIED',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {Moment.utc(rowData.updated_at).local().format('MMM D YYYY, HH:mm')}</a>,
            customFilterAndSearch: (term, rowData) => {
                const formattedDate = Moment.utc(rowData.updated_at).local().format('MMM D YYYY, HH:mm');
                return formattedDate.includes(term);
            },
        },
        {
            field: 'approval_status',
            title: 'STATUS',
            type: 'string',
            render: (rowData) => <a className={classes.hover} > {rowData.approval_status}</a>,
        },
        {
            field: 'Actions',
            title: 'ACTIONS',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {rowData.approval_status !== 'Archived' &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} onClick={() => handleEdit(rowData)} />
                        </IconButton>}
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        size="large" >
                        <ContentCopy color="disabled" style={{ fontSize: 20 }} onClick={() => handleClone(rowData)} />
                    </IconButton>
                    {rowData.approval_status !== 'Archived' &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            size="large">
                            <History color="disabled" style={{ fontSize: 20 }} onClick={() => handleArchive(rowData)} />
                        </IconButton>}
                </div >
        },
    ];

    useEffect(() => {
        props.getFilters()
    }, []);

    useEffect(() => {
        if (passedProductLine)
            setProductType(passedProductLine)
    }, [passedProductLine]);

    useEffect(() => {
        if (productType) {
            if (archive)
                props.getArchive(productType)
            else
                props.getCustomGroup(null, productType)
        }
    }, [productType, archive]);


    useEffect(() => {
        var tempRows = [];
        if (!archive) {
            if (props.customGroupListData && props.customGroupListData.length > 0) {
                props.customGroupListData.map(e => {
                    tempRows.push({
                        id: e.id,
                        custom_group_name: e.custom_group_name, //? `${e.business_rule_name?.join("-")}- ${e.custom_group_name}` : e.business_rule_name?.join("-"),
                        custom_group_description: e.custom_group_description,
                        custom_group_type: e.custom_group_type,
                        product_line: e.product_line,
                        updated_at: e.updated_at,
                        creator: e.creator,
                        approval_status: e.approval_status,
                    });
                });

                setdataRows(tempRows);
                setTotalCount(props.customGroupListData ? props.customGroupListData.length : 0)
            } else {
                setdataRows([]);
                setTotalCount(props.customGroupListData ? props.customGroupListData.length : 0)
            }
        }
    }, [props.customGroupListData]);

    useEffect(() => {
        console.log(props.archivedData)
        var tempRows = [];
        if (archive) {
            console.log("arc")
            if (props.archivedData && props.archivedData.length > 0) {
                props.archivedData.map(e => {
                    tempRows.push({
                        id: e.id,
                        custom_group_name: `${e.business_rule_name?.join("-")}-${e.custom_group_name}`,
                        custom_group_description: e.custom_group_description,
                        custom_group_type: e.custom_group_type,
                        product_line: e.product_line,
                        updated_at: e.updated_at,
                        creator: e.creator,
                        approval_status: e.approval_status,
                    });
                });

                setdataRows(tempRows);
                setTotalCount(props.archivedData ? props.archivedData.length : 0)
            } else {
                setdataRows([]);
                setTotalCount(props.archivedData ? props.archivedData.length : 0)
            }
        }
    }, [props.archivedData]);
    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setProductLinesList(props.filterData['material_master'][0]['material_group']);
            if (props.filterData['material_master'][0]['material_group'] && !isVisited)
                setProductType(props.filterData['material_master'][0]['material_group'][0])
        }
    }, [props.filterData]);

    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleAdd() {
        history.push('/create-custom-group')
    }

    function handleEdit(item) {
        props.getCustomGroup(item.id)
    }

    function handleClone(e) {
        props.cloneCustomGroup(e.id, e.product_line)
    }

    function handleArchive(e) {
        props.createArchive(e.id, e.product_line)
    }

    function handleArchiveList() {
        setArchive(!archive)

    }

    const renderTableContent = (dataRows, columns) => {
        if (dataRows) {
            return (
                <>
                    <MaterialTable
                        components={{
                            Toolbar: (props) => <div style={{ height: '0px' }}></div>,
                        }}
                        title=" "
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginLeft: '6px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                </>
            );
        }
    };

    return (
        <div className={clsx(classes.root, className)}>

            <StickerTableHeader productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} stickerName={""} />
            <div className={classes.header}>
                <div style={{ marginTop: 8 }}>
                    <Button className='promo-font' style={{ marginLeft: 10, height: 33, }} variant="outlined" startIcon={<ArrowLeft />} onClick={() => history.push('/promotion-dashboard')}>
                        Return
                    </Button>
                </div>
                <div className={classes.row}>
                    <Button className='promo-font' variant="contained" style={{ height: 33, right: 20 }} onClick={handleAdd}>
                        Create Custom Group
                    </Button>
                    {!archive && <Tooltip title='Archive'><IconButton onClick={handleArchiveList}><History className={classes.historyButton} /></IconButton></Tooltip>}
                    {archive && <Tooltip title='List'><IconButton onClick={handleArchiveList}><ViewListOutlined className={classes.historyButton} /></IconButton></Tooltip>}
                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.heading}>
                    <Typography >{!archive ? 'BUSINESS RULE - CUSTOM GROUP LIST' :
                        'BUSINESS RULE - CUSTOM GROUP ARCHIVE '}
                    </Typography>
                    <Typography className={`promo-font ${classes.count}`}>{totalCount}{' Entries'}</Typography>
                </div>

                {renderTableContent(dataRows, columns)}
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        customGroupListData: state.promotionData.customGroups,
        filterData: state.promotionData.promoFilters,
        archivedData: state.promotionData.archiveList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomGroup: (id, productLine) => dispatch(getCustomGroups(id, productLine)),
        cloneCustomGroup: (id, productLine) => dispatch(cloneCustomGroups(id, productLine)),
        getArchive: (productline) => dispatch(getArchiveList(productline)),
        createArchive: (id, productline) => dispatch(createArchive(id, productline)),
        getFilters: () => dispatch(getMaterialFilters()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomGroup);