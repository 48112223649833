import React from 'react';
import {
    Typography,
    Grid
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {  ContractChangeReportsTab } from './containers';
// import { Dropdown, DropdownArray } from '../../components/Inputs';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        marginBottom: 23,
        marginTop: 14
    },
    dropdownContainer: {
        padding: 0,
        marginTop: -5,
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

const ContractChangesReports = props => {
    const classes = useStyles();
    const { className } = props;
    const [value, setValue] = React.useState(0);

    return (
            <> <ContractChangeReportsTab /> </>
    );

};

export default ContractChangesReports;