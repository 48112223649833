import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { uploadEmployeeTerritoryFile } from '../../redux/actions';
import { AddBoxOutlined, Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },

    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const EmployeeTerritoryExcelUpload = props => {

    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadEmployeeTerritoryFile(data);
        event.target.value = '';
    }

    function runQuery() {
        inputFile.current.click();
    }


    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Employee Master Territory Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => runQuery()}
                >
                    <CloudUpload color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                </Fab>
                {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => runQuery()}
                >
                    {isDesktop ? 'Upload' : ''}
                </Button> */}
                <input type='file'
                    accept=".xlsx, .xls, .csv"
                    id='file' ref={inputFile} style={{ display: 'none' }}
                    onChange={(e) => handleDrop(e)} />
            </div>

        </div >
    );
};



const mapDispatchToProps = dispatch => {
    return {
        uploadEmployeeTerritoryFile: (file) => dispatch(uploadEmployeeTerritoryFile(file)),

    }
}

export default connect(mapDispatchToProps)(EmployeeTerritoryExcelUpload);