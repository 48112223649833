import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    FormLabel,
    CardHeader,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel } from '../../../../components/Inputs';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        width: '100%',
        padding: '0px 45px 0px 45px !important'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const Ima360Data = (props) => {
    const classes = useStyles();
    const [claimResponseExcel, setClaimResponseExcel] = React.useState('');
    const [claimResponseEDI, setClaimResponseEDI] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState("");
    const [flexAttribute2, setFlexAttribute2] = React.useState("");
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    useEffect(() => {
        props.onChange(formData);
    });
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    var formData = {
        claim_reponse_excel_format: claimResponseExcel,
        claim_reponse_edi_format: claimResponseEDI,
        email_address: emailAddress,
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4
    }
    //functions
    function handleEmailAddress(newValue) {
        setEmailAddress(newValue);
    }
    function handleClaimResponseExcel(newValue) {
        setClaimResponseExcel(newValue);
    }
    function handleClaimResponseEDI(newValue) {
        setClaimResponseEDI(newValue);
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
        // props.onChange(formData);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
        // props.onChange(formData);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue);
    }
    return (
        <div
            className={classes.root}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <CardHeader
                            title="IMA360 DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <Grid container spacing={2}
                            style={{ marginBottom: 16 }}
                        >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['email_address'] ? fieldLabel['email_address']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['email_address'] ? fieldLabel['email_address']['current'] : 'Email Address'}
                                        </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleEmailAddress} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['claim_reponse_excel_format'] ? fieldLabel['claim_reponse_excel_format']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['claim_reponse_excel_format'] ? fieldLabel['claim_reponse_excel_format']['current'] : 'Claim Reponse Excel Format'}
                                        </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleClaimResponseExcel} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['claim_reponse_edi_format'] ? fieldLabel['claim_reponse_edi_format']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['claim_reponse_edi_format'] ? fieldLabel['claim_reponse_edi_format']['current'] : 'Claim Reponse EDI Format'}
                                        </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleClaimResponseEDI} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                        </FormLabel>
                                <TextInputWithLabel
                                    classes={{ root: classes.fontSetting }}
                                    twoline="true"
                                    onChange={handleFlexAttribute1}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                        </FormLabel>
                                <TextInputWithLabel
                                    classes={{ root: classes.fontSetting }}
                                    twoline="true"
                                    onChange={handleFlexAttribute2}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                        </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleFlexAttribute3} />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                 <FormLabel classes={{ root: classes.formLable }}
                                        required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}
                                        style={{ marginTop: 7 }}
                                        >
                                         {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}
                                        </FormLabel>
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline='true' onChange={handleFlexAttribute4} />
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );
};
const mapDispatchToProps = dispatch => {
    return {
        // onLoadingDefault: () => dispatch(getDefaultValuesCustomer())
    }
}
const mapStateToProps = state => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps, null)(Ima360Data);