import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TablePagination,
    Button, IconButton, Fab
} from '@mui/material';

import { Edit, DeleteForever, AddBoxOutlined, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getQualifierConfig, createQualifierConfig } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import secureLocalStorage from 'react-secure-storage';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const AttributeMapping = props => {
    useEffect(() => {
        props.getConfigData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [configData, setConfigData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getConfigData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getConfigData(1, event.target.value);
    };

    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.configData && props.configData.records) {
            setConfigData(props.configData.records);
        }
    }, [props.configData]);

    function editAttribute(id) {
        history.push({
            pathname: '/pricing-qualifier-config/edit-qualifier-config/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.onDelete(id);
    }


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Pricing Attributes </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_pricing_attributes-' + sessionStorage.getItem('application')) &&
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            // component={CustomRouterLink}
                            onClick={() => history.push('/pricing-qualifier-config/add-qualifier-config')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>}
                </div>
                {configData ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Qualifier Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Qualifier Label </TableCell>

                                    <TableCell align='center' className={classes.tabHead}>External Qualifier Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead} /*style={{width: 70}}*/>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {configData.map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.qualifier_key.replace(/_/g, ' ')}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.qualifier_label}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.external_qualifier_name.replace(/_/g, ' ')}</TableCell>
                                            <TableCell align='center' style={{ display: 'flex' }}>
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editAttribute(item.ID)}
                                                        size="large">
                                                        <Edit color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_pricing_attributes-' + sessionStorage.getItem('application')) &&
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => deleteData(item.ID)}
                                                        size="large">
                                                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.configData && props.configData.records? props.configData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                        {props.attributeData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        configData: state.pricingData.qualifierConfigData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit) => dispatch(getQualifierConfig(pagination, limit)),
        onDelete: (id) => dispatch(createQualifierConfig(null, id, 'delete'))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeMapping);