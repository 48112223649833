import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import Chart from "react-apexcharts";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10
    },
    chartContainer: {
        marginTop: 30,
        height: 300
    },
    formLabel: {
        width: '100%',
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: theme.typography.h1.fontSize,
        display: 'flex',
        justifyContent: 'center',

    },
}));

const PromotionBudgetRevenu = props => {
    const classes = useStyles();
    const [series, setSeries] = React.useState([]);
    const [options, setOptions] = React.useState({});

    useEffect(() => {
        var categories = [];
        var costPercentage = [];
        if (props.promoAnalyCost && props.promoAnalyCost.length > 0) {
            props.promoAnalyCost.map(item => {
                costPercentage.push(item['cost_percentage']);
                categories.push(item['month']);
            })

            setSeries([
                {
                    name: 'Cost Percentage',
                    data: costPercentage
                },
            ]);

            setOptions(
                {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    colors: ['#66E64D'],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: categories
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: undefined,
                        width: 2,
                        dashArray: 0,
                    },
                    fill: {
                        opacity: 1
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return val.toLocaleString()
                            }
                        }
                    },
                }
            )
        }

    }, [props.promoAnalyCost])

    return (

        <div className={classes.root}>
            <Typography color="primary" className={classes.formLabel}> Monthly Trend by Cost to Revenue </Typography>
            <div className={classes.chartContainer}>
                {
                    series && series.length > 0 && options && Object.keys(options) && Object.keys(options).length > 0 &&
                    <Chart options={options} type="bar" series={series} height={300} />
                }

            </div>
        </div>
    );
};

export default PromotionBudgetRevenu;