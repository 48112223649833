import React, { useEffect } from 'react';
import {
    Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { createPromotionScenario, getGroupedCustomGroups, getMaterialFilters, getModels, getPrefillScenarioList, getPromoStickers, runCostingSimulation } from '../../../../../redux/actions';
import { useHistory, useLocation } from "react-router-dom";
import { ReviewScenario } from '../../../ReviewScenario';
import GridMain from './GridMain';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
        height: '138vh'
    },

}));


const Grid = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    var mode = location.data && location.data.page ? location.data.page : 'add';
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [previousScenarios, setPreviousScenarios] = React.useState([]);
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoStickerName, setPromoStickerName] = React.useState([])
    const [promoModelFilter, setPromoModelFilter] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [prefillId, setPrefillId] = React.useState('');
    const [prefillData, setPrefillData] = React.useState([])
    const [promoData, setPromoData] = React.useState([])
    const [country, setCountry] = React.useState([]);
    const [colorPicker, setColorPicker] = React.useState({});
    const [models, setModels] = React.useState([]);
    const [promoLoc, setPromoLoc] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);
    const [ID, setID] = React.useState(0)
    const [activeStep, setActiveStep] = React.useState(0);
    const [stickerKeys, setStickerKeys] = React.useState({});
    const [customGroupData, setCustomGroupData] = React.useState({});
    const [type, setType] = React.useState('');
    useEffect(() => {
        const data = {
            table_name: "prefill_scenario"
        }
        props.getScenarioList(data);
        props.getFilters();
    }, []);
    useEffect(() => {
        // Prevent browser's default back/forward navigation when the page loads
        window.history.pushState(null, null, window.location.pathname);

        const handlePopState = (event) => {
            // Prevent back/forward navigation by re-pushing the current state
            window.history.pushState(null, null, window.location.pathname);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const preventSwipeNavigation = (event) => {
            if (event.deltaY < 0 || event.deltaX < 0) {
                // Push the state again if scrolling up or to the left
                window.history.pushState(null, null, window.location.pathname);
            }
        };
        // Add the event listener for scroll events to detect swiping
        window.addEventListener('wheel', preventSwipeNavigation);
        return () => {
            window.removeEventListener('wheel', preventSwipeNavigation);
        };
    }, []);

    useEffect(() => {
        var loc = promoData.promo_location?.location ? Object.keys(promoData.promo_location.location) : []
        if (promoData.promotion_type && promoData.product_lines && promoData.material_group1 && loc) {
            var data = {
                "promotion_type": promoData.promotion_type,
                "product_lines": promoData.product_lines,
                'model_year': promoData.material_group1,
                'location': loc
            }
            props.getCustomGroups(data)
        }
    }, [promoData && promoData.promotion_type && promoData.product_lines && promoData.material_group1 && promoData.promo_location])

    useEffect(() => {
        if (props.scenarioList)
            setPreviousScenarios(props.scenarioList)

    }, [props.scenarioList]);

    useEffect(() => {
        if (props.modelData && props.modelData.length > 0) {
            setModels(props.modelData)
        }
    }, [props.modelData]);

    useEffect(() => {
        if (props.customGroups) {
            setCustomGroupData(props.customGroups)
        }
    }, [props.customGroups]);
    useEffect(() => {
        if (props.stickersData && props.stickersData.records && stickerKeys) {
            var data = {}
            props.stickersData.records.map((item, i) => {
                var sKey = stickerKeys[item.ID]?.name
                data = { ...data, [sKey]: { ...item, 'name': sKey } }
            })
            setPromoStickerData(data);
        }
    }, [props.stickersData, stickerKeys]);

    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0]) {
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
            setPromoDivisionArray(props.filterData['material_master'][0]['material_group'])
        }
    }, [props.filterData]);

    useEffect(() => {
        if (props.scenario && prefillId) {
            setDefineMarket(props.scenario.promotion_grids)
            setPromoModelFilter(props.scenario.promotion_display)
            setPrefillData(props.scenario)
            setPromoData(props.scenario)
            setID(props.scenario.ID)
            setCountry(props.scenario.country)
            setStickerKeys(props.scenario.selected_stickers)
            handleCheckedLoc(props.scenario.promo_location?.location && Object.values(props.scenario.promo_location.location))

            if (props.scenario.selected_stickers && Object.keys(props.scenario.selected_stickers).length > 0) {
                getRandomColor(props.scenario.selected_stickers)
                props.getStickers(props.scenario.product_lines, props.scenario.country, Object.keys(props.scenario.selected_stickers))
            }
            else {
                toast.warn("Selected scenario does not have any stickers")
            }

        }
        else {
            setDefineMarket([])
            setPromoModelFilter([])
            setPrefillData([])
            setPromoData([])
        }
    }, [props.scenario])

    useEffect(() => {
        if (promoData && promoData.material_group1 && promoData.material_group1.length > 0 && promoData.product_lines) {
            var model = {
                table_name: 'material_master',
                filter: {
                    material_group1: promoData.material_group1,
                    material_group: promoData.product_lines
                }
            }
            props.getFilterData('material_master', model)
        }
    }, [promoData && promoData.material_group1])

    useEffect(() => {
        if (defineMarket && Object.entries(defineMarket).length > 0 && promoData.promotion_start_date && promoData.promotion_end_date
            && stickerKeys && Object.keys(stickerKeys).length > 0 &&
            promoData.promo_location && promoData.material_group1 && promoData.country && promoData.promotion_currency && promoData.product_lines) {
            var data = {
                "promotion_start_date": promoData.promotion_start_date,
                "promotion_end_date": promoData.promotion_end_date,
                "promotion_grids": defineMarket,
                "promo_location": promoData.promo_location,
                "material_group1": promoData.material_group1,
                "country": promoData.country,
                "promotion_currency": promoData.promotion_currency,
                "product_lines": promoData.product_lines,
                "selected_stickers": stickerKeys,
            }
            props.runSimulation(data)
        }
    }, [defineMarket, refresh, promoData.promotion_start_date, promoData.promotion_end_date, promoData.material_group1])

    function getRandomColor(stickers) {
        const letters = '0123456789ABCDEF';
        var temp = { ...colorPicker }
        stickers && Object.entries(stickers).filter(([key, value]) => !(colorPicker && colorPicker[value.name])).map(([key, value]) => {
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            temp = { ...temp, [value.name]: color }
        }
        )
        setColorPicker(temp)
    }

    function handleCheckedLoc(selected) {
        // setSelectedLocItems(selected)
        const array2 = [];
        selected && selected.forEach(obj => {
            const { country, region, state } = obj;
            const existingCategoryA = array2.find(item => item.category === region);
            if (existingCategoryA) {
                existingCategoryA.children.push({ category: state, type: 'state', relationship: { country: country, region: region, state: state }, children: [] });

            } else {
                array2.push({
                    category: region, type: 'region',
                    relationship: { country: country, region: region },
                    children: [{
                        category: state, type: 'state',
                        relationship: { country: country, region: region, state: state },
                        children: []
                    }]
                });
            }
        });
        setPromoLoc([...array2])
    };

    function handlePrefill(id) {
        setPrefillId(id);
        if (id)
            props.fetchScenario(id, 'prefillGrid')
    }

    var scenarioData = {
        ...promoData,
        "country": promoData.country,
        "selected_stickers": stickerKeys,
        "promotion_grids": defineMarket,
        "promotion_display": promoModelFilter
    }
    function handleSubmit(type) {
        console.log(type)
        setType(type)
        var data = {
            ID: ID,
            ...scenarioData
        }
        if (type == 'review') {
            props.onUpdate(data, ID, type, type)
            handleNext()
        }
        else {
            props.onUpdate(data, ID, 'edit', type)
        }
        // }
        // else
        // props.onSubmit(scenarioData, null, 'create')
    }
    const handleNext = () => {
        const newActiveStep = activeStep + 1
        setActiveStep(newActiveStep);

    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function handleGrid(type) {
        if (type == 'refresh')
            setRefresh(refresh + 1)
        else if (type == 'return')
            history.push('/#')
        else if (type == 'review')
            handleSubmit(type)
        else if (type == 'grid')
            handleBack()
        else
            handleSubmit()
    };
    function handleDefine(value, page, stickerId) {
        console.log(value)
        if (value) {
            setDefineMarket(prev => ({
                ...prev, ...value.grid_data
            }))
            setPromoModelFilter(prev => ({
                ...prev, ...value.display_data
            }))
        }
    }
    function getStepContent(step) {
        const allSteps = [
            <GridMain
                promoProductLines={promoDivisionArray}
                productLine={promoDivisionArray}
                data={previousScenarios}
                prefill={handlePrefill}
                country={promoData.country}
                definedData={defineMarket}
                stickers={promoStickerData}
                colorPicker={colorPicker}
                locations={promoLoc}
                modelYears={promoData.material_group1}
                modelData={models}
                gridFilter={promoModelFilter}
                currency={promoData.promotion_currency}
                handleGrid={handleGrid}
                // productLine={promoData.product_lines}
                page={'grid-menu'}
                handleDefine={handleDefine}
                customGroup={customGroupData}
            />,
            ...((ID && type == 'review') ? [<ReviewScenario productLine={promoData.product_lines}
                promoProductLines={promoDivisionArray}
                region={promoLoc}
                promoData={promoData}
                definedData={defineMarket}
                handleGrid={handleGrid}
                colorPicker={colorPicker}
                modelYears={promoData.material_group1}
                stickers={promoStickerData}
                scenarioID={ID}
                page={'grid-menu'}
            />] : [])]
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }
    return (
        <div>
            <div className={classes.root}>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            </div>
        </div>
    );

};


const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        runSimulation: (data) => dispatch(runCostingSimulation(data)),
        getScenarioList: (data) => dispatch(getPrefillScenarioList(data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter')),
        onSubmit: (data, id, type) => dispatch(createPromotionScenario(data, id, type)),
        onUpdate: (data, id, type, page) => dispatch(createPromotionScenario(data, id, 'edit', page)),
        fetchScenario: (id, page) => dispatch(createPromotionScenario(null, id, 'fetch_id', page)),
        getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id, null, null, 'list')),
        getCustomGroups: (promotype, productLine) => dispatch(getGroupedCustomGroups(promotype, productLine))
    }
}

const mapStateToProps = state => {
    return {
        scenario: state.promotionData.scenarioData,
        modelData: state.promotionData.modelFilters,
        scenarioList: state.promotionData.prefillScenarioList,
        filterData: state.promotionData.promoFilters,
        stickersData: state.promotionData.promoStickers,
        loading: state.promotionData.loading,
        customDetails: state.promotionData.customGroupList
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Grid);