import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '20px 30px 20px 30px',
        borderRadius: 10,
        marginLeft: 7,
        marginRight: 7
    },
    select: {
        fontSize: 13
    },
    chartContainer: {
        marginTop: 30,
        height: 250
    }
}));

const LineChartGraph = props => {
    const classes = useStyles();
    var series = [{
        name: 'Invoice Price %',
        data: [89.85, 90.05, 90.05, 85.17]
    },
    {
        name: 'Off-Invoice Price %',
        data: [76.75, 76.45, 75.25, 74.28]
    },
    {
        name: 'Margin %',
        data: [15.05, 15.15, 14.85, 13.95]
    }]
    var options = {
        chart: {
            type: 'line',
        },
        stroke: {
            width: 5,
            curve: 'smooth'
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr'],
            tickAmount: 10,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                gradientToColors: ['#FDD835'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
            },
        },
    }


    return (
        <div className={classes.root}>
            <Typography variant="h2" >  Line</Typography>
            <Chart options={options} series={series} type="line" height={300} />
        </div >
    );
};

export default connect(null, null)(LineChartGraph);