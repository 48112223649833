import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getMembershipDetails,
    deleteMembership
} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { MembershipToolbar } from './';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 20
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
}));

const MembershipSecondUserTable = props => {
    const { className } = props;
    const method = props.type
    const memberID = props.memberId
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    useEffect(() => {
        if (props.membershipData && props.membershipData.records && props.membershipData.records.length > 0) {
            setDataRows(props.membershipData.records);
            setDataCount(props.membershipData.total_record)
        }
    }, [props.membershipData]);

    function editMember(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/membership/edit-member/' + id,
            id: id
        });
    }
    function viewMembership(id) {
        props.getMembershipDetails(id);
        history.push({
            pathname: '/membership/view-member/' + id,
            id: id
        });
    }


    const columns = [

        {
            field: 'customer_number',
            title: 'Customer Number',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.customer_number}</a>
        },
        {
            field: 'member_name',
            title: 'Member Name',
            type: 'string',
            editable: 'never',
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE', whiteSpace: 'nowrap', width: '40px'
            },
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.member_name}</a>
        },
        {
            field: 'direct_parent_customer_number',
            title: 'Direct Parent Customer',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.direct_parent_customer_number}</a>
        },
        {
            field: 'top_parent_customer_number',
            title: 'Top Parent Customer',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.top_parent_customer_number}</a>
        },
        {
            field: 'relationship_to_top_parent',
            title: 'Relationship to Top Parent',
            type: 'string',
            editable: 'never',
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE', cellWidth: '10px'
            },
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.relationship_to_top_parent}</a>
        },

        {
            field: 'street',
            title: 'Street',
            type: 'string',
            cellStyle: {
                fontSize: 13,
                textAlign: 'center',
                borderRight: '1px solid #EEEEEE', whiteSpace: 'nowrap', width: '40px'
            },
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.street}</a>

        },
        {
            field: 'city',
            title: 'City',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.city}</a>
        },
        {
            field: 'state',
            title: 'State',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.state}</a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.country}</a>
        },
        {
            field: 'postal_code',
            title: 'Postal Code',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.postal_code}</a>
        },
        {
            field: 'member_status',
            title: 'Member Status',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.member_status}</a>
        },
        {
            field: 'member_start_date',
            title: 'Start Date',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.member_start_date != '0001-01-01T00:00:00Z' ? Moment(rowData.member_start_date).format('MM/DD/YYYY') : ''}</a>
        },
        {
            field: 'membership_id_type',
            title: 'Member ID Type',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.member_id_type[0]}</a>
        }, {
            field: 'membership_id',
            title: 'Member ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.member_id_number[0]}</a>
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            hidden: method,
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editMember(rowData.ID)}
                        size="large">
                        <Edit color="disabled" classes={{ root: classes.caption }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => props.deleteMembershipData(rowData.ID, memberID)}
                        size="large">
                        <DeleteForever color="disabled" classes={{ root: classes.caption }} />
                    </IconButton>
                </div>

        },
    ];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h2"> Members Details </Typography>
                    <MembershipToolbar />
                </div>
                {props.membershipData && props.membershipData.records && props.membershipData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}

                        options={{
                            search: false,
                            filtering: true,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 20,
                            pageSizeOptions: [10, 20, 50, { value: dataCount, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        membershipData: state.customerData.membershipDetailsData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipDetails: (id, ownerId) => dispatch(getMembershipDetails(id, ownerId)),
        deleteMembershipData: (id, memberID) => dispatch(deleteMembership(id, null, memberID)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MembershipSecondUserTable);