import React from 'react';
import {
    Card,
    Grid,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Typography,

} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const OrganizationData = props => {
    const classes = useStyles();
    const { className } = props;

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <div className={classes.container}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.materialMasterData && props.materialMasterData.product_features && props.materialMasterData.product_features.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'className={classes.tabHead}>Product Features</TableCell>
                                                <TableCell align='center'className={classes.tabHead}>Product Feature Values</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {props.materialMasterData.product_features.map((item) => {
                                                return (
                                                    <StyledTableRow key={item.ID}>
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}> {item.product_feature}</TableCell>
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}> {item.product_feature_values}</TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                  There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData
    }
}
export default connect(mapStateToProps)(OrganizationData);